const { Model } = require('Backbone');
const FilterableCommunityController = require('@common/components/filterableCommunity/FilterableCommunityController');
const CommunityCollectionView = require('@common/components/filterableCommunity/CommunityCollectionView');
const RecentCommunityList = require('@common/data/collections/RecentCommunityList');
const KeyboardNavigationHelper = require('@common/components/filterableCommunity/KeyboardNavigationHelper');
const { CommunityItemView } = require('@common/components/filterableCommunity/CommunityItemView');

class PostCreateFilterableCommunityController extends FilterableCommunityController {
  initialize(options = {}) {
    super.initialize(options);

    ({
      recentGroupLabel: this.recentGroupLabel = 'components.communityPicker.heading.recentCommunities',
      allGroupLabel: this.allGroupLabel = 'components.communityPicker.heading.allCommunities'
    } = options);

    this.recentCommunityList = new RecentCommunityList();
    this.fetchRecentDataOptions = new Model({
      pageType: this.pageType,
      communityAction: this.communityAction,
      communityType: options.communityType || 'folder'
    });

    this.keyboardNavigationHelper = new KeyboardNavigationHelper(
      this.filteredCommunityList,
      this._switchDropdown,
      this._setCommunity,
      this.recentCommunityList
    );
  }

  // Needed to override this function only to add the .recent-communities piece. Maybe there are better ways to do this.
  // See FilterableCommunityController super class for more.
  _getTemplate() {
    return `<form class="filterable-community">
      <% if (this.label) { %>
        <label>${ this.label }</label>
      <% } %>
      <div class="js-filter-controls filter-controls"></div>
      <div class="js-dropdown-menu dropdown-menu hidden">
        <div class="recent-communities">
          <h5>${ this.recentGroupLabel }</h5>
          <div class="js-recent-communities-list">
          </div>
        </div>
        <div class="filtered-communities">
          <h5>${ this.allGroupLabel }</h5>
          <div class="js-filtered-communities-list">
          </div>
        </div>
      </div>
    </form>`;
  }

  viewDefinition() {
    const viewDefinition = super.viewDefinition();
    viewDefinition.regions.recentList = '.js-recent-communities-list';
    return viewDefinition;
  }

  regionControllers() {
    return Object.assign(super.regionControllers(), {
      recentList: {
        viewDefinition: {
          ViewClass: CommunityCollectionView,
          collection: this.recentCommunityList,
          childView: CommunityItemView,
          fetchDataOptions: this.fetchRecentDataOptions,
          noResultsHandler: this._updateRecentStatus.bind(this),
          childViewOptions: {
            setCommunity: this._setCommunity
          }
        }
      }
    });
  }

  _onChangeCommunity(article, community) {
    if (!this.contextModel.get('community')) {
      this._updateFilter('');
      this.fetchDataOptions.set('reset', true);
      this.fetchRecentDataOptions.set('reset', true);
    } else if (this._isCommunitySelected(community)) {
      // When a community is selected, reset the search query so the search results are not preserved
      this._clearQuery();
    }

    this.trigger('change:community', community);
  }

  _updateRecentStatus() {
    if (this.isSearching || this.recentCommunityList.length === 0) {
      $('.recent-communities').hide();
    } else {
      $('.recent-communities').show();
    }
  }

  _updateFilter(query = '', isSearching = false) {
    super._updateFilter(query, isSearching);
    this._updateRecentStatus();
  }

  _switchDropdown(isExpanded) {
    super._switchDropdown(isExpanded);

    if (isExpanded) {
      this._updateRecentStatus(this.isSearching);
    }
  }

  _clearQuery() {
    this.isSearching = false;
    this._updateRecentStatus();
    this.fetchDataOptions.set({
      query: '',
      reset: true
    });
  }
}

module.exports = PostCreateFilterableCommunityController;
