const _ = require('underscore');
const { ItemView } = require('Marionette');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const KeyboardNavigationScrollHelper = require('@common/components/filterableCommunity/KeyboardNavigationScrollHelper');

class CommunityItemView extends ItemView {
  tagName() {
    return 'li';
  }

  className() {
    const highlight = this.model.get('highlight') ? ' highlight' : '';
    return `ax-grid ax-grid--no-gutter${ highlight }`;
  }

  getTemplate() {
    return `<div class="js-community-icon community-icon ax-grid__col--auto-size" aria-hidden="true"></div><div class="community-name qa-community-name ax-grid__col--grow-2"><%- name %></div>`;
  }

  templateHelpers() {
    return {
      name: this.model.getName()
    };
  }

  modelEvents() {
    return {change: 'updateSelectedClass'};
  }

  events() {
    return {
      mousedown: '_setCommunity'
    };
  }

  ui() {
    return {
      communityIcon: '.js-community-icon'
    };
  }

  initialize(options = {}) {
    ({ setCommunity: this.setCommunity } = options);
    this.keyboardNavigationScrollHelper = new KeyboardNavigationScrollHelper();
  }

  onRender() {
    this.getCommunityIcon();
    this.updateSelectedClass();
  }

  updateSelectedClass() {
    this.keyboardNavigationScrollHelper.adjustDropdownScrollTop(this.$el.closest('.js-dropdown-menu'), this.$el);
    this.$el.attr('class', _.result(this, 'className'));
  }

  getCommunityIcon() {
    const media = this.model.get('thumbnailImage');
    const communityIcon = this.ui.communityIcon;

    if (media != null) {
      const imageViewer = ImageViewerFactory.createViewerInstance({
        $el: communityIcon,
        maxWidth: 20,
        maxHeight: 20,
        media
      });

      imageViewer.render();
    } else {
      communityIcon.addClass('icon-folder_open');
    }
  }

  _setCommunity() {
    this.setCommunity(this.model);
  }
}

class CommunityItemEmptyView extends ItemView {
  get tagName() {
    return 'li';
  }

  get className() {
    return 'empty-group-item';
  }

  //alert screenreader users that search has no results
  attributes() {
    return {
      'aria-live': 'assertive'
    };
  }

  getTemplate() {
    return `
      <p><%- t('components.communityPicker.noResults') %></p>
    `;
  }
}

module.exports = {
  CommunityItemView,
  CommunityItemEmptyView
};
