// Module
var code = `<td width="15%" class="code-text-break">
  <span><%- communityCode %></span>
</td>
<td width="auto">
  <span><%- communityName %></span>
</td>
<% if (isCommsEnabled) { %>
  <td width="15%">
    <span><%- communityAccessType %></span>
  </td>
<% } %>
<td width="15%">
  <span><%- communityStatus %></span>
</td>
<td width="15%">
  <% if (canEditSettings) { %>
    <button class="js-settings-redirect settings-redirect" title="<%- t('general.settings') %>" aria-label="<%- communitySettingsLabel %>">
      <span class="icon-settings"></span>
    </button>
  <% } %>

  <% if (!communityIsArchived) { %>
    <button class="js-community-navigate community-navigate" title="<%- goToCommunityLinkTitle %>" aria-label="<%- goToCommunityLinkTitle %>">
      <span class="icon-external_link"></span>
    </button>
  <% } %>

  <% if (canArchive) { %>
    <% if (communityIsArchived) { %>
      <button class="js-restore-community restore-community" title="<%- t('communitiesManagement.community.restoreIcon') %>" aria-label="<%- communityRestoreLabel %>">
        <span class="icon-refresh"></span>
      </button>
    <% } else { %>
      <button class="js-archive-community archive-community" title="<%- t('communitiesManagement.community.archiveIcon') %>" aria-label="<%- communityArchiveLabel %>">
        <span class="icon-archive"></span>
      </button>
    <% } %>
  <% } %>

  <% if (canDelete) { %>
    <button class="js-delete-community settings-redirect" title="<%- t('general.delete') %>" aria-label="<%- communityDeleteLabel %>">
      <span class="delete icon-trash"></span>
    </button>
  <% } %>
</td>
`;
// Exports
module.exports = code;