const MetadataView = require('@training/apps/articles/MetadataView');
const dateHelpers = require('@common/libs/dateHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const PageType = require('@common/components/discover/enums/PageType').default;
const I18n = require('@common/libs/I18n');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

class ArticleDetailsMetadataView extends MetadataView {
  initialize(options = {}) {
    super.initialize(options);
  }

  onRender() {
    super.onRender();

    const expiry = this.model.get('expiry');
    if (this.shouldShowExpiryInfo() && expiry.author != null) {
      const $profileElement = this.$(`.page-preview-meta-content__avatar[data-contributor-id='${ expiry.author.id }']`);
      this._getProfileIcon(expiry.author, $profileElement);
    }

    this.renderEditList();
  }

  _getProfileIcon(user, $profileElement) {
    return new ProfileIconHandler({
      user,
      profileElement: $profileElement
    });
  }

  shouldShowBundledLanguageChooser() {
    return this.isAdmin() && !this.isTrainingModule();
  }

  isTrainingModule() {
    return !this.model || this.model.get('type') === PageType.TRAINING;
  }

  isAdmin() {
    return window.apps.auth.session.user.isAdminUser();
  }

  canPublish() {
    return !this.model || ( this.model.canPublish() && this.model.get('community').canPublish());
  }

  shouldShowInsights() {
    return TenantPropertyProvider.get().getProperty('communicationsEnabled') && this.model.get('initialPublishTimestamp');
  }

  canEditPage() {
    return this.model.canEdit() && !this.isLocked();
  }

  shouldShowExpiryInfo() {
    return this.model.canEdit() && this.model.get('expiry') != null;
  }

  createDate(timestamp) {
    return dateHelpers.convertDateFormatToDefaultDate(timestamp);
  }

  getTemplate() {
    return require('@training/apps/articles/ArticleDetailsMetadataViewTemplate.html');
  }

  templateHelpers() {
    const superHelpers = super.templateHelpers();

    Object.assign(superHelpers, {
      getLastUpdatedDate: () => {
        let formattedDate = '';
        const timeStamp = this.model.get('modifyTimestamp');
        if (timeStamp) {
          formattedDate = dateHelpers.timeFromEvent(timeStamp);
        }

        return formattedDate;
      },
      canEditPage: this.canEditPage.bind(this),
      createDate: this.createDate.bind(this),
      shouldShowInsights: this.shouldShowInsights.bind(this),
      shouldShowBundledLanguageChooser: this.shouldShowBundledLanguageChooser.bind(this),
      shouldShowExpiryInfo: this.shouldShowExpiryInfo.bind(this),
      editButtonText: I18n.t(`discover.pageTypes.${ this.model.get('type') }.actionText.edit`)
    });

    return superHelpers;
  }

  regions() {
    return {
      languageChooserRegion: '.js-language-chooser-region',
      deleteActionRegion: '.delete__action'
    };
  }

  isLocked() {
    return this.model.isLocked(apps.auth.session.user.id);
  }

  toggleReportButton() {
    this.ui.reportContainer.toggleClass('hidden');
  }

  toggleEditControls() {
    this.ui.editButton.toggleClass('hidden');
    this.ui.shareLink.toggleClass('hidden');
    this.ui.showHistory.toggleClass('hidden');
  }

  onViewPdfClicked(attachmentItemView) {
    // user wants to view the pdf, so let's show it
    // get media id
    const mediaId = attachmentItemView.model.get('id');

    // go to that view
    this.triggerMethod('click:pdfAttachment', mediaId);
  }
}

module.exports = ArticleDetailsMetadataView;
