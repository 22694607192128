const PageableCollection = require('@common/components/pageableList/PageableCollection');
const CommunityAction = require('@common/data/enums/CommunityAction').default;
const CommunitySettingsModel = require('@training/apps/search/communitiesManagement/models/CommunitySettingsModel');

const LIST_SIZE = 50;

class FilterableCommunityManagementList extends PageableCollection {
  initialize(models, options = {}) {
    ({ isChannels: this.isChannels } = options);
    super.initialize(models, options);
    this.state.pageSize = LIST_SIZE;
  }

  apiEndpoint() {
    return '/pageCommunities/config/search';
  }

  model(attr, options) {
    const attributes = Object.assign({}, attr, { type: options.collection.isChannels ? 'channel' : 'folder' });
    return new CommunitySettingsModel(attributes, options);
  }

  fetch(options = {}) {
    Object.assign(options, {
      data: {
        communityAction: CommunityAction.EDIT_COMMUNITY_SETTINGS,
        query: this.searchQuery,
        includeLocationCommunities: this.includeLocationCommunities,
        includeArchived: this.includeArchived,
        communityType: this.isChannels ? 'channel' : 'folder'
      }
    });

    return super.fetch(options);
  }

  search(searchData, pageNum, options = {}) {
    this.searchQuery = searchData.get('searchString');
    this.includeLocationCommunities = searchData.get('includeLocationCommunities');
    this.includeArchived = searchData.get('includeArchived');
    return this.getPage(pageNum || 0, options);
  }
}

module.exports = FilterableCommunityManagementList;
