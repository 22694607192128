const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class CommunityManagementLayout extends LayoutView {
  initialize(options = {}) {
    this.communityIdExists = options.communityIdExists;
  }

  templateHelpers() {
    return {
      communityTitle: this.communityIdExists
        ? I18n.t('communitiesManagement.community.titleEditFolder')
        : I18n.t('communitiesManagement.community.titleCreateFolder')
    };
  }

  className() {
    return 'community-management-layout parent-height';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/CommunityManagementLayout.html');
  }

  regions() {
    return {
      headerRegion: '.js-community-header',
      tabsRegion: '.js-community-management-tabs',
      actionBarRegion: '.js-action-bar'
    };
  }

  onDestroy() {
    window.app.layout.actionBar.setActionBar();
  }
}

module.exports = CommunityManagementLayout;
