const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'CHANNELS',
  'COMMUNITIES',
  'SETTINGS',
  'FILTERS',
  'IMPORT_HISTORY',
  'TAGS'
]);
