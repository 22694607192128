import type {
  InferApiParamType,
  InitialState
} from '@common/configs/redux/UtilTypes';
import type {
  BaseQueryArgType,
  BaseResponseType,
  BaseResultType,
  OtherMergeArgs,
  UseResultsHookOptions
} from '@common/data/paging';
import type { AjaxBaseQueryFn } from '@common/libs/ajax/AjaxBaseQuery';
import { getValue } from '@common/libs/helpers/types/ObjectHelpers';
import { capitalizeFirstCharacter } from '@common/libs/helpers/types/StringHelpers';
import type {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import type {
  BaseQueryArg,
  BaseQueryError,
  BaseQueryMeta
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import type { ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { ReactHooksModule } from '@reduxjs/toolkit/dist/query/react/module';
import type {
  MaybePromise,
  OmitFromUnion
} from '@reduxjs/toolkit/dist/query/tsHelpers';
import type {
  Api,
  CoreModule,
  QueryDefinition,
  SerializeQueryArgs
} from '@reduxjs/toolkit/query';
import env from 'env';
import {
  compact,
  defaults,
  find,
  forEach,
  get,
  has,
  identity,
  isEqual,
  isMatch,
  isNumber,
  isObject,
  keys,
  map,
  merge,
  omit,
  reduce,
  reject,
  unionBy
} from 'lodash';
import {
  useCallback,
  useMemo,
  useRef
} from 'react';
import {
  useDispatch,
  useStore
} from 'react-redux';
import { useMemoOne } from 'use-memo-one';

export type ReferenceItemId = number | string;

export type ReferenceItemIdParam = ReferenceItemId | ResetReferenceId;

export const INVALID_REFERENCE_ID = -1 as const;
export type InvalidReferenceId = typeof INVALID_REFERENCE_ID;

export const RESET_REFERENCE_ID = null;
export type ResetReferenceId = typeof RESET_REFERENCE_ID;

export type PagedCursorResultsPerPageParams = {
  itemsBefore?: number,
  itemsAfter?: number
};

export type PagedCursorApiParams<T extends BaseQueryArgType = BaseQueryArgType> = T & PagedCursorResultsPerPageParams & {
  referenceItemId: ReferenceItemIdParam;
};

type ResultReferenceItemId<ResultItemIdKey extends string> = InvalidReferenceId | BaseResultType<ResultItemIdKey>[ResultItemIdKey];

export type PagedCursorResponseType<
  ResponseType extends object | BaseResponseType<ResultItemIdKey>[],
  ResultItemIdKey extends string,
> = {
  results: (
    ResponseType extends Array<infer ResponseResultType extends BaseResponseType<ResultItemIdKey>>
      ? ResponseResultType
      : BaseResponseType<ResultItemIdKey>
  )[];
  total?: number;
  nextReferenceItemId?: ResultReferenceItemId<ResultItemIdKey>;
  preceedingReferenceItemId?: ResultReferenceItemId<ResultItemIdKey>;
};

export type PagedCursorApiResult<
  ResultsType extends BaseResultType<ResultItemIdKey>,
  ResponseDataType extends object,
  ResultItemIdKey extends string
> = {
  pagedCursorPageResults: ResultsType[][];
  results: ResultsType[];
  total?: number;
  nextReferenceItemId: ResultReferenceItemId<ResultItemIdKey>;
  preceedingReferenceItemId: ResultReferenceItemId<ResultItemIdKey>;
  requestMetadata: Record<`${ ReferenceItemIdParam }`, {
    hasNullTargetResultItem: boolean;
    beforeTargetResults: ResultsType[];
    afterTargetResults: ResultsType[];
    responseData: ResponseDataType | EmptyObject,
  }>;
};

export function injectPagedCursorApiEndpoint<
  // Result type for the collection data that the endpoint returns
  ResultType extends BaseResultType<ResultItemIdKey>,

  // Response type that the endpoint returns from the server
  ResponseType extends object | ResultType[] = { results: ResultType[] },

  // Query arg type that the endpoint supports
  QueryArg extends BaseQueryArgType = BaseQueryArgType,

  ResultItemIdKey extends string = 'id',

  InjectedTagType extends string = string,

  // Super generic API type that we're going to constrain to a more specific type once we extract the types we need below
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  API extends Api<AjaxBaseQueryFn, NonNullable<unknown>, any, any, CoreModule | ReactHooksModule> = Api<AjaxBaseQueryFn, NonNullable<unknown>, any, any, CoreModule | ReactHooksModule>,

  // Extracted types from the API definition
  BaseQuery extends AjaxBaseQueryFn = InferApiParamType<API, 'BaseQuery'>,
  ReducerPath extends string = InferApiParamType<API, 'ReducerPath'>,
  TagTypes extends string = InferApiParamType<API, 'TagTypes'>
>(options: {
  api: API
  endpointName: string
  endpointDefinition: Override<OmitFromUnion<QueryDefinition<QueryArg, BaseQuery, InjectedTagType | TagTypes, ResultType, ReducerPath>, 'type'>, {
    merge?: never;
    queryFn?: never;

    query: (args: PagedCursorApiParams<QueryArg>) => BaseQueryArg<AjaxBaseQueryFn>;

    mergeResults?: (currentResults: ResultType[], newResults: ResultType[], otherArgs: OtherMergeArgs<PagedCursorApiParams<QueryArg>, BaseQuery>) => ResultType[];

    transformResponse?: (baseQueryReturnValue: ResponseType, meta: BaseQueryMeta<BaseQuery>, args: PagedCursorApiParams<QueryArg>) => MaybePromise<PagedCursorResponseType<ResponseType, ResultItemIdKey>>;
    transformErrorResponse?: (baseQueryReturnValue: ResponseType, meta: BaseQueryMeta<BaseQuery>, args: PagedCursorApiParams<QueryArg>) => MaybePromise<PagedCursorResponseType<ResponseType, ResultItemIdKey>>;

    serializeQueryArgs?: SerializeQueryArgs<PagedCursorApiParams<QueryArg>, string | PagedCursorApiResult<ResultType, ResponseType, ResultItemIdKey>>;

    providesTags?: ResultDescription<TagTypes, PagedCursorApiResult<ResultType, ResponseType, ResultItemIdKey>, PagedCursorApiParams<QueryArg>, BaseQueryError<BaseQuery>, BaseQueryMeta<BaseQuery>>
  }>
  tagType?: InjectedTagType
  resultItemIdKey?: ResultItemIdKey
}) {
  type PagedCursorQueryArg = PagedCursorApiParams<QueryArg>;
  type PagedCursorResultType = PagedCursorApiResult<ResultType, ResponseType, ResultItemIdKey>;
  type PagedCursorTagTypes = InjectedTagType | TagTypes;
  type PagedCursorQueryDefinition = QueryDefinition<PagedCursorQueryArg, BaseQuery, PagedCursorTagTypes, PagedCursorResultType, ReducerPath>;
  type PagedCursorResponseResultType = PagedCursorResponseType<ResponseType, ResultItemIdKey>['results'][number];

  const {
    api: API,
    endpointName,
    endpointDefinition: {
      query,
      mergeResults = (currentCacheResults: ResultType[], newResults: ResultType[]) => {
        return unionBy(currentCacheResults, newResults, resultItemIdKey);
      },
      transformResponse = identity,
      transformErrorResponse = identity,
      serializeQueryArgs,
      providesTags,
      ...definitionOptions
    },
    tagType = `${ endpointName }-PagedCursor` as InjectedTagType,
    resultItemIdKey = 'id' as ResultItemIdKey
  } = options;

  const enhancedApi = API.enhanceEndpoints({
    addTagTypes: [tagType]
  });

  const pagedCursorTransformResponse = async (
    responseData: ResponseType,
    meta: BaseQueryMeta<BaseQuery>,
    args: PagedCursorQueryArg
  ): Promise<PagedCursorResultType> => {
    const {
      results,
      total,
      nextReferenceItemId = INVALID_REFERENCE_ID,
      preceedingReferenceItemId = INVALID_REFERENCE_ID
    } = await Promise.resolve(transformResponse?.(responseData, meta, args) ?? { results: [] as PagedCursorResponseResultType[] });

    type AccType = {
      key: 'beforeTargetResults' | 'afterTargetResults',
      beforeTargetResults: ResultType[],
      targetResult: ResultType | null,
      nullTargetResult: PagedCursorResponseResultType | null,
      afterTargetResults: ResultType[]
    };

    const {
      beforeTargetResults,
      targetResult,
      nullTargetResult,
      afterTargetResults
    } = reduce<PagedCursorResponseResultType, AccType>(results, (acc, result): AccType => {
      const resultId = result[resultItemIdKey];

      if (resultId === null) {
        return {
          ...acc,
          nullTargetResult: result
        };
      }

      if (resultId === args.referenceItemId) {
        return {
          ...acc,
          targetResult: result as ResultType,
          key: 'afterTargetResults'
        };
      }

      return {
        ...acc,
        [acc.key]: [...acc[acc.key], result]
      };

    }, {
      key: isResetRequest(args) ? 'afterTargetResults' : 'beforeTargetResults',
      beforeTargetResults: [],
      targetResult: null,
      nullTargetResult: null,
      afterTargetResults: []
    });

    const hasNullTargetResultItem = nullTargetResult != null;
    const newResults = compact([
      ...beforeTargetResults,
      targetResult,
      ...afterTargetResults
    ]);

    return pagedCursorMergeCacheResults({}, {
      results: newResults,
      total,
      nextReferenceItemId,
      preceedingReferenceItemId,
      pagedCursorPageResults: [newResults],
      requestMetadata: {
        [String(args.referenceItemId)]: {
          beforeTargetResults,
          afterTargetResults,
          hasNullTargetResultItem,
          responseData
        }
      }
    }, {
      arg: args,
      baseQueryMeta: meta
    });
  };

  const pagedCursorMergeCacheResults = (
    currentCacheData: EmptyObject | PagedCursorResultType,
    responseData: null | PagedCursorResultType,
    otherArgs: OtherMergeArgs<PagedCursorQueryArg, BaseQuery>
  ): PagedCursorResultType => {
    // newResponseData will be null if the data was manually being reset with `resetState()`
    if (responseData == null) {
      return {
        results: [],
        total: 0,
        nextReferenceItemId: INVALID_REFERENCE_ID,
        preceedingReferenceItemId: INVALID_REFERENCE_ID,
        pagedCursorPageResults: [],
        requestMetadata: {}
      };
    }

    const {
      results: newResults,
      total: newTotal,
      pagedCursorPageResults: newPagedCursorPageResults,
      requestMetadata: newRequestMetadata
    } = responseData;

    const arg = otherArgs.arg;
    const {
      referenceItemId,
      itemsAfter,
      itemsBefore
    } = arg;

    if (isResetRequest(arg)) {
      const nextReferenceItemId = newResults.length === itemsAfter
        ? newResults[newResults.length - 1][resultItemIdKey]
        : INVALID_REFERENCE_ID;

      return {
        results: mergeResults([], newResults, otherArgs),
        total: newTotal ?? newResults.length,
        preceedingReferenceItemId: INVALID_REFERENCE_ID,
        nextReferenceItemId,
        pagedCursorPageResults: newPagedCursorPageResults,
        requestMetadata: newRequestMetadata
      };
    }

    const {
      results: currentResults = [],
      pagedCursorPageResults: currentPagedCursorPageResults = [],
      nextReferenceItemId: currentNextReferenceItemId = INVALID_REFERENCE_ID,
      preceedingReferenceItemId: currentPreceedingReferenceItemId = INVALID_REFERENCE_ID,
      requestMetadata: currentRequestMetadata = {}
    } = currentCacheData;

    let pagedCursorPageResults: ResultType[][] = [];
    let results: ResultType[] = [];
    let nextReferenceItemId = currentNextReferenceItemId;
    let preceedingReferenceItemId = currentPreceedingReferenceItemId;

    const {
      beforeTargetResults = [],
      afterTargetResults = []
    } = newRequestMetadata[String(referenceItemId)];

    if (isTargetedRequest(arg)) {
      pagedCursorPageResults = newPagedCursorPageResults;

      results = mergeResults([], newResults, otherArgs);

      preceedingReferenceItemId = beforeTargetResults.length === itemsBefore
        ? beforeTargetResults[0][resultItemIdKey]
        : INVALID_REFERENCE_ID;

      nextReferenceItemId = afterTargetResults.length === itemsAfter
        ? afterTargetResults[afterTargetResults.length - 1][resultItemIdKey]
        : INVALID_REFERENCE_ID;

    } else if (isPreceedingRequest(arg)) {
      pagedCursorPageResults = [...newPagedCursorPageResults, ...currentPagedCursorPageResults];

      results = mergeResults(newResults, currentResults, otherArgs);

      preceedingReferenceItemId = beforeTargetResults.length === itemsBefore
        ? beforeTargetResults[0][resultItemIdKey]
        : INVALID_REFERENCE_ID;

    } else if (isNextRequest(arg)) {
      pagedCursorPageResults = [...currentPagedCursorPageResults, ...newPagedCursorPageResults];

      results = mergeResults(currentResults, newResults, otherArgs);

      nextReferenceItemId = afterTargetResults.length === itemsAfter
        ? afterTargetResults[afterTargetResults.length - 1][resultItemIdKey]
        : INVALID_REFERENCE_ID;
    }

    return {
      pagedCursorPageResults,
      results,
      nextReferenceItemId,
      preceedingReferenceItemId,
      total: newTotal ?? results.length,
      requestMetadata: {
        ...currentRequestMetadata,
        ...newRequestMetadata
      }
    };
  };

  const pagedCursorApi = enhancedApi.injectEndpoints({
    endpoints: (builder) => {
      return {
        [endpointName]: builder.query<PagedCursorResultType, PagedCursorQueryArg>({
          ...definitionOptions as unknown as OmitFromUnion<PagedCursorQueryDefinition, 'type'>,
          queryFn: async (args, queryApi, extraOptions, baseQuery) => {
            const {
              referenceItemId,
              itemsAfter,
              itemsBefore
            } = args;

            const argParams = referenceItemId == null ? {
              referenceItemId,
              itemsAfter
            } : {
              referenceItemId,
              itemsBefore,
              itemsAfter
            };

            const {
              data,
              ...baseQueryArgs
            } = query?.(args) ?? { data: {} };

            const requestData = isObject(data) ? data : {};

            const response = await Promise.resolve(baseQuery({
              ...baseQueryArgs,
              data: {
                ...argParams,
                ...requestData
              }
            }));

            if (response.error != null) {
              return {
                ...response,
                error: await Promise.resolve(transformErrorResponse(response.error as ResponseType, response.meta, args))
              };
            }

            return {
              ...response,
              data: await Promise.resolve(pagedCursorTransformResponse(response.data as ResponseType, response.meta, args))
            };
          },
          serializeQueryArgs: (data) => {
            const serializedQueryArgs = serializeQueryArgs?.(data) ?? data.queryArgs;

            if (isObject(serializedQueryArgs)) {
              return omit(serializedQueryArgs, 'referenceItemId', 'itemsBefore', 'itemsAfter');
            }

            return serializedQueryArgs;
          },
          merge: pagedCursorMergeCacheResults,
          providesTags: (response, ...args) => {
            const tags = getValue(providesTags, null, response, ...args) ?? [];

            if (tagType != null) {
              tags.concat(
                map(response?.results, ({ [resultItemIdKey]: id }) => {
                  return {
                    type: tagType,
                    id
                  };
                }), {
                  type: tagType,
                  id: 'LIST'
                }
              );
            }

            return tags;
          },
          forceRefetch(params) {
            return !isEqual(params.currentArg, params.previousArg);
          }
        })
      };
    }
  });

  const capitalizedEndpointName = capitalizeFirstCharacter(endpointName);
  const lazyHookKey = `useLazy${ capitalizedEndpointName }Query` as const;
  const resultsHookKey = `use${ capitalizedEndpointName }ResultsApi` as const;

  const useLazyQuery = pagedCursorApi[lazyHookKey];
  const useQueryState = pagedCursorApi.endpoints[endpointName].useQueryState;

  type State = InitialState<[typeof pagedCursorApi]>;

  const resultsHookFn = (hookOptions: UseResultsHookOptions<QueryArg, PagedCursorQueryDefinition, PagedCursorResultsPerPageParams> = {}) => {
    const {
      resultsPerPage,
      queryArgs: queryParams,
      ...lazyHookOptions
    } = hookOptions;

    const dispatch = useDispatch<ThunkDispatch<State, unknown, UnknownAction>>();
    const store = useStore();

    const [fetch] = useLazyQuery(lazyHookOptions);

    const queryArgs = useMemoOne(() => {
      return queryParams ?? {} as Exclude<QueryArg, undefined>;
    }, Object.values(queryParams ?? {}));

    const queryState = useQueryState(queryArgs as PagedCursorQueryArg);
    const {
      data = {} as PagedCursorResultType
    } = queryState;

    const requestMetadataRef = useRef<PagedCursorResultType['requestMetadata']>({});
    requestMetadataRef.current = get(queryState, 'data.requestMetadata', {});

    const isFetchingRef = useRef<Record<string, ReturnType<typeof fetch>>>({});

    const dataRef = useRef<PagedCursorResultType>(data);
    dataRef.current = data;

    const rppArgs = useMemo(() => {
      return defaults(
        isNumber(resultsPerPage)
          ? {
            itemsBefore: resultsPerPage,
            itemsAfter: resultsPerPage
          }
          : resultsPerPage
        , {
          itemsBefore: env.settings.pageSize,
          itemsAfter: env.settings.pageSize
        }
      );
    }, [resultsPerPage]);

    const resetState = useCallback(async () => {
      forEach(isFetchingRef.current, (fetchResult) => {
        fetchResult.abort();
      });

      requestMetadataRef.current = {};
      isFetchingRef.current = {};

      const args = pagedCursorApi.util.selectCachedArgsForQuery(store.getState(), endpointName) ?? [];

      return Promise.all(args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedCursorApi.util.upsertQueryData(endpointName, arg, null as unknown as PagedCursorResultType));
        }

        return undefined;
      }));
    }, [queryArgs, store]);

    const removeResults = useCallback(<I extends ResultType>(targetItem: I) => {
      const args = pagedCursorApi.util.selectCachedArgsForQuery(store.getState(), endpointName) ?? [];

      return args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedCursorApi.util.updateQueryData(endpointName, arg, (draft) => {
            const item = find(draft.results, targetItem) as ResultType | undefined;

            if (item != null) {
              const requestMetadataKey = String(arg.referenceItemId);

              const results = reject(draft.results, item);
              const pagedCursorPageResults = map(draft.pagedCursorPageResults, (pageResults) => {
                return reject(pageResults, item);
              });

              const requestMetadata = omit(draft.requestMetadata, requestMetadataKey);
              requestMetadataRef.current = omit(requestMetadataRef.current, requestMetadataKey);

              const nextReferenceItemId = draft.nextReferenceItemId === INVALID_REFERENCE_ID
                ? INVALID_REFERENCE_ID
                : get(results, [results.length - 1, resultItemIdKey], INVALID_REFERENCE_ID);

              const preceedingReferenceItemId = draft.preceedingReferenceItemId === INVALID_REFERENCE_ID
                ? INVALID_REFERENCE_ID
                : get(results, [0, resultItemIdKey], INVALID_REFERENCE_ID);

              const total = (draft?.total ?? 0) - ((draft.results?.length ?? 0) - (results?.length ?? 0));

              return merge(draft, {
                results,
                nextReferenceItemId,
                preceedingReferenceItemId,
                pagedCursorPageResults,
                requestMetadata,
                total
              });
            }

            return draft;
          }));
        }

        return undefined;
      });
    }, [queryArgs, store]);

    const updateResults = useCallback(<I extends ResultType>(targetItem: I, updateFn: (item: ResultType) => ResultType) => {
      const args = pagedCursorApi.util.selectCachedArgsForQuery(store.getState(), endpointName) ?? [];

      return args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedCursorApi.util.updateQueryData(endpointName, arg, (draft) => {
            const item = find(draft.results, targetItem) as ResultType | undefined;

            if (item != null) {
              const requestMetadataKey = String(arg.referenceItemId);
              const updatedItem = updateFn(item);

              const results = updateItem(draft.results, item, updatedItem);

              const pagedCursorPageResults = map(draft.pagedCursorPageResults, (pageResults) => {
                return updateItem(pageResults, item, updatedItem);
              });

              const requestMetadata = {
                ...draft.requestMetadata,
                [requestMetadataKey]: {
                  ...(draft.requestMetadata[requestMetadataKey]),
                  beforeTargetResults: updateItem(draft.requestMetadata[requestMetadataKey].beforeTargetResults, item, updatedItem),
                  afterTargetResults: updateItem(draft.requestMetadata[requestMetadataKey].afterTargetResults, item, updatedItem)
                }
              };

              return merge(draft, {
                results,
                pagedCursorPageResults,
                requestMetadata
              });
            }

            return draft;
          }));
        }

        return undefined;
      });
    }, [queryArgs, store]);

    const fetchResults = useCallback((args: Partial<PagedCursorApiParams> = {}, preferCacheValue?: boolean) => {
      const fetchArgs = {
        referenceItemId: INVALID_REFERENCE_ID,
        ...fillInMissingRppArgs(args, rppArgs),
        ...queryArgs
      };

      if (fetchArgs.referenceItemId === INVALID_REFERENCE_ID) {
        return undefined;
      }

      const referenceItemIdString = String(JSON.stringify(fetchArgs));
      let result = isFetchingRef.current[referenceItemIdString];

      if (result != null) {
        return result;
      }

      result = fetch(fetchArgs, preferCacheValue);

      isFetchingRef.current[referenceItemIdString] = result;

      result.finally(() => {
        delete isFetchingRef.current[referenceItemIdString];
      });

      return result;
    }, [fetch, rppArgs, queryArgs]);

    const fetchResetResults = useCallback((preferCacheValue?: boolean) => {
      return (async () => {
        await resetState();

        return fetchResults({
          referenceItemId: RESET_REFERENCE_ID
        }, preferCacheValue);
      })();
    }, [fetchResults, resetState]);

    const fetchNextResults = useCallback((preferCacheValue?: boolean) => {
      return fetchResults({
        referenceItemId: dataRef.current.nextReferenceItemId,
        itemsAfter: rppArgs.itemsAfter
      }, preferCacheValue);
    }, []);

    const fetchPreceedingResults = useCallback((preferCacheValue?: boolean) => {
      return fetchResults({
        referenceItemId: dataRef.current.preceedingReferenceItemId,
        itemsBefore: rppArgs.itemsBefore
      }, preferCacheValue);
    }, []);

    const arePageResultsFetching = useCallback((args?: Partial<PagedCursorApiParams>) => {
      if (args == null) {
        return keys(isFetchingRef.current).length > 0;
      }

      const fetchArgs: PagedCursorQueryArg = {
        referenceItemId: INVALID_REFERENCE_ID,
        ...fillInMissingRppArgs(args, rppArgs),
        ...queryArgs
      };

      if (fetchArgs.referenceItemId === INVALID_REFERENCE_ID) {
        return false;
      }

      return isFetchingRef.current[String(JSON.stringify(fetchArgs))] != null;
    }, [isFetchingRef, queryArgs]);

    const areResetResultsFetching = useCallback(() => {
      return arePageResultsFetching({ referenceItemId: RESET_REFERENCE_ID });
    }, [arePageResultsFetching]);

    const areNextResultsFetching = useCallback(() => {
      return arePageResultsFetching({
        referenceItemId: dataRef.current.nextReferenceItemId,
        itemsAfter: rppArgs.itemsAfter
      });
    }, [arePageResultsFetching, rppArgs]);

    const arePreceedingResultsFetching = useCallback(() => {
      return arePageResultsFetching({
        referenceItemId: dataRef.current.preceedingReferenceItemId,
        itemsBefore: rppArgs.itemsBefore
      });
    }, [arePageResultsFetching, rppArgs]);

    const isNullTargetResult = useCallback((referenceItemId: ReferenceItemIdParam) => {
      return has(dataRef.current, ['requestMetadata', String(referenceItemId), 'hasNullTargetResult']);
    }, []);

    const hasPageData = useCallback((referenceItemId: ReferenceItemIdParam) => {
      return has(dataRef.current, ['requestMetadata', String(referenceItemId)]);
    }, []);


    const getPageData = useCallback((referenceItemId: ReferenceItemIdParam) => {
      return get(dataRef.current, ['requestMetadata', String(referenceItemId)], []);
    }, []);

    return {
      queryState,

      resetState,
      removeResults,
      updateResults,

      fetchResults,
      fetchResetResults,
      fetchNextResults,
      fetchPreceedingResults,

      arePageResultsFetching,
      areResetResultsFetching,
      areNextResultsFetching,
      arePreceedingResultsFetching,

      isNullTargetResult,

      hasPageData,
      getPageData,

      hasNextPage: dataRef.current.nextReferenceItemId !== INVALID_REFERENCE_ID,
      hasPreceedingPage: dataRef.current.preceedingReferenceItemId !== INVALID_REFERENCE_ID
    };
  };

  return {
    ...pagedCursorApi,
    [resultsHookKey]: resultsHookFn
  } as typeof pagedCursorApi & {
    [key: typeof resultsHookKey]: typeof resultsHookFn
  };
}

function _isValidReferenceItemId(id: ReferenceItemIdParam = INVALID_REFERENCE_ID): id is ReferenceItemId {
  return id !== INVALID_REFERENCE_ID && (typeof id === 'number' || typeof id === 'string');
}
export const isValidReferenceItemId = _isValidReferenceItemId;

function _isResetReferenceItemId(id: ReferenceItemIdParam = INVALID_REFERENCE_ID): id is ResetReferenceId {
  return id === RESET_REFERENCE_ID;
}
export const isResetReferenceItemId = _isResetReferenceItemId;

function _isInvalidReferenceItemId(id: ReferenceItemIdParam = INVALID_REFERENCE_ID): id is InvalidReferenceId {
  return id === INVALID_REFERENCE_ID;
}
export const isInvalidReferenceItemId = _isInvalidReferenceItemId;

function isResetRequest(args: PagedCursorApiParams) {
  return isResetReferenceItemId(args.referenceItemId) && args.itemsAfter != null;
}

function isTargetedRequest(args: PagedCursorApiParams) {
  return isValidReferenceItemId(args.referenceItemId) && args.itemsBefore != null && args.itemsAfter != null;
}

function isPreceedingRequest(args: PagedCursorApiParams) {
  return isValidReferenceItemId(args.referenceItemId) && args.itemsBefore != null;
}

function isNextRequest(args: PagedCursorApiParams) {
  return isValidReferenceItemId(args.referenceItemId) && args.itemsAfter != null;
}

function updateItem<T>(
  collection: Array<MaybeDrafted<T>>,
  existingItem: T,
  newItem: T
): Array<MaybeDrafted<T>> {
  return map(collection, (item) => {
    return item === existingItem ? merge(item, newItem) : item;
  });
}

function fillInMissingRppArgs<
  QueryArg extends BaseQueryArgType
>(args: Partial<PagedCursorApiParams<QueryArg>>, rppArgs: Required<PagedCursorResultsPerPageParams>) {
  if (args.referenceItemId === RESET_REFERENCE_ID) {
    return {
      ...args,
      itemsAfter: rppArgs.itemsAfter
    };
  }

  if (!('itemsAfter' in args) && !('itemsBefore' in args)) {
    return {
      ...args,
      itemsAfter: Math.ceil(rppArgs.itemsAfter / 2),
      itemsBefore: Math.ceil(rppArgs.itemsBefore / 2)
    };
  }

  return args;
}
