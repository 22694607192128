const Backbone = require('Backbone');
const ArticleEditController = require('@training/apps/articles/ArticleEditController');
const PageType = require('@common/components/discover/enums/PageType').default;

const nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');

const viewConfig = (articleData, sessionUser, languageData) => {
  const canEditTitle = (articleData.get('type') !== PageType.TRAINING);
  const canEditContent = (articleData.get('type') !== PageType.TRAINING);
  const canEditAttachments = (articleData.get('type') !== PageType.TRAINING) ? nbChannel.reqres.request('canShowAttachments') : false;

  return {
    ViewControllerClass: ArticleEditController,
    model: articleData,
    languageData: languageData,
    canEditTitle: canEditTitle,
    canEditContent: canEditContent,
    canEditAttachments: canEditAttachments,
    delegateEvents: {
      'view:childview:click:more': (controller, view) => {
        view.toggleMetadata();
      }
    }
  };
};

module.exports = viewConfig;

