const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const TimelineCardViewController = require('@training/apps/timeline/TimelineCardViewController');
const { NoSearchResultsView } = require('@training/apps/search/SearchResultEmptyViews');
const PageType = require('@common/components/discover/enums/PageType').default;
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const ImpressionTrackerEnum = require('@training/apps/common/enums/ImpressionTrackerEnum');

class CommunityTimelineController extends LayoutController {
  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState,
      timelineCollection: this.timelineCollection
    } = options);

    this.community = this.searchPageState.get('community');
    this.canUserPostInThisCommunity = this.community.canAuthor(PageType.POST);

    this.showAuthor = TenantPropertyProvider.get().getProperty('dzShowAuthorAndContributorInfo');

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'timeline',
      template: `
          <div class="tabbed-content-wrapper"></div>
        `,
      regions: {
        contentRegion: '.tabbed-content-wrapper'
      }
    };
  }

  regionControllers() {
    return {
      contentRegion: this._getContentRegionDefinition()
    };
  }

  _getContentRegionDefinition() {
    return {
      ViewControllerClass: PageableListViewController,
      id: 'content',
      collection: this.timelineCollection,
      childViewControllerDefinitionFn: (model, itemViewOptions) => {
        return {
          ViewControllerClass: TimelineCardViewController,
          model,
          source: ImpressionTrackerEnum.getEnumFromSearchCategory(this.searchPageState.get('communitySearchCategory')),
          itemViewOptions,
          hideCommunityOnCard: true
        };
      },
      itemViewOptions: {
        searchPageState: this.searchPageState,
        showAuthor: this.showAuthor,
        showReactions: true,
        showFullContent: false,
        isBrowsingCommunity: true
      },
      viewDefinition: {
        behaviors: {
          MutationObservable: {
            observeOptions: {
              childList: true,
              subtree: true
            }
          }
        }
      },
      emptyViewClass: NoSearchResultsView,
      emptyViewOptions: {
        searchString: this.searchPageState.get('searchString'),
        searchCategory: this.searchPageState.get('communitySearchCategory')
      },
      delegateEvents: {
        'view:dom:mutate': () => {
          triggerResize(true);
        },
        'view:attach': (controller) => {
          const view = controller.getView();

          view.listenTo(this.timelineCollection, 'sync', () => {
            view.$el.find('.empty-search-results').toggleClass('hidden');
            SearchUrlHelper.updatePageNumberInUrl(this.searchPageState, this.timelineCollection);
          });

          // Hide the empty view while the timeline collection is being searched, and show it when no results returned
          if (this.timelineCollection != null && this.timelineCollection.isLoading) {
            view.$el.find('.empty-search-results').toggleClass('hidden');
          }

          view.listenTo(Backbone.Wreqr.radio.channel('global').vent, 'pagination:clicked', () => {
            $('#page-view > .adjustable-container').scrollTop(0);
          });
        }
      }
    };
  }
}

module.exports = CommunityTimelineController;
