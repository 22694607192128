const _ = require('underscore');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const PageType = require('@common/components/discover/enums/PageType').default;
const PageSharedCommunitiesList = require('@common/components/discover/collections/PageSharedCommunitiesList');

const MetadataRootView = require('@training/apps/articles/MetadataRootView');
const ReferenceMetadataView = require('@common/components/discover/edit/ReferenceMetadataView');
const EditorNotesView = require('@common/components/discover/edit/EditorNotesView');
const AttachmentsView = require('@training/apps/articles/AttachmentsView');
const LanguageView = require('@common/components/discover/edit/LanguageView');
const TagsView = require('@common/components/discover/edit/TagsView');
const PublishSettingsView = require('@common/components/discover/edit/PublishSettingsView');
const MultilingualArticleBundledLanguageChooserController = require('@training/apps/articles/multilingualArticleBundledLanguageChooser/MultilingualArticleBundledLanguageChooserController');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const ActionableMenuDropdown = require('@common/components/dropdownButton/ActionableMenuDropdown');
const {
  onDeletePage,
  onDeleteLanguageVersion
} = require('@training/apps/articles/PageDeleteHelper');

const CommunityMetadataSettingsController = require('@common/components/discover/edit/CommunityMetadataSettingsController');

class ArticleMetadataEditController extends LayoutController {

  initialize(options = {}) {
    ({
      model: this.model,
      authChannel: this.authChannel,
      glChannel: this.glChannel,
      tagList: this.tagList,
      languageData: this.languageData,
      languageList: this.supportedLanguages,
      currentUser: this.currentUser,
      isMediaAttachmentsEnabled: this.canShowAttachments,
      fileFactory: this.fileFactory,
      confirmActionOnModified: this.confirmActionOnModified,
      isInsideQuestionCreateModal: this.isInsideQuestionCreateModal
    } = options);

    if (this.model.isNew() && !this.model.get('language')) {
      const userLanguage = window.apps.auth.session.user.get('language');
      this.model.set('language', userLanguage);
    }

    this.isMediaAttachmentsEnabled = this.canShowAttachments != null ? this.canShowAttachments : this.model.get('type') !== PageType.TRAINING;
    this.hasComments = this.isMediaAttachmentsEnabled || this.model.get('type') === PageType.TRAINING;
    this.isReferencePage = this.model.get('type') === PageType.REFERENCE;
    this.hasLanguageForm = this.model.get('type') !== PageType.TRAINING;
    this.hasPublishSettings = this.model.get('type') !== PageType.TRAINING && this.model.get('type') !== PageType.QUESTION;
    this.isEditing = this.model.getId() != null && this.model.getStatus() != null;

    this.pageSharedCommunitiesList = new PageSharedCommunitiesList(null, {
      pageId: this.model.get('id')
    });

    if (!this.model.isNew()) {
      this.pageSharedCommunitiesList.add(this.model.get('communities'), { parse: true });
    }

    this._isTagListLoaded = false;
    this._isDataLoaded = false;

    this.ReferenceMetadataView = ReferenceMetadataView;
    this.EditorNotesView = EditorNotesView;
    this.AttachmentsView = AttachmentsView;

    this.LanguageView = LanguageView;

    this.CommunityMetadataSettingsController = CommunityMetadataSettingsController;
    this.TagsView = TagsView;
    this.PublishSettingsView = PublishSettingsView;

    if (this._shouldShowBundledLanguageChooser()) {
      this.listenTo(this.languageData, 'change', () => {
        this.resetRegionController('languageRegion');
      });
    }

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: MetadataRootView,
      model: this.model
    };
  }

  _shouldShowBundledLanguageChooser() {
    return !this.isInsideQuestionCreateModal && !this.isTrainingModule();
  }

  isTrainingModule() {
    return !this.model || this.model.get('type') === PageType.TRAINING;
  }

  regionControllers() {

    const regionControllers = {
      referenceHeaderRegion: () => {
        if (this.isReferencePage) {
          return {
            viewDefinition: {
              ViewClass: this.ReferenceMetadataView,
              model: this.model
            }
          };
        }
        return {};
      },
      editorNotesRegion: () => {
        if (this.hasComments) {
          return {
            viewDefinition: {
              ViewClass: this.EditorNotesView,
              model: this.model
            }
          };
        }
        return {};
      },
      attachmentsRegion: () => {
        if (this.isMediaAttachmentsEnabled) {
          return {
            viewDefinition: {
              ViewClass: this.AttachmentsView,
              model: this.model,
              fileFactory: this.fileFactory
            },
            delegateEvents: {
              'view:change:attachments': (controller, view, documentList) => {
                this.trigger('change:attachments', documentList);
              }
            }
          };
        }
        return {};
      },

      languageRegion: () => {
        if (this.hasLanguageForm) { // note: "hasLanguageForm" just means this is a training type

          if (this._shouldShowBundledLanguageChooser()) {
            return {
              ViewControllerClass: MultilingualArticleBundledLanguageChooserController,
              languageData: this.languageData,
              articleModel: this.model,
              editMode: true,
              confirmActionOnModified: this.confirmActionOnModified,
              containerClassName: 'language-chooser-region'
            };
          }

          return {
            viewDefinition: {
              ViewClass: this.LanguageView,
              model: this.model,
              modelAttribute: 'language',
              languageList: this.supportedLanguages
            }
          };
        }
        return {};
      },

      communityRegion: {
        ViewControllerClass: this.CommunityMetadataSettingsController,
        model: this.model,
        community: this.model.get('community'),
        currentUser: this.currentUser,
        isEditing: this.isEditing,
        delegateEvents: {
          'change:community': (community) => {
            const publishView = this.findControllerByRegion('publishSettingsRegion');
            if (publishView) {
              publishView.getView().trigger('change:community', community);
            }

            const expiryView = this.findControllerByRegion('expirySettingsRegion');
            if (expiryView) {
              expiryView.getView().trigger('change:community', community);
            }

            this.getView().trigger('change:community', community);
          }
        }
      },
      tagsRegion: {
        viewDefinition: {
          ViewClass: this.TagsView,
          model: this.model,
          tagList: this.tagList
        }
      },
      publishSettingsRegion: () => {
        if (this.hasPublishSettings) {
          return {
            viewDefinition: {
              ViewClass: this.PublishSettingsView,
              model: this.model
            }
          };
        }
        return {};
      }
    };

    // the business logic here: if someone is allowed to see the bundled language chooser,
    // then we infer they are also allowed to "Delete all". This region replaces a regular
    // Delete button with the MenuDropdown with Delete and Delete All
    if (!this.model.isNew() && this._shouldShowBundledLanguageChooser() && this.model.canDelete()) {
      regionControllers.deleteActionRegion = {
        viewDefinition: {
          ViewClass: ActionableMenuDropdown,
          primaryText: 'general.delete',
          primaryOnClick: () => {
            onDeleteLanguageVersion(this.model, this.languageData);
          },
          primaryButtonClass: 'qa-delete-action ax-button--destructive',
          buttonConfig: {
            buttonIcon: 'icon-caret_down',
            buttonClass: 'ax-button--destructive inline-block',
            buttonAriaLabel: 'discover.actionText.morePageActions',
            popupAlignment: MenuDropdownPositionEnum.LEFT + MenuDropdownPositionEnum.TOP
          },
          optionsListConfig: [
            {
              buttonText: 'discover.publishFlow.deleteAll',
              buttonSelectorClass: 'secondary-action',
              buttonClass: 'qa-delete-all-action secondary-action',
              clickCallback: () => {
                onDeletePage(this.languageData, this.model.get('type'));
              }
            }
          ]
        }
      };
    }

    return regionControllers;

  }

  commit() {
    let errors = [];

    const errorTests = [
      {
        classTest: this.ReferenceMetadataView && this.isReferencePage,
        region: 'referenceHeaderRegion'
      },
      {
        classTest: this.EditorNotesView && this.hasComments,
        region: 'editorNotesRegion'
      },
      {
        classTest: this.AttachmentsView && this.canShowAttachments,
        region: 'attachmentsRegion'
      },
      {
        classTest: this.CommunityMetadataSettingsController,
        region: 'communityRegion'
      },
      {
        classTest: this.TagsView,
        region: 'tagsRegion'
      },
      {
        classTest: this.PublishSettingsView && this.hasPublishSettings,
        region: 'publishSettingsRegion'
      }
    ];

    _.each(errorTests, (errorTest) => {
      if (errorTest.classTest) {
        errors = errors.concat(this.findControllerByRegion(errorTest.region).getView()
          .commit());
      }
    });

    errors = _.compact(errors, null);
    return errors;
  }

  destroy() {
    if (this._shouldShowBundledLanguageChooser()) {
      this.stopListening(this.languageData, 'change');
    }
    super.destroy();
  }
}

module.exports = ArticleMetadataEditController;
