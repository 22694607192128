const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');
const {
  LayoutView,
  ItemView
} = require('Marionette');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const ReactionsController = require('@training/apps/search/ReactionsController');
const ArticleSearchResultTileView = require('@training/apps/search/ArticleSearchResultTileView');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const UserProfileIcon = require('@common/modules/main/header/userProfileIcon/UserProfileIcon');
const PageType = require('@common/components/discover/enums/PageType').default;
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const {
  getLastEditorViewDefinition,
  getLastModifiedDateViewDefinition
} = require('@common/components/cardMetadata/MetadataStringHelper');
require('jquery.dotdotdot');

const _getPreviewNode = (data) => {
  return $('<tmp></tmp>').append(data.get('previewContent'));
};

const _hasImage = (data) => {
  const previewNode = _getPreviewNode(data);
  const mediaId = previewNode.find('.page__media.media--image').first()
    .data('mediaId');
  const mediaData = data.get('media');
  return _.find(mediaData, (media) => {
    return media.id === mediaId;
  });
};

const _getBottomTileViewDefinition = (resultData = {}, options = {}) => {
  const authorName = resultData.get('authorName');
  const lastModified = resultData.get('lastModified');
  const previewContent = resultData.get('previewContent');
  const resultDataClone = resultData.clone();
  const description = HTMLHelpers.summarizeHTMLToRange(previewContent, 300) || ' ';

  const definition = {
    bottomWrapperControllerDefinition: {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: LayoutView,
        template: `
        <div class="description-region js-impression-tracking-zone"></div>
        <div class="extra-info-region">
          <div class="profile-icon-region${ options && options.showAuthor ? '' : ' hidden' }" aria-hidden="true"></div>
          <div class="metaRegion"></div>
          <button class="base-card__view-all off-screen ax-button view-article-button" <%= viewAriaLabel %>"><%- t('selfDirected.search.view') %></button>
        </div>
        <div class="reactions-region"></div>
        `,
        templateHelpers: {
          viewAriaLabel: `aria-label="${ resultData.get('title') }. ${ I18n.t('selfDirected.search.viewDetailsAria') }"`
        },
        className: 'search-result__lower article-result__lower full-width',
        behaviors: {
          ImpressionTracker: {
            model: resultData,
            objectId: resultData.get('pageId') ?? resultData.id,
            objectType: 'PAGE',
            source: options.source
          }
        },
        regions: {
          descriptionRegion: '.description-region',
          metaRegion: '.metaRegion',
          profileIconRegion: '.profile-icon-region',
          reactionRegion: '.reactions-region'
        }
      },
      regionControllers: {
        descriptionRegion: {
          viewDefinition: {
            ViewClass: ItemView,
            className: 'article-description',
            template: description
          },
          delegateEvents: {
            'view:attach': (controller, view) => {
              const hasImageHeightMultiplier = 3;
              const noImageHeightMultiplier = 4;
              const hasImage = _hasImage(resultDataClone);
              const lineHeight = parseInt(view.$el.css('lineHeight'), 10);
              const height = hasImage ? lineHeight * hasImageHeightMultiplier : lineHeight * noImageHeightMultiplier;

              $(view.$el).dotdotdot({
                watch: true,
                height: height
              });
            }
          }
        },
        metaRegion: {
          viewDefinition: {
            ViewClass: StackedCollectionView,
            viewConfigModel: DetailViewConfig,
            className: 'stacked-collection-view',
            viewConfigs: [
              options && options.showAuthor ? getLastEditorViewDefinition(authorName) : undefined,
              getLastModifiedDateViewDefinition(lastModified)
            ]
          }
        },
        profileIconRegion: {
          viewDefinition: options && options.showAuthor ? _profileImageViewDefinition(resultData) : undefined
        }
      }
    }
  };

  if (options && options.showReactions) {
    definition.bottomWrapperControllerDefinition.regionControllers.reactionRegion = {
      ViewControllerClass: ReactionsController,
      favoriteModel: resultDataClone,
      reactionsModel: resultDataClone,
      showComments: false,
      showFavorites: true,
      itemViewOptions: options
    };
  }
  return definition;
};

const _profileImageViewDefinition = (resultData) => {
  const userModel = new Backbone.Model({
    salutationName: resultData.get('authorName'),
    id: resultData.get('authorUserId'),
    profileImage: resultData.get('authorImage')
  });

  return {
    ViewClass: UserProfileIcon,
    model: userModel,
    className: 'profile-icon-wrapper'
  };
};

const _getTitleOptions = (resultData, itemViewOptions) => {
  let title = resultData.get('title');

  if (resultData.get('type') === PageType.REFERENCE) {
    title = `<a class="page-item-title no-navigate" href="${ resultData.get('url') }" target="_blank" title="${ resultData.get('title') }">${ resultData.get('title') }</a>`;
  } else if (resultData.get('type') === PageType.TRAINING) {
    title = _.escape(title);
  }

  return {
    title: title,
    titleType: 'PRETEXT_TITLE',
    titleTag: itemViewOptions.titleTag,
    titleClass: 'truncate-3'
  };
};

const _getType = (resultData) => {
  const type = resultData.get('type');

  switch (type) {
    case PageType.TRAINING:
      return type + '-article-nobg';
    case PageType.ARTICLE:
    case PageType.QUESTION:
    case PageType.REFERENCE:
    default:
      return type;
  }
};

const articleSearchResultControllerDefinition = (resultData, itemViewOptions = {}) => {
  if (resultData == null || resultData.get('type') == null) {
    logging.warn('No article data returned by the search');
    window.app.layout.flash.error(I18n.t('selfDirected.search.errors.noSearchData'));
    return {};
  }

  let queryString = '';

  if (itemViewOptions.searchPageState != null) {
    queryString = itemViewOptions.searchPageState.get('searchString');
  }
  const defaultTileOptions = {
    viewClass: ArticleSearchResultTileView,
    cardClass: 'article-result__tile',
    hasLink: true,
    extraOptions: {
      searchPageState: itemViewOptions.searchPageState,
      userId: itemViewOptions.userId
    },
    templateHelpersOverride: {
      topRegionAttributes: '',
      topRegionClass: '',
      type: _getType(resultData)
    },
    attributesOverride: {
      'aria-label': resultData.get('title')
    },
    tabbableLinkClickableCard: true,
    linkOptions: {
      target: 'fullCard',
      callback: (e) => {
        if (!$(e.target).hasClass('no-navigate')) {
          Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ encodeURIComponent(resultData.get('pageId')) }?query=${ encodeURIComponent(queryString) }`, {
            trigger: true
          });
        }
      }
    },
    behaviors: {
      Resizable: {}
    },
    titleOptions: _getTitleOptions(resultData, itemViewOptions),
    data: {
      model: resultData
    }
  };

  Object.assign(
    defaultTileOptions,
    _getBottomTileViewDefinition(resultData, itemViewOptions)
  );

  return getBaseCardControllerDefinition(defaultTileOptions);
};

module.exports = {
  articleSearchResultControllerDefinition
};
