const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const _ = require('underscore');

const FilterableCommunityList = require('@common/data/collections/FilterableCommunityList');
const Community = require('@common/data/models/Community');
const {
  CommunityItemView,
  CommunityItemEmptyView
} = require('@common/components/filterableCommunity/CommunityItemView');
const CommunityCollectionView = require('@common/components/filterableCommunity/CommunityCollectionView');
const FilterControlView = require('@common/components/filterableCommunity/FilterControlView');
const KeyboardNavigationHelper = require('@common/components/filterableCommunity/KeyboardNavigationHelper');

const { getAllCommunitiesModel } = require('@common/components/filterableCommunity/ReportsCommunityHelper');

const ON = true;
const OFF = false;

class FilterableCommunityController extends LayoutController {
  initialize(options = {}) {
    ({
      setCommunityHandler: this.setCommunityHandler,
      placeholderText: this.placeholderText,
      contextModel: this.contextModel,
      label: this.label = '',
      hasAllCommunitiesOption: this.hasAllCommunitiesOption = false,
      pageType: this.pageType,
      communityAction: this.communityAction,
      community: this.community,
      filterCommunities: this.filterCommunities,
      communityType: this.communityType = 'folder'
    } = options);

    if (this.hasAllCommunitiesOption && (this.community && this.community.get('id') == null)) {
      this.community = getAllCommunitiesModel().get('community');
    }

    this.filteredCommunityList = this._getFilteredCommunityList();

    // Ensure current community is top of list for admin and
    // community will exist even if it is supposed to be hidden from admin in other contexts
    if (this._shouldPinCommunityForAdmin()) {
      // Use .clone to break the reference to this.community and avoid the model reference pointing to the selected
      // community when the selection changes
      this.hiddenFromAdminCommunity = this.community.clone();
      this.filteredCommunityList.add(this.hiddenFromAdminCommunity);
    }

    this.selectedCommunity = this._getSelectedCommunity(this.community);
    this.fetchDataOptions = this._getFetchDataOptions(this.pageType, this.communityAction, this.communityType);

    this._bodyHandler = this._bodyHandler.bind(this);
    this._switchDropdown = this._switchDropdown.bind(this);
    this._setCommunity = this._setCommunity.bind(this);
    this._updateFilter = this._updateFilter.bind(this);
    this.viewDefinition = this.viewDefinition.bind(this);

    this.keyboardNavigationHelper = new KeyboardNavigationHelper(this.filteredCommunityList, this._switchDropdown, this._setCommunity);

    if (this.contextModel) {
      this.listenTo(this.contextModel, 'change:community', this._onChangeCommunity);
    }

    $('body').on('click', this._bodyHandler);

    this.listenTo(this.filteredCommunityList, 'sync', () => {
      // Ensure current community is top of list for admin and community will exist even if it is supposed to be hidden
      // from admin in other contexts - do this when a hidden-from-admin selection exists and the search is cleared
      if (!this.isSearching && this.hiddenFromAdminCommunity != null) {
        this.filteredCommunityList.unshift(this.hiddenFromAdminCommunity);
      }
    });
  }

  _onChangeCommunity(article, community) {
    if (!this.contextModel.get('community')) {
      this._updateFilter('');
      this.fetchDataOptions.set('reset', true);
    } else if (this._isCommunitySelected(community)) {
      // When a community is selected, reset the search query so the search results are not preserved
      this._clearQuery();
    }

    this.trigger('change:community', community);
  }

  _getFilteredCommunityList() {
    return new FilterableCommunityList();
  }

  _getFetchDataOptions(pageType, communityAction) {
    const options = new Backbone.Model({
      p: 0,
      rpp: 10,
      pageType,
      communityAction,
      communityType: this.communityType
    });

    return options;
  }

  _getSelectedCommunity(community) {
    if (!community) {
      return this._getEmptyCommunity();
    }

    if (community.className === 'Community') {
      return community;
    }

    // at this point, `community` is a barebones object
    return new Community(community);
  }

  _getEmptyCommunity(query = '') {
    return new Community({
      names: {
        translations: {},
        default: query
      },
      id: null
    });
  }

  // See PostCreateFilterableCommunityController. Needed to override this function there only to add the
  // .recent-communities piece. If we need to do this again, maybe _getTemplate returns the outer container, and pieces
  // of this template can be broken down and returned in individual functions - a template generator, if you will
  _getTemplate() {
    return `<form class="filterable-community">
    ${ this.label ? `<label>${ this.label }</label>` : '' }
      <div class="js-filter-controls filter-controls"></div>
      <div class="js-dropdown-menu dropdown-menu hidden">
        <div class="filtered-communities">
          <h5><%- t('components.communityPicker.heading.allCommunities') %></h5>
          <div class="js-filtered-communities-list">
          </div>
        </div>
      </div>
    </form>`;
  }

  _shouldPinCommunityForAdmin() {
    return window.apps.auth.session.user.isAdminUser()
      && this.community != null
      && _.isFunction(this.community.get)
      && this.community.get('id') != null;
  }

  // Account for the possibility that a community option is a community model or a plain object
  // This can be improved. See SE-19531
  _isCommunitySelected(community) {
    return community != null
      && (_.isFunction(community.get) && community.get('id') != null)
      || (community.id != null);
  }

  _clearQuery() {
    this.isSearching = false;
    this.fetchDataOptions.set({
      query: '',
      reset: true
    });
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: this._getTemplate(),
      regions: {
        filterControls: '.js-filter-controls',
        filteredList: '.js-filtered-communities-list'
      }
    };
  }

  regionControllers() {
    return {
      filterControls: {
        viewDefinition: {
          ViewClass: FilterControlView,
          switchDropdown: this._switchDropdown,
          keyboardNavigationHandler: this.keyboardNavigationHelper.navHandler,
          updateFilter: this._updateFilter,
          placeholderText: this.placeholderText,
          model: this.selectedCommunity
        }
      },
      filteredList: {
        viewDefinition: {
          ViewClass: CommunityCollectionView,
          collection: this.filteredCommunityList,
          showAllCommunities: this.hasAllCommunitiesOption,
          childView: CommunityItemView,
          fetchDataOptions: this.fetchDataOptions,
          oneResultHandler: this._setCommunity,
          childViewOptions: {
            setCommunity: this._setCommunity
          },
          emptyView: CommunityItemEmptyView,
          filter: this.filterCommunities
        }
      }
    };
  }

  _updateFilter(query = '', isSearching = false) {
    // If All Communities is an option, hide it when the user is searching
    if (this.hasAllCommunitiesOption) {
      const collectionView = this.findControllerByRegion('filteredList').getView();
      collectionView.toggleAllCommunitiesOption(!isSearching);
    }

    this.isSearching = isSearching;
    this._setCommunity(this._getEmptyCommunity(query));


    if (query) {
      this.fetchDataOptions.set({
        query,
        reset: true
      });
    } else {
      this.fetchDataOptions.set({
        query: '',
        reset: true
      });
    }
  }

  _setCommunity(communityModel) {
    this.selectedCommunity.set(communityModel.attributes);
    this.setCommunityHandler(communityModel);
  }

  _bodyHandler(e) {
    if (!$(e.target).hasClass('js-filter-input') && !$(e.target).hasClass('js-trigger-dropdown')) {
      this._switchDropdown(OFF);
    }
  }

  _switchDropdown(state) {
    $('.js-filter-controls .js-trigger-dropdown').attr('aria-expanded', state === ON);
    $('.js-dropdown-menu').toggleClass('hidden', state === OFF);

    if (state === OFF) {
      this.keyboardNavigationHelper.reset();
    }
  }

  onBeforeDestroy() {
    $('body').off('click', this._bodyHandler);
  }
}

module.exports = FilterableCommunityController;
