module.exports = {
  "GuidedLearning": {
    "ExplanationModal": {
      "Description": {
        "context": "Path refers to a learning path.",
        "source": "professional",
        "value": "Welcome to your Path! Here you'll find items that will help you become great at a job or skill."
      },
      "certification": {
        "context": "description of what a certification is",
        "source": "professional",
        "value": "Demonstrate your knowledge by completing a certification."
      },
      "evaluation": {
        "source": "professional",
        "value": "Show your skills during an evaluation."
      },
      "event_enrollment": {
        "context": "description of what a event is",
        "source": "professional",
        "value": "Enroll in virtual or in-person training sessions."
      },
      "itemOneText": {
        "source": "professional",
        "value": "Milestones are goals in your learning path. Achieve all milestones by completing the items before it by the specified due date."
      },
      "itemOneTitle": {
        "source": "professional",
        "value": "Milestones"
      },
      "itemThreeText": {
        "source": "professional",
        "value": "The status at the top of your learning path will help you know if you are on track or falling behind to reach your Milestones."
      },
      "itemThreeTitle": {
        "source": "professional",
        "value": "Check your Status"
      },
      "itemTwoText": {
        "source": "professional",
        "value": "Your learning path may include items like Topics and Tasks that will help you become great at your job. You can select the Start button to start an item in your learning path."
      },
      "itemTwoTitle": {
        "source": "professional",
        "value": "Learning Path"
      },
      "milestone": {
        "context": "description of what a milestone is",
        "source": "professional",
        "value": "Stay on track by completing learning goals by their specified due dates."
      },
      "task": {
        "context": "description of what a task is",
        "source": "professional",
        "value": "Apply the knowledge you have learned on the job by completing tasks."
      },
      "topic": {
        "context": "description of what a topic is",
        "source": "professional",
        "value": "Learn by watching videos, completing training modules, and answering questions."
      }
    },
    "callSub": {
      "source": "professional",
      "value": "Select a learning destination"
    },
    "callToAction": {
      "source": "professional",
      "value": "Develop & grow your knowledge"
    },
    "checkpoints": {
      "source": "professional",
      "value": "<%- available %> checkpoint(s)"
    },
    "emptyMessage": {
      "source": "professional",
      "value": "There is no learning assigned."
    },
    "evaluation": {
      "criteria": {
        "emptyMessage": {
          "source": "professional",
          "value": "There are no active criteria set for this evaluation."
        },
        "label": {
          "context": "The label for the criteria being assessed during an evaluation",
          "source": "professional",
          "value": "Evaluation Criteria"
        }
      },
      "details": {
        "attemptNumber": {
          "context": "The current attempt number for this Evaluations. (Example) Attempt 3",
          "source": "professional",
          "value": "Attempt <%- attemptNumber %>"
        },
        "changeFile": {
          "context": "This is the button label when users on a Virtual Evaluation form want to replace the file they have currently uploaded",
          "source": "professional",
          "value": "Change File"
        },
        "coachMessage": {
          "failed": {
            "context": "Indicates that the user failed their evaluation, and shows the percentage they need to pass it the next time. (Example) Sorry, you need 75% to pass. Review and try again!",
            "source": "professional",
            "value": "Sorry, you need <%- passingScore %>% to pass. Review and try again!"
          },
          "lastAttempt": {
            "source": "professional",
            "value": "These are the results of your most recent evaluation."
          },
          "notStarted": {
            "source": "professional",
            "value": "Continue when you're ready to be evaluated."
          },
          "notSubmitted": {
            "source": "professional",
            "value": "Submit a file to be evaluated."
          },
          "passed": {
            "source": "professional",
            "value": "You passed the Evaluation! Let's see your results..."
          }
        },
        "lastAttemptDate": {
          "context": "The date that the previous attempt of this Evaluation was undertaken. (Example) Evaluated on February 22, 2021",
          "source": "professional",
          "value": "Evaluated on"
        },
        "percentUploaded": {
          "context": "The percentage of the upload process that has completed so far. (Example) 25% uploaded",
          "source": "professional",
          "value": "<%- percent %>% uploaded"
        },
        "pointsAvailable": {
          "context": "The number of reward points available for passing this evaluation. (Example) Reward points available: 10",
          "source": "professional",
          "value": "Reward points available: <%- points %>"
        },
        "toBeAssigned": {
          "source": "professional",
          "value": "To be assigned"
        },
        "uploadAFile": {
          "context": "This is the button label when users on a Virtual Evaluation form are ready to upload a file to be evaluated",
          "source": "professional",
          "value": "Upload a File"
        },
        "uploadModalTitle": {
          "context": "This is the title of the modal window allowing users to upload a file for a Virtual Evaluation",
          "source": "professional",
          "value": "Submit Evaluation File"
        },
        "uploadProcessing": {
          "context": "This is the message to inform the user that the file has been completely transmitted but is still being processed before it will be ready",
          "source": "professional",
          "value": "100% uploaded - processing..."
        },
        "uploadWarning": {
          "context": "This is the (generic) message to inform the user that their file was unable to be uploaded",
          "source": "professional",
          "value": "File upload invalid - please choose another file"
        }
      },
      "error": {
        "3136": {
          "source": "professional",
          "value": "You cannot create a virtual evaluation with no active criteria.  Please contact your Administrator."
        }
      },
      "imported": {
        "source": "professional",
        "value": "Details not available"
      },
      "status": {
        "ATTEMPT_PENDING": {
          "source": "professional",
          "value": "Pending"
        },
        "FAILED": {
          "context": "This is the Label for the status checkbox on the evaluation details page when the user did not meet the evaluation criteria",
          "source": "professional",
          "value": "Not Passed"
        },
        "NOT_STARTED": {
          "source": "professional",
          "value": "Not Started"
        },
        "NOT_SUBMITTED": {
          "context": "This is the Label for the status on the evaluation details page of a virtual evaluation when the user has not yet submitted anything to evaluate",
          "source": "professional",
          "value": "Not Submitted"
        },
        "PASSED": {
          "context": "This is the Label for the status checkbox on the evaluation details page when the user successfully met the evaluation criteria",
          "source": "professional",
          "value": "Passed"
        },
        "PENDING": {
          "context": "This is the Label for the status checkbox on the evaluation details page when there are no results yet for a given evaluation criteria",
          "source": "professional",
          "value": "No Results"
        }
      }
    },
    "milestoneReachedMessage": {
      "context": "The message displayed when a specified guided learning milestone has been reached.",
      "source": "professional",
      "value": "Milestone Complete"
    },
    "viewDeletedItem": {
      "source": "professional",
      "value": "The item has been deleted"
    }
  },
  "SelfDirectedPaths": {
    "enrollment": {
      "enroll": {
        "context": "'title' refers to the self-directed paths program title",
        "source": "professional",
        "value": "Congratulations! You have successfully enrolled into '<%- title %>'"
      },
      "unenroll": {
        "context": "'title' refers to the self-directed paths program title",
        "source": "professional",
        "value": "You have successfully unenrolled from '<%- title %>'"
      }
    },
    "search": {
      "notFound": {
        "source": "professional",
        "value": "This path could not be found."
      }
    },
    "title": {
      "source": "professional",
      "value": "Paths"
    }
  },
  "achievements": {
    "CorrectAnswerStreak": {
      "subTitle": {
        "source": "professional",
        "value": "Your longest correct answer streaks to date"
      },
      "title": {
        "source": "professional",
        "value": "Correct Answer Streak Badges"
      }
    },
    "ParticipationFrequency": {
      "subTitle": {
        "source": "professional",
        "value": "Your highest number of training sessions in 30 days"
      },
      "title": {
        "source": "professional",
        "value": "Frequent Training Badges"
      }
    },
    "SessionStreak": {
      "subTitle": {
        "source": "professional",
        "value": "The total number of training sessions you've completed"
      },
      "title": {
        "source": "professional",
        "value": "Life-Time Training Sessions"
      }
    },
    "TopicLevelGraduationStreak": {
      "subTitle": {
        "source": "professional",
        "value": "The total number of topic levels you've graduated"
      },
      "title": {
        "source": "professional",
        "value": "Graduation Milestones"
      }
    },
    "accessibilityNotes": {
      "allEarned": {
        "source": "professional",
        "value": "All achievements are earned."
      },
      "lastEarned": {
        "source": "professional",
        "value": "Last achievement earned is <%- achievementName %>."
      },
      "nextAvailable": {
        "source": "professional",
        "value": "Next available achievement to earn is <%- achievementName %>."
      },
      "noneEarned": {
        "source": "professional",
        "value": "All achievements are available to earn."
      }
    },
    "achieved": {
      "source": "professional",
      "value": "Achieved."
    },
    "iconLabels": {
      "AuctionWinner": {
        "source": "professional",
        "value": "Auction winner"
      },
      "BuyNowWinner": {
        "source": "professional",
        "value": "Buy now winner"
      },
      "CorrectAnswerStreak": {
        "source": "professional",
        "value": "Streak of <%- streakSize %> correct answers"
      },
      "GameChallengeLoss": {
        "source": "professional",
        "value": "Game challenge loss"
      },
      "GameChallengeTie": {
        "source": "professional",
        "value": "Game challenge tie"
      },
      "GameChallengeWon": {
        "source": "professional",
        "value": "Game challenge won"
      },
      "GameHighScore1": {
        "source": "professional",
        "value": "First place, ranked gold"
      },
      "GameHighScore2": {
        "source": "professional",
        "value": "Second place, ranked silver"
      },
      "GameHighScore3": {
        "source": "professional",
        "value": "Third place, ranked bronze"
      },
      "ParticipationFrequency": {
        "source": "professional",
        "value": "Frequent training, <%- streakSize %> sessions"
      },
      "RaffleWinner": {
        "source": "professional",
        "value": "Raffle winner"
      },
      "SessionStreak": {
        "source": "professional",
        "value": "Streak of <%- streakSize %> sessions"
      },
      "SlotsWinner": {
        "source": "professional",
        "value": "Winner of spin to win"
      },
      "TopicLevelGraduation": {
        "source": "professional",
        "value": "Topic level graduation"
      },
      "TopicLevelGraduationStreak": {
        "source": "professional",
        "value": "Streak of <%- streakSize %> topic level graduations"
      }
    },
    "notAchieved": {
      "source": "professional",
      "value": "Not achieved."
    },
    "recognitionPins": {
      "aboutThisPin": {
        "source": "professional",
        "value": "About this pin"
      },
      "header": {
        "source": "professional",
        "value": "My Recognition Pins"
      },
      "myAwards": {
        "source": "professional",
        "value": "My Pins:"
      },
      "numberReceived": {
        "source": "professional",
        "value": "<%- count %> received"
      },
      "sendAPinButton": {
        "source": "professional",
        "value": "Send a Pin"
      },
      "viewButton": {
        "source": "professional",
        "value": "View Pins"
      }
    },
    "tabs": {
      "pins": {
        "source": "professional",
        "value": "Pins"
      },
      "progress": {
        "source": "professional",
        "value": "Progress"
      }
    }
  },
  "activateaccount": {
    "activated": {
      "source": "professional",
      "value": "Your password has been successfully changed and account reactivated."
    },
    "entersecurityanswer": {
      "source": "professional",
      "value": "Enter security answer"
    },
    "securityanswer": {
      "source": "professional",
      "value": "Answer <%- i %>:"
    },
    "updateaoi": {
      "source": "professional",
      "value": "Update Areas of Intreset"
    }
  },
  "assessments": {
    "bail": {
      "error": {
        "source": "professional",
        "value": "Failed to pause assessment. Please try again."
      },
      "label": {
        "source": "professional",
        "value": "Save and exit assessment"
      }
    },
    "deeplink": {
      "button": {
        "source": "professional",
        "value": "Take Quiz"
      },
      "subheading": {
        "plural": {
          "source": "professional",
          "value": "<%- questionCount %> questions are ready for you"
        },
        "singular": {
          "source": "professional",
          "value": "1 question is ready for you"
        }
      }
    },
    "item": {
      "action": {
        "pass": {
          "source": "professional",
          "value": "Start"
        }
      },
      "attemptsRemaining": {
        "plural": {
          "context": "the number of attempts remaining to retake the exam",
          "source": "professional",
          "value": "<%- count %> attempts remaining"
        },
        "singular": {
          "context": "1 attempt remaining to retake the exam",
          "source": "professional",
          "value": "1 attempt remaining"
        }
      },
      "catSub": {
        "source": "professional",
        "value": "<%- category.name %>, <%- subject.name %>"
      },
      "evaluation": {
        "pending": {
          "source": "professional",
          "value": "<strong>Evaluation Pending:</strong> Return to the path later to prepare or view your results."
        },
        "pendingVirtual": {
          "source": "professional",
          "value": "<strong>Evaluation Pending:</strong> You've submitted your evaluation. Check back later to view your results."
        },
        "success": {
          "source": "professional",
          "value": "Success! Your evaluation will begin when your evaluator is ready."
        },
        "successVirtual": {
          "source": "professional",
          "value": "Success! Your evaluation file has been submitted."
        }
      },
      "grade": {
        "text": {
          "currentScore": {
            "source": "professional",
            "value": "Current score"
          },
          "fail": {
            "source": "professional",
            "value": "Not passed"
          },
          "incomplete": {
            "source": "professional",
            "value": "Incomplete"
          },
          "pass": {
            "source": "professional",
            "value": "Passed"
          }
        },
        "value": {
          "source": "professional",
          "value": "<%- grade %>%"
        }
      },
      "lastScore": {
        "source": "professional",
        "value": "<%- correct %> of <%- total %> correct"
      },
      "level": {
        "source": "professional",
        "value": "Level"
      },
      "locked": {
        "source": "professional",
        "value": "Locked"
      },
      "passGrade": {
        "source": "professional",
        "value": "Need <%- grade %>% to pass"
      },
      "passToUnlock": {
        "context": "Pass Cashier Best Practices to unlock",
        "source": "professional",
        "value": "Pass <%- topic %> to unlock"
      },
      "progress": {
        "source": "professional",
        "value": "<%- answered %> of <%- total %> answered"
      },
      "unlockBy": {
        "source": "professional",
        "value": "Unlock by passing <%= name %>"
      }
    },
    "result": {
      "current": {
        "source": "professional",
        "value": "Current result"
      },
      "fastTrack": {
        "complete": {
          "source": "professional",
          "value": "Completed with Fast Track"
        },
        "fail": {
          "message": {
            "source": "professional",
            "value": "Take the full training to increase your knowledge and earn reward points."
          },
          "title": {
            "source": "professional",
            "value": "You have not completed this topic with Fast Track."
          }
        },
        "success": {
          "message": {
            "source": "professional",
            "value": "You've saved time by completing this topic with Fast Track."
          },
          "title": {
            "source": "professional",
            "value": "Great job!"
          }
        }
      },
      "message": {
        "incomplete": {
          "source": "professional",
          "value": "Time's Up!"
        }
      },
      "next": {
        "source": "professional",
        "value": "Next item"
      }
    },
    "select": {
      "formalExam": {
        "searchLabel": {
          "source": "professional",
          "value": "Filter by Exam name"
        }
      },
      "header": {
        "title": {
          "CertificationTraining": {
            "source": "professional",
            "value": "Certifications"
          },
          "EvaluationTraining": {
            "source": "professional",
            "value": "Evaluation"
          },
          "EventEnrollment": {
            "source": "professional",
            "value": "Events"
          },
          "ExtraTrainingQuestions": {
            "source": "professional",
            "value": "Extra Training"
          },
          "FormalExamTraining": {
            "source": "professional",
            "value": "Exams"
          },
          "GuidedLearningTraining": {
            "context": "Learning Path",
            "source": "professional",
            "value": "Path"
          },
          "IntroductoryTraining": {
            "source": "professional",
            "value": "New Topics"
          },
          "RefresherTraining": {
            "source": "professional",
            "value": "Refresher Training"
          },
          "SelfDirectedTraining": {
            "source": "professional",
            "value": "Path"
          },
          "TaskTraining": {
            "source": "professional",
            "value": "Task"
          },
          "learnerTraining": {
            "source": "professional",
            "value": "Training"
          }
        },
        "upNext": {
          "source": "professional",
          "value": "Up Next"
        }
      },
      "instructions": {
        "plural": {
          "CertificationTraining": {
            "context": "Prompt for user to begin certification assessments that are assigned to them.",
            "source": "professional",
            "value": "There are some certifications available to you."
          },
          "FormalExamTraining": {
            "context": "Prompt for user to begin exam assessments that are assigned to them.",
            "source": "professional",
            "value": "There are some exams available to you."
          },
          "IntroductoryTraining": {
            "context": "Prompt for user to begin intro assessments that are assigned to them.",
            "source": "professional",
            "value": "There are some new topics available to you."
          },
          "RefresherTraining": {
            "context": "Prompt for user to begin refresher assessments that are assigned to them.",
            "source": "professional",
            "value": "You have some topics in need of a refresher."
          }
        },
        "singular": {
          "CertificationTraining": {
            "context": "Prompt for user to begin a singular certification assessment that is assigned to them.",
            "source": "professional",
            "value": "There is a certification available to you."
          },
          "FormalExamTraining": {
            "context": "Prompt for user to begin a singular exam assessment that is assigned to them.",
            "source": "professional",
            "value": "There is an exam available to you."
          },
          "IntroductoryTraining": {
            "context": "Prompt for user to begin a singular intro assessment that is assigned to them.",
            "source": "professional",
            "value": "There is a new topic available to you."
          },
          "RefresherTraining": {
            "context": "Prompt for user to begin a singular refresher assessment that is assigned to them.",
            "source": "professional",
            "value": "You have a topic in need of a refresher."
          }
        }
      },
      "notopics": {
        "source": "professional",
        "value": "There are no topics to display."
      },
      "progress": {
        "score": {
          "source": "professional",
          "value": "<%- complete %> of <%- total %>"
        },
        "text": {
          "CertificationTraining": {
            "source": "professional",
            "value": "Certifications Completed"
          },
          "FormalExamTraining": {
            "source": "professional",
            "value": "Exams Completed"
          },
          "IntroductoryTraining": {
            "source": "professional",
            "value": "Topics Completed"
          },
          "RefresherTraining": {
            "source": "professional",
            "value": "Topics Completed"
          }
        }
      },
      "retake": {
        "source": "",
        "value": "Re-Take"
      },
      "searchLabel": {
        "source": "professional",
        "value": "Filter by Category, Subject, or Topic"
      },
      "startFullTopic": {
        "source": "professional",
        "value": "Start Full Topic"
      },
      "startNext": {
        "source": "professional",
        "value": "Start Next"
      },
      "viewPage": {
        "CertificationTraining": {
          "source": "professional",
          "value": "View Certifications"
        },
        "FormalExamTraining": {
          "source": "professional",
          "value": "View Exams"
        },
        "GuidedLearningTraining": {
          "context": "Path = Learning Path",
          "source": "professional",
          "value": "View Path"
        },
        "IntroductoryTraining": {
          "source": "professional",
          "value": "View New Topics"
        },
        "PathTraining": {
          "source": "professional",
          "value": "View Path"
        },
        "RefresherTraining": {
          "source": "professional",
          "value": "View Refresher Training"
        }
      }
    }
  },
  "auction": {
    "auction": {
      "source": "professional",
      "value": "Rewards - Auction"
    },
    "bidLabel": {
      "source": "professional",
      "value": "Bid Amount:"
    },
    "bidbtn": {
      "source": "professional",
      "value": "Bid"
    },
    "bidcount": {
      "source": "professional",
      "value": "Bid Count"
    },
    "buyNowEnded": {
      "source": "professional",
      "value": "Item is not currently available."
    },
    "buybtn": {
      "source": "professional",
      "value": "Buy"
    },
    "buybtnAccessibility": {
      "source": "professional",
      "value": "Buy <%- prizeName %> for <%- prizeCost %>"
    },
    "buynow": {
      "source": "professional",
      "value": "Shop"
    },
    "buynowmodal": {
      "source": "professional",
      "value": "Buy for <span class='coin'><%- price %></span>?"
    },
    "buynowprice": {
      "source": "professional",
      "value": "<%- price %> Reward Points"
    },
    "closed": {
      "source": "professional",
      "value": "Auction has closed."
    },
    "confirmPurchase": {
      "source": "professional",
      "value": "Confirm Purchase"
    },
    "costPerSpin": {
      "points": {
        "source": "professional",
        "value": "Cost Per Spin"
      },
      "title": {
        "source": "professional",
        "value": "Cost Per Spin"
      }
    },
    "current": {
      "source": "professional",
      "value": "Current Bid"
    },
    "editbid": {
      "source": "professional",
      "value": "Edit Bid"
    },
    "imageZoomLabel": {
      "context": "This is a text callout for screen readers on a button that will open a modal of an enlarged image",
      "source": "professional",
      "value": "View enlarged image for reward <%- prizeName %>"
    },
    "issoldout": {
      "source": "professional",
      "value": "<%- name %> is sold out."
    },
    "itemdetails": {
      "source": "professional",
      "value": "Auction Details"
    },
    "locked": {
      "source": "professional",
      "value": "Opens in "
    },
    "minimum": {
      "source": "professional",
      "value": "Minimum Bid"
    },
    "noRewards": {
      "source": "professional",
      "value": "There are no rewards."
    },
    "noauctions": {
      "source": "professional",
      "value": "There are no auctions."
    },
    "notice": {
      "auctionclosed": {
        "source": "professional",
        "value": "This auction has closed."
      },
      "biddeleted": {
        "source": "professional",
        "value": "The minimum bid for this item has been raised. Please bid again."
      },
      "forbiddenbid": {
        "source": "professional",
        "value": "The winning bidder can't lower the bid."
      },
      "invalidBid": {
        "source": "professional",
        "value": "Your bid was not a valid number."
      },
      "minbid": {
        "source": "professional",
        "value": "Your bid does not meet the minimum bid for this item."
      },
      "nobuynow": {
        "source": "professional",
        "value": "You don't have enough reward points to buy this prize."
      },
      "nomoney": {
        "source": "professional",
        "value": "You don't have enough reward points to bid this amount."
      }
    },
    "outOfSpins": {
      "source": "professional",
      "value": "Out of spins"
    },
    "outOfStock": {
      "source": "professional",
      "value": "Item is out of stock."
    },
    "outbid": {
      "source": "professional",
      "value": "You have been outbid."
    },
    "placebid": {
      "source": "professional",
      "value": "Place Bid"
    },
    "pointBalance": {
      "source": "professional",
      "value": "Reward Points"
    },
    "prizepurchased": {
      "source": "professional",
      "value": "<%- name %> purchased!"
    },
    "purchased": {
      "source": "professional",
      "value": "Purchased"
    },
    "raffle": {
      "source": "professional",
      "value": "Rewards - Raffle"
    },
    "raffleItem": {
      "buybtn": {
        "source": "professional",
        "value": "Buy Tickets"
      },
      "notice": {
        "exceedsMaxTicketCount": {
          "source": "professional",
          "value": "You can buy a maximum <%- maxTicketCount %> raffle tickets at one time. Please make multiple purchases."
        },
        "insufficientFunds": {
          "source": "professional",
          "value": "You don't have enough reward points to buy <%- count %> raffle tickets."
        },
        "insufficientFundsSingular": {
          "context": "Error message when trying to buy 1 raffle ticket.",
          "source": "professional",
          "value": "You don't have enough reward points to buy a raffle ticket."
        },
        "invalidTicketCount": {
          "source": "professional",
          "value": "Not a valid number of tickets."
        }
      },
      "purchaseTickets": {
        "label": {
          "source": "professional",
          "value": "Number of tickets to purchase"
        },
        "title": {
          "source": "professional",
          "value": "Purchase Tickets"
        }
      },
      "raffleEnded": {
        "source": "professional",
        "value": "The raffle has ended."
      },
      "ticketPrice": {
        "source": "professional",
        "value": "Ticket Price"
      },
      "ticketsPurchased": {
        "source": "professional",
        "value": "Tickets You Have Purchased"
      }
    },
    "removebid": {
      "source": "professional",
      "value": "Remove Bid"
    },
    "shop": {
      "source": "professional",
      "value": "Rewards - Shop"
    },
    "slotMachineWin": {
      "source": "professional",
      "value": "<%- name %> won!"
    },
    "spin": {
      "source": "professional",
      "value": "Spin"
    },
    "spinToWin": {
      "source": "professional",
      "value": "Rewards - Spin-To-Win"
    },
    "spinToWinText": {
      "source": "professional",
      "value": "Spin-To-Win"
    },
    "spinning": {
      "source": "professional",
      "value": "Good Luck"
    },
    "spinsLeft": {
      "source": "professional",
      "value": "Spins Left"
    },
    "timeremaining": {
      "source": "professional",
      "value": "Closes in"
    },
    "tryAgain": {
      "source": "professional",
      "value": "Try Again"
    },
    "winner": {
      "source": "professional",
      "value": "Winner"
    },
    "winningbid": {
      "source": "professional",
      "value": "You have a winning bid, for now."
    },
    "yourbid": {
      "source": "professional",
      "value": "Your Bid"
    }
  },
  "broadcastMessage": {
    "announcement": {
      "source": "professional",
      "value": "Announcement"
    },
    "dzArticleHeader": {
      "source": "professional",
      "value": "<%= t('appNames.knowledge') %> Article"
    },
    "dzArticleMediaDownload": {
      "source": "professional",
      "value": "Download"
    },
    "dzArticleMediaDownloadWhich": {
      "source": "professional",
      "value": "Download file <%- name %>"
    },
    "dzArticleOpenBtn": {
      "context": "button to view article",
      "source": "professional",
      "value": "View full article"
    },
    "dzArticleReadBtn": {
      "source": "professional",
      "value": "Read"
    },
    "header": {
      "source": "professional",
      "value": "Broadcast"
    },
    "kicker": {
      "source": "professional",
      "value": "Broadcast Message"
    },
    "messageBody": {
      "source": "professional",
      "value": "Broadcast Message body"
    },
    "publishedInCommunity": {
      "source": "professional",
      "value": "Published in <%- communityName %>"
    },
    "region": {
      "dzContentRegion": {
        "source": "professional",
        "value": "Article content"
      },
      "dzWholeRegion": {
        "source": "professional",
        "value": "Article"
      }
    },
    "rewardPoints": {
      "source": "professional",
      "value": "<%- points %> reward points"
    }
  },
  "channels": {
    "channelDetails": {
      "readLessAria": {
        "context": "Aria label that instructs the user that upon clicking a button, the description of a channel of a particular name will be partially hidden",
        "source": "professional",
        "value": "Click to read less of the description for the <%- name %> channel"
      },
      "readMoreAria": {
        "context": "Aria label that instructs the user that upon clicking a button, the description of a channel of a particular name will be revealed",
        "source": "professional",
        "value": "Click to read more of the description for the <%- name %> channel"
      }
    },
    "channelsEngagementReport": {
      "title": {
        "source": "professional",
        "value": "Channels Engagement Report"
      }
    },
    "management": {
      "access": {
        "error": {
          "3064": {
            "source": "professional",
            "value": "Channel Name and Description must not include HTML tags. Please remove any HTML tags from these fields."
          }
        }
      },
      "archive": {
        "success": {
          "source": "professional",
          "value": "You have successfully archived this channel"
        }
      },
      "archiveButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to archive a channel.",
        "source": "professional",
        "value": "Archive the <%- communityName %> channel."
      },
      "archiveModal": {
        "confirmationText": {
          "source": "professional",
          "value": "This will hide the channel and all contained content from any form of access, including search. All content items in this channel will be preserved and can be restored."
        },
        "title": {
          "context": "The title of the modal a user sees when archiving a channel",
          "source": "professional",
          "value": "Archive Channel"
        }
      },
      "count": {
        "context": "A string above a table of results letting the user know they are viewing a subset of results out of the total set of results",
        "source": "professional",
        "value": "Viewing <%- currentCommunitiesList %> of <%- totalCommunities %> Channels"
      },
      "create": {
        "context": "The text on the create button to create a new channel",
        "source": "professional",
        "value": "Add Channel"
      },
      "delete": {
        "success": {
          "source": "professional",
          "value": "The channel has been deleted."
        }
      },
      "deleteButtonAriaLabel": {
        "context": "Delete the Development channel.",
        "source": "professional",
        "value": "Delete the <%- communityName %> channel."
      },
      "deleteModal": {
        "confirmationText": {
          "context": "This text shows up on a modal to confirm a users choice in deleting a channel, where communityName is the name of the channel the user wants to delete.",
          "source": "professional",
          "value": "Are you sure you want to delete the <%- communityName %> channel? This cannot be undone."
        },
        "title": {
          "context": "This is the title of the modal a user sees when deleting a channel",
          "source": "professional",
          "value": "Delete Channel"
        }
      },
      "error": {
        "3001": {
          "source": "professional",
          "value": "The channel could not be found"
        },
        "3017": {
          "source": "professional",
          "value": "You do not have access to this channel."
        },
        "3054": {
          "context": "A channel or folder with code: \"DS321\" already exists. Please enter a different code or leave it blank to assign one automatically",
          "source": "professional",
          "value": "A channel or folder with code: \"<%- code %>\" already exists. Please enter a different code or leave it blank to assign one automatically"
        },
        "3060": {
          "source": "professional",
          "value": "This channel was recently archived and cannot be restored yet. Please wait 1 minute and try again."
        },
        "3064": {
          "code": {
            "source": "professional",
            "value": "Channel Code is invalid. The text entered contains HTML tags, which are not allowed. Fix the entered text to not include HTML tags."
          }
        },
        "delete": {
          "3001": {
            "source": "professional",
            "value": "The channel you tried to delete has been deleted by someone else, or does not exist anymore."
          },
          "3007": {
            "source": "professional",
            "value": "You can't delete a channel with posts in it. Delete all the posts before trying to delete this channel again."
          }
        },
        "edit": {
          "3054": {
            "context": "A channel or folder with code: \"DS321\" already exists. Please enter a different code or leave it blank to assign one automatically",
            "source": "professional",
            "value": "A channel or folder with code: \"<%- code %>\" already exists. Please enter a different code or leave it blank to assign one automatically"
          }
        }
      },
      "exportTeamChannels": {
        "source": "professional",
        "value": "Export Team Channels List"
      },
      "goToCommunityLinkTitle": {
        "source": "professional",
        "value": "Go to channel"
      },
      "import": {
        "fail": {
          "context": "Attempting to import team channels from file with id 12892 has failed.",
          "source": "professional",
          "value": "Attempting to import team channels from file with id <%- id %> has failed. You can find more details in the Import History tab."
        }
      },
      "importTeamChannels": {
        "source": "professional",
        "value": "Import Team Channels (.csv)"
      },
      "inputPlaceholder": {
        "context": "This is a placeholder string on an input field that tells the user what criteria they can search by. This will update a table below this input field.",
        "source": "professional",
        "value": "Search by Channel Name or Code"
      },
      "restore": {
        "success": {
          "source": "professional",
          "value": "You have successfully restored this channel"
        }
      },
      "restoreButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to restore a channel.",
        "source": "professional",
        "value": "Restore the <%- communityName %> channel."
      },
      "restoreModal": {
        "confirmationText": {
          "source": "professional",
          "value": "All content items and Channel settings will be restored."
        },
        "title": {
          "source": "professional",
          "value": "Restore Channel"
        }
      },
      "saved": {
        "source": "professional",
        "value": "Your channel has been saved. Changes may take up to 1 minute to be reflected in the channel."
      },
      "settingsLinkAriaLabel": {
        "context": "Accessibility text to tell the user that this link redirects to the Channel Settings page of the chosen channel.",
        "source": "professional",
        "value": "Go to the <%- communityName %> Channel Settings page."
      },
      "status": {
        "expired": {
          "source": "professional",
          "value": "Expired"
        },
        "scheduled": {
          "source": "professional",
          "value": "Scheduled"
        }
      },
      "unsafeContentRemoved": {
        "source": "professional",
        "value": "Unsafe content was removed from the channel name or description before saving."
      }
    },
    "postModal": {
      "allChannelsGroup": {
        "source": "professional",
        "value": "All Channels"
      },
      "channelSelectorPlaceholder": {
        "source": "professional",
        "value": "Select a Channel..."
      },
      "recentChannelsGroup": {
        "source": "professional",
        "value": "Recent Channels"
      }
    },
    "settings": {
      "administrationVisibility": {
        "source": "professional",
        "value": "Administration Visibility"
      },
      "channelAccess": {
        "source": "professional",
        "value": "Channel Access:"
      },
      "channelCode": {
        "source": "professional",
        "value": "Channel Code"
      },
      "channelName": {
        "source": "professional",
        "value": "Channel Name"
      },
      "channelThumbnail": {
        "source": "professional",
        "value": "Channel Thumbnail"
      },
      "commenting": {
        "source": "professional",
        "value": "Commenting"
      },
      "commentingSecondary": {
        "source": "professional",
        "value": "Members can create and view comments on posts."
      },
      "contentCreation": {
        "source": "professional",
        "value": "Content Creation"
      },
      "delivery": {
        "source": "professional",
        "value": "Delivery"
      },
      "editChannel": {
        "source": "professional",
        "value": "Edit Channel"
      },
      "enableEndDate": {
        "source": "professional",
        "value": "Enable End Date"
      },
      "enableRewardsPoints": {
        "source": "professional",
        "value": "Enable Rewards Points"
      },
      "enableRewardsPointsSecondary": {
        "source": "professional",
        "value": "Users will receive reward points for engagement in this Channel (up to the total sum of points available)"
      },
      "endDate": {
        "source": "professional",
        "value": "End Date"
      },
      "endDateSecondary": {
        "source": "professional",
        "value": "End Date is visible to users."
      },
      "engagements": {
        "context": "title for the number of engagments in a given time frame",
        "source": "professional",
        "value": "Engagements"
      },
      "error": {
        "create": {
          "source": "professional",
          "value": "Could not create a channel, an unexpected error has occurred."
        },
        "update": {
          "source": "professional",
          "value": "Could not update a channel, an unexpected error has occurred."
        }
      },
      "errors": {
        "required": {
          "name": {
            "source": "professional",
            "value": "Channel Name is required."
          },
          "startDate": {
            "source": "professional",
            "value": "Start Date is required."
          }
        }
      },
      "gamification": {
        "source": "professional",
        "value": "Gamification"
      },
      "hidePosts": {
        "source": "professional",
        "value": "Hide posts from this Channel on Administrator's Timeline"
      },
      "hidePostsSecondary": {
        "source": "professional",
        "value": "Channels can be accessed from Channels Management page"
      },
      "imageSharing": {
        "source": "professional",
        "value": "Image Sharing"
      },
      "imageSharingSecondary": {
        "source": "professional",
        "value": "Members can attach images when creating posts and comments."
      },
      "includeSubTeamsSwitchDescription": {
        "source": "professional",
        "value": "Sub-Teams are included in this Channel and their users will inherit the same access settings"
      },
      "maxAI": {
        "source": "professional",
        "value": "Max AI"
      },
      "maxAISecondary": {
        "source": "professional",
        "value": "Max can reference Posts in this channel when answering questions."
      },
      "newChannel": {
        "source": "professional",
        "value": "New Channel"
      },
      "newChannelAlert": {
        "source": "professional",
        "value": "New Channel Alert"
      },
      "newChannelAlertSecondary": {
        "source": "professional",
        "value": "Any recipients using the Axonify mobile application will receive a push notification alerting them of this new channel"
      },
      "newPosts": {
        "source": "professional",
        "value": "New Posts from this Channel"
      },
      "newPostsSecondary": {
        "source": "professional",
        "value": "Any recipients using the Axonify mobile application will receive a push notification each time a new post is added in this channel"
      },
      "other": {
        "source": "professional",
        "value": "Other"
      },
      "pointsForPost": {
        "source": "professional",
        "value": "Points per Post"
      },
      "pointsPerComment": {
        "source": "professional",
        "value": "Points per Comment"
      },
      "reactions": {
        "source": "professional",
        "value": "Reactions"
      },
      "reactionsSecondary": {
        "source": "professional",
        "value": "Members can react and see reactions on posts and comments."
      },
      "showNewChannelAlert": {
        "source": "professional",
        "value": "Show New Channel Alert on All Messages in Timeline"
      },
      "showNewChannelAlertSecondary": {
        "source": "professional",
        "value": "Display an alert on the user’s Timeline based on the Channel's Start Date."
      },
      "showPosts": {
        "source": "professional",
        "value": "Show Posts on All Messages in Timeline"
      },
      "showPostsSecondary": {
        "source": "professional",
        "value": "Display posts from this Channel in a users Timeline."
      },
      "startDate": {
        "source": "professional",
        "value": "Start Date"
      },
      "success": {
        "create": {
          "source": "professional",
          "value": "Channel has been successfully created."
        },
        "update": {
          "source": "professional",
          "value": "Channel has been successfully updated."
        }
      },
      "totalPointsLimit": {
        "source": "professional",
        "value": "Total Points Limit"
      },
      "userMentioning": {
        "source": "professional",
        "value": "User Mentioning"
      },
      "userMentioningSecondary": {
        "source": "professional",
        "value": "Members can <b>@mention</b> others in the same <b>active</b> channel to notify them in posts and comments."
      },
      "videoSharing": {
        "source": "professional",
        "value": "Video Sharing"
      },
      "videoSharingSecondary": {
        "source": "professional",
        "value": "Members can attach a video when creating posts."
      }
    }
  },
  "chat": {
    "channel": {
      "accessibility": {
        "chatChannelButton": {
          "context": "Open channel: Channel name",
          "source": "professional",
          "value": "Open channel: <%- name %>"
        }
      },
      "creationError": {
        "source": "professional",
        "value": "Something went wrong while creating the channel. Please try again later."
      },
      "lastMessage": {
        "sentAttachmentPlural": {
          "source": "professional",
          "value": "<%- senderName %> sent <%- attachmentCount %> attachments"
        },
        "sentAttachmentSingular": {
          "source": "professional",
          "value": "<%- senderName %> sent an attachment"
        }
      },
      "menu": {
        "item": {
          "AddParticipants": {
            "context": "Menu item to add participants to a chat channel",
            "source": "professional",
            "value": "Add Participants"
          },
          "EditChatName": {
            "context": "Menu item to edit the name of a chat channel",
            "source": "professional",
            "value": "Edit Chat Name"
          },
          "leaveChat": {
            "context": "Menu item to leave a chat channel",
            "source": "professional",
            "value": "Leave Chat"
          },
          "viewParticipants": {
            "context": "Menu item to view participants in a chat channel",
            "source": "professional",
            "value": "View Participants"
          }
        }
      },
      "name": {
        "edit": {
          "help": {
            "context": "helper text for the field to edit the chat channel name",
            "source": "professional",
            "value": "Maximum length: 255 characters"
          },
          "label": {
            "context": "label for the field to edit the chat channel name",
            "source": "professional",
            "value": "Chat Name"
          }
        }
      },
      "noActiveUsers": {
        "context": "Specifying that there are no active users in a chat",
        "source": "professional",
        "value": "No Active Users"
      },
      "participants": {
        "source": "Professional",
        "value": "Chat Participants"
      }
    },
    "chatContextMenuButton": {
      "source": "professional",
      "value": "Action menu for channel"
    },
    "delete": {
      "modal": {
        "body": {
          "context": "Chat delete modal body text",
          "source": "professional",
          "value": "Are you sure you want to delete this message? It cannot be undone."
        },
        "title": {
          "context": "Chat delete message modal title",
          "source": "professional",
          "value": "Delete Message?"
        }
      }
    },
    "errors": {
      "userList": {
        "source": "Professional",
        "value": "Error loading available users"
      }
    },
    "headerNavButton": {
      "button": {
        "source": "professional",
        "value": "Chat"
      }
    },
    "message": {
      "accessibility": {
        "contextActionButton": {
          "source": "professional",
          "value": "action menu for message"
        },
        "contextEmojiButton": {
          "source": "professional",
          "value": "emoji menu for message"
        }
      },
      "deleted": {
        "context": "placeholder for a position in a chat conversation where a message has been deleted",
        "source": "professional",
        "value": "message deleted"
      },
      "entry": {
        "placeholder": {
          "context": "placeholder in the input for writing a message",
          "source": "professional",
          "value": "Type something"
        }
      },
      "input": {
        "error": {
          "profanity": {
            "source": "professional",
            "value": "Inappropriate content"
          },
          "tooLong": {
            "context": "Error message that shows when the message is too long",
            "source": "professional",
            "value": "Too many characters"
          }
        },
        "placeholder": {
          "context": "placeholder for the text input for creating a message",
          "source": "professional",
          "value": "Type your message here..."
        }
      },
      "invalidFileType": {
        "context": "Error message when trying to attach an invalid file type to a chat message",
        "source": "professional",
        "value": "Invalid file type. Only Image, Video or PDF files are allowed."
      },
      "maxAttachmentsReached": {
        "context": "Example: Maximum of 4 attachments allowed per message.",
        "source": "professional",
        "value": "Maximum of <%- max %> attachments allowed per message."
      },
      "sendMessage": {
        "source": "professional",
        "value": "Send message"
      },
      "system": {
        "channel": {
          "renamed": {
            "context": "indicator that a user has renamed a chat channel",
            "source": "professional",
            "value": "<%= userName %> renamed the chat to <%= channelName %>"
          }
        },
        "membership": {
          "join": {
            "context": "indicator that one or more users have joined a chat channel",
            "source": "professional",
            "value": "<%= displayNames %> joined the conversation"
          },
          "leave": {
            "context": "indicator that one or more users have left a chat channel",
            "source": "professional",
            "value": "<%= displayNames %> left the conversation"
          }
        }
      }
    },
    "messages": {
      "actions": {
        "copy": {
          "context": "Copy a message in chat",
          "source": "professional",
          "value": "Copy Message"
        },
        "copySuccess": {
          "context": "A phrase that displays momentarily when the user has copied a message to their clipboard",
          "source": "professional",
          "value": "Copied!"
        },
        "delete": {
          "context": "command to delete a message from a chat channel",
          "source": "professional",
          "value": "Delete Message"
        }
      },
      "beginning": {
        "context": "a message that displays at the top of a channel before any of the messages",
        "source": "professional",
        "value": "This is the beginning of your direct message history."
      },
      "deletedUsername": {
        "source": "professional",
        "value": "Removed User"
      },
      "reactionDeleteError": {
        "context": "a reaction is a reaction icon (like thumbs up) that a user can add to a message in a chat channel",
        "source": "professional",
        "value": "Something went wrong while deleting the reaction. Please try again later."
      },
      "reactionSendError": {
        "source": "professional",
        "value": "Something went wrong while sending the reaction. Please try again later."
      },
      "reactions": {
        "longPressDescription": {
          "source": "professional",
          "value": "Long press to see who reacted."
        },
        "title": {
          "source": "professional",
          "value": "Reactions"
        }
      },
      "status": {
        "error": {
          "source": "professional",
          "value": "Not delivered"
        },
        "errorTryAgain": {
          "source": "professional",
          "value": "Try Again"
        },
        "sending": {
          "source": "professional",
          "value": "Sending"
        }
      }
    },
    "new": {
      "source": "professional",
      "value": "New Chat"
    },
    "noMessages": {
      "context": "placeholder label for an empty chat channel list",
      "source": "professional",
      "value": "No messages yet..."
    },
    "noUsersToDisplay": {
      "context": "This message is shown in the chat interface in the user list and appears when there are no users available to be displayed due to filters applied in the search or if the user list is empty. ",
      "source": "professional",
      "value": "No users available to display."
    },
    "profile": {
      "loading": {
        "error": {
          "context": "Displayed when the app fails to fetch a user's profile in the chat interface due to network or server errors",
          "source": "professional",
          "value": "Failed to load user profile"
        }
      }
    },
    "results": {
      "source": "professional",
      "value": "Results"
    },
    "select": {
      "source": "professional",
      "value": "Select a chat"
    },
    "selectToAdd": {
      "source": "professional",
      "value": "Select who you would like to add to this chat group"
    },
    "start": {
      "source": "professional",
      "value": "Start Chat"
    },
    "startChatButton": {
      "source": "professional",
      "value": "Start new chat"
    },
    "startChatWith": {
      "source": "professional",
      "value": "Start a chat with..."
    },
    "title": {
      "source": "professional",
      "value": "Chat"
    },
    "userNames": {
      "exactlyThree": {
        "context": "how we join the names of three users (two sample names and one other)",
        "source": "professional",
        "value": "<%= firstUsername %>, <%= secondUsername %> and 1 other"
      },
      "exactlyTwo": {
        "context": "how we join the names of two users",
        "source": "professional",
        "value": "<%= firstUsername %> and <%= secondUsername %>"
      },
      "moreThanThree": {
        "context": "how we join the names of more than three users (two sample names and a count of the rest)",
        "source": "professional",
        "value": "<%= firstUsername %>, <%= secondUsername %> and <%= remainingCount %> others"
      }
    }
  },
  "coaches": {
    "coachDescription": {
      "source": "professional",
      "value": "Coach <%- currentNum %> of <%- totalNum %>"
    },
    "correctAnswer": {
      "source": "professional",
      "value": "Coach correct answer"
    },
    "fail": {
      "source": "professional",
      "value": "Coach failed"
    },
    "hello": {
      "source": "professional",
      "value": "Hello coach"
    },
    "incorrectAnswer": {
      "source": "professional",
      "value": "Coach incorrect answer"
    },
    "noCoachDescription": {
      "source": "professional",
      "value": "No coach <%- currentNum %> of <%- totalNum %>"
    },
    "pass": {
      "source": "professional",
      "value": "Coach passed"
    },
    "selectedCoach": {
      "source": "professional",
      "value": "Selected"
    },
    "title": {
      "source": "professional",
      "value": "Select a coach to guide you"
    }
  },
  "comms": {
    "broadcasts": {
      "broadcastsButton": {
        "context": "This text is on a button that allows users navigate to another part of the application",
        "source": "professional",
        "value": "Broadcasts"
      }
    },
    "posts": {
      "addImageAriaLabel": {
        "context": "Aria label for the upload image button",
        "source": "professional",
        "value": "Add Image"
      },
      "addMediaButton": {
        "context": "This text is on a button that allows users to add a photo or a video to a post they are creating",
        "source": "professional",
        "value": "Add Photo or Video"
      },
      "continueReading": {
        "source": "professional",
        "value": "Continue Reading"
      },
      "createModalTitle": {
        "context": "The title of a modal to create a post",
        "source": "professional",
        "value": "New Post"
      },
      "createPost": {
        "source": "professional",
        "value": "Create Post"
      },
      "createSuccess": {
        "context": "This is message the user gets when their post gets saved",
        "source": "professional",
        "value": "Post Created"
      },
      "editModalTitle": {
        "context": "The title of a modal when editing a post",
        "source": "professional",
        "value": "Edit Post"
      },
      "editSuccess": {
        "context": "This is the message the user gets when they save their edited post",
        "source": "professional",
        "value": "Post Updated"
      },
      "inappropriateContentMsg": {
        "context": "This is the message the user gets when they save their post and it doesn't pass a profanity filter",
        "source": "professional",
        "value": "Unable to post due to inappropriate content. Please update and try again."
      },
      "maxNumImages": {
        "context": "Max number of images supported with examples of image file types",
        "source": "professional",
        "value": "Supports up to <%- numOfImg %> images (JPG, PNG, GIF)"
      },
      "maxVideoSize": {
        "context": "The maximum video size users can upload. eg. Maximum video size: 2GB",
        "source": "professional",
        "value": "Maximum video size: <%- size %>GB"
      },
      "mentionAriaLabel": {
        "context": "Aria label for each mention. Ex: Maryna Salii. Please enter to tag this user in your post.",
        "source": "professional",
        "value": "<%- name %>. Press enter to tag this user in your post."
      },
      "noMatchesFound": {
        "source": "professional",
        "value": "No Matches Found"
      },
      "postButton": {
        "context": "(Verb) This is the text on the button in the modal to submit/create a post",
        "source": "professional",
        "value": "Post"
      },
      "postCreatedBy": {
        "context": "This text is read out by screen readers letting the user know by whom a post was created by",
        "source": "professional",
        "value": "Post Author:"
      },
      "removeMediaButton": {
        "context": "This is hidden text for screen readers to let users know this is a button that will remove media from the post they are editing",
        "source": "professional",
        "value": "Remove media"
      },
      "replaceMediaButton": {
        "context": "Text on a button that will replace the media currently on the page with a new photo or video",
        "source": "professional",
        "value": "Replace Photo or Video"
      },
      "shareToHeader": {
        "source": "professional",
        "value": "Share To"
      },
      "startPost": {
        "context": "This text is on a button that a user would click to begin creating a new post",
        "source": "professional",
        "value": "Start a Post"
      },
      "startPostAria": {
        "context": "Accessibility text on a button that a user would click to begin creating a new post",
        "source": "professional",
        "value": "Click to start a post"
      },
      "textEditor": {
        "source": "professional",
        "value": "Text Editor"
      },
      "uploadImage": {
        "source": "professional",
        "value": "Upload Photo(s)"
      },
      "uploadVideo": {
        "source": "professional",
        "value": "Upload Video"
      }
    }
  },
  "communications": {
    "betterLuckNextTime": {
      "source": "professional",
      "value": "Better luck next time"
    },
    "channels": {
      "channelDetailsFeedAria": {
        "source": "professional",
        "value": "<%- channel %> channel feed"
      },
      "channelDetailsSearchCount": {
        "context": "A count of the number of search results shown within a channel. Example: 1 Result(s) in Ask me Anything!",
        "source": "professional",
        "value": "<%- count %> Result(s) in <%- channel %>"
      }
    },
    "claimPoints": {
      "source": "professional",
      "value": "Claim Points!"
    },
    "claimPointsAria": {
      "context": "A screen reader label for a button that when clicked will claim points for a message with a title. The title is user-entered data and is variable.",
      "source": "professional",
      "value": "Claim points for <%- title %> announcement"
    },
    "confidentialResponse": {
      "source": "professional",
      "value": "Your response will be confidential"
    },
    "correctAnswer": {
      "source": "professional",
      "value": "Correct Answer"
    },
    "earnPoints": {
      "context": "Survey Feedback dialog secondary title. Ex: Earn +5 Bonus Points.",
      "source": "professional",
      "value": "Earn +<%- points %> Bonus Points"
    },
    "earnPointsSingular": {
      "context": "Survey Feedback dialog secondary title singular version.",
      "source": "professional",
      "value": "Earn +1 Bonus Point"
    },
    "gotItAria": {
      "context": "A screen reader label for a button that when clicked will mark a message with a title as read. The title is user-entered data and is variable.",
      "source": "professional",
      "value": "Got it button. Click to acknowledge that you have read the <%- title %> announcement."
    },
    "gotItButton": {
      "context": "Acknowledgement of announcement on button",
      "source": "professional",
      "value": "Got it!"
    },
    "homepage": {
      "buttonText": {
        "viewPrevious": {
          "source": "professional",
          "value": "View Previous Messages"
        }
      },
      "channels": {
        "ariaLabel": {
          "context": "Aria label for the off screen button to navigate to Channels",
          "source": "professional",
          "value": "View Channels"
        },
        "mainText": {
          "context": "notify users that there are new updates to view in channels",
          "source": "professional",
          "value": "New Activity in Channels"
        },
        "subText": {
          "source": "professional",
          "value": "Reward points available"
        }
      },
      "noMessages": {
        "mainText": {
          "source": "professional",
          "value": "You're all caught up on Timeline Messages!"
        },
        "subText": {
          "source": "professional",
          "value": "View all previous messages"
        }
      },
      "priorityCard": {
        "ariaLabel": {
          "context": "Aria label for the off screen button to navigate to Priority Messages",
          "source": "professional",
          "value": "View Priority Messages"
        },
        "mainText": {
          "plural": {
            "context": "Plural version of number of priority messages a user has, eg. 2 Priority Messages",
            "source": "professional",
            "value": "<%- messages %> Priority Messages"
          },
          "singular": {
            "context": "Singular version of number of priority messages a user has, eg. 1 Priority Message",
            "source": "professional",
            "value": "<%- messages %> Priority Message"
          }
        },
        "subText": {
          "plural": {
            "context": "Plural version of number of reward points a user can earn, eg. Earn up to 10 reward points",
            "source": "professional",
            "value": "Earn up to <%- points %> reward points"
          },
          "singular": {
            "context": "Singular version of number of reward points a user can earn, eg. Earn up to 1 reward point",
            "source": "professional",
            "value": "Earn up to <%- points %> reward point"
          }
        }
      },
      "timelineMessages": {
        "ariaLabel": {
          "context": "Aria label for the off screen button to navigate to All Messages on Timeline",
          "source": "professional",
          "value": "View Timeline Messages"
        },
        "mainText": {
          "plural": {
            "context": "plural version of number of timeline messages; eg. 4 New Timeline Messages",
            "source": "professional",
            "value": "<%- messages %> New Timeline Messages"
          },
          "pluralMoreThan20": {
            "context": "String ot display if there are more than 20 new timeline messages",
            "source": "professional",
            "value": "20+ New Timeline Messages"
          },
          "singular": {
            "context": "singular version of number of timeline messages; ie. 1 New Timeline Message",
            "source": "professional",
            "value": "<%- messages %> New Timeline Message"
          }
        }
      }
    },
    "modal": {
      "celebration": {
        "title": {
          "source": "professional",
          "value": "Great Work"
        }
      }
    },
    "plusRewardPoints": {
      "context": "Earned Reward Points by submitting quiz or poll. Ex: +5 Reward Points.",
      "source": "professional",
      "value": "+<%- points %> Reward Points"
    },
    "plusRewardPointsSingular": {
      "context": "Earned Reward Points by submitting quiz or poll singular version. ie. +1 Reward Point",
      "source": "professional",
      "value": "+1 Reward Point"
    },
    "pointsEarned": {
      "plural": {
        "context": "Points earned from acknowledging a nudge with a value of more than 1 point; eg. You've earned 50 reward points!",
        "source": "professional",
        "value": "You've earned <%- points %> reward points!"
      },
      "singular": {
        "context": "Points earned from acknowledging a nudge with a value of 1 point",
        "source": "professional",
        "value": "You've earned 1 reward point!"
      }
    },
    "rewardPoints": {
      "source": "professional",
      "value": "<%- points %> reward points"
    },
    "rewardPointsSingular": {
      "source": "professional",
      "value": "1 reward point"
    },
    "shareAdditionalThoughts": {
      "source": "professional",
      "value": "Are there any additional thoughts you'd like to share?"
    },
    "skipStep": {
      "source": "professional",
      "value": "Skip this step"
    },
    "surveyAnswerError": {
      "source": "professional",
      "value": "There was an error answering this communication."
    },
    "surveyDuplicateAnswerError": {
      "source": "professional",
      "value": "This communication has already been answered."
    },
    "thanksForFeedback": {
      "source": "professional",
      "value": "Thanks for your feedback"
    },
    "timeline": {
      "claimPoints": {
        "source": "professional",
        "value": "Claim points!"
      },
      "content": {
        "divider": {
          "source": "professional",
          "value": "Keep up the great work! Find previously reviewed priority messages below."
        },
        "noNewMessages": {
          "mainText": {
            "source": "professional",
            "value": "Nice work! You're all caught up on priority messages."
          },
          "subText": {
            "source": "professional",
            "value": "Find previously reviewed communications below."
          }
        },
        "returnHome": {
          "source": "professional",
          "value": "Return Home"
        },
        "viewAllMessages": {
          "source": "professional",
          "value": "View All Messages"
        }
      },
      "emptyTimelineFeed": {
        "source": "professional",
        "value": "Your feed is currently empty. Please check back later."
      },
      "feedAria": {
        "source": "professional",
        "value": "Timeline Feed"
      },
      "messageNotAvailableError": {
        "source": "professional",
        "value": "This message is no longer available"
      },
      "missingReferenceItemId": {
        "context": "Error message when the user tries to directly navigate to a timeline item but it's not available to that user or has been deleted.",
        "source": "professional",
        "value": "The linked timeline item is not available."
      },
      "searchCount": {
        "context": "Displays the number of results in a list. (Example: 35 result(s)",
        "source": "professional",
        "value": "<%- resultCount %> result(s)"
      },
      "tab": {
        "allMessages": {
          "source": "professional",
          "value": "All Messages"
        },
        "channels": {
          "source": "professional",
          "value": "Channels"
        },
        "priorityMessage": {
          "source": "professional",
          "value": "Priority Messages"
        }
      },
      "viewLessAria": {
        "context": "A screen reader label for a button that when clicked will collapse the content of a message with a title. The title is user-entered data and is variable.",
        "source": "professional",
        "value": "Click to view less content for <%- title %> announcement"
      },
      "viewMoreAria": {
        "context": "A screen reader label for a button that when clicked will expand the content of a message with a title. The title is user-entered data and is variable.",
        "source": "professional",
        "value": "Click to view more content for <%- title %> announcement"
      }
    },
    "youAreCorrect": {
      "source": "professional",
      "value": "You are correct!"
    },
    "yourAnswer": {
      "source": "professional",
      "value": "Your answer"
    }
  },
  "communitiesManagement": {
    "accessSettings": {
      "businessUnit": {
        "helpText": {
          "context": "Helper text for business unit selection dropdownm menu",
          "source": "professional",
          "value": "After saving, the Business Unit cannot be edited."
        },
        "targetAudience": {
          "source": "professional",
          "value": "Target Audience"
        }
      },
      "creator": {
        "source": "professional",
        "value": "Creator"
      },
      "editor": {
        "source": "professional",
        "value": "Editor"
      },
      "groupLabel": {
        "source": "professional",
        "value": "Add Groups and assign roles to each"
      },
      "groupTable": {
        "cautionMessage": {
          "source": "professional",
          "value": "Target audience cleared. Reselect an audience that belongs to this business unit."
        },
        "cautionTitle": {
          "source": "professional",
          "value": "Caution"
        },
        "everyoneNote": {
          "context": "An extra note to the user contained in brackets to give extra information about what a group titled Everyone truly is",
          "source": "professional",
          "value": "(includes all users in the organization)"
        },
        "removeGroup": {
          "context": "Label for a button the when clicked, removes a row from a table, where name is the name of the group in that table",
          "source": "professional",
          "value": "Remove group: <%- name %>"
        }
      },
      "includeSubTeamsSwitchDescription": {
        "source": "professional",
        "value": "Sub-Teams are included in this Folder and their users will inherit the same access settings"
      },
      "includeSubTeamsSwitchLabel": {
        "source": "professional",
        "value": "Include all Sub-Teams"
      },
      "member": {
        "source": "professional",
        "value": "Member"
      },
      "moderator": {
        "source": "professional",
        "value": "Moderator"
      },
      "removeGroup": {
        "source": "professional",
        "value": "Group access settings"
      },
      "searchForGroupsLabel": {
        "context": "Screen reader text and placeholder text for a search input field. Used to search for groups",
        "source": "professional",
        "value": "Search for Groups"
      },
      "selectTeamLabel": {
        "source": "professional",
        "value": "Select a Team"
      },
      "teamAccessRows": {
        "everyoneSubtext": {
          "source": "professional",
          "value": "includes Guest, Employee/User, Report, Manager User Types"
        },
        "leadersLabel": {
          "source": "professional",
          "value": "Leaders"
        },
        "leadersSubtext": {
          "source": "professional",
          "value": "includes Report, Manager User Types"
        },
        "managerLabel": {
          "source": "professional",
          "value": "Managers"
        },
        "managerSubtext": {
          "source": "professional",
          "value": "includes Manager User Types, Facilitators"
        }
      },
      "teamSelectorLabel": {
        "source": "professional",
        "value": "Search for or select a Team"
      },
      "teamUserTypesHeader": {
        "source": "professional",
        "value": "User Types"
      }
    },
    "accessType": {
      "context": "Communities table headers",
      "source": "professional",
      "value": "Access Type"
    },
    "actions": {
      "context": "Communities table headers",
      "source": "professional",
      "value": "Actions"
    },
    "communities": {
      "add": {
        "source": "professional",
        "value": "Add Folder"
      },
      "archive": {
        "success": {
          "source": "professional",
          "value": "You have successfully archived this folder"
        }
      },
      "archiveButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to archive a folder.",
        "source": "professional",
        "value": "Archive the <%- communityName %> folder."
      },
      "archiveModal": {
        "confirmationText": {
          "source": "professional",
          "value": "This will hide the folder and all contained content from any form of access, including search. All content items in this folder will be preserved and can be restored."
        },
        "details": {
          "contentCount": {
            "context": "Indicator of how many content items exist in a community, shown when the user is deciding to archive a community",
            "source": "professional",
            "value": "Content Items: <%- contentCount %>"
          },
          "memberCount": {
            "context": "Indicator of how many members are in a community, shown when the user is deciding to archive a community",
            "source": "professional",
            "value": "Members: <%- memberCount %>"
          }
        },
        "title": {
          "context": "The title of the modal a user sees when archiving a folder",
          "source": "professional",
          "value": "Archive Folder"
        }
      },
      "communitiesCount": {
        "context": "A string above a table of results letting the user know they are viewing a subset of results out of the total set of results",
        "source": "professional",
        "value": "Viewing <%- currentCommunitiesList %> of <%- totalCommunities %> Folders"
      },
      "deleteButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to delete a folder. It will open a modal to confirm the users action",
        "source": "professional",
        "value": "Delete the <%- communityName %> folder."
      },
      "deleteModal": {
        "confirmationText": {
          "context": "This text shows up on a modal to confirm a users choice in deleting a folder, where communityName is the name of the folder the user wants to delete.",
          "source": "professional",
          "value": "Are you sure you want to delete the <%- communityName %> folder? This cannot be undone."
        },
        "title": {
          "context": "This is the title of the modal a user sees when deleting a folder",
          "source": "professional",
          "value": "Delete Folder"
        }
      },
      "downloadTeamsTemplate": {
        "label": {
          "source": "professional",
          "value": "Download Teams Template"
        }
      },
      "exportTeamCommunities": {
        "label": {
          "source": "professional",
          "value": "Export Team Folders List"
        }
      },
      "goToCommunityLinkTitle": {
        "source": "professional",
        "value": "Go to folder"
      },
      "import": {
        "fail": {
          "context": "Attempting to import team folders from file with id 12892 has failed.",
          "source": "professional",
          "value": "Attempting to import team folders from file with id <%- id %> has failed. You can find more details in the Import History tab."
        },
        "success": {
          "context": "User has uploaded the file and is waiting for it to be processed/imported",
          "source": "professional",
          "value": "Successfully uploaded file for team folders import. You can find it in the Import History tab."
        }
      },
      "importExportDropdown": {
        "label": {
          "source": "professional",
          "value": "Import and Export Folders Actions"
        }
      },
      "importTeamCommunities": {
        "label": {
          "source": "professional",
          "value": "Import Team Folders (.csv)"
        }
      },
      "inputPlaceholder": {
        "context": "This is a placeholder string on an input field that tells the user what criteria they can search by. This will update a table below this input field.",
        "source": "professional",
        "value": "Search by Folder Name or Code"
      },
      "restore": {
        "success": {
          "source": "professional",
          "value": "You have successfully restored this folder"
        }
      },
      "restoreButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to restore a folder.",
        "source": "professional",
        "value": "Restore the <%- communityName %> folder."
      },
      "restoreModal": {
        "confirmationText": {
          "source": "professional",
          "value": "All content items and Folder settings will be restored."
        },
        "title": {
          "source": "professional",
          "value": "Restore Folder"
        }
      },
      "searchFilterArchived": {
        "context": "This is a checkbox that a user will select to include rows in a table for items that have been archived.",
        "source": "professional",
        "value": "Show Archived"
      },
      "searchFilterTeamBased": {
        "context": "This is a checkbox that a user will select to include rows in a table for team based folders or channels",
        "source": "professional",
        "value": "Show Team-Based"
      },
      "settingsLinkAriaLabel": {
        "context": "Accessibility text to tell the user that this link redirects to the Folder Settings page of the choosen folder.",
        "source": "professional",
        "value": "Go to the <%- communityName %> Folder Settings page."
      },
      "success": {
        "context": "This popup success message occurs when the deleting a folder is successful.",
        "source": "professional",
        "value": "The folder has been deleted."
      }
    },
    "community": {
      "activeCommunity": {
        "context": "This text lets an Administrator know that this community is visible and can be viewed by users",
        "source": "professional",
        "value": "Active"
      },
      "archiveIcon": {
        "source": "professional",
        "value": "Archive"
      },
      "archivedCommunity": {
        "context": "This text lets an Administrator know that this community is NOT visible and can NOT be viewed by users",
        "source": "professional",
        "value": "Archived"
      },
      "articlesEnabled": {
        "can": {
          "source": "professional",
          "value": "Creators, Editors and Moderators are able to create new Articles"
        },
        "cant": {
          "source": "professional",
          "value": "Creators, Editors and Moderators are not able to create new Articles"
        }
      },
      "commentsEnabled": {
        "can": {
          "source": "professional",
          "value": "Member can create comments on articles."
        }
      },
      "communityTypeQuestion": {
        "source": "professional",
        "value": "Folder Access:"
      },
      "contentCreation": {
        "source": "professional",
        "value": "Content Creation"
      },
      "enableArticles": {
        "context": "Create Articles switch description",
        "source": "professional",
        "value": "Enable Articles"
      },
      "enableAskAQuestion": {
        "source": "professional",
        "value": "Ask a Question"
      },
      "enableCommenting": {
        "source": "professional",
        "value": "Commenting"
      },
      "enableReactions": {
        "source": "professional",
        "value": "Reactions"
      },
      "enableRecommendations": {
        "source": "professional",
        "value": "Recommendations"
      },
      "groupType": {
        "source": "professional",
        "value": "Group-based"
      },
      "groupTypeGrantAccess": {
        "source": "professional",
        "value": "Grant access settings by User Groups"
      },
      "hideContentFromTimeline": {
        "source": "professional",
        "value": "Show Articles on All Messages in Timeline"
      },
      "hideFromGlobalTimeline": {
        "can": {
          "source": "professional",
          "value": "Display articles from this folder in a users Timeline."
        }
      },
      "imageApplyError": {
        "source": "professional",
        "value": "Unable to save some communities. Please click Apply to save images to the following communities:"
      },
      "includeInConversationalSearch": {
        "can": {
          "source": "professional",
          "value": "Max can reference articles in this folder when answering questions."
        },
        "title": {
          "source": "professional",
          "value": "Max AI"
        }
      },
      "moderators": {
        "context": "Publish Articles dropdown option",
        "source": "professional",
        "value": "Moderators only"
      },
      "moderatorsAndEditors": {
        "context": "Publish Articles dropdown option",
        "source": "professional",
        "value": "Moderators and Editors"
      },
      "moderatorsAndEditorsText": {
        "source": "professional",
        "value": "Moderators and Editors can publish Articles"
      },
      "onlyModeratorsText": {
        "source": "professional",
        "value": "Only Moderators can publish Articles"
      },
      "postsEnabled": {
        "can": {
          "source": "professional",
          "value": "Creators, Editors and Moderators are able to create new Posts and edit them"
        },
        "cant": {
          "source": "professional",
          "value": "Creators, Editors and Moderators are not able to create new Posts or edit them"
        }
      },
      "questionEnabled": {
        "cant": {
          "source": "professional",
          "value": "Nobody can submit new questions"
        }
      },
      "questionsEnabled": {
        "can": {
          "source": "professional",
          "value": "Members can submit questions in this folder."
        }
      },
      "reactionsEnabled": {
        "can": {
          "source": "professional",
          "value": "Members can react and see reactions on articles"
        }
      },
      "recommendationsEnabled": {
        "can": {
          "source": "professional",
          "value": "Display articles from this folder in Recommended Articles."
        }
      },
      "restoreIcon": {
        "source": "professional",
        "value": "Restore"
      },
      "showInGlobalTimeline": {
        "can": {
          "source": "professional",
          "value": "All content will be visible in the Timeline"
        },
        "cant": {
          "source": "professional",
          "value": "All content will be hidden from the Timeline"
        }
      },
      "tabs": {
        "accessSettings": {
          "source": "professional",
          "value": "Access Settings"
        },
        "contentSettings": {
          "source": "professional",
          "value": "Content Settings"
        },
        "generalSettings": {
          "source": "professional",
          "value": "General Settings"
        },
        "integration": {
          "source": "professional",
          "value": "Integration"
        }
      },
      "teamType": {
        "source": "professional",
        "value": "Team-based"
      },
      "teamTypeGrantAccess": {
        "source": "professional",
        "value": "Grant access settings based on existing Team"
      },
      "title": {
        "source": "professional",
        "value": "Folder Settings"
      },
      "titleCreateFolder": {
        "source": "professional",
        "value": "New Folder"
      },
      "titleEditFolder": {
        "source": "professional",
        "value": "Edit Folder"
      },
      "whoCanPublishArticles": {
        "source": "professional",
        "value": "Who can publish Articles?"
      }
    },
    "communityCode": {
      "source": "professional",
      "value": "Folder Code"
    },
    "communityDescription": {
      "source": "professional",
      "value": "Description"
    },
    "communityName": {
      "source": "professional",
      "value": "Folder Name"
    },
    "communityType": {
      "discussions": {
        "source": "professional",
        "value": "Discussions"
      },
      "label": {
        "source": "professional",
        "value": "Folder Type"
      },
      "none": {
        "source": "professional",
        "value": "Other"
      },
      "resources": {
        "source": "professional",
        "value": "Resources"
      }
    },
    "dropdown": {
      "selectOne": {
        "context": "Business unit dropdown menu default value",
        "source": "professional",
        "value": "--Select--"
      }
    },
    "error": {
      "3054": {
        "context": "A folder or channel with code: \"DS321\" already exists. Please enter a different code or leave it blank to assign one automatically",
        "source": "professional",
        "value": "A folder or channel with code: \"<%- code %>\" already exists. Please enter a different code or leave it blank to assign one automatically"
      },
      "3064": {
        "code": {
          "source": "professional",
          "value": "Folder Code is invalid. The text entered contains HTML tags, which are not allowed. Fix the entered text to not include HTML tags."
        }
      },
      "3088": {
        "source": "professional",
        "value": "There is a Business Unit consistency problem. Check the access settings."
      },
      "allContentDisabled": {
        "source": "professional",
        "value": "At least one of 'Enable Articles', 'Enable Posts', or 'Enable Ask a Question' must be enabled. These can be adjusted in the Content Settings tab."
      },
      "businessUnitRequired": {
        "source": "professional",
        "value": "A Business Unit must be selected"
      },
      "edit": {
        "3054": {
          "context": "A folder or channel with code: \"DS321\" already exists. Please enter a different code or leave it blank to assign one automatically",
          "source": "professional",
          "value": "A folder or channel with code: \"<%- code %>\" already exists. Please enter a different code or leave it blank to assign one automatically"
        }
      }
    },
    "filters": {
      "add": {
        "source": "professional",
        "value": "Add Filter"
      },
      "count": {
        "context": "Number of filters shown to the user",
        "source": "professional",
        "value": "Showing <%- count %> Filters"
      },
      "countSingle": {
        "context": "Number of filters shown to the user if only one",
        "source": "professional",
        "value": "Showing 1 Filter"
      },
      "deleteModal": {
        "confirmationText": {
          "context": "This text shows up on a modal to confirm a users choice in deleting a community, where filterName is the name of the filter the user wants to delete.",
          "source": "professional",
          "value": "Are you sure you want to delete the <%- filterName %> filter? This cannot be undone."
        },
        "title": {
          "context": "This is the title of the modal a user sees when deleting a filter",
          "source": "professional",
          "value": "Delete Filter"
        }
      },
      "deleteSuccess": {
        "source": "professional",
        "value": "Filter deleted successfully"
      },
      "errorFilterNotFound": {
        "source": "professional",
        "value": "Filter has been removed"
      },
      "errors": {
        "3082": {
          "source": "professional",
          "value": "Filter could not be found."
        },
        "generalDelete": {
          "source": "professional",
          "value": "An error occurred while trying to delete the filter."
        },
        "generalSave": {
          "source": "professional",
          "value": "An error occurred while trying to save the filter."
        },
        "needName": {
          "source": "professional",
          "value": "Filter name is a required field."
        }
      },
      "name": {
        "context": "Filter name input with language options",
        "source": "professional",
        "value": "Filter name"
      },
      "saveSuccess": {
        "source": "professional",
        "value": "Filter saved successfully"
      },
      "selectFilter": {
        "context": "Empty view instruction for filters tab",
        "source": "professional",
        "value": "Select a Filter"
      },
      "tagWarning": {
        "source": "professional",
        "value": "A Tag can only be part of one Filter. Adding a Tag that is already part of another Filter will move it to this Filter on save."
      },
      "tagsHeader": {
        "source": "professional",
        "value": "Tags in this Filter"
      }
    },
    "generalSettings": {
      "enableMobileNotifications": {
        "checkboxCaption": {
          "source": "professional",
          "value": "Any recipients using the Axonify mobile application will receive a push notification each time a new article is added to this Folder"
        },
        "checkboxLabel": {
          "source": "professional",
          "value": "New Articles Added"
        },
        "header": {
          "source": "professional",
          "value": "Mobile Push Notifications"
        }
      },
      "hideContent": {
        "source": "professional",
        "value": "Hide content from this Folder on Administrator's Timeline and Moderation pages"
      },
      "hideContentSecondary": {
        "source": "professional",
        "value": "Folders can be accessed from Folders Management page"
      }
    },
    "importHistory": {
      "tableHeading": {
        "created": {
          "source": "professional",
          "value": "Created"
        },
        "date": {
          "source": "professional",
          "value": "Date"
        },
        "file": {
          "source": "professional",
          "value": "File"
        },
        "uploadLogs": {
          "source": "professional",
          "value": "Upload Logs"
        },
        "uploadResults": {
          "source": "professional",
          "value": "Upload Results"
        },
        "uploadStatus": {
          "source": "professional",
          "value": "Upload Status"
        }
      }
    },
    "status": {
      "context": "Communities table headers",
      "source": "professional",
      "value": "Status"
    },
    "tabs": {
      "channels": {
        "source": "professional",
        "value": "Channels"
      },
      "communities": {
        "source": "professional",
        "value": "Folders"
      },
      "filters": {
        "source": "professional",
        "value": "Filters"
      },
      "importHistory": {
        "source": "professional",
        "value": "Import History"
      },
      "profanityFilter": {
        "source": "professional",
        "value": "Profanity Filter"
      },
      "tags": {
        "source": "professional",
        "value": "Tags"
      }
    },
    "tags": {
      "deleteButtonAriaLabel": {
        "context": "Accessibility text to tell the user that this button is used to delete a tag. It will open a modal to confirm the users action",
        "source": "professional",
        "value": "Delete the <%- tagName %> tag."
      },
      "deleteModal": {
        "confirmationText": {
          "context": "This text shows up on a modal to confirm a users choice in deleting a tag, where tagName is the name of the tag the user wants to delete.",
          "source": "professional",
          "value": "Are you sure you want to delete the <%- tagName %> tag? This cannot be undone."
        },
        "title": {
          "context": "This is the title of the modal a user sees when deleting a tag",
          "source": "professional",
          "value": "Delete Tag"
        }
      },
      "inputPlaceholder": {
        "context": "This is a placeholder string on an input field that tells the user what criteria they can search by. This will update a table below this input field.",
        "source": "professional",
        "value": "Search by Tag Name"
      },
      "success": {
        "context": "This popup success message occurs when the deleting a tag is successful.",
        "source": "professional",
        "value": "The tag has been deleted."
      },
      "tagName": {
        "source": "professional",
        "value": "Tag Name"
      },
      "tagsCount": {
        "context": "A string above a table of results letting the user know they are viewing a subset of results out of the total set of results",
        "source": "professional",
        "value": "Viewing <%- currentTagsList %> of <%- totalTags %> Tags"
      },
      "timesUsed": {
        "source": "professional",
        "value": "Times Used"
      }
    },
    "thumbnailTitle": {
      "source": "professional",
      "value": "Thumbnail"
    },
    "title": {
      "source": "professional",
      "value": "Folders Management"
    }
  },
  "completeprofile": {
    "chooselang": {
      "source": "professional",
      "value": "Select a Language"
    },
    "failedtosave": {
      "source": "professional",
      "value": "Failed to save your preference. Please try again later."
    },
    "selectedLanguage": {
      "source": "professional",
      "value": "Selected language"
    }
  },
  "content": {
    "access": {
      "everyone": {
        "businessUnit": {
          "context": "Text value for groups in communities access settings when all users includes everyone in a business unit.",
          "source": "professional",
          "value": "Everyone (includes all users in the Business Unit)"
        },
        "organization": {
          "context": "Text value for groups in communities access settings when targeting all users in the organization",
          "source": "professional",
          "value": "Everyone (includes all users in the organization)"
        }
      }
    }
  },
  "contentTranslation": {
    "seeOriginal": {
      "context": "Part of the toggle used to translate posts and comments",
      "source": "professional",
      "value": "See Original"
    },
    "seeOriginalAriaLabel": {
      "source": "professional",
      "value": "This post has been translated. Click to see original text."
    },
    "seeTranslation": {
      "context": "Part of the toggle used to translate posts and comments",
      "source": "professional",
      "value": "See Translation"
    },
    "seeTranslationAriaLabel": {
      "source": "professional",
      "value": "Translation is available for this post. Click to see translation."
    }
  },
  "conversationalSearch": {
    "charCount": {
      "context": "updates as user types in question to show current character count out of max characters allowed",
      "source": "professional",
      "value": "<%- current %>/<%- max %>"
    },
    "charCountText": {
      "context": "Text to describe number of characters used in question",
      "source": "professional",
      "value": "characters used"
    },
    "detectedLanguage": {
      "context": "Indicates the detected language of the user's question",
      "source": "professional",
      "value": "Language Detected: <%- language %>"
    },
    "error": {
      "2012": {
        "context": "Error message when the tenant feature is turned off",
        "source": "professional",
        "value": "This feature is not available.  Please contact your administrator for assistance."
      },
      "9050": {
        "context": "Error message when the NLP service is unavailable",
        "source": "professional",
        "value": "We're currently experiencing a temporary disruption with our AI language service. Our team is actively working to restore full functionality. Thank you for your patience!"
      },
      "9051": {
        "context": "Error message when the NLP service is too busy",
        "source": "professional",
        "value": "There is currently an unusually high volume of requests and I am unable to answer your question.  Please try again later."
      },
      "9052": {
        "context": "Error message when the NLP service is not allowed to answer a question about some restricted topic(s)",
        "source": "professional",
        "value": "I am unable to answer questions on this matter. Is there something else I can assist you with today?"
      },
      "9053": {
        "context": "Error message when the NLP service is unable to find any relevant documents for the user query",
        "source": "professional",
        "value": "There are no sources available for you based on your access. Please contact your administrator for assistance."
      },
      "9054": {
        "source": "professional",
        "value": "You have exceeded your weekly usage limit, please try again later."
      },
      "9055": {
        "source": "professional",
        "value": "You have exceeded your daily usage limit, please try again later."
      },
      "-2": {
        "1": {
          "context": "Message when there were no relevant documents found for the user query. There are multiple variations on this messsage that also include asking the user to include more details, so that Max is not as repetitive.",
          "source": "professional",
          "value": "I couldn't find any information about that. Including more details may help me answer your question."
        },
        "2": {
          "context": "Message when there were no relevant documents found for the user query. There are multiple variations on this messsage that also include asking the user to include more details, so that Max is not as repetitive.",
          "source": "professional",
          "value": "Unfortunately, I couldn't find any relevant information. Could you provide more details so I can assist you better?"
        },
        "3": {
          "context": "Message when there were no relevant documents found for the user query. There are multiple variations on this messsage that also include asking the user to include more details, so that Max is not as repetitive.",
          "source": "professional",
          "value": "I couldn't find any information on that topic. If you could provide more details, I'll do my best to assist you further."
        },
        "4": {
          "context": "Message when there were no relevant documents found for the user query. There are multiple variations on this messsage that also include asking the user to include more details, so that Max is not as repetitive.",
          "source": "professional",
          "value": "I couldn't find any relevant information. To better help you, could you please provide more details with your question?"
        },
        "5": {
          "context": "Message when there were no relevant documents found for the user query. There are multiple variations on this messsage that also include asking the user to include more details, so that Max is not as repetitive.",
          "source": "professional",
          "value": "I'm sorry, but I couldn't find any information about that. Sharing additional details might give me a better chance to help."
        }
      }
    },
    "feedback": {
      "followupQuestion": {
        "context": "If the user selects thumbs down, we ask them to provide more info for feedback",
        "source": "professional",
        "value": "Why was this response not helpful?"
      },
      "initialQuestion": {
        "context": "For each max answer, we include a question at the bottom of the response to ask the user for feedback",
        "source": "professional",
        "value": "Was this response helpful?"
      },
      "reasonConfusing": {
        "source": "professional",
        "value": "Confusing"
      },
      "reasonIncorrect": {
        "source": "professional",
        "value": "Incorrect"
      },
      "reasonIrrelevant": {
        "source": "professional",
        "value": "Irrelevant"
      },
      "thanksMessage": {
        "context": "Shown after the user provides some feedback",
        "source": "professional",
        "value": "Thanks for the feedback"
      },
      "thumbDownAltText": {
        "source": "professional",
        "value": "Not Helpful"
      },
      "thumbUpAltText": {
        "source": "professional",
        "value": "Helpful"
      }
    },
    "followUp": {
      "context": "Reminds user that they are entering a follow up question",
      "source": "professional",
      "value": "Follow Up"
    },
    "followUpCancel": {
      "context": "Reminder that user is currently entering a follow up prompt",
      "source": "professional",
      "value": "Cancel follow up"
    },
    "followUpInstruction": {
      "context": "Appears in user input when asking a follow up question",
      "source": "professional",
      "value": "Ask a follow up"
    },
    "header": {
      "source": "professional",
      "value": "Ask Max!"
    },
    "initializationError": {
      "context": "Shows as flash message if an error occurs when view is first starting",
      "source": "professional",
      "value": "Something went wrong when loading Max.  Sorry, try again."
    },
    "learnMore": {
      "context": "Source articles appear aftewards in a list",
      "source": "professional",
      "value": "Learn more:"
    },
    "more": {
      "context": "Number of source articles appear aftewards clicking button",
      "source": "professional",
      "value": "+ <%- sourcesCount %> more"
    },
    "placeholderInstruction": {
      "context": "placeholder text for user input to ask Max a question",
      "source": "professional",
      "value": "Ask a new question"
    },
    "responseError": {
      "source": "professional",
      "value": "Something went wrong. Sorry, try again."
    },
    "sourceDocumentsHeader": {
      "context": "Documents listed and used in max conversational search response messages",
      "source": "professional",
      "value": "Source Documents:"
    },
    "sources": {
      "context": "Source articles appear aftewards in a list",
      "source": "professional",
      "value": "Sources:"
    },
    "thinkingWithTimerMessage": {
      "context": "While ai assistant is thinking, shows timer counting down",
      "source": "professional",
      "value": "Max is thinking"
    },
    "unableToAnswerDefaultResponse": {
      "source": "professional",
      "value": "Hm, we couldn't find any relevant information. Please adjust your message and try again."
    },
    "warningMessage": {
      "context": "Asking user to validate answers after Max answers a question",
      "source": "professional",
      "value": "AI can make rare mistakes.  Please check important info."
    },
    "welcomeMessage": {
      "context": "A welcome message sent from Max (AI assistant) to a user who interacts with Max",
      "source": "professional",
      "value": "Hi! I'm Max, your assistant for answering work-related questions. What would you like to know?"
    },
    "welcomeMessageTitle": {
      "context": "Title for welcome message",
      "source": "professional",
      "value": "Welcome to Max"
    },
    "welcomeMessageWithTools": {
      "context": "A welcome message sent from Max (AI assistant) to a user who interacts with Max",
      "source": "professional",
      "value": "Hi! I'm Max, your assistant for answering work-related questions.  I can also answer questions about training topics, upcoming events, tasks, and quick links."
    }
  },
  "discover": {
    "access": {
      "error": {
        "2012": {
          "source": "professional",
          "value": "Access rules have changed and you no longer have access to this feature"
        }
      }
    },
    "actionText": {
      "insights": {
        "context": "The navigation button on articles/posts/etc. to get to page insights",
        "source": "professional",
        "value": "Insights"
      },
      "morePageActions": {
        "context": "This is for a button that opens a popup, which will contain a list of more buttons/actions to perform",
        "source": "professional",
        "value": "Click to view more page actions"
      }
    },
    "browse": {
      "community": {
        "contextMenuDropdown": {
          "source": "professional",
          "value": "Dropdown for Folder Settings"
        }
      }
    },
    "comments": {
      "actions": {
        "accessibility": {
          "label": {
            "context": "Describes an options menu for a comment, in a way that is descriptive for a screen reader",
            "source": "professional",
            "value": "More options for comment posted by: <%- name %> <%- datestamp %>"
          }
        },
        "delete": {
          "button": {
            "ariaLabel": {
              "context": "Describes the delete button for a comment, in a way that is descriptive for a screen reader",
              "source": "professional",
              "value": "Delete comment posted by: <%- name %> <%- datestamp %>"
            },
            "label": {
              "source": "professional",
              "value": "Delete"
            }
          }
        }
      },
      "button": {
        "ariaLabel": {
          "context": "Button to comment on an item such as an article",
          "source": "professional",
          "value": "Comment Button. Click to comment on: <%- title %>"
        },
        "label": {
          "source": "professional",
          "value": "Comment"
        }
      },
      "count": {
        "plural": {
          "context": "a phrase counting how many comments exist, with a plural number",
          "source": "professional",
          "value": "<%- commentCount %> comments"
        },
        "singular": {
          "source": "professional",
          "value": "1 comment"
        }
      },
      "delete": {
        "modal": {
          "confirmation": {
            "source": "professional",
            "value": "Are you sure you want to delete this comment? It cannot be undone."
          },
          "title": {
            "source": "professional",
            "value": "Delete Comment"
          }
        }
      },
      "none": {
        "message": {
          "source": "professional",
          "value": "There are no comments"
        }
      },
      "post": {
        "button": {
          "label": {
            "source": "professional",
            "value": "Post"
          }
        },
        "textarea": {
          "error": {
            "empty": {
              "source": "professional",
              "value": "The comment field cannot be empty"
            }
          },
          "placeholder": {
            "source": "professional",
            "value": "Write a comment"
          }
        }
      }
    },
    "dzArticleTypes": {
      "article": {
        "source": "professional",
        "value": "Article"
      },
      "link": {
        "source": "professional",
        "value": "Link"
      },
      "question": {
        "source": "professional",
        "value": "Question"
      },
      "reference": {
        "source": "professional",
        "value": "Reference"
      },
      "training": {
        "source": "professional",
        "value": "Training module"
      }
    },
    "insights": {
      "abrevated": {
        "billion": {
          "context": "An abrevation for a number in the billions",
          "source": "professional",
          "value": "B"
        },
        "million": {
          "context": "An abrevation for a number in the millions",
          "source": "professional",
          "value": "M"
        }
      },
      "allTime": {
        "context": "Used in filtering by date range, all days",
        "source": "professional",
        "value": "All Time"
      },
      "allTimeAriaLabel": {
        "context": "Used in filtering by date range, all days",
        "source": "professional",
        "value": "Show results for all time."
      },
      "allTypes": {
        "context": "Default value for dropdown filter of page types",
        "source": "professional",
        "value": "All Types"
      },
      "changeLabel": {
        "negative": {
          "context": "Negative tense difference in metrics",
          "source": "professional",
          "value": "Decreased <%- percent %>%."
        },
        "positive": {
          "context": "Positive tense difference in metrics",
          "source": "professional",
          "value": "Increased <%- percent %>%."
        }
      },
      "comments": {
        "context": "Page Insights Engagement Comments title",
        "source": "professional",
        "value": "Comments"
      },
      "commsInsightsAllPagesTitle": {
        "context": "title for the All Pages section of communication insights",
        "source": "professional",
        "value": "All Pages"
      },
      "communicationInsightsTitle": {
        "context": "the title of the communcation insights page",
        "source": "professional",
        "value": "Communications Insights"
      },
      "current": {
        "context": "title for the current tab",
        "source": "professional",
        "value": "Current"
      },
      "engagement": {
        "context": "Stats about how the team is engaging with the system",
        "source": "professional",
        "value": "Engagement"
      },
      "engagementTeamTitle": {
        "context": "Engagement title when drilled into a subteam",
        "source": "professional",
        "value": "<%- teamName %> in <%= parentTeamName %>"
      },
      "engagements": {
        "context": "title for the number of engagments in a given time frame",
        "source": "professional",
        "value": "Engagements"
      },
      "engagmentRate": {
        "context": "header for the engagment rate column",
        "source": "professional",
        "value": "Engagement Rate"
      },
      "errors": {
        "3001": {
          "context": "API error",
          "source": "professional",
          "value": "This team no longer exists"
        },
        "3017": {
          "context": "Error shown to user if the user does not have permission to see insights values for a page",
          "source": "professional",
          "value": "You no longer have access to the insights for this page"
        },
        "3075": {
          "context": "This is the error we display if the user navigates to an insights page using a page or team ID that fails server validation",
          "source": "professional",
          "value": "The Page or Team ID is invalid"
        },
        "exports": {
          "2012": {
            "source": "professional",
            "value": "The insights export feature is currently unavailable."
          },
          "3001": {
            "source": "professional",
            "value": "One or more pages could not be found."
          },
          "3017": {
            "source": "professional",
            "value": "You no longer have access to insights exports."
          },
          "general": {
            "source": "professional",
            "value": "An unexpected error occurred during the export."
          },
          "inProgressExport": {
            "context": "Error displayed when user attempts to create an insights export while one is in progress",
            "source": "professional",
            "value": "An export is already in progress."
          }
        },
        "required": {
          "community": {
            "source": "professional",
            "value": "You must select a folder."
          }
        }
      },
      "favourites": {
        "context": "Engagement favorites section title",
        "source": "professional",
        "value": "Favorites"
      },
      "firstDays": {
        "context": "Title of the first 7 days overview stats tile",
        "source": "professional",
        "value": "First 7-Days"
      },
      "firstHours": {
        "context": "Title of the first 24 hours overview stats tile",
        "source": "professional",
        "value": "First 24-Hours"
      },
      "howItWorks": {
        "engagements": {
          "context": "How it works text for how the engagement metric works",
          "source": "professional",
          "value": "Includes reactions, comments, and favorites on Posts and Articles"
        },
        "engagmentRate": {
          "context": "How it works text for how the engagement rate metric works",
          "source": "professional",
          "value": "Percentage of addressable audience who have engaged with a specific page. All engagements are counted."
        },
        "impressions": {
          "context": "How it works text for how the impressions metric works",
          "source": "professional",
          "value": "Number of times pages have been displayed on the user's Timeline or Communities"
        },
        "pages": {
          "context": "How it works text for how the pages metric works",
          "source": "professional",
          "value": "Includes Posts and Articles"
        },
        "reach": {
          "context": "How it works text for how the reach metric works",
          "source": "professional",
          "value": "Percentage of addressable audience who have viewed a specific page"
        },
        "title": {
          "context": "\"How it works\" button text",
          "source": "professional",
          "value": "How it works"
        },
        "uniqueViews": {
          "context": "How it works text for how the unique views metric works",
          "source": "professional",
          "value": "Number of times pages have been viewed by unique employees. Does not include repeat views."
        },
        "views": {
          "context": "How it works text for how the views metric works",
          "source": "professional",
          "value": "Number of times pages have been opened. Includes repeat views."
        }
      },
      "impressions": {
        "context": "Plural noun describing the occurrence of something being shown on a screen",
        "source": "professional",
        "value": "Impressions"
      },
      "includeSubteams": {
        "context": "Page Insights Engagement subteams checkbox",
        "source": "professional",
        "value": "Include Sub-teams"
      },
      "initial": {
        "context": "Title for the initial tab",
        "source": "professional",
        "value": "Initial"
      },
      "lastModified": {
        "context": "Label corresponding to the last modified date statistic in the overview tiles",
        "source": "professional",
        "value": "Last Modified"
      },
      "lastNumOfDays": {
        "context": "Number indicates the number of days since today that the related stats are for",
        "source": "professional",
        "value": "Last <%- numberOfDays %> Days"
      },
      "lastNumOfDaysAriaLabel": {
        "context": "Number indicates the number of days since today that the related stats are for",
        "source": "professional",
        "value": "Filter results by last <%- numberOfDays %> days."
      },
      "newPages": {
        "context": "title for the number of new pages created metric",
        "source": "professional",
        "value": "New Pages"
      },
      "noChange": {
        "context": "Indicates no change in metrics vs the previous time period",
        "source": "professional",
        "value": "No Change"
      },
      "noReactions": {
        "context": "This is shown when there are no reactons",
        "source": "professional",
        "value": "No users have reacted to this content yet"
      },
      "noReactionsInTab": {
        "context": "the <img/> could represent a reaction like: 'like', 'love', 'thinking', 'celebrate', or 'upset'. Note the aria-label will be internationalized.",
        "source": "professional",
        "value": "No one has reacted with <span class=\"reaction-icon__filled--<%= tabName %> filled\" aria-label=\"<%= t('reaction.reactionType.' + tabNameCapitalized + '.name') %>\"></span> yet"
      },
      "pageInsights": {
        "context": "Column for links to page insights",
        "source": "professional",
        "value": "Page Insights"
      },
      "pageType": {
        "article": {
          "context": "Article type label for type column",
          "source": "professional",
          "value": "Article"
        },
        "link": {
          "context": "Link type label for type column",
          "source": "professional",
          "value": "Link"
        },
        "post": {
          "context": "(Noun) Post type label for type column",
          "source": "professional",
          "value": "Post"
        }
      },
      "pages": {
        "source": "professional",
        "value": "Pages"
      },
      "reach": {
        "context": "Label corresponding to the reach statistic in the overview tiles",
        "source": "professional",
        "value": "Reach"
      },
      "reactionsButtonAriaLabel": {
        "context": "Aria Label for button to open reactions modal.",
        "source": "professional",
        "value": "Reactions: View Details"
      },
      "reactionsButtonLabel": {
        "context": "Label for button to open reactions modal.",
        "source": "professional",
        "value": "View details"
      },
      "reactionsModalTitle": {
        "context": "This is the title of a modal that displays the details of reactions.",
        "source": "professional",
        "value": "Reactions"
      },
      "reactionsShowOneResult": {
        "context": "This is the messaging at the bottom of the Reactions modal.",
        "source": "professional",
        "value": "Showing the most recent reaction"
      },
      "reactionsShowResults": {
        "context": "This is the messaging at the bottom of the Reactions modal",
        "source": "professional",
        "value": "Showing most recent <%- reactionsCount %> results"
      },
      "recentPagesMobileReach": {
        "context": "Header for mobile view of first 24 hour reach column",
        "source": "professional",
        "value": "First 24 h Reach"
      },
      "recentpages": {
        "context": "title for the recent pages table",
        "source": "professional",
        "value": "Recent Pages"
      },
      "recentpagesAria": {
        "context": "Screen reader label for the external link icon associated to a recent page in communcation insights feature",
        "source": "professional",
        "value": "Open <%- title %> page in a new tab"
      },
      "reset": {
        "source": "professional",
        "value": "Reset Filters"
      },
      "selectCommunityLabel": {
        "context": "Label for a dropdown to select folders",
        "source": "professional",
        "value": "Folders:"
      },
      "selectSingleCommunityLabel": {
        "context": "Label for a dropdown to select a folder",
        "source": "professional",
        "value": "Folder:"
      },
      "selectTypeLabel": {
        "context": "Label for a dropdown to select a type",
        "source": "professional",
        "value": "Types:"
      },
      "showReactionsModal": {
        "context": "This is an accessibility tooltip for showing the reactions modal",
        "source": "professional",
        "value": "Reactions - Click for more details."
      },
      "subteamNavigationTitle": {
        "source": "professional",
        "value": "Go to Sub-team"
      },
      "subteams": {
        "source": "professional",
        "value": "Sub-teams"
      },
      "teamBasedFilterLabel": {
        "context": "Label for the team based folders filter",
        "source": "professional",
        "value": "Include Team-Based Folders"
      },
      "teamNavigationAriaLabel": {
        "context": "ARIA label describing the link that navigates to the page insights constrained to a particular team",
        "source": "professional",
        "value": "Go to Insights for <%- teamName %>"
      },
      "teams": {
        "source": "professional",
        "value": "Teams"
      },
      "timePeriod": {
        "context": "Time period for shown metrics",
        "source": "professional",
        "value": "<%- startDate %> - <%- endDate %>"
      },
      "title": {
        "context": "Title of insights page and the navigation button on articles/posts/etc. to get to page insights",
        "source": "professional",
        "value": "Insights"
      },
      "totalReactions": {
        "context": "Page Insights total Reactions count title",
        "source": "professional",
        "value": "Total Reactions"
      },
      "totalReactionsAriaLabel": {
        "context": "Number of reactions this post has",
        "source": "professional",
        "value": "Total Reactions: <%- totalReactions %>"
      },
      "viewCount": {
        "context": "Number of page views this page has.",
        "source": "professional",
        "value": "Views: <%- pageViews %>"
      },
      "viewInsights": {
        "context": "title of the article being referred to",
        "source": "professional",
        "value": "View insights for <%- title %>"
      },
      "viewInsightsByTeam": {
        "context": "Link to view page insights filtered by team",
        "source": "professional",
        "value": "View page insights by team"
      },
      "viewers": {
        "context": "The plural noun describing unique humans who have viewed something",
        "source": "professional",
        "value": "Viewers"
      },
      "views": {
        "context": "Title for Views description text or table column heading for view statistics",
        "source": "professional",
        "value": "Views"
      }
    },
    "metadata": {
      "errors": {
        "expiryInPast": {
          "source": "professional",
          "value": "Expiry Date must be in the future and after the scheduled publish date."
        },
        "expiryNotesTooLong": {
          "source": "professional",
          "value": "Expiry notes are too long."
        }
      },
      "expiryDate": {
        "source": "professional",
        "value": "Set Expiry Date"
      },
      "expiryHelp": {
        "source": "professional",
        "value": "When this date is reached, the article will change to In Review state."
      },
      "expiryNotes": {
        "source": "professional",
        "value": "Expiry Notes"
      },
      "expiryOnDate": {
        "source": "professional",
        "value": "Expiry Date"
      }
    },
    "pageAccess": {
      "community": {
        "error": {
          "2008": {
            "source": "professional",
            "value": "Description must be less than 1024 characters."
          },
          "3001": {
            "source": "professional",
            "value": "The folder could not be found"
          },
          "3017": {
            "source": "professional",
            "value": "You do not have access to this folder."
          },
          "3060": {
            "source": "professional",
            "value": "This folder was recently archived and cannot be restored yet. Please wait 1 minute and try again."
          }
        }
      },
      "tag": {
        "error": {
          "3017": {
            "source": "professional",
            "value": "You do not have access to this tag"
          }
        }
      }
    },
    "pageTypes": {
      "article": {
        "approve": {
          "success": {
            "context": "The title of the article that has just been approved. For example: HR Policies article approved!",
            "source": "professional",
            "value": "\"<%- title %>\" article approved!"
          }
        }
      },
      "question": {
        "approve": {
          "success": {
            "context": "The title of the question that has just been approved. For example: HR Policies question approved!",
            "source": "professional",
            "value": "\"<%- title %>\" question approved!"
          }
        }
      },
      "reference": {
        "approve": {
          "success": {
            "context": "The title of the link that has just been approved. For example: HR Policies link approved!",
            "source": "professional",
            "value": "\"<%- title %>\" link approved!"
          }
        }
      },
      "training": {
        "approve": {
          "success": {
            "context": "The title of the training module article that has just been approved. For example: HR Policies training module article approved!",
            "source": "professional",
            "value": "\"<%- title %>\" training module article approved!"
          }
        }
      }
    },
    "posts": {
      "actions": {
        "accessibility": {
          "label": {
            "source": "professional",
            "value": "contextual action menu for post"
          }
        },
        "delete": {
          "button": {
            "label": {
              "source": "professional",
              "value": "Delete"
            }
          }
        },
        "edit": {
          "button": {
            "label": {
              "source": "professional",
              "value": "Edit"
            }
          }
        }
      },
      "delete": {
        "modal": {
          "confirmation": {
            "source": "professional",
            "value": "Are you sure you want to delete this post? It cannot be undone."
          },
          "title": {
            "source": "professional",
            "value": "Delete Post"
          }
        }
      }
    },
    "publishFlow": {
      "continueEditing": {
        "source": "professional",
        "value": "Continue Editing"
      },
      "deleteAll": {
        "source": "professional",
        "value": "Delete All"
      },
      "deleteArticle": {
        "source": "professional",
        "value": "Delete Article"
      },
      "deleteArticleConfirmation": {
        "source": "professional",
        "value": "This will permanently remove <strong>all language versions</strong> of your article and cannot be undone."
      },
      "deleteArticleLanguageVersionConfirmation": {
        "context": "Confirm message for deleting one language version",
        "source": "professional",
        "value": "This will permanently remove the <strong><%- languageName %></strong> version of your article and cannot be undone."
      },
      "deleteArticleLanguageVersionSuccess": {
        "context": "Success message saying that a language version has been deleted",
        "source": "professional",
        "value": "The <strong><%- languageName %></strong> version of the article has been deleted."
      },
      "deleteArticleMonoglotConfirmation": {
        "source": "professional",
        "value": "This will permanently remove the article and cannot be undone."
      },
      "deleteArticleSuccess": {
        "source": "professional",
        "value": "The article has been deleted."
      },
      "deleteLanguageNameArticle": {
        "context": "Title of confirmation prompt when deleting an article version in a particular language",
        "source": "professional",
        "value": "Delete <%- languageName %> Article"
      },
      "deleteLanguageNameLink": {
        "context": "Title of confirmation prompt when deleting an article version in a particular language",
        "source": "professional",
        "value": "Delete <%- languageName %> Link"
      },
      "deleteLanguageNameQuestion": {
        "context": "Title of confirmation prompt when deleting an article version in a particular language",
        "source": "professional",
        "value": "Delete <%- languageName %> Question"
      },
      "deleteLanguageNameTrainingModule": {
        "context": "Title of confirmation prompt when deleting an article version in a particular language",
        "source": "professional",
        "value": "Delete <%- languageName %> Training Module"
      },
      "deleteLanguageVersion": {
        "source": "professional",
        "value": "Delete Language Version"
      },
      "deleteLink": {
        "source": "professional",
        "value": "Delete Link"
      },
      "deleteLinkConfirmation": {
        "source": "professional",
        "value": "This will permanently remove <strong>all language versions</strong> of your link and cannot be undone."
      },
      "deleteLinkLanguageVersionConfirmation": {
        "context": "Confirm message for deleting one language version",
        "source": "professional",
        "value": "This will permanently remove the <strong><%- languageName %></strong> version of your link and cannot be undone."
      },
      "deleteLinkLanguageVersionSuccess": {
        "context": "Success message saying that a language version has been deleted",
        "source": "professional",
        "value": "The <strong><%- languageName %></strong> version of the link has been deleted."
      },
      "deleteLinkMonoglotConfirmation": {
        "source": "professional",
        "value": "This will permanently remove the link and cannot be undone."
      },
      "deleteLinkSuccess": {
        "source": "professional",
        "value": "The link has been deleted."
      },
      "deleteQuestion": {
        "source": "professional",
        "value": "Delete Question"
      },
      "deleteQuestionConfirmation": {
        "source": "professional",
        "value": "This will permanently remove <strong>all language versions</strong> of your question and cannot be undone."
      },
      "deleteQuestionLanguageVersionConfirmation": {
        "context": "Confirm message for deleting one language version",
        "source": "professional",
        "value": "This will permanently remove the <strong><%- languageName %></strong> version of your question and cannot be undone."
      },
      "deleteQuestionLanguageVersionSuccess": {
        "context": "Success message saying that a language version has been deleted",
        "source": "professional",
        "value": "The <strong><%- languageName %></strong> version of the question has been deleted."
      },
      "deleteQuestionMonoglotConfirmation": {
        "source": "professional",
        "value": "This will permanently remove the question and cannot be undone."
      },
      "deleteQuestionSuccess": {
        "source": "professional",
        "value": "The question has been deleted."
      },
      "deleteTrainingModule": {
        "source": "professional",
        "value": "Delete Training Module"
      },
      "deleteTrainingModuleConfirmation": {
        "source": "professional",
        "value": "This will permanently remove <strong>all language versions</strong> of your training module and cannot be undone."
      },
      "deleteTrainingModuleLanguageVersionConfirmation": {
        "context": "Confirm message for deleting one language version",
        "source": "professional",
        "value": "This will permanently remove the <strong><%- languageName %></strong> version of your training module and cannot be undone."
      },
      "deleteTrainingModuleLanguageVersionSuccess": {
        "context": "Success message saying that a language version has been deleted",
        "source": "professional",
        "value": "The <strong><%- languageName %></strong> version of the training module has been deleted."
      },
      "deleteTrainingModuleMonoglotConfirmation": {
        "source": "professional",
        "value": "This will permanently remove the training module and cannot be undone."
      },
      "deleteTrainingModuleSuccess": {
        "source": "professional",
        "value": "The training module has been deleted."
      },
      "ignoringScheduled": {
        "source": "professional",
        "value": "Ignoring scheduled publish date. Will publish immediately."
      },
      "languageChooser": {
        "triggerButton": {
          "ariaLabel": {
            "context": "invisible label for accessibility describing that this button opens a dropdown to pick a different language",
            "source": "professional",
            "value": "Switch from <%- languageName %> to another language"
          }
        }
      },
      "memberConfirmPhrase": {
        "source": "professional",
        "value": "Members will not be able to view this article until it is published again."
      },
      "navigateConfirmBody": {
        "source": "professional",
        "value": "You can <%- saveAction %> to preserve changes made to the <strong><%- languageName %></strong> version or Discard your changes and continue"
      },
      "navigateConfirmTitle": {
        "source": "professional",
        "value": "Are you done editing this language?"
      },
      "navigateLanguage": {
        "ariaLabel": {
          "context": "invisible label for accessibility describing that this button navigates to a different language",
          "source": "professional",
          "value": "Switch to <%- languageName %>"
        }
      },
      "placeholderTitle": {
        "source": "professional",
        "value": "Article Title"
      },
      "publishAll": {
        "context": "Button to launch dialog in which an article can be published in multiple languages simultaneously",
        "source": "professional",
        "value": "Publish All..."
      },
      "publishAllDisclaimer": {
        "source": "professional",
        "value": "All selected versions will be published and visible to members."
      },
      "publishAllTitle": {
        "context": "Title of the modal in which an Article can be published simultaneously in multiple languages",
        "source": "professional",
        "value": "Publish Languages"
      },
      "publishError": {
        "source": "professional",
        "value": "An unknown error occurred and this language was not published."
      },
      "publishState": {
        "expired": {
          "context": "the state of a page having passed an expiry date",
          "source": "professional",
          "value": "Expired"
        },
        "inProgress": {
          "source": "professional",
          "value": "In progress - this language is not currently In Review or Published."
        },
        "inReview": {
          "source": "professional",
          "value": "In Review"
        },
        "locked": {
          "source": "professional",
          "value": "Locked for editing"
        },
        "modified": {
          "source": "professional",
          "value": "In progress - changes have been made to this language while being In Review or Published."
        },
        "notPublishedWithReason": {
          "context": "When an article is not published, displayed with the reason for failure",
          "source": "professional",
          "value": "Not Published. <%- notPublishedReason %>"
        },
        "published": {
          "source": "professional",
          "value": "Published"
        }
      },
      "reviewConfirmPhrase": {
        "source": "professional",
        "value": "The article will be considered for publishing. When sent for review you will no longer have edit access."
      },
      "sendForReview": {
        "source": "professional",
        "value": "Send For Review"
      },
      "sendForReviewConfirm": {
        "context": "Similar to our existing translation of 'send for review', but in the context of a question instead of a statement.",
        "source": "professional",
        "value": "Send For Review?"
      },
      "successfullyPublished": {
        "source": "professional",
        "value": "<%- successful %> of <%- total %> versions have been successfully published."
      },
      "viewArticle": {
        "source": "professional",
        "value": "View Article"
      }
    },
    "reactions": {
      "POST": {
        "error": {
          "3017": {
            "source": "professional",
            "value": "Could not update reaction, the page may have been removed or the reactions feature was disabled"
          },
          "default": {
            "source": "professional",
            "value": "Could not save reaction"
          }
        }
      },
      "contextActions": {
        "comments": {
          "delete": {
            "source": "professional",
            "value": "Delete Comment"
          }
        }
      },
      "favorite": {
        "count": {
          "plural": {
            "context": "a count of how many people have clicked the star and made this article a favorite article, expressed with a plural number",
            "source": "professional",
            "value": "<%- favoriteCount %> Favorites"
          },
          "singular": {
            "context": "a count of how many people have clicked the star and made this article a favorite article, expressed with a singular number",
            "source": "professional",
            "value": "1 Favorite"
          }
        },
        "label": {
          "source": "professional",
          "value": "Favorite"
        }
      }
    },
    "report": {
      "type": {
        "prompt": {
          "source": "professional",
          "value": "What problem would you like to report about this article?"
        }
      }
    },
    "reportLabel": {
      "source": "professional",
      "value": "Report"
    },
    "share": {
      "success": {
        "source": "professional",
        "value": "Shared folder successfully added"
      }
    },
    "unshare": {
      "success": {
        "source": "professional",
        "value": "Shared folder successfully removed"
      }
    }
  },
  "errors": {
    "3043": {
      "source": "professional",
      "value": "This session was utilised in another browser.  Please click the button or refresh the page."
    },
    "3141": {
      "source": "professional",
      "value": "You need to be clocked into your company's scheduling system to do this training item. Clock in and please try again."
    },
    "Article": {
      "required": {
        "community": {
          "source": "professional",
          "value": "You must select a folder."
        },
        "editComment": {
          "source": "professional",
          "value": "The comment is too long."
        },
        "language": {
          "source": "professional",
          "value": "You must select a language."
        },
        "title": {
          "source": "professional",
          "value": "You must provide a valid title."
        }
      }
    },
    "CommunitySettingsModel": {
      "required": {
        "names": {
          "source": "professional",
          "value": "Folder Name is a required field"
        }
      }
    },
    "Reference": {
      "required": {
        "community": {
          "source": "professional",
          "value": "You must select a folder."
        },
        "language": {
          "source": "professional",
          "value": "You must select a language."
        },
        "title": {
          "source": "professional",
          "value": "You must provide a valid title."
        }
      }
    },
    "RegistrationTicket": {
      "department": {
        "source": "professional",
        "value": "You must select a department."
      },
      "jobTitle": {
        "source": "professional",
        "value": "You must select a job title."
      },
      "language": {
        "source": "professional",
        "value": "You must select a language."
      },
      "lineOfBusiness": {
        "source": "professional",
        "value": "You must select a line of business."
      },
      "location": {
        "source": "professional",
        "value": "You must select a location."
      },
      "recoveryMissing": {
        "source": "professional",
        "value": "Please enter a recovery answer for each question selected."
      },
      "required": {
        "email": {
          "source": "professional",
          "value": "Please enter a valid email address."
        },
        "employeeId": {
          "source": "professional",
          "value": "Please enter your employee ID."
        },
        "fullname": {
          "source": "professional",
          "value": "Please enter your full name."
        },
        "location": {
          "source": "professional",
          "value": "Please select your location."
        },
        "passwordPlain": {
          "source": "professional",
          "value": "Your password may not be blank."
        },
        "passwordRepeated": {
          "source": "professional",
          "value": "Please repeat your password again."
        }
      }
    },
    "Session": {
      "required": {
        "passwd": {
          "source": "professional",
          "value": "Password is required."
        },
        "user": {
          "source": "professional",
          "value": "Username is required."
        }
      }
    },
    "assessment": {
      "3050": {
        "source": "professional",
        "value": "This training option has been removed from your training program, please pick another one."
      },
      "3051": {
        "source": "professional",
        "value": "This training option has been removed from your training program, please pick another one."
      },
      "errorCreatingAssessment": {
        "source": "professional",
        "value": "An error occurred while trying to create the assessment."
      }
    },
    "channel": {
      "required": {
        "channel": {
          "source": "professional",
          "value": "You must select a channel."
        }
      }
    },
    "invalidEmail": {
      "source": "professional",
      "value": "Please enter a valid email address."
    },
    "noContent": {
      "source": "professional",
      "value": "You must provide some content."
    },
    "passwordEmpty": {
      "source": "professional",
      "value": "Your password may not be blank."
    },
    "questions": {
      "hotspot": {
        "imageLoadFailure": {
          "source": "professional",
          "value": "Failed to load the hotspot image! Please click Retry or try using a different device. If this error repeats please contact your administrators."
        }
      }
    },
    "unknown400": {
      "source": "professional",
      "value": "400 Error. Unknown Error code: <%- errCode %>. Logging out."
    }
  },
  "eventCheckin": {
    "errors": {
      "3117": {
        "source": "professional",
        "value": "The event code you have entered is invalid. Please re-enter the code."
      },
      "3118": {
        "source": "professional",
        "value": "You have already checked into this event. Cannot check-in again."
      },
      "3119": {
        "source": "professional",
        "value": "Check-In Attendance for this event has already been confirmed. To view record of attendance, go to Events &#62; History."
      },
      "3120": {
        "source": "professional",
        "value": "The check-in time for the event matching this code has expired.<br/><br/>Please contact your event facilitator if you require further assistance."
      },
      "empty": {
        "source": "professional",
        "value": "Please enter a check-in code."
      }
    },
    "none": {
      "source": "professional",
      "value": "There are no more available events to check into today."
    },
    "prompt": {
      "source": "professional",
      "value": "Enter the provided code"
    },
    "submit": {
      "source": "professional",
      "value": "Check In"
    },
    "subtext": {
      "source": "professional",
      "value": "You must check in to record your attendance for available events"
    },
    "success": {
      "context": "User has successfully enrolled in an event. We notifiy them of the name of the event they have enrolled for",
      "source": "professional",
      "value": "Your event attendance for <%- eventName %> has successfully been confirmed."
    },
    "title": {
      "source": "professional",
      "value": "Event Self Check-In"
    }
  },
  "externalLink": {
    "launch": {
      "source": "professional",
      "value": "Click start to launch training module"
    },
    "start": {
      "source": "professional",
      "value": "Start"
    },
    "title": {
      "source": "professional",
      "value": "External Module Link"
    }
  },
  "extratraining": {
    "noquestions": {
      "source": "professional",
      "value": "No questions answered"
    },
    "notopics": {
      "source": "professional",
      "value": "You have no topics for Extra Training."
    },
    "recommended": {
      "noTopics": {
        "source": "professional",
        "value": "You have no recommended topics for Extra Training."
      },
      "reasons": {
        "confidence": {
          "source": "professional",
          "value": "You can improve your confidence by taking this training"
        },
        "performance": {
          "source": "professional",
          "value": "You can improve your knowledge by taking this training"
        },
        "pregress": {
          "source": "professional",
          "value": "You can increase your progress by taking this training"
        },
        "recency": {
          "source": "professional",
          "value": "You have not taken this training in a while"
        }
      },
      "title": {
        "source": "professional",
        "value": "Recommended Topics"
      }
    },
    "takequickquiz": {
      "source": "professional",
      "value": "Take Quick Quiz"
    },
    "title": {
      "source": "professional",
      "value": "Extra Training"
    },
    "topiclist": {
      "source": "professional",
      "value": "Topic List"
    },
    "viewtraining": {
      "source": "professional",
      "value": "Take Training"
    }
  },
  "feedback": {
    "commentLabel": {
      "context": "This is the label for the comments/additional details field when submitting feedback",
      "source": "professional",
      "value": "Additional Details"
    },
    "contextTypeLabel": {
      "QUESTION": {
        "context": "This is the label for the context field when providing feedback about a specific question",
        "source": "professional",
        "value": "Question"
      }
    },
    "contextTypePrompt": {
      "QUESTION": {
        "context": "This is the type-specific text encouraging users to select a feedback type",
        "source": "professional",
        "value": "What did you think of the question and answers?"
      }
    },
    "emptyFormError": {
      "context": "This is the message when the user has provided neither a feedback type nor any additional details",
      "source": "professional",
      "value": "Select a feedback type and/or enter additional details."
    },
    "messages": {
      "failureText": {
        "context": "The message text notifying the user of an unsuccessful feedback submission",
        "source": "professional",
        "value": "Unable to submit your question feedback. Please contact your administrator directly."
      },
      "successHeader": {
        "context": "The header for the message notifying the user of a successful feedback submission",
        "source": "professional",
        "value": "Feedback Submitted"
      },
      "successText": {
        "context": "The message text notifying the user of a successful feedback submission",
        "source": "professional",
        "value": "We will look into this issue."
      }
    },
    "modalHeader": {
      "context": "This is the header text for the feedback modal window",
      "source": "professional",
      "value": "Feedback"
    },
    "type": {
      "CONFUSING": {
        "context": "This is the button text when users are reporting that an item is confusing",
        "source": "professional",
        "value": "Confusing"
      },
      "INCORRECT": {
        "context": "This is the button text when users are reporting that an item is incorrect",
        "source": "professional",
        "value": "Incorrect"
      },
      "IRRELEVANT": {
        "context": "This is the button text when users are reporting that an item is irrelevant",
        "source": "professional",
        "value": "Irrelevant"
      },
      "OTHER": {
        "context": "This is the button text when users are reporting something about an item that does not fit under other options",
        "source": "professional",
        "value": "Other"
      },
      "OUTDATED": {
        "context": "This is the button text when users are reporting that an item is outdated",
        "source": "professional",
        "value": "Outdated"
      }
    }
  },
  "files": {
    "error": {
      "fileIsEmpty": {
        "source": "professional",
        "value": "The file is empty. Please try another file."
      },
      "fileSizeTooBig": {
        "source": "professional",
        "value": "The file is too large. The maximum allowed size is <%= maximumSize %>MB."
      },
      "fileTypeNotAllowed": {
        "source": "professional",
        "value": "The type of file(s) you selected is not allowed. Please try another file."
      },
      "tooManyFiles": {
        "source": "professional",
        "value": "Error trying to upload multiple files. Please upload only one file."
      }
    }
  },
  "flash": {
    "answernotprovided": {
      "source": "professional",
      "value": "Answer text must be provided."
    },
    "file_is_empty": {
      "source": "professional",
      "value": "The file is empty. Please try another file."
    },
    "file_size_too_large": {
      "source": "professional",
      "value": "The file is too large. The maximum allowed size is <%= maximumSize %>MB."
    },
    "file_type_not_allowed": {
      "source": "professional",
      "value": "The type of file(s) you selected is not allowed. Please try another file."
    },
    "questionnotprovided": {
      "source": "professional",
      "value": "Question text must be provided."
    }
  },
  "games": {
    "BumpOut": {
      "instructions": {
        "source": "professional",
        "value": "Click or tap on a bubble and drag or swipe it in the direction of another bubble. The bubble will knock one bubble in its path off the board. Use the bubbles to clear the board until only one bubble remains. Complete multiple boards in a row to build up a score multiplier"
      }
    },
    "Convergence": {
      "instructions": {
        "source": "professional",
        "value": "Click on empty cells to merge adjacent filled cells. When there is only a single cell left, you win! If the last filled cell is on a star space, you get bonus points"
      }
    },
    "Curling": {
      "instructions": {
        "source": "professional",
        "value": "Tap to choose the angle of your shot, then the power, then how much curve your shot will have. You and the computer alternate shots. The closer your rock is to the center of the target, the more points it is worth. Each rock of yours that is closer to the center than the opponent's best shot multiplies your score."
      }
    },
    "Elements": {
      "instructions": {
        "source": "professional",
        "value": "Move tiles around on the board to form vertical or horizontal chains of three or greater matching symbols. Click and drag a tile to move it to anywhere else on the board.  Matching 4 or more tiles will create special tiles with additional effects and matching two chains together creates a wildcard. Matching the element shown at the bottom will increase your score multiplier."
      }
    },
    "FieldGoal": {
      "instructions": {
        "source": "professional",
        "value": "In this game you race against the clock to kick the football between the goal posts. Click below the ball and move the mouse in the direction you want the football to go. Make sure you alter your target to account for the wind direction! A Question will appear when you miss, go wide or time runs out."
      }
    },
    "FoodSearch": {
      "instructions": {
        "source": "professional",
        "value": "Search the board vertically, horizontally, and diagonally to find the patterns from the list. Click and drag to highlight the patterns you find. Complete the board to earn bonus score and a question."
      }
    },
    "Kick-Off": {
      "instructions": {
        "source": "New string",
        "value": "Click and drag to choose the direction and power of your shot. Then click where on the ball you want to kick. Kicking the bottom half of the ball will cause it to go up in the air and kicking the side of the ball will cause it to curve. Pass to your teammates to make it up the field"
      }
    },
    "LightPuzzle": {
      "instructions": {
        "source": "professional",
        "value": "Blue areas are owned by your team. Red areas are owned by the opposing team. Choose any area on the map and then collect as many points as you can. You will be awarded bonus points for finding the exit with as many coins as you can."
      }
    },
    "LightsOut": {
      "instructions": {
        "source": "professional",
        "value": "Drag and pull balls to launch them across the screen as you try to break the light bulb within a specified time frame. You can change the direction the ball will travel using the mouse before you release it. There may be obstacles you need to destroy to get to the light bulb. Drag the ball and launch it to break the light bulb, potentially eliminating obstacles in the way. A Question will appear when an obstacle marked with a \"?\" is removed."
      }
    },
    "Monkey": {
      "instructions": {
        "source": "professional",
        "value": "Launch the monkeys and try to collect bananas and destroy obstacles. Drag the monkey in the slingshot and release to launch the monkey. You can change the angle and the distance by pulling the monkey further back and changing the angle of the shot. A Question will appear when a obstacle marked with a \"?\" is removed."
      }
    },
    "Quiz": {
      "instructions": {
        "source": "professional",
        "value": "A word puzzle is displayed after the questions are answered. Be quick because you only have a certain amount of time."
      }
    },
    "Simon2": {
      "instructions": {
        "source": "professional",
        "value": "Watch and memorize the sequence as it plays out across the different elements, then re-enact it. The sequence gets longer after each success and reset if you get it wrong. Answer Questions to instantly complete the current sequence "
      }
    },
    "Space": {
      "instructions": {
        "source": "professional",
        "value": "Destroy all the space ships in the allotted time. Use the arrow buttons to move the space person left and right. Use the space bar to fire at the space ships to destroy them.<br/><br/>A Question will appear when a space ship marked with a \"?\" is removed."
      }
    },
    "Summer": {
      "instructions": {
        "context": "Game instructions",
        "source": "professional",
        "value": "Compete in Archery, Hurdles, Rowing, Pole Vault, and Throwing to earn medals.<br/>Single-Target Archery - Select the target and adjust your aim by pulling the moving bubble toward the middle of the target.<br/>Hurdles - Use the arrow keys or tap the sides and top of your screen to run and jump over the hurdles. Highlighted arrows indicate which motion to select next to move quickly.<br/>Rowing - Select the paddle and move it in an oval shape. Enter the water on the right, and exit the water on the left to row faster.<br/>Pole Vault - Click/tap to plant your pole as close to the target as possible. Then hold as long as you can, without breaking your pole, and release to launch yourself as high as you can. <br/>Multi-Target Archery - Select a target to launch an arrow. Make sure to hit the targets in order based on the indicator. The faster you do it, the higher your score. <br/>Throwing - Select and drag, following the path shown as closely and as quickly as possible."
      }
    },
    "Sushi": {
      "instructions": {
        "source": "professional",
        "value": "Swipe your mouse/finger across the screen to slash and splatter as much sushi as possible before time runs out.<br/><br/>Be careful, points are awarded based on the angle of the cut. Additional points are awarded for multiple cuts to the same sushi and for maintaining a streak of good cuts.<br/><br/>When a bamboo appears slash it as much as possible for bonus points and a Question!"
      }
    },
    "TeamRunner": {
      "instructions": {
        "source": "professional",
        "value": "Blue areas are owned by your team. Red areas are owned by the opposing team. Choose any area on the map, if you run the longest distance you will claim that area."
      }
    },
    "TurtleHurtle": {
      "instructions": {
        "source": "professional",
        "value": "You've fallen behind your family of turtles, use the hills on the ocean floor to catch up with them. Tap and hold while in the air to descend, hold while going down hill to increase speed release while going up hill. Collect coins and hit boosts to increase your score."
      }
    },
    "Unicycle": {
      "instructions": {
        "source": "professional",
        "value": "Use the arrow keys, A and D, or tap the left and right sides of the screen to lean and steer the unicycle. Try to stay upright as long as possible while collecting coins and avoiding obstacles. The longer you last the harder it becomes"
      }
    },
    "atoms": {
      "instructions": {
        "source": "professional",
        "value": "The object of this multilevel game is to connect the given atoms to make a molecule. As you work your way through the levels, the difficulty level increases. Select the atom. Click the arrow to select the direction you want the atom to move.  The atoms will continue in the direction selected until they hit something. A Question will appear when an atom moves over a question."
      }
    },
    "ballbob": {
      "instructions": {
        "source": "professional",
        "value": "Remove blocks to navigate Bob back to his bowl throughout each level.<br/><br/>Tap or click a block to remove it. When Bob reaches his bowl, a question for bonus time will be presented. In some levels, Bob will be able to use other objects like swings, levers, and other balls to achieve his goal."
      }
    },
    "battlefish": {
      "gameOverMessage": {
        "source": "professional",
        "value": "GAME COMPLETE! Thanks for playing!!!"
      },
      "instructions": {
        "source": "professional",
        "value": "The object of this multilevel game is to increase the size of the battle fish and eliminate the enemy fish. Select the battle fish and click to increase its size. A Question will appear when a fish marked with a \"?\" is hit."
      }
    },
    "blackaway": {
      "texteTuto1": {
        "source": "professional",
        "value": "Swipe the screen or use the arrow keys to move.\nStand the block vertically on\nthe alligator to win.\nYou lose when you fall\n off the edges."
      }
    },
    "blockart": {
      "instructions": {
        "source": "professional",
        "value": "Select one of the colored blocks at the bottom of the screen. Use the selected block to duplicated the pattern in the centre area. Pay close attention to the pattern as it will disappear once the 3rd block is placed on the pattern."
      }
    },
    "blockrush": {
      "instructions": {
        "source": "professional",
        "value": "Clear the blocks by selecting groups of 3 blocks (or more) of the same color.<br/><br/>Use the keyboard (arrow keys to move your selection and spacebar to apply your selection) or a mouse to select a group of blocks. The more blocks you clear - the more points you earn!"
      }
    },
    "breaker": {
      "instructions": {
        "source": "professional",
        "value": "Bounce and ricochet your way through an endless supply of colourful new levels. With every bounce you gain more speed and power, and increase your points multiplier. Tap or click to launch the ball. Tap and drag, or move the mouse below the pad to change its position."
      }
    },
    "bubbleshooter": {
      "instructions": {
        "source": "professional",
        "value": "Clear the bubbles by aiming the colored bubble at the bottom of the screen towards bubbles of the same color.<br/><br/>Use your keyboard (arrow keys to aim and spacebar to fire) or mouse to fire a bubble. The more bubbles you match - the more points you earn!"
      }
    },
    "catcher": {
      "instructions": {
        "context": "instructions",
        "source": "professional",
        "value": "Move the shape at the bottom of the screen to catch the matching shapes as they fall. Stars are wildcards and can be caught by any shape for a bonus as well the chance to change shape without breaking your streak.<br/><br/>You can use mouse, touch, or arrow keys to control the game. Maintin a streak by only catching the matching shape and stars for maximum points"
      }
    },
    "cheesequest": {
      "texteTuto": {
        "source": "professional",
        "value": "Collect all the cheese by guiding the mouse. Swipe the screen or use your arrow keys to move the mouse in the direction you want."
      }
    },
    "collisions": {
      "instructions": {
        "source": "professional",
        "value": "Create a bubble by clicking or touching anywhere on the screen to trigger a collision with the moving bubbles on the screen. The more bubbles collide the more points you earn. You can only have one active collision at a time. Collide with power up bubbles for extra effects"
      }
    },
    "curves": {
      "instructions": {
        "source": "professional",
        "value": "Connect the circles on the left side to the circles on the right side of the screen by rotating tiles between the circles. Click a tile to rotate it clockwise and align the curves. The longer your chain - the more points you earn!"
      }
    },
    "errors": {
      "limit": {
        "description": {
          "source": "professional",
          "value": "The application could not recover from the error.\n\n Click finish to continue to the questions"
        },
        "title": {
          "source": "professional",
          "value": "Game Error Occured"
        }
      }
    },
    "fishy": {
      "instructions": {
        "source": "professional",
        "value": "Fishy Adventures is a side-scrolling game where the goal is to swim between other sea creatures without coming into contact with them. <br/><br/>Control the fish by tapping the screen or pressing the mouse button. The farther you swim the more points you collect and the higher your score. A question is delivered when the fish comes into contact with a \"?\" bubble. Play resumes after a question is answered."
      }
    },
    "gamedone": {
      "source": "professional",
      "value": "You Scored"
    },
    "gameover": {
      "ballover": {
        "source": "professional",
        "value": "Ball over line"
      },
      "gameover": {
        "source": "professional",
        "value": "Game Over"
      },
      "noballs": {
        "source": "professional",
        "value": "All balls cleared"
      },
      "noblocks": {
        "source": "professional",
        "value": "No matches left"
      },
      "noclusters": {
        "source": "professional",
        "value": "No matches left"
      },
      "remainingquestion": {
        "source": "professional",
        "value": "There is 1 question left to answer!"
      },
      "remainingquestions": {
        "source": "professional",
        "value": "There are <%- questionsleft %> questions left to answer!"
      },
      "spacewin": {
        "source": "professional",
        "value": "Congratulations, you have defeated space."
      },
      "timeout": {
        "source": "professional",
        "value": "Time is up!"
      }
    },
    "gem": {
      "instructions": {
        "source": "professional",
        "value": "Arrange a group of 4 gems so that they match the pattern shown in the top left corner of the screen in any orientation.<br/><br/>Click/tap anywhere on the board to rotate a group of 4 gems and move them to the desired positions. The more patterns you match, the more points you will earn!"
      }
    },
    "genre": {
      "ActionAdventure": {
        "source": "professional",
        "value": "Action & Adventure"
      },
      "BrainTeaser": {
        "source": "professional",
        "value": "Brain Teaser"
      },
      "MultiplayerBrainTeaser": {
        "source": "professional",
        "value": "Multiplayer Brain Teaser"
      },
      "Sports": {
        "source": "professional",
        "value": "Sports"
      }
    },
    "golf": {
      "instructions": {
        "source": "professional",
        "value": "Click or tap to set the ball's direction and power, but watch out for the wind. The closer you get to the hole, the more points you will earn!"
      }
    },
    "head2head": {
      "instructions": {
        "source": "professional",
        "value": "Select your opponent. Answer questions correctly and faster than your opponent to get more points and to win.<br/><br/>Your leaderboard score for this game is the sum of points you earn over a winning streak of multiple games. Lose a game and your score is reset."
      },
      "nothing": {
        "source": "professional",
        "value": "No questions left"
      }
    },
    "howtoplay": {
      "source": "professional",
      "value": "How to Play"
    },
    "invasion": {
      "instructions": {
        "source": "professional",
        "value": "The goal of the game is to cross the board and bring as many cows as you can back to the mothership while avoiding enemy fire.<br/><br/>Create your path by clicking on tiles. Anticipate where the enemy is firing and choose the safest path.\n The more cows you bring back, the more points you will earn."
      }
    },
    "jigsaw": {
      "detailedInstructions": {
        "source": "professional",
        "value": "Blue squares are owned by your team. Red squares are owned by the opposing team. Choose any puzzle square on the board, if you get the highest score you will claim that square. <br/><br/>The team with the most squares claimed when the game expires wins."
      }
    },
    "matchten": {
      "instructions": {
        "source": "professional",
        "value": "Observe the target number shown. Click any number to begin a chain. Continue to click connected numbers to create a sum equal to your target.<br/><br/>You will receive more points for a larger sequence of numbers. Question marks can be used as any value."
      }
    },
    "memory": {
      "instructions": {
        "source": "professional",
        "value": "Find matching pairs by flipping two cards over. Click a card to flip it over. The more pairs you match - the more points you earn!"
      }
    },
    "monster": {
      "instructions": {
        "source": "professional",
        "value": "Swipe or drag your mouse from a monster in any direction to hit another.When you hit a monster, it will disappear from the board.<br/><br/>Clear the board as fast as you can to get the most points and three stars!"
      }
    },
    "outro": {
      "challenge": {
        "source": "professional",
        "value": "Challenge Result"
      },
      "challengeNotAccepted": {
        "source": "professional",
        "value": "Has not played yet"
      },
      "challengeVersus": {
        "source": "professional",
        "value": "vs"
      },
      "challengeWinner": {
        "source": "professional",
        "value": "WINNER!"
      },
      "challengeWinnerWithWager": {
        "source": "professional",
        "value": "WINNER! - <%- wager %> REWARD POINTS"
      },
      "emptyslot": {
        "source": "professional",
        "value": "Position not filled"
      }
    },
    "pinball": {
      "instructions": {
        "source": "professional",
        "value": "Click or tap on the left and right side, or use the arrow keys, to use the paddles. Click and drag or swipe across the table to tilt the table in the desired direction. Be careful: too many table tilts will temporarily disable the paddles.  Alternate controls: \"a\" and \"d\" for left and right paddles. \"z\" and \"c\" for left and right table tilts.\nPress space, up arrow, or click/tap on the game to launch the ball."
      }
    },
    "pixel": {
      "finish": {
        "value": "You cleared the board!"
      },
      "instructions": {
        "source": "professional",
        "value": "Flood the board with the same color in less than 25 moves and you win.<br/><br/>The flood area starts in the top left corner and expands with each move. To expand the flood area select a paint can from the bottom of the screen that matches one of the colours touching the flooded area."
      },
      "lost": {
        "value": "You didn't quite clear the board in time."
      }
    },
    "shapes": {
      "instructions": {
        "source": "professional",
        "value": "Move the white square with the arrow keys or by clicking an adjacent square. Swap position with shapes to make a match of three or more identical shapes in a row.<br/><br/>Question marks are wild cards that can match with any shape. You have a maximum of seven moves at a time to make a match."
      }
    },
    "shark": {
      "instructions": {
        "source": "professional",
        "value": "Tap or click the screen to swim up, release to begin descending. Collect fish and swing through rings for points while avoiding the dangerous sea creatures.<br/><br/>The more fish you eat and the more rings you go through, the more points you will earn!"
      }
    },
    "simonsays": {
      "instructions": {
        "source": "professional",
        "value": "Click the blocks in the order they are highlighted on the screen. Click a block to highlight it. The longer your correct sequence - the more points you earn!"
      }
    },
    "snake": {
      "instructions": {
        "source": "professional",
        "value": "Guide the snake around the screen collecting fruit and questions but avoid running into the sides. Use the arrow keys to guide the snake around the screen. The longer your snake becomes - the more points you earn!"
      }
    },
    "spacerocks": {
      "instructions": {
        "source": "professional",
        "value": "Catch the falling rocks when they reach the shapes at the bottom of the screen.<br/><br/>Find the rhythm and use your arrow keys to match the pattern of falling rocks. Find the rhythm and tap the rocks as they reach the bottom. The more rocks you catch - the more points you will earn!"
      }
    },
    "start": {
      "source": "professional",
      "value": "Start Game"
    },
    "teamGame": {
      "challengeSelection": {
        "currentTeamSuffix": {
          "source": "professional",
          "value": "(your team)"
        },
        "headers": {
          "score": {
            "source": "professional",
            "value": "Score"
          },
          "team": {
            "source": "professional",
            "value": "Team"
          },
          "timeRemaining": {
            "source": "professional",
            "value": "Time Left"
          }
        },
        "howTo": {
          "source": "professional",
          "value": "Join a Game in Progress"
        },
        "overtime": {
          "source": "professional",
          "value": "Overtime"
        }
      },
      "scoreCard": {
        "header": {
          "losses": {
            "source": "professional",
            "value": "L"
          },
          "teamRank": {
            "source": "professional",
            "value": "Rank"
          },
          "wins": {
            "source": "professional",
            "value": "W"
          }
        },
        "noScores": {
          "source": "professional",
          "value": "There are currently no completed games."
        },
        "title": {
          "source": "professional",
          "value": "Team Standings"
        }
      }
    },
    "topplayers": {
      "source": "professional",
      "value": "Top players this week"
    },
    "unirat": {
      "instructions": {
        "source": "professional",
        "value": "The object of the game is to avoid the coconuts and keep the pirate on the plank. Earn extra points by collecting the falling coins. Use the left/right arrow keys to move the pirate. A Question will appear when the pirate falls off the plank. Play resumes after a question is answered."
      }
    },
    "zombietaps": {
      "instructions": {
        "source": "professional",
        "value": "Zombie Taps is a fast-faced \"whack em\" game where the player's objective is to tap incoming zombies. As the levels progress, the zombies appear quicker and the players reflexes are tested.<br/><br/>Zombies will frequently appear on screen that need to be tapped to destroy. Players will be instructed the number of times they must tap a zombie to destroy it. Destroying zombies that hold questions will cause a question to be delivered on the platform."
      }
    }
  },
  "gamescores": {
    "allgames": {
      "source": "professional",
      "value": "All Games"
    },
    "alltime": {
      "source": "professional",
      "value": "All-Time High Score"
    },
    "losses": {
      "source": "professional",
      "value": "Losses"
    },
    "nogames": {
      "source": "professional",
      "value": "You have no games to show scores for."
    },
    "nooneplayed": {
      "source": "professional",
      "value": "No one has played this game yet."
    },
    "nooneplayedinlast7days": {
      "source": "professional",
      "value": "No one in your location has played this game in the last 7 days."
    },
    "rank": {
      "source": "professional",
      "value": "Rank"
    },
    "score": {
      "source": "professional",
      "value": "Score"
    },
    "team_losses": {
      "source": "professional",
      "value": "All-Time Team Losses"
    },
    "team_wins": {
      "source": "professional",
      "value": "All-Time Team Wins"
    },
    "title": {
      "source": "professional",
      "value": "Game Scores & Challenges"
    },
    "user": {
      "source": "professional",
      "value": "Name"
    },
    "wins": {
      "source": "professional",
      "value": "Wins"
    },
    "youhavenotplayed": {
      "source": "professional",
      "value": "You have not played this game yet"
    },
    "yourbest": {
      "source": "professional",
      "value": "Your All-Time Best Score"
    }
  },
  "general": {
    "gotIt": {
      "source": "professional",
      "value": "Got it"
    },
    "inputUrl": {
      "source": "professional",
      "value": "Enter URL"
    },
    "overall": {
      "context": "Overall string",
      "source": "professional",
      "value": "Overall"
    },
    "page": {
      "context": "header for the page column",
      "source": "professional",
      "value": "Page"
    },
    "percentValueWithSign": {
      "context": "Percent value & percent symbol",
      "source": "professional",
      "value": "<%- percent %>%"
    },
    "published": {
      "context": "header for the published time column",
      "source": "professional",
      "value": "Published"
    },
    "versus": {
      "context": "Abbreviation for versus",
      "source": "professional",
      "value": "vs"
    }
  },
  "header": {
    "userScoreAriaLabel": {
      "source": "professional",
      "value": "User score"
    }
  },
  "hub": {
    "achievements": {
      "source": "professional",
      "value": "Achievements"
    },
    "achievementsCard": {
      "linkText": {
        "source": "professional",
        "value": "View Achievements"
      },
      "noAchievements": {
        "source": "professional",
        "value": "No achievements are currently available."
      },
      "numberNeededToNextBadge": {
        "source": "professional",
        "value": "<%- number %> more needed to get next badge"
      },
      "questionsAnsweredCorrectlyToday": {
        "source": "professional",
        "value": "Questions answered correctly today"
      },
      "rewardPointsEarnedToday": {
        "source": "professional",
        "value": "Reward Points earned today"
      }
    },
    "achievementsModal": {
      "CorrectAnswerStreak": {
        "badgeName": {
          "source": "professional",
          "value": "Correct Answer Streak"
        },
        "description": {
          "source": "professional",
          "value": "Correct Answer Streak"
        }
      },
      "ParticipationFrequency": {
        "badgeName": {
          "source": "professional",
          "value": "Frequent Training"
        },
        "description": {
          "source": "professional",
          "value": "Training Sessions"
        }
      },
      "SessionStreak": {
        "badgeName": {
          "source": "professional",
          "value": "Life-time Training"
        },
        "description": {
          "source": "professional",
          "value": "Training Sessions"
        }
      },
      "TopicLevelGraduationStreak": {
        "badgeName": {
          "source": "professional",
          "value": "Graduation"
        },
        "description": {
          "source": "professional",
          "value": "Topic Graduations"
        }
      },
      "currentBadge": {
        "context": "(Example) You earned a Life-time Training badge.",
        "source": "professional",
        "value": "You earned a <%= badgeName %> badge."
      },
      "currentBadgeAriaLabel": {
        "context": "(Example) You earned a Life-time Training badge of 10.",
        "source": "professional",
        "value": "You earned a <%= badgeName %> badge of <%= currentMilestone %>."
      },
      "nextBadge": {
        "context": "(Example) Next badge: 10 Training Sessions",
        "source": "professional",
        "value": "Next badge: <%- badgeNumber %> <%= badgeName %>"
      },
      "title": {
        "source": "professional",
        "value": "Congratulations!"
      },
      "viewAll": {
        "source": "professional",
        "value": "View all badges"
      }
    },
    "allTimeNewTopicsCompleted": {
      "source": "professional",
      "value": "New Topics Completed"
    },
    "ariaRegionLabels": {
      "recommendations": {
        "source": "professional",
        "value": "Recommendations"
      }
    },
    "auctions": {
      "source": "professional",
      "value": "Rewards"
    },
    "certifications": {
      "source": "professional",
      "value": "Certifications"
    },
    "currentKnowledgeScore": {
      "source": "professional",
      "value": "Average Knowledge Score"
    },
    "dailyActivites": {
      "source": "professional",
      "value": "Daily Activities"
    },
    "discoveryZoneButton": {
      "general": {
        "source": "professional",
        "value": "Launch <%= t('appNames.knowledge') %>"
      }
    },
    "discoveryZonePrompt": {
      "general": {
        "source": "professional",
        "value": "Find what you need, when you need it."
      },
      "reviews": {
        "source": "professional",
        "value": "Find what you need, when you need it."
      }
    },
    "extratraining": {
      "source": "professional",
      "value": "Extra Training"
    },
    "formalExams": {
      "source": "professional",
      "value": "Exams"
    },
    "gamescores": {
      "source": "professional",
      "value": "Game Scores"
    },
    "guidedLearning": {
      "context": "Learning Path",
      "source": "professional",
      "value": "Path"
    },
    "home": {
      "context": "homepage",
      "source": "professional",
      "value": "Home"
    },
    "knowledgemap": {
      "source": "professional",
      "value": "Report Card"
    },
    "latestMessages": {
      "source": "professional",
      "value": "Latest Messages"
    },
    "latestMessagesLinkText": {
      "source": "professional",
      "value": "View Messages"
    },
    "leaderboards": {
      "source": "professional",
      "value": "Top Performers"
    },
    "learningEvents": {
      "source": "professional",
      "value": "Events"
    },
    "myTraining": {
      "source": "professional",
      "value": "My Training"
    },
    "newTopics": {
      "source": "professional",
      "value": "New Topics"
    },
    "newsfeed": {
      "source": "professional",
      "value": "Feed"
    },
    "numItems": {
      "source": "professional",
      "value": "<%- numItems %> items"
    },
    "numItemsSingular": {
      "source": "professional",
      "value": "1 item"
    },
    "postTraining": {
      "doneMessage": {
        "source": "professional",
        "value": "You are done for the day. Come back next time for a new session!"
      },
      "noTrainingMessage": {
        "source": "professional",
        "value": "No training points available. Explore more below."
      },
      "previews": {
        "events": {
          "linkText": {
            "source": "professional",
            "value": "View Events"
          },
          "noEvents": {
            "source": "professional",
            "value": "No events are currently available."
          },
          "title": {
            "source": "professional",
            "value": "Upcoming Events"
          }
        },
        "leaderboard": {
          "empty": {
            "source": "professional",
            "value": "No users found"
          },
          "linkText": {
            "source": "professional",
            "value": "View Leaderboard"
          },
          "title": {
            "last30days": {
              "context": "Indicates the Leaderboard represents the last 30 days.",
              "source": "professional",
              "value": "Top Performers - Last 30 days"
            },
            "monthly": {
              "context": "'currentMonth' is the full name of the current month, ie. January, February, etc.",
              "source": "professional",
              "value": "Top Performers - <%- currentMonth %>"
            }
          }
        },
        "rewards": {
          "auction": {
            "closes": {
              "future": {
                "context": "when an auction will end, where the time phrase is something similar to in 3 Days, or in 4 hours, already translated into the locale",
                "source": "professional",
                "value": "Closes <%- timePhrase %>"
              },
              "past": {
                "context": "when an auction ended in the past, where the time phrase is something similar to 3 Days ago, or 4 hours ago, already translated into the locale",
                "source": "professional",
                "value": "Closed <%- timePhrase %>"
              }
            },
            "notWinning": {
              "source": "professional",
              "value": "You do not have the winning bid."
            },
            "winning": {
              "source": "professional",
              "value": "You have the winning bid."
            }
          },
          "buyables": {
            "available": {
              "plural": {
                "context": "number of rewards available as buyables, plural",
                "source": "professional",
                "value": "<%- numberOfPrizes %> rewards available."
              },
              "singular": {
                "context": "number of rewards available as buyables, singular",
                "source": "professional",
                "value": "<%- numberOfPrizes %> reward available."
              }
            }
          },
          "linkText": {
            "source": "professional",
            "value": "View Rewards"
          },
          "noRewards": {
            "source": "professional",
            "value": "No rewards are currently available. Check again next time!"
          },
          "rewardsAvailable": {
            "plural": {
              "context": "number of rewards available for the spin to win game, plural",
              "source": "professional",
              "value": "<%- rewardsAvailable %> rewards available."
            },
            "singular": {
              "context": "number of rewards available for the spin to win game, singular",
              "source": "professional",
              "value": "<%- rewardsAvailable %> reward available."
            }
          },
          "title": {
            "source": "professional",
            "value": "Rewards Available"
          }
        }
      },
      "resultMessageGreat": {
        "source": "professional",
        "value": "Great job, <%- name %>!"
      },
      "resultMessageNiceTry": {
        "context": "Praising effort instead of results",
        "source": "professional",
        "value": "Nice try, <%- name %>!"
      },
      "resultMessageStreak": {
        "context": "The user has answered a long series of questions in a row correctly",
        "source": "professional",
        "value": "Wow, you're on a great streak!"
      }
    },
    "preTraining": {
      "timeGreeting": {
        "afternoon": {
          "source": "professional",
          "value": "Good afternoon, <%- name %>!"
        },
        "evening": {
          "source": "professional",
          "value": "Good evening, <%- name %>!"
        },
        "generic": {
          "source": "professional",
          "value": "Welcome, <%- name %>!"
        },
        "morning": {
          "source": "professional",
          "value": "Good morning, <%- name %>!"
        },
        "night": {
          "source": "professional",
          "value": "Welcome back, <%- name %>!"
        }
      }
    },
    "profile": {
      "source": "professional",
      "value": "Profile"
    },
    "referral": {
      "source": "professional",
      "value": "Tell-A-Friend"
    },
    "refresher": {
      "source": "professional",
      "value": "Refresher Training"
    },
    "rewardPointsLast30Days": {
      "source": "professional",
      "value": "Reward Points Last 30 Days"
    },
    "tasks": {
      "source": "professional",
      "value": "To-Dos"
    },
    "title": {
      "context": "homepage",
      "source": "professional",
      "value": "Home"
    },
    "topicLevelStatus": {
      "beginner": {
        "source": "professional",
        "value": "Beginner"
      },
      "expert": {
        "source": "professional",
        "value": "Expert"
      },
      "graduate": {
        "source": "professional",
        "value": "Graduated"
      },
      "intermediate": {
        "source": "professional",
        "value": "Intermediate"
      },
      "notStarted": {
        "source": "professional",
        "value": "Not Started"
      }
    },
    "topicProgress": {
      "source": "professional",
      "value": "Topic Progress"
    },
    "topicProgressLinkText": {
      "source": "professional",
      "value": "View Report Card"
    },
    "training": {
      "source": "professional",
      "value": "Training"
    }
  },
  "knowledge": {
    "answered": {
      "source": "professional",
      "value": "answered"
    },
    "buttonAccessibilityLabel": {
      "source": "professional",
      "value": "<%- correctness %>.  Submitted <%- date %>.  Select to view."
    },
    "category": {
      "source": "professional",
      "value": "Category:"
    },
    "certification": {
      "CERTIFIED": {
        "source": "professional",
        "value": "Certified"
      },
      "DUE": {
        "source": "professional",
        "value": "Due"
      },
      "NA": {
        "source": "professional",
        "value": ""
      },
      "OVERDUE": {
        "source": "professional",
        "value": "Overdue"
      }
    },
    "certificationLabel": {
      "CERTIFIED": {
        "source": "professional",
        "value": "Certification certified"
      },
      "DUE": {
        "source": "professional",
        "value": "Certification due"
      },
      "OVERDUE": {
        "source": "professional",
        "value": "Certification overdue"
      }
    },
    "correct": {
      "source": "professional",
      "value": "Correct"
    },
    "correctanswercount": {
      "source": "professional",
      "value": "Total Correct Answers"
    },
    "correctness": {
      "source": "professional",
      "value": "Correctness"
    },
    "date": {
      "source": "professional",
      "value": "Date"
    },
    "empty": {
      "source": "professional",
      "value": "There are no recent answer results"
    },
    "graduatedTopicLevel": {
      "source": "professional",
      "value": "Topics Graduated"
    },
    "incorrect": {
      "source": "professional",
      "value": "Incorrect"
    },
    "level": {
      "context": "Displays the level number of a topic",
      "source": "professional",
      "value": "Level <%- level %>"
    },
    "notAvailable": {
      "source": "professional",
      "value": "--"
    },
    "notopics": {
      "source": "professional",
      "value": "You have no topics to report on."
    },
    "overallaverage": {
      "source": "professional",
      "value": "Current Average"
    },
    "plusminus": {
      "source": "professional",
      "value": "Plus/Minus"
    },
    "questionText": {
      "source": "professional",
      "value": "Question Text"
    },
    "questionnumber": {
      "source": "professional",
      "value": "Question Number"
    },
    "questionsTitle": {
      "source": "professional",
      "value": "<%- part %> of <%- total %> Answered Correctly"
    },
    "recentanswerresults": {
      "source": "professional",
      "value": "Recent Answer Results"
    },
    "scoreHighNotice": {
      "source": "professional",
      "value": "High result."
    },
    "scoreLowWarning": {
      "source": "professional",
      "value": "Low result."
    },
    "showQuestions": {
      "source": "professional",
      "value": "Show Questions"
    },
    "statusTitle": {
      "beginner": {
        "source": "professional",
        "value": "Beginner"
      },
      "expert": {
        "source": "professional",
        "value": "Expert - Graduates over 90% with high confidence"
      },
      "fair": {
        "source": "professional",
        "value": "Fair - Between 50% and 80%"
      },
      "good": {
        "source": "professional",
        "value": "Good - Over 80%"
      },
      "graduated": {
        "source": "professional",
        "value": "Graduate - Over 80% after 2 iterations"
      },
      "intermediate": {
        "source": "professional",
        "value": "Intermediate"
      },
      "needsCoaching": {
        "source": "professional",
        "value": "Needs Coaching - Below 50% for at least 3 iterations"
      },
      "notStarted": {
        "source": "professional",
        "value": "Not Started - No answer"
      },
      "overconfident": {
        "source": "professional",
        "value": "Overconfident - Below 40% but high confidence"
      },
      "poor": {
        "source": "professional",
        "value": "Poor - Below 50%"
      },
      "recommended": {
        "source": "professional",
        "value": "Recommended"
      },
      "underconfident": {
        "source": "professional",
        "value": "Underconfident - Over 90% but low confidence"
      }
    },
    "subject": {
      "source": "professional",
      "value": "Subject:"
    },
    "title": {
      "source": "professional",
      "value": "Report Card"
    },
    "toggleTableRecentAnswerResults": {
      "source": "professional",
      "value": "Toggle table for recent answer results"
    },
    "topiclisttitle": {
      "source": "professional",
      "value": "Topic List"
    },
    "totalanswercount": {
      "source": "professional",
      "value": "Total Questions Answered"
    },
    "trend": {
      "source": "professional",
      "value": "Trend"
    },
    "viewQuestion": {
      "source": "professional",
      "value": "View Question"
    },
    "youhavegraduated": {
      "source": "professional",
      "value": "You have graduated this topic."
    }
  },
  "layout": {
    "balance": {
      "source": "professional",
      "value": "Reward Points"
    },
    "confirm": {
      "source": "professional",
      "value": "Confirm"
    },
    "correct": {
      "source": "professional",
      "value": "Correct"
    },
    "currentAverage": {
      "source": "professional",
      "value": "Average"
    },
    "disclaimer": {
      "source": "professional",
      "value": ""
    },
    "earned": {
      "source": "professional",
      "value": "+<%- points %>"
    },
    "incorrect": {
      "source": "professional",
      "value": "Incorrect"
    },
    "logout": {
      "source": "professional",
      "value": "Log Out"
    },
    "newversion": {
      "source": "professional",
      "value": "The application has been updated. Would you like to reload now?"
    },
    "noframes": {
      "source": "professional",
      "value": "Sorry, your browser does not support iFrames."
    },
    "pointsearned": {
      "source": "professional",
      "value": "Good for"
    },
    "poweredby": {
      "source": "professional",
      "value": "Powered by"
    },
    "resetapp": {
      "source": "professional",
      "value": "Reset App"
    },
    "send": {
      "source": "professional",
      "value": "Send"
    },
    "skip": {
      "source": "professional",
      "value": "Skip"
    },
    "submit": {
      "source": "professional",
      "value": "Submit"
    }
  },
  "learnerTrainingPage": {
    "catalog": {
      "ariaLabel": {
        "filterApplied": {
          "context": "Tell the user a filter has been applied and user will not see the complete list",
          "source": "professional",
          "value": "A filter has already been applied."
        },
        "filterButton": {
          "context": "Tell the user to click the button to open modal that contains the filter",
          "source": "professional",
          "value": "Click to open filter modal"
        }
      },
      "empty": {
        "source": "professional",
        "value": "Results not found. Try expanding your search and filter criteria."
      },
      "filter": {
        "all": {
          "source": "professional",
          "value": "All"
        },
        "filter": {
          "source": "professional",
          "value": "Filter"
        },
        "notAvailable": {
          "source": "professional",
          "value": "Subject filter not available for this type"
        },
        "pathsOnly": {
          "source": "professional",
          "value": "Paths only"
        },
        "results": {
          "context": "Displays the number of results in a list. (Example: 35 result(s)",
          "source": "professional",
          "value": "<%- resultCount %> result(s)"
        },
        "resultsPlural": {
          "context": "Number of results in a list with more than 1 item in it (Example: 25 results)",
          "source": "professional",
          "value": "<%- resultCount %> results"
        },
        "resultsSingular": {
          "context": "Number of results in a list when there is only a single item in that list.",
          "source": "professional",
          "value": "1 result"
        },
        "subjects": {
          "source": "professional",
          "value": "Subjects"
        },
        "subjectsExplanation": {
          "source": "professional",
          "value": "(Topics only)"
        },
        "topicsOnly": {
          "source": "professional",
          "value": "Topics only"
        },
        "type": {
          "source": "professional",
          "value": "Type"
        }
      },
      "noTraining": {
        "context": "Message declaring that there are no training items to be displayed on this page.",
        "source": "professional",
        "value": "There is no other training available at this time, please check back later."
      }
    },
    "extraTrainingAvailabilityMessage": {
      "source": "professional",
      "value": "All training will be available after your daily training is complete. See topic details to view a training module."
    },
    "search": {
      "catalog": {
        "context": "This is the placeholder for the search filter input field on the Training Catalog tab",
        "source": "professional",
        "value": "Filter All Available Content"
      },
      "paths": {
        "context": "This is the placeholder for the search filter input field on the Training Paths tab",
        "source": "professional",
        "value": "Filter Paths"
      },
      "topics": {
        "context": "This is the placeholder for the search filter input field on the Training Topics tab",
        "source": "professional",
        "value": "Filter Topics and Subjects"
      }
    },
    "seeTopicDetailsMessage": {
      "source": "professional",
      "value": "See topic details to view a training module."
    },
    "tabTitle": {
      "catalog": {
        "source": "professional",
        "value": "Catalog"
      },
      "path": {
        "source": "professional",
        "value": "Paths"
      },
      "topic": {
        "source": "professional",
        "value": "Topics"
      }
    },
    "title": {
      "source": "professional",
      "value": "Training"
    }
  },
  "learningEvents": {
    "details": {
      "attendees": {
        "source": "professional",
        "value": "Attendees"
      },
      "attendeesTabTitle": {
        "source": "professional",
        "value": "Event Attendees"
      },
      "cancelEnrollment": {
        "source": "professional",
        "value": "Cancel Enrollment"
      },
      "detailsTabTitle": {
        "source": "professional",
        "value": "Event Details"
      },
      "enroll": {
        "source": "professional",
        "value": "Enroll"
      },
      "enrolled": {
        "source": "professional",
        "value": "Enrolled"
      },
      "errors": {
        "3001": {
          "source": "professional",
          "value": "Your enrollment has changed or been removed since performing this operation. Try again later."
        },
        "3067": {
          "source": "professional",
          "value": "This event is full."
        },
        "3068": {
          "source": "professional",
          "value": "You are already enrolled in a session for this event."
        },
        "3069": {
          "source": "professional",
          "value": "You do not have permission to join in this event anymore."
        }
      },
      "event": {
        "source": "professional",
        "value": "Event"
      },
      "eventDetails": {
        "source": "professional",
        "value": "Details"
      },
      "joinWaitingList": {
        "source": "professional",
        "value": "Join Waiting List"
      },
      "leaveWaitingList": {
        "source": "professional",
        "value": "Leave Waiting List"
      },
      "noScheduledEventss": {
        "source": "professional",
        "value": "No Session Details have been created for this event."
      },
      "noSetTime": {
        "source": "professional",
        "value": "To be determined"
      },
      "peopleWaiting": {
        "source": "professional",
        "value": "There are <%- numWaiting %> people on the waiting list."
      },
      "personWaiting": {
        "source": "professional",
        "value": "There is 1 person on the waiting list."
      },
      "session": {
        "date": {
          "source": "professional",
          "value": "Date:"
        },
        "language": {
          "source": "professional",
          "value": "Language:"
        },
        "location": {
          "source": "professional",
          "value": "Location:"
        },
        "room": {
          "source": "professional",
          "value": "Room:"
        },
        "time": {
          "source": "professional",
          "value": "Time:"
        }
      },
      "thisEventFull": {
        "source": "professional",
        "value": "This event is full."
      },
      "titles": {
        "description": {
          "source": "professional",
          "value": "Description"
        },
        "instructor": {
          "source": "professional",
          "value": "Facilitator"
        },
        "location": {
          "source": "professional",
          "value": "Location:"
        },
        "onlineLink": {
          "source": "professional",
          "value": "More Details"
        },
        "scheduledEvents": {
          "source": "professional",
          "value": "Session Details"
        }
      },
      "userEnrolled": {
        "context": "Indicates that the user is enrolled in the event",
        "source": "professional",
        "value": "You are enrolled"
      },
      "waiting": {
        "source": "professional",
        "value": "On Waiting List"
      }
    },
    "filtered": {
      "selectSessionEnroll": {
        "source": "professional",
        "value": "Select a session to enroll"
      }
    },
    "history": {
      "source": "professional",
      "value": "History"
    },
    "listStatus": {
      "attendanceNotTaken": {
        "source": "professional",
        "value": "Attendance Not Taken"
      },
      "attended": {
        "source": "professional",
        "value": "Attended"
      },
      "enrolled": {
        "source": "professional",
        "value": "Enrolled"
      },
      "full": {
        "source": "professional",
        "value": "Full"
      },
      "fullWaitingList": {
        "source": "professional",
        "value": "Full (Waiting List Available)"
      },
      "notAttended": {
        "source": "professional",
        "value": "Not Attended"
      },
      "onWaitingList": {
        "source": "professional",
        "value": "On Waiting List (<%- waitingPosition %>)"
      },
      "spotLeft": {
        "source": "professional",
        "value": "<%- numSeats %> Spot left"
      },
      "spotsLeft": {
        "source": "professional",
        "value": "<%- numSeats %> Spots left"
      }
    },
    "noAttendees": {
      "source": "professional",
      "value": "There are currently no attendees"
    },
    "noEvents": {
      "source": "professional",
      "value": "There are no events."
    },
    "notification": {
      "addedToWaitingList": {
        "source": "professional",
        "value": "<%= userFullName %> added you to the waiting list for"
      },
      "ended": {
        "source": "professional",
        "value": "When event happened: <%- relativeDateString %>"
      },
      "enrolledIn": {
        "source": "professional",
        "value": "<%= userFullName %> enrolled you in"
      },
      "retiredWarning": {
        "value": "Event has been deleted or retired."
      },
      "starts": {
        "source": "professional",
        "value": "When event starts: <%- relativeDateString %>"
      },
      "title": {
        "source": "professional",
        "value": "Event Notifications"
      },
      "updatedEvent": {
        "source": "professional",
        "value": "<%= userFullName %> updated the event"
      }
    },
    "scheduled": {
      "source": "professional",
      "value": "Scheduled"
    },
    "searchLabel": {
      "source": "professional",
      "value": "Search Events"
    },
    "upcoming": {
      "source": "professional",
      "value": "Upcoming"
    }
  },
  "login": {
    "genericError": {
      "source": "professional",
      "value": "Failed To Log In"
    },
    "trainingLoginMsg": {
      "source": "professional",
      "value": "Log in to start today's activities!"
    }
  },
  "mentions": {
    "deletedUser": {
      "source": "professional",
      "value": "Deleted User"
    }
  },
  "menu": {
    "reports": {
      "source": "professional",
      "value": "Reports"
    }
  },
  "messages": {
    "ArticleDetailsCommentModel": {
      "DELETE": {
        "3016": {
          "source": "professional",
          "value": "That comment has already been deleted."
        },
        "error": {
          "source": "professional",
          "value": "Failed to delete comment."
        },
        "success": {
          "source": "professional",
          "value": "Comment deleted successfully."
        }
      },
      "POST": {
        "error": {
          "3016": {
            "source": "professional",
            "value": "The page you are attempting to comment on is no longer available or comments have been disabled"
          },
          "3064": {
            "source": "professional",
            "value": "Your comment contains unsafe content and cannot be posted. Please edit your comment and try again."
          },
          "default": {
            "source": "professional",
            "value": "Failed to add comment"
          }
        },
        "success": {
          "source": "professional",
          "value": "Successfully added comment"
        }
      }
    },
    "PostModel": {
      "DELETE": {
        "3001": {
          "source": "professional",
          "value": "That post does not exist."
        },
        "error": {
          "source": "professional",
          "value": "There was an error deleting that post."
        },
        "success": {
          "source": "professional",
          "value": "Post deleted successfully."
        }
      },
      "POST": {
        "2001": {
          "context": "This is an error when a user is missing a required item before publishing a post. Currently only a channel is required",
          "source": "professional",
          "value": "Channel has not been selected"
        }
      }
    }
  },
  "months": {
    "apr": {
      "source": "professional",
      "value": "Apr"
    },
    "aug": {
      "source": "professional",
      "value": "Aug"
    },
    "dec": {
      "source": "professional",
      "value": "Dec"
    },
    "feb": {
      "source": "professional",
      "value": "Feb"
    },
    "formattedDate": {
      "source": "professional",
      "value": "<%- months %> <%- day %>, <%- year %>"
    },
    "jan": {
      "source": "professional",
      "value": "Jan"
    },
    "july": {
      "source": "professional",
      "value": "July"
    },
    "june": {
      "source": "professional",
      "value": "June"
    },
    "mar": {
      "source": "professional",
      "value": "Mar"
    },
    "may": {
      "source": "professional",
      "value": "May"
    },
    "nov": {
      "source": "professional",
      "value": "Nov"
    },
    "oct": {
      "source": "professional",
      "value": "Oct"
    },
    "sept": {
      "source": "professional",
      "value": "Sept"
    }
  },
  "multiFilter": {
    "createNew": {
      "source": "professional",
      "value": "Create New Filter"
    }
  },
  "newTasks": {
    "selectTeam": {
      "context": "Message when viewing team tasks",
      "source": "professional",
      "value": "Select a team to view their tasks"
    }
  },
  "news": {
    "gameRank": {
      "1": {
        "source": "professional",
        "value": "Gold"
      },
      "2": {
        "source": "professional",
        "value": "Silver"
      },
      "3": {
        "source": "professional",
        "value": "Bronze"
      }
    },
    "like": {
      "source": "professional",
      "value": "Like"
    },
    "likes": {
      "clickToLike": {
        "source": "professional",
        "value": "Click to like"
      },
      "clickToUnlike": {
        "source": "professional",
        "value": "Click to unlike"
      },
      "n_others": {
        "source": "professional",
        "value": "<%- count %> people like this"
      },
      "noone": {
        "source": "professional",
        "value": ""
      },
      "one": {
        "source": "professional",
        "value": "<%- names[0] %> likes this"
      },
      "one_and_n_others": {
        "source": "professional",
        "value": "<%- names[0] %> and <%- count %> others like this"
      },
      "one_and_one_other": {
        "source": "professional",
        "value": "<%- names[0] %> and <%- count %> other like this"
      },
      "one_other": {
        "source": "professional",
        "value": "<%- count %> person likes this"
      },
      "three": {
        "source": "professional",
        "value": "<%- names[0] %>, <%- names[1] %> and <%- names[2] %> like this"
      },
      "three_and_n_others": {
        "source": "professional",
        "value": "<%- names[0] %>, <%- names[1] %>, <%- names[2] %> and <%- count %> others like this"
      },
      "three_and_one_other": {
        "source": "professional",
        "value": "<%- names[0] %>, <%- names[1] %>, <%- names[2] %> and <%- count %> other like this"
      },
      "two": {
        "source": "professional",
        "value": "<%- names[0] %> and <%- names[1] %> like this"
      },
      "two_and_n_others": {
        "source": "professional",
        "value": "<%- names[0] %>, <%- names[1] %> and <%- count %> others like this"
      },
      "two_and_one_other": {
        "source": "professional",
        "value": "<%- names[0] %>, <%- names[1] %> and <%- count %> other like this"
      },
      "you": {
        "source": "professional",
        "value": "You like this"
      },
      "you_and_n_others": {
        "source": "professional",
        "value": "You and <%- count %> others like this"
      },
      "you_and_one": {
        "source": "professional",
        "value": "You and <%- names[0] %> like this"
      },
      "you_and_one_and_n_others": {
        "source": "professional",
        "value": "You, <%- names[0] %> and <%- count %> others like this"
      },
      "you_and_one_and_one_other": {
        "source": "professional",
        "value": "You, <%- names[0] %> and <%- count %> other like this"
      },
      "you_and_one_other": {
        "source": "professional",
        "value": "You and <%- count %> other like this"
      },
      "you_and_three": {
        "source": "professional",
        "value": "You, <%- names[0] %>, <%- names[1] %> and <%- names[2] %> like this"
      },
      "you_and_three_and_n_others": {
        "source": "professional",
        "value": "You, <%- names[0] %>, <%- names[1] %>, <%- names[2] %> and <%- count %> others like this"
      },
      "you_and_three_and_one_other": {
        "source": "professional",
        "value": "You, <%- names[0] %>, <%- names[1] %>, <%- names[2] %> and <%- count %> other like this"
      },
      "you_and_two": {
        "source": "professional",
        "value": "You, <%- names[0] %> and <%- names[1] %> like this"
      },
      "you_and_two_and_n_others": {
        "source": "professional",
        "value": "You, <%- names[0] %>, <%- names[1] %> and <%- count %> others like this"
      },
      "you_and_two_and_one_other": {
        "source": "professional",
        "value": "You, <%- names[0] %>, <%- names[1] %> and <%- count %> other like this"
      }
    },
    "messageDetails": {
      "title": {
        "source": "professional",
        "value": "Broadcast Message"
      }
    },
    "messageNotFound": {
      "source": "professional",
      "value": "Message not found."
    },
    "noAchievements": {
      "source": "professional",
      "value": "No achievements right now"
    },
    "noMessages": {
      "source": "professional",
      "value": "No messages right now"
    },
    "noMoreMessages": {
      "source": "professional",
      "value": "No more messages"
    },
    "nomorepages": {
      "source": "professional",
      "value": "No more news"
    },
    "nonews": {
      "source": "professional",
      "value": "No news right now"
    },
    "recent": {
      "source": "professional",
      "value": "Just now"
    },
    "tabs": {
      "messageFeed": {
        "source": "professional",
        "value": "Messages"
      },
      "newsFeed": {
        "source": "professional",
        "value": "News"
      }
    },
    "title": {
      "source": "professional",
      "value": "Feed"
    },
    "type": {
      "AuctionWinner": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Won the <span><%- content.prizeName %></span> auction with a winning bid of <span class=\"coinvalue\"><%- content.coinValue %></span> points. Congratulations!"
      },
      "BuyNowWinner": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Bought the <span><%- content.prizeName %></span> for <span class=\"coinvalue\"><%- content.coinValue %></span> points."
      },
      "CorrectAnswerStreak": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Correct Answer Streak Badge Earned - <%- content.streakSize %> Correct"
      },
      "CorrectAnswerStreakSingular": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Correct Answer Streak Badge Earned - First Answer"
      },
      "GameChallengeLoss": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> <span class=\"won-text\">Lost</span> a game challenge to <%- content.winnerUserName %> in <span class=\"gamename\"><%- content.gameName %></span> with a score of <%- numberWithCommas(content.loserScore) %> to <%- numberWithCommas(content.winnerScore) %>."
      },
      "GameChallengeTie": {
        "source": "professional",
        "value": "<span class=\"name\"><%- content.challengerUserName %></span> Tied <%- content.challengeeUserName %> in a <span class=\"gamename\"><%- content.gameName %></span> game challenge with a score of <%- numberWithCommas(content.challengerScore) %>."
      },
      "GameChallengeWon": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> <span class=\"won-text\">Won</span> a game challenge against <%- content.loserUserName %> in <span class=\"gamename\"><%- content.gameName %></span> with a score of <%- numberWithCommas(content.winnerScore) %> to <%- numberWithCommas(content.loserScore) %>."
      },
      "GameHighScore": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Ranked <%- content.gameRank %> in <span class=\"gamename\"><%- content.gameName %></span> with a score of <span class=\"score\"><%- numberWithCommas(content.gameScore) %></span>."
      },
      "MilestoneAchievement": {
        "context": "Bill Onboarding Milestone Achieved",
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> <%- content.milestoneName %> Milestone Achieved"
      },
      "MilestoneAchievement_allPointsAchieved": {
        "context": "Bob Onboarding Milestone Achieved - 90 + 10 points earned",
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> <%- content.milestoneName %> Milestone Achieved - <%- content.pointsAchieved %> + <%- content.bonusPointsAchieved %> reward points earned"
      },
      "MilestoneAchievement_onlyBasePointsAchieved": {
        "context": "Bob Onboarding Milestone Achieved - 90 points earned",
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> <%- content.milestoneName %> Milestone Achieved - <%- content.pointsAchieved %> reward points earned"
      },
      "ParticipationFrequency": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Frequent Training Badge Earned - <%- content.streakSize %> Sessions"
      },
      "RaffleWinner": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Won the <span><%- content.prizeName %></span> raffle."
      },
      "SessionStreak": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Life-time Training Milestone Achieved - <%- content.streakSize %> Sessions"
      },
      "SessionStreakSingular": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Life-time Training Milestone Achieved - First Session"
      },
      "SlotsWinner": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Won the <span><%- content.prizeName %></span> playing Spin-To-Win!"
      },
      "TopicLevelGraduation": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Graduated <span class=\"topicname\"><%- content.topicName %>, difficulty level <%- content.level %></span>."
      },
      "TopicLevelGraduationStreak": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Graduation Milestone Achieved - <%- content.streakSize %> Graduations"
      },
      "TopicLevelGraduationStreakSingular": {
        "source": "professional",
        "value": "<span class=\"name\"><%- user.displayName %></span> Graduation Milestone Achieved - First Graduation"
      }
    },
    "unlike": {
      "source": "professional",
      "value": "Unlike"
    }
  },
  "notificationCenter": {
    "commentOnPost": {
      "context": "Single comment notification. Ex: Maryna Salii commented on your post.",
      "source": "professional",
      "value": "<%-name %> commented on your post"
    },
    "commentOnPostStacked": {
      "context": "Stacked comment notification. Ex: Maryna Salii and 5 others commented on your post.",
      "source": "professional",
      "value": "<%-name %> and <%-count %> others commented on your post"
    },
    "commentOnPostStackedSingle": {
      "context": "Stacked single comment notification. Ex: Maryna Salii and 1 other commented on your post.",
      "source": "professional",
      "value": "<%-name %> and 1 other commented on your post"
    },
    "markAllAsRead": {
      "source": "professional",
      "value": "Mark All as Read"
    },
    "mentionInComment": {
      "context": "Mention in a comment notification. Ex: Maryna Salii tagged you in a comment.",
      "source": "professional",
      "value": "<%-name %> tagged you in a comment"
    },
    "mentionInPost": {
      "context": "Mention in a post notification. Ex: Maryna Salii tagged you in a post.",
      "source": "professional",
      "value": "<%-name %> tagged you in a post"
    },
    "noNotifications": {
      "source": "professional",
      "value": "No Notifications"
    },
    "noNotificationsDescription": {
      "source": "professional",
      "value": "We'll let you know when notifications arrive."
    },
    "reactionOnComment": {
      "context": "Single post reaction notification. Ex: Maryna Salii reacted to your comment.",
      "source": "professional",
      "value": "<%-name %> reacted to your comment "
    },
    "reactionOnCommentStacked": {
      "context": "Stacked comment reaction notification. Ex: Maryna Salii and 3 others reacted to your comment.",
      "source": "professional",
      "value": "<%-name %> and <%-count %> others reacted to your comment"
    },
    "reactionOnCommentStackedSingle": {
      "context": "Stacked single comment reaction notification. Ex: Maryna Salii and 1 other reacted to your comment.",
      "source": "professional",
      "value": "<%-name %> and 1 other reacted to your comment"
    },
    "reactionOnPost": {
      "context": "Single post reaction notification. Ex: Maryna Salii reacted to your post. ",
      "source": "professional",
      "value": "<%-name %> reacted to your post"
    },
    "reactionOnPostStacked": {
      "context": "Stacked post reaction notification. Ex: Maryna Salii and 5 others reacted to your post.",
      "source": "professional",
      "value": "<%-name %> and <%-count %> others reacted to your post"
    },
    "reactionOnPostStackedSingle": {
      "context": "Stacked single post reaction notification. Ex: Maryna Salii and 1 other reacted to your post.",
      "source": "professional",
      "value": "<%-name %> and 1 other reacted to your post"
    }
  },
  "nudge": {
    "opentext": {
      "bonus": {
        "context": "Bonus reward points for answering an open text question",
        "source": "professional",
        "value": "<%- points %> bonus reward points"
      }
    }
  },
  "nudgeFeed": {
    "question": {
      "correctTitle": {
        "source": "professional",
        "value": "You got that right!"
      },
      "incorrectTitle": {
        "source": "professional",
        "value": "Better luck next time!"
      }
    },
    "survey": {
      "answeredTitle": {
        "source": "professional",
        "value": "Thanks for your feedback!"
      }
    }
  },
  "nudgeIntegration": {
    "viewDetails": {
      "source": "professional",
      "value": "View details"
    },
    "viewDetailsAria": {
      "context": "A screen reader label for a button that when clicked, launches further details regarding a task or nudge that is assigned to a user. Example: 'Log your time off - view details'",
      "source": "professional",
      "value": "<%- title %> - view details"
    }
  },
  "nudgeOffers": {
    "celebration": {
      "source": "professional",
      "value": "Thanks!"
    },
    "claimPoints": {
      "source": "professional",
      "value": "Claim my points!"
    },
    "communication": {
      "emptyViewSecondary": {
        "source": "professional",
        "value": "Previously reviewed campaign communications will appear here."
      },
      "emptyViewTitle": {
        "source": "professional",
        "value": "Nothing to see here (yet)."
      },
      "moreCommunications": {
        "plural": {
          "context": "Number of communication nudges there are remaining for more than 1, eg. 4 more communications to view",
          "source": "professional",
          "value": "<%- count %> more communications to view"
        },
        "short": {
          "context": "Number of communication nudges there are remaining for mobile view",
          "source": "professional",
          "value": "<%- count %> more to view"
        },
        "singular": {
          "context": "Number of communication nudges there are remaining singular version, eg. 1 more communication to view",
          "source": "professional",
          "value": "1 more communication to view"
        }
      },
      "noIncomplete": {
        "source": "professional",
        "value": "You have no new communications."
      },
      "showMore": {
        "source": "professional",
        "value": "Show more"
      },
      "title": {
        "context": "Communication page title",
        "source": "professional",
        "value": "Communication"
      },
      "viewButton": {
        "context": "Label for a button that navigates to the completed communications page",
        "source": "professional",
        "value": "View Completed Communications"
      }
    },
    "confidential": {
      "source": "professional",
      "value": "Your response will be confidential"
    },
    "correctAnswer": {
      "context": "prompt appearing above the correct answer button after acknowledging a nudge offer",
      "source": "professional",
      "value": "The correct answer is"
    },
    "followUp": {
      "source": "professional",
      "value": "Follow-Up"
    },
    "noPoints": {
      "context": "Acknowledgement of announcement on button",
      "source": "professional",
      "value": "Got it!"
    },
    "openTextQuestion": {
      "source": "professional",
      "value": "Anything else you'd like to share with us? Please add your thoughts below."
    },
    "openTextRewards": {
      "context": "A message to encourage a user to provide an optional response to a text field in return for reward points.",
      "source": "professional",
      "value": "Earn <%- points %> bonus reward points if you share your thoughts!"
    },
    "playVideo": {
      "source": "professional",
      "value": "Play Video"
    },
    "quiz": {
      "source": "professional",
      "value": "Quiz"
    },
    "reminder": {
      "read": {
        "source": "professional",
        "value": "Thanks for reading!"
      },
      "unread": {
        "source": "professional",
        "value": "Announcement"
      }
    },
    "session": {
      "invalid": {
        "source": "professional",
        "value": "Communication feed is not available at this time. Please try again later."
      }
    },
    "skipStep": {
      "source": "professional",
      "value": "Skip this step"
    },
    "visitLink": {
      "source": "professional",
      "value": "Visit Link"
    }
  },
  "nudgeTasks": {
    "addComment": {
      "source": "professional",
      "value": "Add any additional comments"
    },
    "assignOwner": {
      "button": {
        "assignOwner": {
          "source": "professional",
          "value": "Assign Owner"
        },
        "editOwner": {
          "source": "professional",
          "value": "Edit Owner"
        }
      }
    },
    "assignedToYou": {
      "context": "Labelspecifying a task is assigned to your user",
      "source": "professional",
      "value": "Assigned To You"
    },
    "assignmentText": {
      "assignedToSingleUser": {
        "context": "a task that is assigned to a single user",
        "source": "professional",
        "value": "Assigned to 1 person"
      },
      "assignedToTeam": {
        "context": "a task that is assigned to the team",
        "source": "professional",
        "value": "Assigned to <%= teamName %>"
      },
      "assignedToTeamCompletedBySomeoneElse": {
        "context": "a task that is assigned to the team and completed by another user",
        "source": "professional",
        "value": "Assigned to <%= teamName %>. Completed by <%= name %>."
      },
      "assignedToTeamCompletedByYou": {
        "context": "a task that is assigned to the team and completed by the user",
        "source": "professional",
        "value": "Assigned to <%= teamName %>. Completed by you."
      },
      "assignedToTeamOwnedBySomeoneElse": {
        "context": "a task that is assigned to the team and owned by another user",
        "source": "professional",
        "value": "Assigned to <%= teamName %>. Owned by <%= name %>."
      },
      "assignedToTeamOwnedByYou": {
        "context": "a task that is assigned to the team and owned by the user",
        "source": "professional",
        "value": "Assigned to <%= teamName %>. Owned by you."
      },
      "assignedToUsers": {
        "context": "the number of users that the task is assigned to",
        "source": "professional",
        "value": "Assigned to <%- totalAssignedUsers %> people"
      },
      "assignedToYou": {
        "source": "professional",
        "value": "Assigned to you"
      },
      "completedByYou": {
        "source": "professional",
        "value": "Completed by you"
      }
    },
    "card": {
      "group": {
        "pluralTask": {
          "context": "Number of tasks due in a group plural version, eg. 2 Tasks Due",
          "source": "professional",
          "value": "<%- tasks %> Tasks Due"
        },
        "singleTask": {
          "context": "Number of tasks due in a group singular version, eg. 1 Task Due",
          "source": "professional",
          "value": "<%- tasks %> Task Due"
        }
      },
      "viewMore": {
        "checkLater": {
          "source": "professional",
          "value": "Check back later for updates."
        },
        "fullList": {
          "source": "professional",
          "value": "View All Tasks"
        },
        "loadMore": {
          "source": "professional",
          "value": "Show more tasks"
        },
        "none": {
          "source": "professional",
          "value": "You have no tasks to complete today!"
        },
        "plural": {
          "context": "Number of tasks a user has left that are due, eg. 8 more tasks due today",
          "source": "professional",
          "value": "<%- count %> more tasks due today."
        },
        "single": {
          "source": "professional",
          "value": "1 more task due today."
        }
      }
    },
    "checklists": {
      "checklistCompletion": {
        "context": "Number of tasks completed in a checklist.",
        "source": "professional",
        "value": "<%- taskCount %>/<%- totalTasks %> Tasks Completed"
      },
      "checklistSubtitle": {
        "context": "Checklist subtitle for nudge tasks",
        "source": "professional",
        "value": "These checklists are assigned to you and your team."
      },
      "checklistTitle": {
        "context": "Checklist title for nudge tasks",
        "source": "professional",
        "value": "Checklists"
      },
      "recurring": {
        "context": "Recurring task label",
        "source": "professional",
        "value": "Recurring"
      },
      "recurringChecklist": {
        "context": "Checklist with recurring tasks",
        "source": "professional",
        "value": "Has recurrences"
      },
      "tasksNotAssigned": {
        "plural": {
          "context": "Number of tasks in a checklist that are not assigned to you",
          "source": "professional",
          "value": "+<%- count %> tasks not assigned to you"
        },
        "singular": {
          "source": "professional",
          "value": "+1 task not assigned to you"
        }
      }
    },
    "completedBy": {
      "context": "Task create modal label",
      "source": "professional",
      "value": "Completed by"
    },
    "completedByAll": {
      "context": "Task create modal label",
      "source": "professional",
      "value": "Completion by all assignees"
    },
    "completedByAllSecondary": {
      "context": "Task create modal label",
      "source": "professional",
      "value": "A task that needs to be completed by all assignees."
    },
    "completedByOne": {
      "context": "Task create modal label",
      "source": "professional",
      "value": "Completion by one assignee"
    },
    "completedByOneSecondary": {
      "context": "Task create modal label",
      "source": "professional",
      "value": "A task that only needs to be completed by one assignee."
    },
    "completionList": {
      "completeTab": {
        "source": "professional",
        "value": "Complete"
      },
      "emptyMessage": {
        "source": "professional",
        "value": "Once someone completes the task on your team you will see them appear here."
      },
      "emptyTitle": {
        "source": "professional",
        "value": "No completions yet"
      },
      "incompleteEmptyMessage": {
        "source": "professional",
        "value": "All team members have successfully completed this task."
      },
      "incompleteEmptyTitle": {
        "source": "professional",
        "value": "Everyone has completed the task"
      },
      "incompleteTab": {
        "source": "professional",
        "value": "Incomplete"
      },
      "title": {
        "source": "professional",
        "value": "Completion List"
      }
    },
    "create": {
      "assignTask": {
        "source": "professional",
        "value": "Assign Task"
      },
      "assignToAll": {
        "source": "professional",
        "value": "Assign To All"
      },
      "assignToAria": {
        "context": "A prompt for screenreaders to announce to the user that they can click to modify the user assignment field in the task creation form",
        "source": "professional",
        "value": "Assigned To Field. Click or press enter key to edit"
      },
      "assignUsersConfirm": {
        "context": "Button text for a button to confirm users selected to be assigned to a task",
        "source": "professional",
        "value": "Confirm Selection"
      },
      "assignedTo": {
        "context": "Task creation assigned to field label",
        "source": "professional",
        "value": "Assigned to"
      },
      "button": {
        "source": "professional",
        "value": "Create Task"
      },
      "completedBy": {
        "context": "Create Tasks field name",
        "source": "professional",
        "value": "Completed by"
      },
      "deleteTask": {
        "source": "professional",
        "value": "Delete Task"
      },
      "editTask": {
        "source": "professional",
        "value": "Edit Task"
      },
      "firstOccurrence": {
        "context": "Label describing the first day a task will occur on, eg. First occurrence: Friday December 3, 2023",
        "source": "professional",
        "value": "First occurrence: <%- date %>"
      },
      "newTask": {
        "source": "professional",
        "value": "New Task"
      },
      "requirePhoto": {
        "source": "professional",
        "value": "Require photo for completion"
      },
      "save": {
        "source": "professional",
        "value": "Save Task"
      },
      "selectAssignment": {
        "source": "professional",
        "value": "Select Assignment"
      },
      "selected": {
        "context": "Header for selected users",
        "source": "professional",
        "value": "Selected"
      },
      "selectedUsers": {
        "context": "A count of the users assigned to a task where numUsers is the number of users assigned.",
        "source": "professional",
        "value": "<%- numUsers %> users selected"
      },
      "titleLabel": {
        "source": "professional",
        "value": "Title"
      },
      "users": {
        "source": "professional",
        "value": "Users"
      },
      "verification": {
        "source": "professional",
        "value": "Verification"
      }
    },
    "createErrorMessage": {
      "source": "professional",
      "value": "Failed to create the task."
    },
    "createSuccessMessage": {
      "source": "professional",
      "value": "Task created successfully."
    },
    "daily": {
      "context": "Label describing daily recurrence of a task",
      "source": "professional",
      "value": "Daily"
    },
    "deleteErrorMessage": {
      "context": "Error message displayed when there is a problem deleting a task",
      "source": "professional",
      "value": "Failed to delete the task."
    },
    "deleteSuccessMessage": {
      "context": "Success message displayed when a task is deleted successfully",
      "source": "professional",
      "value": "Task deleted successfully."
    },
    "deleteTask": {
      "source": "professional",
      "value": "This will permanently remove your task and can not be undone."
    },
    "due": {
      "context": "Label describing the due date of a task, eg. Due: Friday December 3, 2023",
      "source": "professional",
      "value": "Due"
    },
    "editErrorMessage": {
      "source": "professional",
      "value": "Failed to edit the task."
    },
    "editSuccessMessage": {
      "source": "professional",
      "value": "Task edited successfully."
    },
    "error": {
      "general": {
        "context": "Message that is displayed when we can't process a user's request.",
        "source": "professional",
        "value": "Oops! We're unable to handle your request right now. Please try again later."
      },
      "locationAccess": {
        "source": "professional",
        "value": "You do not have access to this group"
      },
      "markComplete": {
        "source": "professional",
        "value": "Task has already been marked complete or incomplete."
      },
      "noOlderTasks": {
        "source": "professional",
        "value": "There are no older completed tasks to show"
      }
    },
    "errors": {
      "dateFormat": {
        "context": "Create Tasks field validation",
        "source": "professional",
        "value": "Invalid date format"
      },
      "invalidBeforeTodayDate": {
        "source": "professional",
        "value": "The date cannot be before today's date"
      },
      "maxLengthExceed": {
        "context": "Example: Username cannot exceed 20 characters",
        "source": "professional",
        "value": "<%- label %> cannot exceed <%- maxLength %> characters"
      },
      "required": {
        "context": "Example: Username is a required field",
        "source": "professional",
        "value": "<%- label %> is a required field"
      }
    },
    "filter": {
      "showCompletedTasks": {
        "source": "professional",
        "value": "Show Completed Tasks"
      }
    },
    "hideCompletedTasks": {
      "source": "professional",
      "value": "Hide Completed Tasks"
    },
    "markComplete": {
      "context": "Checkbox label for marking a task as complete",
      "source": "professional",
      "value": "Mark task as complete"
    },
    "markIncomplete": {
      "context": "Checkbox label for marking a task as incomplete",
      "source": "professional",
      "value": "Mark task as incomplete"
    },
    "markedComplete": {
      "button": {
        "source": "professional",
        "value": "Marked as Complete"
      },
      "others": {
        "context": "Label to describe that a user completed a task on behalf of another user eg. Manager A marked as complete for Associate A",
        "source": "professional",
        "value": "<%= submittingUser %> marked as complete for <%= assignedUser %>"
      },
      "self": {
        "context": "Label on the timeline to describe that a user completed a task",
        "source": "professional",
        "value": "<%= submittingUser %> marked as complete"
      }
    },
    "markedIncomplete": {
      "others": {
        "context": "Label to describe that a user marked a task as incomplete on behalf of another user eg. Manager A marked as incomplete for Associate A",
        "source": "professional",
        "value": "<%= submittingUser %> marked as incomplete for <%= assignedUser %>"
      },
      "self": {
        "context": "Label on the timeline to describe that a user marked a task as incomplete, eg. Bob Smith marked as incomplete",
        "source": "professional",
        "value": "<%= submittingUser %> marked as incomplete"
      }
    },
    "maxFileSize": {
      "source": "professional",
      "value": "Max file size: 10MB"
    },
    "navigation": {
      "yourTasks": {
        "source": "professional",
        "value": "My Tasks"
      },
      "yourTeam": {
        "source": "professional",
        "value": "My Team"
      },
      "yourTeams": {
        "source": "professional",
        "value": "My Teams"
      }
    },
    "noTasks": {
      "source": "professional",
      "value": "No tasks yet!"
    },
    "overdue": {
      "source": "professional",
      "value": "Overdue"
    },
    "photoVerification": {
      "attached": {
        "source": "professional",
        "value": "Photo Attached"
      },
      "commentLabel": {
        "source": "professional",
        "value": "Comment"
      },
      "required": {
        "source": "professional",
        "value": "Photo Required"
      }
    },
    "session": {
      "invalid": {
        "source": "professional",
        "value": "Task feed is not available at this time. Please try again later."
      }
    },
    "showCompletedTasks": {
      "context": "Checkbox label for toggling the visibility of completed tasks in a task list",
      "source": "professional",
      "value": "Show Older Completed Tasks"
    },
    "success": {
      "taskAssigned": {
        "source": "professional",
        "value": "Task has been assigned."
      }
    },
    "suggestedTasks": {
      "emptyTitle": {
        "source": "professional",
        "value": "No Suggested Tasks Found"
      },
      "title": {
        "source": "professional",
        "value": "Suggested Tasks"
      }
    },
    "taskAssignment": {
      "completedBy": {
        "context": "A count for the number of users that have completed a task.",
        "source": "professional",
        "value": "Completed by <%- numUsers %>"
      },
      "completedByWithTeamMembers": {
        "context": "A count for the number of users that have completed the task, eg. Completed by 2 team members",
        "source": "professional",
        "value": "Completed by <%- numUsers %> team members"
      },
      "completedByWithTeamMembersSingular": {
        "context": "A count for the number of users that have completed the task, singular version",
        "source": "professional",
        "value": "Completed by 1 team member"
      },
      "completedByWithTotal": {
        "context": "A count for the number of users that have completed a task while also showing the total number of users assigned to this task.",
        "source": "professional",
        "value": "Completed by <%- numUsers %>/<%- totalUsers %>"
      },
      "completedByWithTotalTeamMembers": {
        "context": "A count for the number of users that have completed a task while also showing the total number of users assigned to this task",
        "source": "professional",
        "value": "Completed by <%- numUsers %>/<%- totalUsers %> team members"
      },
      "incomplete": {
        "source": "professional",
        "value": "Incomplete"
      },
      "incompleteByAll": {
        "context": "A note to tell users that a task is incomplete by all assigned users.",
        "source": "professional",
        "value": "Incomplete by all"
      },
      "incompleteByAllTeam": {
        "source": "professional",
        "value": "Incomplete by all team members"
      },
      "noAssignment": {
        "source": "professional",
        "value": "No Assignment"
      }
    },
    "taskCreate": {
      "emptyUserList": {
        "source": "professional",
        "value": "No users found"
      },
      "first30": {
        "source": "professional",
        "value": "First 30 users shown. Search to find others."
      },
      "selectedUsers": {
        "context": "A count of the users assigned to a task where numUsers is the number of users assigned.",
        "source": "professional",
        "value": "<%- numUsers %> users selected"
      },
      "selectedUsersSingular": {
        "context": "Singular version of number of users assigned",
        "source": "professional",
        "value": "1 user selected"
      }
    },
    "timeline": {
      "expandedImageLabel": {
        "context": "Aria label for an image that is showing as expanded. Instructs user on how to close the image.",
        "source": "professional",
        "value": "Timeline image expanded. To close, click away or press the escape key."
      }
    },
    "tomorrow": {
      "source": "professional",
      "value": "Tomorrow"
    },
    "viewPerformanceBanner": {
      "bannerText": {
        "context": "Banner text explanation on where to view task performance and metrics",
        "source": "professional",
        "value": "To monitor task performance and metrics, use LeaderZone."
      },
      "buttonText": {
        "source": "professional",
        "value": "View Performance"
      }
    }
  },
  "observations": {
    "details": {
      "labels": {
        "evaluatorComment": {
          "context": "Comment left by an evaluator",
          "source": "professional",
          "value": "Evaluator Comment"
        }
      }
    }
  },
  "onTheClock": {
    "login": {
      "warning": {
        "message": {
          "source": "professional",
          "value": "Access to training functionality has been restricted, you must be clocked in. Working off the clock is strictly prohibited and a violation of company policy. Employees must be paid for all time worked.<br/><br/>Clock in and please try again.<br/><br/>If you are clocked in and believe you have received this message inerror, please contact your administrator."
        }
      }
    }
  },
  "opsTask": {
    "createTask": {
      "modal": {
        "toggleOneTime": {
          "source": "professional",
          "value": "Change to One-Time"
        },
        "toggleRepeating": {
          "context": "Label for a button that will expand Repeating section",
          "source": "professional",
          "value": "Change to Repeating"
        }
      }
    }
  },
  "opsTasks": {
    "createTask": {
      "modal": {
        "addDescription": {
          "context": "Label for a button that will expand the Description section",
          "source": "professional",
          "value": "Add Description"
        },
        "changeAssignment": {
          "title": {
            "source": "professional",
            "value": "Change Assignment"
          }
        },
        "togglePhotoVerification": {
          "source": "professional",
          "value": "Add Photo Verification"
        },
        "toggleSteps": {
          "context": "Button that adds steps to task",
          "source": "professional",
          "value": "Add Steps"
        }
      }
    }
  },
  "pdf": {
    "complianceMessage": {
      "context": "Text show to a user beside a checkbox that states they have read and understand the presented information",
      "source": "professional",
      "value": "I have completed the training module"
    },
    "title": {
      "source": "professional",
      "value": "PDF Training Module"
    }
  },
  "printCertification": {
    "buttonLabelDesktop": {
      "source": "professional",
      "value": "Print Certificate"
    },
    "buttonLabelMobile": {
      "source": "professional",
      "value": "View Certificate"
    },
    "downloadError": {
      "source": "professional",
      "value": "Certificate could not be generated"
    },
    "languageModal": {
      "details": {
        "source": "professional",
        "value": "Please note that not all information within the certificate may be translated."
      },
      "generateButtonLabel": {
        "source": "professional",
        "value": "Generate Certificate"
      },
      "title": {
        "source": "professional",
        "value": "Select Certificate Language"
      }
    }
  },
  "question": {
    "accessibility": {
      "correctAnswer": {
        "source": "professional",
        "value": "Correct answer."
      },
      "correctlyChecked": {
        "source": "professional",
        "value": "Correctly checked."
      },
      "correctlyUnchecked": {
        "source": "professional",
        "value": "Correctly unchecked."
      },
      "feedback": {
        "context": "aria label for leaving feedback for this question",
        "source": "professional",
        "value": "Leave Feedback"
      },
      "fillInBlankCorrectLabel": {
        "source": "professional",
        "value": "The correct answer was:"
      },
      "fillInBlankInstructions": {
        "source": "professional",
        "value": "Select an answer in every blank listbox in order to submit this question"
      },
      "fillInBlankLabel": {
        "source": "professional",
        "value": "Select an answer"
      },
      "graphic": {
        "source": "professional",
        "value": "Graphic."
      },
      "incorrectAnswer": {
        "source": "professional",
        "value": "Incorrect answer."
      },
      "incorrectlyChecked": {
        "source": "professional",
        "value": "Incorrectly checked."
      },
      "incorrectlyUnchecked": {
        "source": "professional",
        "value": "Incorrectly unchecked."
      },
      "matchingQuestion": {
        "correct": {
          "source": "professional",
          "value": "Correct. "
        },
        "feedbackInstructions": {
          "source": "professional",
          "value": "Please find the feedback to your answers below. You cannot change your answers on this screen."
        },
        "generalInstructions": {
          "source": "professional",
          "value": "Match the corresponding items. Select a button from the first list of unmatched buttons, and then select the correct corresponding button from the second list of unmatched items. Matched items will appear before the unmatched items, once matched. Matched buttons can also be re matched again if you change your mind."
        },
        "hintDefault": {
          "source": "professional",
          "value": "Select an item. "
        },
        "hintSelectFirstItem": {
          "source": "professional",
          "value": "Item selected. Select the correct corresponding item from the first list of unmatched items."
        },
        "hintSelectSecondItem": {
          "source": "professional",
          "value": "Item selected. Select the correct corresponding item from the second list of unmatched items."
        },
        "hintUnselected": {
          "source": "professional",
          "value": "Unselected item. "
        },
        "incorrect": {
          "source": "professional",
          "value": "Incorrect. "
        },
        "matched": {
          "source": "professional",
          "value": "Matched. "
        },
        "matchedItemsFirst": {
          "source": "professional",
          "value": "First list of matched items"
        },
        "matchedItemsSecond": {
          "source": "professional",
          "value": "Second list of matched items"
        },
        "questionInstructions": {
          "source": "professional",
          "value": "<%- paired %> of <%- max %>: Options have been matched."
        },
        "selected": {
          "source": "professional",
          "value": "Selected. "
        },
        "submissionInstructions": {
          "source": "professional",
          "value": "Match the required number of answers in order to submit this question."
        },
        "unmatched": {
          "source": "professional",
          "value": "Unmatched. "
        },
        "unmatchedItemsFirst": {
          "source": "professional",
          "value": "First list of unmatched items"
        },
        "unmatchedItemsSecond": {
          "source": "professional",
          "value": "Second list of unmatched items"
        }
      },
      "multichoiceGroupLabel": {
        "source": "professional",
        "value": "Multiple choice options for: <%- questionText %>."
      },
      "multichoiceInstructions": {
        "source": "professional",
        "value": "Select an answer in order to submit this question."
      },
      "surveyInstructions": {
        "source": "professional",
        "value": "Select an option in order to submit this survey."
      }
    },
    "blank": {
      "source": "professional",
      "value": "blank"
    },
    "confidencehigh": {
      "source": "professional",
      "value": "High"
    },
    "confidencelow": {
      "source": "professional",
      "value": "Low"
    },
    "confidencemedium": {
      "source": "professional",
      "value": "Medium"
    },
    "confidencetitle": {
      "source": "professional",
      "value": "How confident are you?"
    },
    "fillInTheBlank": {
      "source": "professional",
      "value": "Fill in the blank:"
    },
    "fitbinstruction": {
      "source": "professional",
      "value": "Complete the sentence"
    },
    "hotspotinstruction": {
      "source": "professional",
      "value": "Select the correct areas on the image"
    },
    "matchinginstruction": {
      "source": "professional",
      "value": "Match the correct items"
    },
    "multianswerinstruction": {
      "source": "professional",
      "value": "Select all that apply"
    },
    "multichoiceinstruction": {
      "source": "professional",
      "value": "Select the correct answer"
    },
    "numberseperator": {
      "source": "professional",
      "value": "of"
    },
    "preview": {
      "source": "professional",
      "value": "Matches"
    },
    "questionNumberHeader": {
      "context": "This is the heading text indicating which question (by number in the sequence) is being displayed - e.g. 'Question 1'",
      "source": "professional",
      "value": "Question <%- questionOrdinal %>"
    },
    "showcorrectmatches": {
      "source": "professional",
      "value": "Show Correct Matches"
    },
    "submit": {
      "source": "professional",
      "value": "Submit these matches"
    },
    "submitAnswersButton": {
      "source": "professional",
      "value": "Submit Answers"
    },
    "submitanswers": {
      "source": "professional",
      "value": "Submit"
    },
    "survey": {
      "source": "professional",
      "value": "Survey"
    },
    "surveyBanner": {
      "source": "professional",
      "value": "Anonymous Survey"
    },
    "surveyComments": {
      "source": "professional",
      "value": "Comments"
    },
    "surveyInstruction": {
      "source": "professional",
      "value": "Survey questions are recorded anonymously"
    },
    "surveyOptOut": {
      "source": "professional",
      "value": "I prefer not to answer this question"
    }
  },
  "quickLinks": {
    "viewAllAria": {
      "context": "A label for a view all button that informs the user that when the button is clicked, they will view all quick links available",
      "source": "professional",
      "value": "View all quick links"
    }
  },
  "reaction": {
    "reactionType": {
      "ALL": {
        "name": {
          "source": "professional",
          "value": "All"
        },
        "reactionCount": {
          "context": "Reaction count for total number of reactions.",
          "source": "professional",
          "value": "Number of reactions: <%- count %>"
        },
        "showReactions": {
          "context": "Button to show more reactions for an item such as an article.",
          "source": "professional",
          "value": "Show reactions for: <%- title %>"
        }
      },
      "CELEBRATE": {
        "name": {
          "source": "professional",
          "value": "Celebrate"
        },
        "reactionCount": {
          "source": "professional",
          "value": "Number of people who celebrated this: <%- count %>"
        },
        "userReaction": {
          "context": "This is read out by screen readers that mentions what type of reaction a user has made on the article, post, or content.",
          "source": "professional",
          "value": "Celebrated by <%- salutationName %>, <%- locationName %>"
        }
      },
      "LIKE": {
        "name": {
          "source": "professional",
          "value": "Like"
        },
        "reactionCount": {
          "source": "professional",
          "value": "Number of people who liked this: <%- count %>"
        },
        "userReaction": {
          "context": "This is read out by screen readers that mentions what type of reaction a user has made on the article, post, or content.",
          "source": "professional",
          "value": "Liked by <%- salutationName %>, <%- locationName %>"
        }
      },
      "LOVE": {
        "name": {
          "source": "professional",
          "value": "Love"
        },
        "reactionCount": {
          "source": "professional",
          "value": "Number of people who loved this: <%- count %>"
        },
        "userReaction": {
          "context": "This is read out by screen readers that mentions what type of reaction a user has made on the article, post, or content.",
          "source": "professional",
          "value": "Loved by <%- salutationName %>, <%- locationName %>"
        }
      },
      "THINKING": {
        "name": {
          "source": "professional",
          "value": "Thinking"
        },
        "reactionCount": {
          "source": "professional",
          "value": "Number of people who are thinking about this: <%- count %>"
        },
        "userReaction": {
          "context": "This is read out by screen readers that mentions what type of reaction a user has made on the article, post, or content.",
          "source": "professional",
          "value": "Thinking by <%- salutationName %>, <%- locationName %>"
        }
      },
      "UPSET": {
        "name": {
          "source": "professional",
          "value": "Upset"
        },
        "reactionCount": {
          "source": "professional",
          "value": "Number of people who are upset about this: <%- count %>"
        },
        "userReaction": {
          "context": "This is read out by screen readers that mentions what type of reaction a user has made on the article, post, or content.",
          "source": "professional",
          "value": "Upset by <%- salutationName %>, <%- locationName %>"
        }
      }
    }
  },
  "recentChallenges": {
    "against": {
      "source": "professional",
      "value": "against"
    },
    "coldStreak": {
      "source": "professional",
      "value": "You're on a cold streak"
    },
    "emptyList": {
      "source": "professional",
      "value": "You have not participated in any challenges yet."
    },
    "heading": {
      "source": "professional",
      "value": "Recent Challenges"
    },
    "hotStreak": {
      "source": "professional",
      "value": "You're on a hot streak"
    },
    "loss": {
      "source": "professional",
      "value": "Lost"
    },
    "lostWager": {
      "source": "professional",
      "value": "<%- game.name %> - <%- wager %> reward points lost"
    },
    "stats": {
      "challengesWon": {
        "source": "professional",
        "value": "<%- numWins %> of <%- numChallenges %>"
      },
      "totalChallengesWon": {
        "source": "professional",
        "value": "Total Challenges Won"
      }
    },
    "tie": {
      "source": "professional",
      "value": "Tie"
    },
    "win": {
      "source": "professional",
      "value": "Won"
    },
    "winningStreak": {
      "source": "professional",
      "value": "You're on a winning streak"
    },
    "wonWager": {
      "source": "professional",
      "value": "<%- game.name %> - <%- wager %> reward points won"
    }
  },
  "recognitionPins": {
    "hasNotReceivedPins": {
      "source": "professional",
      "value": "You have not received any pins yet"
    },
    "hasNotReceivedPinsSuggestion": {
      "source": "professional",
      "value": "Recognition Pins are a great way to appreciate each other's amazing work!"
    },
    "hasReceivedPinsMultiple": {
      "source": "professional",
      "value": "You have received <%- count %> Recognition Pins in the last 30 days"
    },
    "hasReceivedPinsSingular": {
      "source": "professional",
      "value": "You have received 1 Recognition Pin in the last 30 days"
    },
    "inappropriateContentMsg": {
      "source": "professional",
      "value": "Unable to send due to inappropriate content. Please update and try again."
    },
    "messageInputLabel": {
      "source": "professional",
      "value": "Include a message (Optional)"
    },
    "noPinsAvailable": {
      "source": "professional",
      "value": "No Pins Available"
    },
    "noRecipientsAvailable": {
      "source": "professional",
      "value": "No users found"
    },
    "notifications": {
      "congratsMessageMultiplePins": {
        "context": "you received 2 new pins",
        "source": "professional",
        "value": "You received <%- numOfPins %> new pins."
      },
      "congratsMessageOnePin": {
        "source": "professional",
        "value": "You received a new recognition pin."
      },
      "sentBy": {
        "source": "professional",
        "value": "Sent by:"
      },
      "title": {
        "source": "professional",
        "value": "Recognition"
      }
    },
    "recipientButtonAriaLabel": {
      "source": "professional",
      "value": "Recognition Pin Recipient Field. Click or press enter key to pick user"
    },
    "recipientButtonLabel": {
      "source": "professional",
      "value": "Recipient"
    },
    "recipientButtonPlaceholder": {
      "source": "professional",
      "value": "Select recipient"
    },
    "rewardPoints": {
      "congratsMessagePlural": {
        "context": "100 reward points",
        "source": "professional",
        "value": "<%- points %> reward points"
      },
      "congratsMessageSingular": {
        "source": "professional",
        "value": "1 reward point"
      },
      "sendPinNotificationPlural": {
        "context": "the recipient will be awarded 25 points",
        "source": "professional",
        "value": "The recipient will be awarded <%- points %> reward points."
      },
      "sendPinNotificationSingular": {
        "source": "professional",
        "value": "The recipient will be awarded 1 reward point."
      }
    },
    "searchForMoreResults": {
      "source": "professional",
      "value": "Can't find who you're looking for? Try searching for them."
    },
    "selectPinExplanation": {
      "source": "professional",
      "value": "Recognition Pins can be given to someone when they have done something great. <br/><br/>When you want to give someone a Recognition Pin, first choose a pin from the list, then choose someone to send it to. The pin will be sent directly to the chosen person."
    },
    "selectPinListLabel": {
      "source": "professional",
      "value": "Select Recognition Pin"
    },
    "sendAPinButton": {
      "source": "professional",
      "value": "Send a Pin"
    },
    "sendAPinDialogTitle": {
      "source": "professional",
      "value": "Send a Pin"
    },
    "sendError": {
      "invalidPinAccess": {
        "source": "professional",
        "value": "You don't have permission to send this pin!"
      },
      "invalidRecipientAccess": {
        "source": "professional",
        "value": "The recipient is not valid. Please try again."
      },
      "messageNotAllowed": {
        "source": "professional",
        "value": "A message cannot be sent with this pin. Please try again."
      }
    },
    "sendPin": {
      "success": {
        "source": "professional",
        "value": "Pin sent successfully!"
      }
    },
    "viewButton": {
      "source": "professional",
      "value": "View Pins"
    }
  },
  "referral": {
    "accept": {
      "source": "professional",
      "value": "Accept"
    },
    "additionalReward": {
      "source": "professional",
      "value": "<%- points %> reward points for each additional login over the next <%- days %> days"
    },
    "availableInvites": {
      "source": "professional",
      "value": "Available invites"
    },
    "bountyLoggedIn": {
      "source": "professional",
      "value": "<%- name %> logged in"
    },
    "confirmationCallToAction": {
      "source": "professional",
      "value": "Now go convince your colleague to login!"
    },
    "confirmationText": {
      "source": "professional",
      "value": "You have <%- days %> day to get <%- name %> logged into the system"
    },
    "confirmationTextPlurial": {
      "source": "professional",
      "value": "You will have <%- days %> days to get <%- name %> logged into the system"
    },
    "countLabel": {
      "source": "professional",
      "value": "Friend <%- currentNum %> of <%- totalNum %>"
    },
    "dayLeft": {
      "source": "professional",
      "value": "1 day left"
    },
    "daysLeft": {
      "source": "professional",
      "value": "<%- days %> days left"
    },
    "decline": {
      "source": "professional",
      "value": "Decline"
    },
    "errors": {
      "3001": {
        "source": "professional",
        "value": "The selected friend is no longer available."
      },
      "3091": {
        "source": "professional",
        "value": "Someone has already claimed this friend, please select another."
      },
      "3092": {
        "source": "professional",
        "value": "Failed to select friend. Try a different one."
      },
      "3093": {
        "source": "professional",
        "value": "You have a friend who has not logged in yet. Have them login before selecting another."
      },
      "3094": {
        "source": "professional",
        "value": "The selected friend is no longer available."
      },
      "failedToRemoveBounty": {
        "source": "professional",
        "value": "There was a problem removing the bounty. Please try again later"
      },
      "failedToSelectBounty": {
        "source": "professional",
        "value": "Selecting friend failed.  Please try again later."
      }
    },
    "expiredMessage": {
      "firstLoginPoints": {
        "SubText": {
          "source": "professional",
          "value": "For the login of <%- name %>"
        },
        "Title": {
          "source": "professional",
          "value": "<%- points %> reward points earned for first login"
        }
      },
      "subsequentPoints": {
        "SubText": {
          "source": "professional",
          "value": "<%- points %> total reward points earned for <%- loginCount %> additional login(s) over <%- days %> days"
        },
        "Title": {
          "source": "professional",
          "value": "<%- points %> Reward Points Earned"
        }
      }
    },
    "firstLoginMessage": {
      "points": {
        "lowerText": {
          "source": "professional",
          "value": "Reward Points"
        },
        "upperText": {
          "source": "professional",
          "value": "You Earned"
        }
      },
      "text": {
        "referralAccepted": {
          "source": "professional",
          "value": "Congratulations, <%- name %> has accepted your invite and has logged in for the first time."
        },
        "subsequentLoginInfo": {
          "source": "professional",
          "value": "Remember, you will also earn <%- points %> reward points each additional time <%- name %> logs in over the next <%- days %> days."
        }
      }
    },
    "howTo": {
      "accept": {
        "source": "professional",
        "value": "Got It"
      },
      "description": {
        "source": "professional",
        "value": "Select a friend, and then start encouraging them to log in."
      },
      "disclaimer": {
        "source": "professional",
        "value": "You can only select one friend at a time"
      },
      "forMe": {
        "description": {
          "source": "professional",
          "value": "Earn reward points for your friends first login and every follow-up login over the next <%- days %> days."
        },
        "title": {
          "source": "professional",
          "value": "What's in it for me?"
        }
      },
      "helptitle": {
        "source": "professional",
        "value": "Tell-A-Friend"
      },
      "link": {
        "source": "professional",
        "value": "How it Works"
      },
      "title": {
        "source": "professional",
        "value": "How does it work?"
      },
      "welcome": {
        "source": "professional",
        "value": "Tell-A-Friend rewards you for convincing your friends to log in and use the system."
      }
    },
    "loginReward": {
      "source": "professional",
      "value": "<%- points %> reward points for first login"
    },
    "noinvites": {
      "source": "professional",
      "value": "There are no invites at this time<br />Check back soon"
    },
    "openInvites": {
      "source": "professional",
      "value": "Open Invites"
    },
    "pendingCalltoAction": {
      "source": "professional",
      "value": "<%- days %> days left to complete this invite"
    },
    "pointsAvailable": {
      "source": "professional",
      "value": "<%- points %>+ Reward Points"
    },
    "recentActivityEmpty": {
      "source": "professional",
      "value": "There has been no recent activity."
    },
    "recentInvites": {
      "source": "professional",
      "value": "Recent Invites"
    },
    "referredBy": {
      "source": "professional",
      "value": "Invited by <%- name %>"
    },
    "select": {
      "source": "professional",
      "value": "Select"
    },
    "selectWho": {
      "source": "professional",
      "value": "Select friend <%- name %>"
    },
    "title": {
      "source": "professional",
      "value": "Tell-A-Friend"
    },
    "uninvite": {
      "source": "professional",
      "value": "Cancel Invite"
    },
    "uninviteWho": {
      "source": "professional",
      "value": "Uninvite friend <%- name %>"
    },
    "youinvited": {
      "source": "professional",
      "value": "You Invited"
    }
  },
  "resetpassword": {
    "confirmpassword": {
      "source": "professional",
      "value": "Confirm your new password:"
    },
    "invalidcomplexpasswordgroups": {
      "source": "professional",
      "value": "Weak Password, must include characters from <%- minNumberOfCharacterGroups %> of the following groups: uppercase letter, lowercase letter, number or symbol."
    },
    "invalidcomplexpasswordshort": {
      "source": "professional",
      "value": "Short Password, must be at least <%- minPasswordLength %> characters."
    },
    "invalidcomplexpasswordshortsingle": {
      "source": "professional",
      "value": "Short Password, must be at least 1 character."
    },
    "invalidpassword": {
      "source": "professional",
      "value": "Password should be at least <%- minPasswordLength %> characters."
    },
    "newpassword": {
      "source": "professional",
      "value": "Create a new password:"
    },
    "no_recovery_question": {
      "source": "professional",
      "value": "The user has not set up a recovery question. Please contact your adminstrator for assistance."
    },
    "passwordmismatch": {
      "source": "professional",
      "value": "Passwords don't match."
    },
    "sameasoldpassword": {
      "source": "professional",
      "value": "Old password and new password can't be the same."
    },
    "username": {
      "source": "professional",
      "value": "Enter your username:"
    }
  },
  "scorm": {
    "desktopRequired": {
      "context": "let the user know that they should sign on a desktop browser to complete this scorm.",
      "source": "professional",
      "value": "This training module requires a desktop browser. Please sign in on a desktop browser."
    },
    "moduleIsCompleteMessage": {
      "source": "professional",
      "value": "The module is complete."
    },
    "title": {
      "source": "professional",
      "value": "SCORM"
    }
  },
  "search": {
    "numberOfResultsFor": {
      "source": "professional",
      "value": "Found <%- number %> results for \"<%- query %>\""
    }
  },
  "secondTimer": {
    "timeFormatShortform": {
      "day": {
        "source": "professional",
        "value": "d"
      },
      "hour": {
        "source": "professional",
        "value": "h"
      },
      "minute": {
        "source": "professional",
        "value": "m"
      },
      "second": {
        "source": "professional",
        "value": "s"
      },
      "week": {
        "source": "professional",
        "value": "w"
      },
      "year": {
        "source": "professional",
        "value": "y"
      }
    }
  },
  "securityQuestions": {
    "q1": {
      "source": "professional",
      "value": "What was your childhood nickname?"
    },
    "q10": {
      "source": "professional",
      "value": "What is the country of your ultimate dream vacation?"
    },
    "q11": {
      "source": "professional",
      "value": "What was your dream job as a child?"
    },
    "q12": {
      "source": "professional",
      "value": "What is the street number of the house you grew up in?"
    },
    "q13": {
      "source": "professional",
      "value": "Who was your childhood hero?"
    },
    "q14": {
      "source": "professional",
      "value": "What was the first concert you attended?"
    },
    "q15": {
      "source": "professional",
      "value": "What was the make and model of your first car?"
    },
    "q2": {
      "source": "professional",
      "value": "What is the name of your favorite childhood friend?"
    },
    "q3": {
      "source": "professional",
      "value": "In what city did you meet your spouse/significant other?"
    },
    "q4": {
      "source": "professional",
      "value": "In what city or town did your mother and father meet?"
    },
    "q5": {
      "source": "professional",
      "value": "Who do you admire the most?"
    },
    "q6": {
      "source": "professional",
      "value": "What was the name of your first stuffed animal?"
    },
    "q7": {
      "source": "professional",
      "value": "What was the last name of your third grade teacher?"
    },
    "q8": {
      "source": "professional",
      "value": "In what city or town was your first job?"
    },
    "q9": {
      "source": "professional",
      "value": "What was your favorite place to visit as a child?"
    },
    "select": {
      "source": "professional",
      "value": "Select a security question"
    }
  },
  "selectGame": {
    "HowToPlay": {
      "source": "professional",
      "value": "How to Play"
    },
    "accessibilityNote": {
      "source": "professional",
      "value": "Please note that games require the use of a mouse and screen, or touchscreen."
    },
    "challengeInfo": {
      "challenger": {
        "source": "professional",
        "value": "<%- displayName %> challenged you!"
      },
      "wager": {
        "source": "professional",
        "value": "<%- wager %> reward points"
      }
    },
    "dismissChallenge": {
      "source": "professional",
      "value": "Dismiss challenge for <%- gameName %>"
    },
    "gameChallenge": {
      "challengeHeading": {
        "source": "professional",
        "value": "Highest Score Wins"
      },
      "emptyList": {
        "source": "professional",
        "value": "No users found"
      },
      "howTo": {
        "accept": {
          "source": "professional",
          "value": "Got It"
        },
        "description": {
          "source": "professional",
          "value": "Challenge a friend for bragging rights.  Winner take all."
        },
        "howTitle": {
          "source": "professional",
          "value": "How it Works"
        },
        "title": {
          "source": "professional",
          "value": "Challenging a Friend"
        },
        "wagerTitle": {
          "source": "professional",
          "value": "Make it interesting..."
        },
        "wagering": {
          "source": "professional",
          "value": "Feeling confident? Win or lose reward points based on the outcome of the challenge."
        }
      },
      "searchLabel": {
        "source": "professional",
        "value": "Search for a friend"
      },
      "user": {
        "buttonText": {
          "context": "play a game",
          "source": "professional",
          "value": "Play"
        },
        "meta": {
          "coldStreak": {
            "source": "professional",
            "value": "Cold Streak"
          },
          "hotStreak": {
            "source": "professional",
            "value": "Hot Streak"
          },
          "losingStreak": {
            "source": "professional",
            "value": "<%- value %> losses"
          },
          "winningStreak": {
            "source": "professional",
            "value": "<%- value %> wins"
          }
        }
      },
      "wagerInfo": {
        "source": "professional",
        "value": "Win or lose <%- wager %> reward points on this challenge"
      },
      "wagerLabel": {
        "source": "professional",
        "value": "Make it interesting..."
      }
    },
    "gameInfo": {
      "acceptChallenge": {
        "source": "professional",
        "value": "Accept Challenge"
      },
      "challengeFriend": {
        "source": "professional",
        "value": "Challenge Friend"
      },
      "challengeInfoWithWager": {
        "source": "professional",
        "value": "<%- wager %> reward points"
      },
      "challengeInfoWithoutWager": {
        "source": "professional",
        "value": "Highest score wins"
      },
      "declineChallenge": {
        "source": "professional",
        "value": "Decline Challenge"
      },
      "instructions": {
        "source": "professional",
        "value": "How to Play:"
      },
      "playNow": {
        "source": "professional",
        "value": "Play Now"
      },
      "topScores": {
        "source": "professional",
        "value": "Top Scores"
      }
    },
    "howToModal": {
      "chooseGame": {
        "instruction": {
          "source": "professional",
          "value": "Choose whichever game you're interested in and use the instructions to learn how to play it. Training questions will appear throughout the game."
        }
      },
      "didYouKnow": {
        "instruction": {
          "source": "professional",
          "value": "Playing a game while training helps you learn better!"
        }
      },
      "skipGame": {
        "instruction": {
          "source": "professional",
          "value": "Skip the game and get right to your training questions."
        },
        "title": {
          "source": "professional",
          "value": "In a hurry?"
        }
      }
    },
    "noGame": {
      "accessibile": {
        "context": "Accessible name including visible label for answering questions without playing a game",
        "source": "professional",
        "value": "Short on time? Just answer questions with no game"
      },
      "meta": {
        "source": "professional",
        "value": "Just answer questions"
      },
      "quiz": {
        "source": "professional",
        "value": "Just Questions"
      },
      "title": {
        "source": "professional",
        "value": "Short on time?"
      }
    },
    "pageTitle": {
      "source": "professional",
      "value": "Select Game"
    },
    "section": {
      "allGames": {
        "source": "professional",
        "value": "All Games"
      },
      "challenges": {
        "source": "professional",
        "value": "Challenges"
      },
      "featured": {
        "source": "professional",
        "value": "Featured Game"
      },
      "popularGames": {
        "source": "professional",
        "value": "Popular Games - Your Team"
      },
      "recentlyPlayed": {
        "source": "professional",
        "value": "Your Recent Games"
      }
    },
    "whichGameToPlay": {
      "source": "professional",
      "value": "Select a Game to Play"
    }
  },
  "selfDirected": {
    "articleDetails": {
      "errors": {
        "noId": {
          "context": "This error will be displayed if the user tries to view an article page without providing an ID",
          "source": "professional",
          "value": "No Article ID value provided"
        },
        "notAvailable": {
          "context": "This error will be displayed if the user tries to view an article page and gets an error",
          "source": "professional",
          "value": "Article ID <%- articleId %> is not available."
        }
      },
      "windowTitle": {
        "context": "This will be the title displayed on the browser window tab for the Article Details page",
        "source": "professional",
        "value": "Article Details"
      }
    },
    "browse": {
      "article": {
        "context": "This is the title for the article tab page.",
        "source": "professional",
        "value": "Browse Communities"
      },
      "articleListHeader": {
        "context": "This is the title for the article tab page",
        "source": "professional",
        "value": "Browse Folders"
      },
      "favoriteChecked": {
        "context": "Button to unfavorite an item such as an article",
        "source": "professional",
        "value": "Favorites Button. Click to unfavorite: <%- title %>"
      },
      "favoriteUnchecked": {
        "context": "Button to favorite an item such as an article",
        "source": "professional",
        "value": "Favorites Button. Click to favorite: <%- title %>"
      },
      "pathTopicsAvailabilityMessage": {
        "source": "professional",
        "value": "Topics and Paths will be available after your daily training is complete."
      },
      "recentlyUpdatedCommunities": {
        "context": "This is a title above a list showing the most recently update communities",
        "source": "professional",
        "value": "Recently Updated Communities"
      },
      "viewAllCommunities": {
        "context": "This is a button that will take the user to a page showing all their communities, not just the most recent communities list (which is a subset)",
        "source": "professional",
        "value": "View All Folders"
      }
    },
    "search": {
      "assigned": {
        "source": "professional",
        "value": "Assigned"
      },
      "categoryNames": {
        "all": {
          "context": "This is the title for the 'All' tab in the reactions modal.",
          "source": "professional",
          "value": "All"
        },
        "articles": {
          "context": "This is the title for the articles category/tab on the search page.",
          "source": "professional",
          "value": "Articles"
        },
        "events": {
          "context": "This is the title for the events category/tab on the search page.",
          "source": "professional",
          "value": "Events"
        },
        "paths": {
          "context": "This is the title for the paths category/tab on the search page.",
          "source": "professional",
          "value": "Paths"
        },
        "posts": {
          "context": "This is the title for the posts category/tab on the search page, while browsing in a community.",
          "source": "professional",
          "value": "Posts"
        },
        "topics": {
          "context": "This is the title for the topics category/tab on the search page.",
          "source": "professional",
          "value": "Topics"
        }
      },
      "communities": {
        "context": "This will be used as the link text to the list of all communities",
        "source": "professional",
        "value": "Folders"
      },
      "errors": {
        "articleSearchNotAvailable": {
          "context": "The error message shown when no DZ communities are available for the user to search within",
          "source": "professional",
          "value": "Article search is not available."
        },
        "communityNotFound": {
          "source": "professional",
          "value": "The requested folder is not yet ready or available. Please search for your folder or try again soon."
        },
        "noSearchData": {
          "context": "The error message shown when the search does not return a results object. This is not the same as a result returning no matches, but when the underlying code fails to return anything at all",
          "source": "professional",
          "value": "No data returned by the search"
        },
        "noTopicLevels": {
          "context": "The error message shown when the topic search results return a topic with no topic levels",
          "source": "professional",
          "value": "No topic levels associated with the returned topic"
        },
        "tabNotAvailable": {
          "source": "professional",
          "value": "The folder settings have recently changed, and some features are not available at this time"
        },
        "topicSearchNotAvailable": {
          "context": "The error message shown when Extra Training is not enabled, so no topics are available to search",
          "source": "professional",
          "value": "Topic search is not available."
        }
      },
      "inputPlaceholderWithCommunity": {
        "context": "This is the placeholder value for the input field where the user enters their search query when looking for articles with a community selected",
        "source": "professional",
        "value": "Search in <%= communityName %>"
      },
      "itemsFound": {
        "context": "This is a piece of a larger string construction. Full string will look like: [ CategoryName ] - [ Some Number ] found, letting the user know how many items were found from a search",
        "source": "professional",
        "value": "found"
      },
      "levels": {
        "plural": {
          "context": "The number of levels available in a specific topic",
          "source": "professional",
          "value": "<%- levelNum %> levels"
        },
        "singular": {
          "source": "professional",
          "value": "1 level"
        }
      },
      "moreResults": {
        "context": "this text on a button that shows up when more than 3 results are returned in a search, telling the user they can click the button to see more results in another tab.",
        "source": "professional",
        "value": "More Results"
      },
      "noArticlesAvailable": {
        "context": "The provided browse folder string in which no articles were found for specific folder",
        "source": "professional",
        "value": "No articles available for this folder"
      },
      "noContentAvailable": {
        "context": "The provided browse folder string in which no content was found for specific folder",
        "source": "professional",
        "value": "No content available for this folder"
      },
      "noItemsAvailableGeneric": {
        "source": "professional",
        "value": "No items to show"
      },
      "noPostsAvailable": {
        "context": "The provided browse folder string in which no posts were found for specific folder",
        "source": "professional",
        "value": "No posts avilable for this folder"
      },
      "noResults": {
        "context": "The provided search string in which no results were found. i.e. 'No results found for 'puppies''",
        "source": "professional",
        "value": "No results found for \"<%- searchString %>\""
      },
      "notAvailable": {
        "primaryMessage": {
          "context": "A message letting users know the requested page is not available",
          "source": "professional",
          "value": "Sorry, that page is not available"
        },
        "secondaryMessage": {
          "context": "A message giving suggestions to users whose requested page is not available",
          "source": "professional",
          "value": "Check again later or try a new search"
        }
      },
      "promptType": {
        "context": "This is the prompt for users to search for results of a particular type. Provided 'type' values would be things like 'topics' or 'articles' - or there could be no 'type' value provided if they are interested in all types.",
        "source": "professional",
        "value": "What <%- type %> are you interested in today?"
      },
      "searchingMessage": {
        "context": "This is the message to display while a search is in progress (before results have come back from the server)",
        "source": "professional",
        "value": "Searching..."
      },
      "view": {
        "source": "professional",
        "value": "View"
      },
      "viewDetails": {
        "source": "professional",
        "value": "View details"
      },
      "viewDetailsAria": {
        "context": "Screen reader label for more details when searching in discover zone.",
        "source": "professional",
        "value": "Select to view details."
      },
      "viewPathDetailsAria": {
        "source": "professional",
        "value": "View details for path: <%- title %>."
      },
      "viewTopicDetailsAria": {
        "source": "professional",
        "value": "View details for topic: <%- title %>."
      },
      "viewTypes": {
        "gridView": {
          "context": "This is the label for the button to select the type of view being shown (e.g. Grid View or List View)",
          "source": "professional",
          "value": "Grid View"
        },
        "listView": {
          "context": "This is the label for the button to select the type of view being shown (e.g. Grid View or List View)",
          "source": "professional",
          "value": "List View"
        }
      }
    },
    "topicDetails": {
      "errors": {
        "notAvailable": {
          "source": "professional",
          "value": "Topic ID <%- topicId %> is not available."
        }
      },
      "knowledgeScore": {
        "source": "professional",
        "value": "Your Knowledge Score"
      },
      "noAction": {
        "source": "professional",
        "value": "Come back tomorrow to do more training"
      },
      "noSubLevelsMessage": {
        "source": "professional",
        "value": "No sub-topics found"
      },
      "nonGuidedLearningPath": {
        "context": "pathName here is a link",
        "source": "professional",
        "value": "This topic is part of your training path <%= pathName %>"
      },
      "subLevelsTitle": {
        "source": "professional",
        "value": "What's included"
      },
      "takeTopic": {
        "source": "professional",
        "value": "Take this Topic to get a score"
      },
      "topicLevels": {
        "passInfo": {
          "context": "The level number of a particular topic level i.e. ('Level 2')",
          "source": "professional",
          "value": "- Pass Level <%- levelNum %> to unlock"
        },
        "title": {
          "source": "professional",
          "value": "What's Included"
        }
      },
      "topicPath": {
        "context": "pathName is the name of a path, which ultimately becomes a link",
        "source": "professional",
        "value": "This topic is part of the Path <%= pathName %>"
      },
      "windowTitle": {
        "context": "This will be the title displayed on the browser window tab for the Topic Details page",
        "source": "professional",
        "value": "Topic Details"
      }
    }
  },
  "selfRegistration": {
    "alreadyPrompt": {
      "source": "professional",
      "value": "Already registered? Log-in Here"
    },
    "buttonTitle": {
      "source": "professional",
      "value": "Register"
    },
    "complete": {
      "gotoLoginBtn": {
        "source": "professional",
        "value": "Login"
      },
      "prompt": {
        "source": "professional",
        "value": "A confirmation email has been sent. Login to begin your training."
      },
      "title": {
        "source": "professional",
        "value": "Registration Complete"
      }
    },
    "confirmPassword": {
      "source": "professional",
      "value": "Confirm password"
    },
    "department": {
      "source": "professional",
      "value": "Department: "
    },
    "email": {
      "source": "professional",
      "value": "Email:"
    },
    "employeeId": {
      "source": "professional",
      "value": "Username:"
    },
    "enterPassword": {
      "source": "professional",
      "value": "Enter password"
    },
    "enterSecurityAnswer": {
      "source": "professional",
      "value": "Enter security answer"
    },
    "errors": {
      "captchaInvalid": {
        "source": "professional",
        "value": "The captcha entered is not valid. Make sure the reCAPTCHA below shows completed."
      },
      "emptySecurity": {
        "source": "professional",
        "value": "You must select a security question and answer for each field presented."
      },
      "failedToRegister": {
        "source": "professional",
        "value": "Failed to register your account. Please try again later."
      },
      "invalidCode": {
        "source": "professional",
        "value": "This registration link is invalid. Please contact your administrator to get a valid registration link."
      },
      "invalidEmail": {
        "source": "professional",
        "value": "Please enter a valid email address."
      },
      "noFullName": {
        "source": "professional",
        "value": "Please enter your full name."
      },
      "publicKey": {
        "source": "professional",
        "value": "Failed to fetch public key. Please contact your administrator."
      },
      "usernameTaken": {
        "source": "professional",
        "value": "This username is taken. Please choose another."
      }
    },
    "explainUsername": {
      "source": "professional",
      "value": "Be sure to pick something memorable as you will use this to login."
    },
    "fullName": {
      "source": "professional",
      "value": "Full Name:"
    },
    "fullNamePlaceHolder": {
      "source": "professional",
      "value": "First and Last Name"
    },
    "jobTitle": {
      "source": "professional",
      "value": "Job Title: "
    },
    "language": {
      "source": "professional",
      "value": "Language:"
    },
    "lineOfBusiness": {
      "source": "professional",
      "value": "Line of Business (LOB): "
    },
    "location": {
      "source": "professional",
      "value": "Team: "
    },
    "locationModalTitle": {
      "source": "professional",
      "value": "Choose a team"
    },
    "newPassword": {
      "source": "professional",
      "value": "New Password:"
    },
    "passwordAgain": {
      "source": "professional",
      "value": "Enter Password Again:"
    },
    "securityAnswer": {
      "source": "professional",
      "value": "Answer <%- i %>:"
    },
    "securityQuestion": {
      "source": "professional",
      "value": "Security Question <%- i %>:"
    },
    "title": {
      "source": "professional",
      "value": "Register Your Account"
    },
    "updateSecurityQuestion": {
      "source": "professional",
      "value": "Enter Security Questions"
    }
  },
  "settings": {
    "accounttitle": {
      "source": "professional",
      "value": "Account"
    },
    "coachsaved": {
      "source": "professional",
      "value": "Coach selection has been saved"
    },
    "coachtitle": {
      "source": "professional",
      "value": "Coach"
    },
    "emailNotificationsText": {
      "source": "professional",
      "value": "Send notification when employees I manage enroll for events"
    },
    "emailNotificationsTitle": {
      "source": "professional",
      "value": "Email Notifications"
    },
    "languagesaved": {
      "source": "professional",
      "value": "Language preferences have been saved."
    },
    "languagetitle": {
      "source": "professional",
      "value": "Language"
    },
    "mfatitle": {
      "source": "professional",
      "value": "2-Step Verification"
    },
    "navigation": {
      "source": "professional",
      "value": "Profile navigation"
    },
    "notificationsTitle": {
      "source": "professional",
      "value": "Notifications"
    },
    "notificationssaved": {
      "source": "professional",
      "value": "Notification preferences have been saved."
    },
    "preferredLanguageHelp": {
      "source": "professional",
      "value": "Used for training and user interface"
    },
    "preferredLanguageTitle": {
      "source": "professional",
      "value": "Primary Language"
    },
    "secondaryLanguageHelp": {
      "source": "professional",
      "value": "Used for searching and viewing content from Communities"
    },
    "secondaryLanguageTitle": {
      "source": "professional",
      "value": "Secondary Languages"
    },
    "tenantProperties": {
      "newValue": {
        "source": "professional",
        "value": "Updated Value"
      },
      "oldValue": {
        "source": "professional",
        "value": "Current Value"
      }
    },
    "title": {
      "source": "professional",
      "value": "Profile"
    },
    "userSaveError": {
      "source": "professional",
      "value": "There was a problem saving your profile"
    },
    "userSaveSuccess": {
      "source": "professional",
      "value": "Profile has been saved"
    },
    "userTitle": {
      "source": "professional",
      "value": "User"
    }
  },
  "start": {
    "greeting": {
      "source": "professional",
      "value": "<%- fullname %>"
    },
    "homepage": {
      "continueTrainingAccessibilityLabel": {
        "source": "professional",
        "value": "<%- title %>.  <%- progress %>."
      },
      "continueTrainingAccessibilityLabelWithSubtitle": {
        "source": "professional",
        "value": "<%- title %>, <%- subTitle %>.  <%- progress %>."
      },
      "continueTrainingEmpty": {
        "source": "professional",
        "value": "There is no training to display."
      },
      "continueTrainingHeader": {
        "source": "professional",
        "value": "More Training"
      },
      "continueTrainingProgress": {
        "source": "professional",
        "value": "<%- part %> of <%- total %> completed"
      },
      "hubPreviewsHeader": {
        "source": "professional",
        "value": "Updates"
      },
      "messagesHeader": {
        "source": "professional",
        "value": "Messages"
      },
      "performanceHeader": {
        "source": "professional",
        "value": "Your Performance"
      },
      "recommendedArticlesHeader": {
        "source": "professional",
        "value": "Recommended Articles"
      }
    },
    "message": {
      "integration": {
        "context": "A phrase to inform the user that they may have tasks or nudges to view/complete after they finish their daily training.",
        "source": "professional",
        "value": "Explore today's activities below."
      },
      "noPoints": {
        "CertificationTraining": {
          "source": "professional",
          "value": "You have some certifications to do today."
        },
        "DailyTraining": {
          "source": "professional",
          "value": "You have daily training today."
        },
        "FormalExamTraining": {
          "source": "professional",
          "value": "You have some exams today."
        },
        "GuidedLearningTraining": {
          "context": "Path = Learning Path",
          "source": "professional",
          "value": "Let's continue on your Path today."
        },
        "IntroductoryTraining": {
          "source": "professional",
          "value": "You have some new topics to learn today."
        },
        "RefresherTraining": {
          "source": "professional",
          "value": "You have some topics below <%- percent %>%. Time for a refresher."
        }
      },
      "withPoints": {
        "CertificationTraining": {
          "source": "professional",
          "value": "You have some certifications to do today."
        },
        "DailyTraining": {
          "source": "professional",
          "value": "You can earn up to <%- points %> reward points today."
        },
        "FormalExamTraining": {
          "source": "professional",
          "value": "You can earn up to <%- points %> reward points taking some exams."
        },
        "GuidedLearningTraining": {
          "context": "Path = Learning Path",
          "source": "professional",
          "value": "Let's continue on your Path today."
        },
        "IntroductoryTraining": {
          "source": "professional",
          "value": "You have some new topics to learn today."
        },
        "RefresherTraining": {
          "source": "professional",
          "value": "You have some topics below <%- percent %>%. Time for a refresher."
        }
      }
    },
    "startTraining": {
      "source": "professional",
      "value": "Start training"
    }
  },
  "stats": {
    "avatar": {
      "source": "professional",
      "value": "User Avatar"
    },
    "down": {
      "source": "professional",
      "value": "Down <%- num %> spots"
    },
    "downmessage": {
      "source": "professional",
      "value": "Let's get back on track next time."
    },
    "downmessageheader": {
      "source": "professional",
      "value": "Keep Working Hard!"
    },
    "finish": {
      "source": "professional",
      "value": "Finish"
    },
    "name": {
      "source": "professional",
      "value": "Name"
    },
    "noData": {
      "source": "professional",
      "value": "--"
    },
    "noTeams": {
      "source": "professional",
      "value": "No teams"
    },
    "noUsers": {
      "source": "professional",
      "value": "No users"
    },
    "none": {
      "source": "professional",
      "value": "None"
    },
    "people": {
      "source": "professional",
      "value": "Top People in <%= teamName %> - <%= timeFrame %>"
    },
    "points": {
      "source": "professional",
      "value": "Reward Points"
    },
    "pointsAllTime": {
      "source": "professional",
      "value": "Reward Points Earned All Time"
    },
    "pointsLast30Days": {
      "source": "professional",
      "value": "Reward Points Earned Last 30 Days"
    },
    "rank": {
      "source": "professional",
      "value": "Rank"
    },
    "rank1trophy": {
      "source": "professional",
      "value": "First Place, Gold Trophy"
    },
    "rank2trophy": {
      "source": "professional",
      "value": "Second Place, Silver Trophy"
    },
    "rank3trophy": {
      "source": "professional",
      "value": "Third Place, Bronze Trophy"
    },
    "separatorRow": {
      "source": "professional",
      "value": "Separator row"
    },
    "team": {
      "source": "professional",
      "value": "Name"
    },
    "teampoints": {
      "source": "professional",
      "value": "Team Average"
    },
    "teams": {
      "source": "professional",
      "value": "Top Teams - <%= timeFrame %>"
    },
    "timeframe": {
      "allTime": {
        "source": "professional",
        "value": "All Time"
      },
      "last30Days": {
        "source": "professional",
        "value": "Last 30 Days"
      },
      "last7Days": {
        "source": "professional",
        "value": "Last 7 Days"
      },
      "month": {
        "source": "professional",
        "value": "Monthly"
      }
    },
    "title": {
      "source": "professional",
      "value": "Top Performers"
    },
    "up": {
      "source": "professional",
      "value": "Up <%- num %> spots"
    },
    "upmessage": {
      "source": "professional",
      "value": "You're doing really well, keep it up."
    },
    "upmessageheader": {
      "source": "professional",
      "value": "Great Work!"
    },
    "users": {
      "source": "professional",
      "value": "Name"
    },
    "you": {
      "source": "professional",
      "value": "You"
    },
    "yourranklisted": {
      "source": "professional",
      "value": "Your rank is <%- rank %>."
    },
    "yourrankunlisted": {
      "source": "professional",
      "value": "Your rank is not shown."
    },
    "yourteam": {
      "source": "professional",
      "value": "Your Team"
    },
    "yourteamranklisted": {
      "source": "professional",
      "value": "Your team's rank is <%- rank %>."
    },
    "yourteamrankunlisted": {
      "source": "professional",
      "value": "Your team's rank is not shown."
    }
  },
  "submission": {
    "header": {
      "source": "professional",
      "value": "Your Submission"
    }
  },
  "tasks": {
    "assignment": {
      "team": {
        "source": "professional",
        "value": "Team Task: Completed once for each team"
      },
      "user": {
        "source": "professional",
        "value": "User Task: Completed once for each user"
      }
    },
    "card": {
      "view": {
        "available": {
          "chip": {
            "context": "Display for the taskChip",
            "source": "professional",
            "value": "<%- dueAfterTodayTasks %> Due Soon"
          },
          "plural": {
            "context": "When there are tasks that can be completed",
            "source": "professional",
            "value": "<%- count %> Tasks Available for Completion"
          },
          "singular": {
            "context": "When there is 1 task that can be completed",
            "source": "professional",
            "value": "1 Task Available for Completion"
          }
        },
        "due": {
          "chip": {
            "context": "Display for the taskChip",
            "source": "professional",
            "value": "<%- dueTodayTasks %> Due"
          },
          "plural": {
            "context": "When tasks are due the same day",
            "source": "professional",
            "value": "<%- count %> Tasks Due Today"
          },
          "singular": {
            "context": "When a task is due the same day",
            "source": "professional",
            "value": "1 Task Due Today"
          }
        },
        "new": {
          "chip": {
            "context": "Display for the taskChip",
            "source": "professional",
            "value": "<%- newTasks %> New"
          },
          "plural": {
            "context": "When Tasks are new",
            "source": "professional",
            "value": "<%- count %> New Tasks"
          },
          "singular": {
            "context": "When a task is new",
            "source": "professional",
            "value": "1 New Task"
          }
        },
        "none": {
          "context": "When there are no tasks that needs to be completed",
          "source": "professional",
          "value": "You're all caught up on Tasks!"
        },
        "overdue": {
          "chip": {
            "context": "Display for the taskChip",
            "source": "professional",
            "value": "<%- overdueTasks %> Overdue"
          },
          "plural": {
            "context": "When Tasks are overdue",
            "source": "professional",
            "value": "<%- count %> Overdue Tasks"
          },
          "singular": {
            "context": "When a task is overdue",
            "source": "professional",
            "value": "1 Overdue Task"
          }
        },
        "urgent": {
          "context": "All the tasks that need to be completed today are overdue or are new",
          "source": "professional",
          "value": "<%- count %> tasks for you"
        }
      }
    },
    "details": {
      "completedMsg": {
        "source": "professional",
        "value": "Completed <%- date %>"
      },
      "loadingError": {
        "source": "professional",
        "value": "Failed to load item.  Please try again later."
      },
      "markComplete": {
        "source": "professional",
        "value": "Mark Complete"
      },
      "markIncomplete": {
        "source": "professional",
        "value": "Mark Incomplete"
      },
      "subjectHeader": {
        "source": "professional",
        "value": "Subject:"
      },
      "title": {
        "source": "professional",
        "value": "Task"
      },
      "toggleCompletionError": {
        "source": "professional",
        "value": "Failed to update item.  Please try again later."
      },
      "topicHeader": {
        "source": "professional",
        "value": "Topic:"
      }
    },
    "errors": {
      "custom": {
        "expirationDateOffset": {
          "source": "professional",
          "value": "Task Expires can only be on the due date or a positive number of days after the due date."
        }
      },
      "required": {
        "dueDate": {
          "source": "professional",
          "value": "Due is a required field."
        },
        "stepTitle": {
          "source": "professional",
          "value": "Step requires a title."
        },
        "title": {
          "source": "professional",
          "value": "Title is a required field."
        }
      }
    },
    "filter": {
      "chip": {
        "complete": {
          "source": "professional",
          "value": "Complete"
        },
        "incomplete": {
          "source": "professional",
          "value": "Incomplete"
        }
      }
    },
    "multiFilter": {
      "filterGroups": {
        "mine": {
          "source": "professional",
          "value": "My Filters"
        },
        "shared": {
          "source": "professional",
          "value": "Shared Filters"
        }
      }
    },
    "pushNotification": {
      "instruction": {
        "source": "professional",
        "value": "Include in Task Summary notification"
      },
      "label": {
        "source": "professional",
        "value": "Mobile Push Notification"
      }
    },
    "schedule": {
      "tooltip": {
        "pushNotificationTooltipText1": {
          "context": "When turned on, this task will be included in a daily push notification at 10:00 AM in the assignee's local timezone that summarizes which tasks are new and/or due.",
          "source": "professional",
          "value": "When turned on, this task will be included in a daily push notification at <%- time %> in the assignee's local timezone that summarizes which tasks are new and/or due."
        },
        "pushNotificationTooltipText2": {
          "source": "professional",
          "value": "If turned off, this task will still show in the assignee's task list."
        }
      }
    },
    "steps": {
      "confirmation": {
        "uploadPhotoLabel": {
          "source": "professional",
          "value": "Add a photo to complete this task"
        }
      },
      "labelWithCompletedSteps": {
        "context": "Steps of a task",
        "source": "professional",
        "value": "<%= completedSteps %> of <%= totalSteps %>"
      },
      "managerLabel": {
        "context": "Steps of a task",
        "source": "professional",
        "value": "<%= totalSteps %> Steps"
      },
      "managerLabelSingular": {
        "context": "Steps of a task",
        "source": "professional",
        "value": "1 Step"
      }
    },
    "summary": {
      "rewardPointsEarnedPlural": {
        "source": "professional",
        "value": "<%- numRewardPoints %> reward points earned"
      },
      "rewardPointsEarnedSingular": {
        "source": "professional",
        "value": "1 reward point earned"
      },
      "rewardPointsPlural": {
        "source": "professional",
        "value": "<%- numRewardPoints %> Reward Points"
      },
      "rewardPointsSingular": {
        "source": "professional",
        "value": "1 Reward Point"
      }
    }
  },
  "timeline": {
    "channels": {
      "allChannels": {
        "source": "professional",
        "value": "All Channels"
      },
      "card": {
        "availableRewardPointSingular": {
          "context": "Singular version for the number of reward points available for participating in a channel.",
          "source": "professional",
          "value": "1 reward point available"
        },
        "availableRewardPoints": {
          "context": "Number of reward points available for participating in a channel.",
          "source": "professional",
          "value": "<%- rewardPoints %> reward points available"
        },
        "channelEndDate": {
          "context": "The date in the form of November 30, for when the channel is ending.",
          "source": "professional",
          "value": "Ends <%- endDateString %>"
        },
        "exactlyOneMemberPosted": {
          "context": "when exactly one member posted in a channel, mentioned by username.",
          "source": "professional",
          "value": "<%- member %> posted"
        },
        "memberSingular": {
          "context": "Singular version for the number of members in a channel; ie. 1 member",
          "source": "professional",
          "value": "<%- number %> member"
        },
        "memberWhoPostedRecently": {
          "context": "last person who posted recently and total number of people who posted",
          "source": "professional",
          "value": "<%- member %> and <%- number %> others posted"
        },
        "memberWhoPostedRecentlySingular": {
          "context": "when exactly two people have posted in a channel and one is mentioned by name",
          "source": "professional",
          "value": "<%- member %> and 1 other user posted"
        },
        "members": {
          "context": "Number of members in a channel; eg. 4 members",
          "source": "professional",
          "value": "<%- number %> members"
        },
        "remainingMembers": {
          "context": "the number of members in a group besides the ones shown on the list",
          "source": "professional",
          "value": "and <%- number %> more"
        },
        "rewardPointsProgress": {
          "context": "Number of points earned in channel vs number of total points available",
          "source": "professional",
          "value": "<%- earnedPoints %> of <%- maxPoints %> reward points collected"
        },
        "rewardPointsProgressInfo": {
          "comments": {
            "context": "Info text telling user ways to earn points in the channel",
            "source": "professional",
            "value": "Create Comments to earn points!"
          },
          "posts": {
            "context": "Info text telling user ways to earn points in the channel",
            "source": "professional",
            "value": "Create Posts to earn points!"
          },
          "postsAndComments": {
            "context": "Info text telling user ways to earn points in the channel",
            "source": "professional",
            "value": "Create Posts and Comments to earn points!"
          }
        }
      },
      "errors": {
        "featureUnavailable": {
          "context": "Error message when the tenant feature is turned off",
          "source": "professional",
          "value": "This feature is not available.  Please contact your administrator for assistance."
        },
        "postNotAvailable": {
          "source": "professional",
          "value": "Post ID <%- postId %> is not available."
        }
      },
      "newActivity": {
        "source": "professional",
        "value": "New Activity"
      }
    },
    "points": {
      "dialog": {
        "earned": {
          "context": "Text content for reward points dialog",
          "source": "professional",
          "value": "You've earned <%= points %> reward points"
        }
      }
    }
  },
  "tincan": {
    "title": {
      "source": "professional",
      "value": "Experience API"
    }
  },
  "topPerformers": {
    "howToModal": {
      "explanation": {
        "source": "professional",
        "value": "The Top Performers leaderboard shows how you and your team rank against other teams in the company. Go for the gold!"
      },
      "pointsCalculation": {
        "explanation": {
          "source": "professional",
          "value": "The 'Last 30 Days' tab on the leaderboard only shows points earned in the last 30 calendar days to encourage everyone to move up in the rankings. Don't worry, you're not losing points!"
        },
        "title": {
          "source": "professional",
          "value": "How are points calculated on the leaderboard?"
        }
      },
      "topPeople": {
        "explanation": {
          "context": "leaderboard explanation",
          "source": "professional",
          "value": "The top 10 users on your team by highest points amount."
        },
        "title": {
          "context": "list of users with highest scores",
          "source": "professional",
          "value": "Top People"
        }
      },
      "topTeams": {
        "explanation": {
          "context": "leaderboard explanation",
          "source": "professional",
          "value": "The top 10 teams in the company by highest average points."
        },
        "title": {
          "context": "list of top performing teams",
          "source": "professional",
          "value": "Top Teams"
        }
      },
      "totalPoints": {
        "explanation": {
          "source": "professional",
          "value": "To see your reward points, click on the 'Home' tab in the main navigation bar. At the top of the page, you'll find a banner displaying your 'Current' and 'All Time' reward points."
        },
        "title": {
          "source": "professional",
          "value": "Where can I see the total reward points I've earned?"
        }
      }
    }
  },
  "training": {
    "cards": {
      "actions": {
        "boost": {
          "source": "professional",
          "value": "Boost"
        },
        "continue": {
          "source": "professional",
          "value": "Continue"
        },
        "resume": {
          "source": "professional",
          "value": "Resume"
        },
        "start": {
          "source": "professional",
          "value": "Start"
        }
      },
      "info": {
        "boostOverconfident": {
          "source": "professional",
          "value": "Overconfident"
        },
        "notStartedWithLevel": {
          "source": "professional",
          "value": "Not Started - Level <%- level %>"
        }
      }
    },
    "completed": {
      "source": "professional",
      "value": "Done"
    },
    "howToModal": {
      "explanation": {
        "beginner": {
          "context": "Explaining what 'Beginner' status means.",
          "source": "professional",
          "value": "You have not yet reached an average of <%- introTrainingPassingGrade %>% or higher on the topic."
        },
        "boost": {
          "context": "Explaining what the 'Boost' action is.",
          "source": "professional",
          "value": "Complete training on the topic to enhance your knowledge or improve your confidence."
        },
        "expert": {
          "context": "Explaining what 'Expert' status means.",
          "source": "professional",
          "value": "You have answered all questions in a topic level at least twice and maintained an average of 90% or more with high confidence. Great job!"
        },
        "graduate": {
          "context": "Explaining what 'Graduate' status means.",
          "source": "professional",
          "value": "You have answered all questions in a topic level at least twice and achieved an average of 80% or more. Awesome!"
        },
        "intermediate": {
          "context": "Explaining what 'Intermediate' status means.",
          "source": "professional",
          "value": "You have answered all questions in a topic level at least once and achieved an average of <%- introTrainingPassingGrade %>% or more."
        },
        "notStarted": {
          "context": "Explaining what 'Not Started' status means.",
          "source": "professional",
          "value": "You have not answered questions on any levels in the topic."
        }
      },
      "instruction": {
        "context": "instruction of a Training section in the application",
        "source": "professional",
        "value": "Welcome to your Training page! Here you'll find Topics, Paths, and a Catalog of training materials. These are some helpful terms to know:"
      }
    },
    "menu": {
      "tasksAriaLabel": {
        "source": "professional",
        "value": "Tasks - Unread Items"
      }
    },
    "skip": {
      "source": "professional",
      "value": "Finish Later"
    },
    "takeQuiz": {
      "source": "professional",
      "value": "Take Quiz"
    }
  },
  "trainingPreview": {
    "errors": {
      "notAuthorized": {
        "source": "professional",
        "value": "You no longer have access to this resource, please reload the page."
      }
    },
    "externalLinkButtonLabel": {
      "source": "professional",
      "value": "View Link"
    },
    "moduleButtonLabel": {
      "source": "professional",
      "value": "View Module"
    },
    "pdfButtonLabel": {
      "source": "professional",
      "value": "View PDF"
    },
    "videoButtonLabel": {
      "source": "professional",
      "value": "Watch Video"
    },
    "videoConfirmationModal": {
      "description": {
        "context": "Modal description for video preview confirmation modal",
        "source": "professional",
        "value": "This module is for viewing purposes only and will not count towards training progress."
      },
      "title": {
        "context": "Modal title for video preview confirmation modal",
        "source": "professional",
        "value": "Watch Video"
      }
    }
  },
  "user": {
    "adjustPhoto": {
      "croppableImage": {
        "context": "Aria label for adjust photo section in user profile photo upload.",
        "source": "professional",
        "value": "Croppable Image: Adjust the boundries to crop the profile image"
      },
      "remove": {
        "source": "professional",
        "value": "Remove profile image"
      }
    },
    "birthYear": {
      "title": {
        "source": "professional",
        "value": "Birth Year"
      }
    },
    "gender": {
      "optionFemale": {
        "source": "professional",
        "value": "Female"
      },
      "optionMale": {
        "source": "professional",
        "value": "Male"
      },
      "optionUnspecified": {
        "source": "professional",
        "value": "Unspecified"
      },
      "title": {
        "source": "professional",
        "value": "Gender"
      }
    },
    "imageUploader": {
      "adjustPhoto": {
        "button": {
          "title": {
            "source": "professional",
            "value": "Change Photo"
          }
        },
        "subTitle": {
          "source": "professional",
          "value": "Drag the square boundaries to change position and size"
        },
        "title": {
          "source": "professional",
          "value": "Adjust Photo"
        }
      },
      "camera": {
        "source": "professional",
        "value": "camera"
      },
      "choosePhoto": {
        "button": {
          "title": {
            "source": "professional",
            "value": "Add Photo"
          }
        },
        "subTitle": {
          "source": "professional",
          "value": "You can upload a JPEG, GIF, or PNG file (File size limit is <%= maximumImageSize %> MB)."
        },
        "title": {
          "source": "professional",
          "value": "Choose Photo"
        }
      },
      "preview": {
        "subTitle": {
          "source": "professional",
          "value": "How you will appear"
        },
        "title": {
          "source": "professional",
          "value": "Preview"
        }
      },
      "title": {
        "source": "professional",
        "value": "Edit Photo"
      }
    },
    "nickname": {
      "source": "professional",
      "value": "Nickname"
    }
  },
  "usermessage": {
    "fromLabel": {
      "source": "professional",
      "value": "From:"
    },
    "head2head": {
      "source": "professional",
      "value": "I recently defeated you in the <%- name %> game!\n\n I scored <%- score %> points on you. Can you do better?\n\n \"<%- message %>\""
    },
    "msgTypeTitle": {
      "bounty_expired_points_earned": {
        "source": "professional",
        "value": "Invite Completed"
      },
      "bounty_first_login_bonus": {
        "source": "professional",
        "value": "Invite Accepted"
      },
      "default": {
        "source": "professional",
        "value": "Message"
      },
      "game_victory_declaration": {
        "source": "professional",
        "value": "<%- gameName %>"
      },
      "graduation": {
        "source": "professional",
        "value": "Congrats!"
      },
      "prize_winner": {
        "source": "professional",
        "value": "Congrats!"
      },
      "team_game_over": {
        "source": "professional",
        "value": "Team Game Over"
      },
      "user_message": {
        "source": "professional",
        "value": "Message"
      }
    },
    "prizeLoser": {
      "bidType": {
        "AUCTION_BID": {
          "source": "professional",
          "value": "bid"
        },
        "AUCTION_BUY_NOW": {
          "source": "professional",
          "value": "buy now"
        }
      },
      "highestWinningBidder": {
        "source": "professional",
        "value": "Highest Winning Bid"
      },
      "lowestWinningBidder": {
        "source": "professional",
        "value": "Lowest Winning Bid"
      },
      "point": {
        "source": "professional",
        "value": "point"
      },
      "pointBid": {
        "source": "professional",
        "value": "<%- points %> point bid"
      },
      "prizeWinners": {
        "source": "professional",
        "value": "<%- name %> Winners"
      },
      "singleWinningBidder": {
        "source": "professional",
        "value": "Winning Bid"
      },
      "title": {
        "source": "professional",
        "value": "Rewards Message"
      },
      "winnersInYourLocation": {
        "source": "professional",
        "value": "Winners in your Location"
      }
    },
    "prizeSummary": {
      "messageHeader": {
        "source": "professional",
        "value": "Better luck next time"
      },
      "otherWinners": {
        "source": "professional",
        "value": "Winners in your company"
      },
      "title": {
        "source": "professional",
        "value": "Rewards Message"
      },
      "winnerDetail": {
        "auction": {
          "source": "professional",
          "value": "<%- highBid %> point bid"
        },
        "raffle": {
          "source": "professional",
          "value": "<%- raffleTicketCount %> raffle tickets"
        }
      },
      "winnersInLocation": {
        "source": "professional",
        "value": "Winners in your location"
      }
    },
    "prizeWinner": {
      "auction": {
        "source": "professional",
        "value": "You won the auction!"
      },
      "raffles": {
        "source": "professional",
        "value": "You won the raffle!"
      }
    },
    "teamGameOver": {
      "source": "professional",
      "value": "Congratulations to <%= winningTeam %> for defeating <%= losingTeam %> in <%= gameName %>"
    },
    "title": {
      "source": "professional",
      "value": "Message"
    }
  }
};