const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const CommunityHeaderSimpleView = require('@training/apps/search/CommunityHeaderSimpleView');

class CommunityHeaderSimpleController extends LayoutController {
  initialize(options = {}) {
    ({
      community: this.community
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: CommunityHeaderSimpleView,
      model: this.community
    };
  }
}

module.exports = CommunityHeaderSimpleController;
