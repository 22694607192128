const {
  Wreqr,
  history,
  Model,
  Collection
} = require('Backbone');

const $os = require('detectOS');
const _ = require('underscore');
const logging = require('logging');
const { sendWarnLog } = require('LoggingService');
const { toggleSensitiveContent } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');

const InactivityMonitor = require('@common/libs/InactivityMonitor');
const {
  NUDGE_TIMEOUT,
  CMSPoller
} = require('@common/modules/auth/models/CMSPoller');
const EntityPoller = require('@common/libs/EntityPoller');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

const AxonifyExceptionCode = require('AxonifyExceptionCode');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');
const CurrentTrainingSession = require('@common/data/models/CurrentTrainingSession');
const TopicProgressItemList = require('@training/apps/training/collections/TopicProgressItemList');
const ContinueTrainingCardCollection = require('@common/components/trainingCards/ContinueTrainingCardsCollection');
const { LearningEventUpcomingList } = require('@training/apps/training/collections/LearningEventList');
const RewardsSummarySampleCollection = require('@training/apps/home/hubPreviews/rewards/RewardsSummarySampleCollection');
const AchievementsCongratulationCollection = require('@training/apps/home/collections/AchievementsCongratulationCollection');
const RecommendedArticlesList = require('@training/apps/home/collections/RecommendedArticlesList');
const CoachesList = require('@training/apps/training/collections/CoachesList');


const DefaultDailyTrainingSessionInitiatorProviderFactory = require('@training/apps/training/controllers/assessments/daily/DefaultDailyTrainingSessionInitiatorProviderFactory');

const AssessmentType = require('@common/data/enums/AssessmentType');

const HeadlessAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/HeadlessAssessmentInitiatorController');
const AssessmentControllerFactory = require('@training/apps/training/controllers/assessments/AssessmentControllerFactory');
const AssessmentInitiatorRegistry = require('@training/apps/training/controllers/assessments/selection/AssessmentInitiatorRegistry');
const AssessmentInitiatorTrackerFactory = require('@training/apps/training/controllers/assessments/selection/AssessmentInitiatorTrackerFactory');
const AssessmentInitiatorPruner = require('@training/apps/training/controllers/assessments/selection/AssessmentInitiatorPruner');
const SessionBoundAssessmentFactory = require('@common/data/models/assessments/SessionBoundAssessmentFactory');

const prefetchControllerData = require('@training/apps/training/controllers/ControllerCacheWarmupStrategy');

const ActionBarType = require('@common/components/actionBarButton/ActionBarType');

const DeepLinkingAssessmentInitiator = require('@training/apps/training/controllers/assessments/deep_linking/DeepLinkingAssessmentInitiator');
const SelfDirectedAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/SelfDirectedAssessmentInitiatorController');
const GuidedLearningController = require('@training/apps/training/controllers/GuidedLearningController');
const SelfDirectedPathsController = require('@training/apps/training/controllers/SelfDirectedPathsController');
const SelfDirectedPathAssessmentInitiatorContext = require('@training/apps/training/controllers/selfDirectedPath/SelfDirectedPathAssessmentInitiatorContext');
const GuidedLearningSessionConfigurationProvider = require('@training/apps/training/controllers/guidedLearning/GuidedLearningSessionConfigurationProvider');
const EventNotificationsController = require('@training/apps/training/controllers/EventNotificationsController');
const BroadcastMessageController = require('@training/apps/training/controllers/BroadcastMessageController');
const UserMessagesController = require('@training/apps/training/controllers/UserMessagesController');
const HubController = require('@training/apps/training/controllers/HubController');
const MobileNotificationsController = require('@training/apps/training/controllers/MobileNotificationsController');
const FilteredEventIndex = require('@training/apps/events/filteredEvents/FilteredEventIndex');
const TaskIndex = require('@training/apps/legacyTasks/TaskIndex');
const MessageCollection = require('@training/apps/training/collections/MessageCollection');
const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const { getCreateStartPageView } = require('@training/apps/training/views/StartPageFactory');
const { getMemoizedFunctionWithInvalidator } = require('@common/libs/helpers/app/MemoizeHelpers');

const CompleteProfilePage = require('@training/apps/training/views/CompleteProfilePage');
const SelectCoachController = require('@training/apps/training/coachSelection/SelectCoachController');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const DiscoverController = require('@training/apps/training/controllers/DiscoverController');
const TimelineController = require('@training/apps/training/controllers/TimelineController').default;
const CommunitiesManagementFlowController = require('@training/apps/training/controllers/CommunitiesManagementFlowController');
const InsightsFlowController = require('@training/apps/training/controllers/InsightsFlowController');
const CommunitiesInsightsFlowController = require('@training/apps/training/communitiesInsights/CommunityInsightsFlowController');

const TopicTrainingCardsCollection = require('@common/components/trainingCards/TopicTrainingCardsCollection');
const PathTrainingCardsCollection = require('@common/components/trainingCards/PathTrainingCardsCollection');
const CatalogTrainingCardsCollection = require('@training/apps/training/trainingCards/CatalogTrainingCardsCollection');
const CatalogFilterSubjectList = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogFilterSubjectList');

const TimelineNotifierModel = require('@training/apps/training/models/TimelineNotifierModel');
const TasksNotifierModel = require('@training/apps/training/models/TasksNotifierModel');
const {
  TIMELINE,
  EXECUTION_TASKS
} = require('@common/data/enums/HubTileIdentifiers').default;

let taskIndex = null;
let filteredEventIndex = null;
let broadcastMessageController = null;
let eventsNotificationsController = null;
let userMessagesController = null;
let hubController = null;
let discoverController = null;
let timelineController = null;
let communitiesManagementFlowController = null;
let insightsFlowController = null;
let communitiesInsightsFlowController = null;
let guidedLearningController = null;
let selfDirectedPathsController = null;
let selfDirectedController = null;
let mobileNotificationsController = null;
let deepLinkingAssessmentController = null;
const assessmentControllers = {};
const fallbackControllers = {};
const MsTeamsHelpers = require('@common/libs/helpers/app/MsTeamsHelpers');

const PRE_TRAINING_HASHES = [
  '#hub/profile',
  '#hub/profile/user',
  '#hub/profile/coach',
  '#hub/profile/language',
  '#hub/profile/account',
  '#hub/feed/messages'
];

const PRE_TRAINING_DYNAMIC_HASH_PREFIXES = [
  '#hub/message/',
  '#hub/events',
  '#hub/eventDetails',
  '#hub/training',
  '#hub/max',
  '#hub/taskList'
];

class SessionController extends FlowController {
  constructor(...args) {
    super(...args);

    const EventCheckinModel = Model.extend({
      apiEndpoint: '/learning-event-enrollments/checkin-available'
    });

    this.getBroadcastMessageController = this.getBroadcastMessageController.bind(this);
    this.getUserMessagesController = this.getUserMessagesController.bind(this);
    this.getHubController = this.getHubController.bind(this);
    this.startSession = this.startSession.bind(this);
    this.resumeSession = this.resumeSession.bind(this);
    this.processUserSetup = this.processUserSetup.bind(this);
    this.processSequenceFlow = this.processSequenceFlow.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
    this.sendUpdateSession = this.sendUpdateSession.bind(this);
    this.sendBadgeUpdate = this.sendBadgeUpdate.bind(this);
    this.endSession = this.endSession.bind(this);
    this.endSessionAndWipe = this.endSessionAndWipe.bind(this);
    this.appDidBecomeActive = this.appDidBecomeActive.bind(this);
    this.appDidEnterBackground = this.appDidEnterBackground.bind(this);
    this.startTraining = this.startTraining.bind(this);
    this.skipTraining = this.skipTraining.bind(this);

    this.nbChannel = Wreqr.radio.channel('nativeBridge');
    this.listenTo(this.nbChannel.vent, 'appDidBecomeActive', this.appDidBecomeActive);
    this.listenTo(this.nbChannel.vent, 'appDidEnterBackground', this.appDidEnterBackground);
    this.listenTo(this.nbChannel.vent, 'notificationPayload', this.getNotificationPayload);

    this.currentNotificationData = {};
    this.didBecomeActive = false;

    // Update session flag
    this.updateSessionEnabled = false;

    this.session = new CurrentTrainingSession();
    this.eventCheckinModel = new EventCheckinModel();
    this.topicProgressItemList = new TopicProgressItemList();
    this.messageCollection = new MessageCollection();
    this.continueTrainingItems = new ContinueTrainingCardCollection([], {
      session: this.session
    });
    this.eventsCollection = new LearningEventUpcomingList([], {
      currentUserLanguage: window.apps.auth.session.user.get('language'),
      recordsPerPage: 3
    });
    this.rewardsCollection = new RewardsSummarySampleCollection();
    this.achievementsCongratulationCollection = new AchievementsCongratulationCollection();
    this.recommendedArticlesList = new RecommendedArticlesList();
    this.timelineNotifier = new TimelineNotifierModel();
    this.tasksNotifier = new TasksNotifierModel();

    const QuickLinksCollection = Collection.extend({ apiEndpoint: '/quickLinks' });
    this.quickLinksList = new QuickLinksCollection();
    this.quickLinksOnPaywallEnabled = TenantPropertyProvider.get().getProperty('quickLinksOnPaywallEnabled');
    this.openExternalLinksEnabled = TenantPropertyProvider.get().getProperty('openExternalLinks');

    ({
      memoizedFn: this.loadIndex
    } = getMemoizedFunctionWithInvalidator(this._loadIndex.bind(this)));

    const memoizedRecommendedArticlesListFnInfo = getMemoizedFunctionWithInvalidator(this._fetchRecommendedArticles.bind(this));
    this._getRecommendedArticlesList = memoizedRecommendedArticlesListFnInfo.memoizedFn;
    const memoizedQuickLinksListFnInfo = getMemoizedFunctionWithInvalidator(this._fetchQuickLinks.bind(this));
    this._getQuickLinksList = memoizedQuickLinksListFnInfo.memoizedFn;

    this._hadCompletedAssessmentWhenStartedThisInstance = false;

    this.recommendedArticlesEnabledOnStartPage = TenantPropertyProvider.get().getProperty('showArticleRecommendationsOnStart');
    this.recommendedArticlesEnabledOnHubPage = TenantPropertyProvider.get().getProperty('showArticleRecommendationsOnHub');

    if (this._isUserLoggedIn()) {
      if ($os.isInMobileApp()) {
        NativeBridgeHelpers.requestNotificationPayload();
      }

      this.topicTrainingCardsCollection = new TopicTrainingCardsCollection(null, {session: this.session});
      this.pathTrainingCardsCollection = new PathTrainingCardsCollection();
      this.catalogTrainingCardsCollection = new CatalogTrainingCardsCollection();
      this.catalogFilterSubjectList = new CatalogFilterSubjectList();
    }

    // This is a workaround to a chrome bug that prevents network calls in the unload callback. See ticket #1009
    if ($os.desktop && $os.browser === 'chrome' && $os.version === 31) {
      $(window).on('beforeunload', () => {
        this.sendUpdateSession();
      });
    } else {
      // Update session when closing
      $(window).on('unload', () => {
        this.sendUpdateSession();
      });
    }

    this.brandingChannelModel = this._getBrandingChannelModel();

    this.isTrainingPaywallDisabled = !window.apps.auth.session.user.get('trainingPaywallEnabled');
  }

  _isUserLoggedIn() {
    return window.apps.auth && window.apps.auth.session && window.apps.auth.session.user.isLoggedIn();
  }

  setupRegistry() {
    const registry = new AssessmentInitiatorRegistry();
    return registry;
  }

  registerInitiatorsForRegistry(registry) {
    registry.registerInitiator(this.getDeepLinkingAssessmentInitiatorController());
    registry.registerInitiator(this.getSelfDirectedAssessmentInitiatorController());
    registry.registerInitiator(this.getIntroductoryTrainingAssessmentController());
    registry.registerInitiator(this.getCertificationTrainingAssessmentController());
    registry.registerInitiator(this.getRefresherTrainingAssessmentController());
    registry.registerInitiator(this.getDailyTrainingAssessmentController());
    registry.registerInitiator(this.getFormalExamAssessmentController());
    registry.registerInitiator(this.getGuidedLearningController());
    registry.registerInitiator(this.getSelfDirectedPathsController());
    registry.registerInitiator(this.getExtraTrainingAssessmentController());
  }

  registerSessionTypeTrainingInitiatorProvider() {
    const trainingFactory = new DefaultDailyTrainingSessionInitiatorProviderFactory(this.session, this.initiatorRegistry, this._getCreateStartPage.bind(this));
    this.trainingInitiatorProvider = trainingFactory.create();
  }

  setSelfDirectedPathConfiguration() {
    const selfDirectedPathAssessmentInitiatorController = this.initiatorRegistry.findInitiatorWithHandlerFor(SelfDirectedPathAssessmentInitiatorContext.getType());
    const showInitiatorPredicateAsync = () => {
      return Promise.resolve(!this.session.isMinDailyTrainingFulfilled());
    };
    const optionsProvider = new GuidedLearningSessionConfigurationProvider(this.session, showInitiatorPredicateAsync);
    selfDirectedPathAssessmentInitiatorController.setConfigurationProvider(optionsProvider);
  }

  createAssessmentInitiatorTracker() {
    return AssessmentInitiatorTrackerFactory.create(this.initiatorRegistry);
  }

  createAssessmentFactory() {
    return new SessionBoundAssessmentFactory(this.session, this.assessmentInitiatorTracker);
  }

  getTaskIndex() {
    if (taskIndex == null) {
      taskIndex = new TaskIndex();
    }
    return taskIndex;
  }

  getFilteredEventIndex() {
    if (filteredEventIndex == null) {
      filteredEventIndex = new FilteredEventIndex();
    }
    return filteredEventIndex;
  }

  getDiscoverController() {
    if (discoverController == null) {
      discoverController = new DiscoverController(this);
    }

    return discoverController;
  }

  getTimelineController() {
    if (timelineController == null) {
      timelineController = new TimelineController(this);
    }
    return timelineController;
  }

  getCommunitiesManagementFlowController() {
    if (communitiesManagementFlowController == null) {
      communitiesManagementFlowController = new CommunitiesManagementFlowController(this);
    }

    return communitiesManagementFlowController;
  }

  getInsightsFlowController() {
    if (insightsFlowController == null) {
      insightsFlowController = new InsightsFlowController(this);
    }

    return insightsFlowController;
  }

  getCommunitiesInsightsFlowController() {
    if (communitiesInsightsFlowController == null) {
      communitiesInsightsFlowController = new CommunitiesInsightsFlowController(this);
    }

    return communitiesInsightsFlowController;
  }

  getGuidedLearningController() {
    if (guidedLearningController == null) {
      guidedLearningController = this.getPathController(GuidedLearningController);
    }
    return guidedLearningController;
  }

  getSelfDirectedPathsController() {
    if (selfDirectedPathsController == null) {
      selfDirectedPathsController = this.getPathController(SelfDirectedPathsController);
    }
    return selfDirectedPathsController;
  }

  getPathController(Controller) {
    return new Controller(
      this,
      this.getTaskIndex().showDetailView,
      this.getFilteredEventIndex,
      this.assessmentFactory
    );
  }

  getEventsNotificationsController() {
    if (eventsNotificationsController == null) {
      eventsNotificationsController = new EventNotificationsController(this);
    }

    return eventsNotificationsController;
  }

  getBroadcastMessageController() {
    if (broadcastMessageController == null) {
      broadcastMessageController = new BroadcastMessageController(this);
    }

    return broadcastMessageController;
  }

  getMobileNotificationsController() {
    if (mobileNotificationsController == null) {
      mobileNotificationsController = new MobileNotificationsController(this);
    }
    return mobileNotificationsController;
  }

  getQuickLinksList() {
    if (this.openExternalLinksEnabled !== 'DISABLED') {
      this._getQuickLinksList();
    }

    return (new $.Deferred()).resolve()
      .promise();
  }

  _fetchQuickLinks() {
    return this.quickLinksList.fetch({
      showSpinner: false
    }).fail(() => {
      logging.error('Failed to load quick links data');
      return (new $.Deferred()).resolve()
        .promise();
    });
  }

  _getCreateStartPage(callback) {
    return getCreateStartPageView({
      availableTiles: this.getHubController().getAvailableTiles(),
      eventCheckinModel: this.eventCheckinModel,
      recommendedArticlesList: this.recommendedArticlesList,
      discoveryZoneEnabled: this.discoveryZoneEnabled,
      recommendedArticlesEnabled: this.recommendedArticlesEnabledOnStartPage,
      startTrainingCallback: this.startTraining,
      skipTrainingCallback: this.skipTraining,
      brandingChannelModel: this.brandingChannelModel,
      quickLinksList: this.quickLinksList,
      quickLinksOnPaywallEnabled: this.quickLinksOnPaywallEnabled,
      openExternalLinksEnabled: this.openExternalLinksEnabled
    })({
      model: this.session,
      complete: callback
    });
  }

  getIntroductoryTrainingAssessmentController() {
    // There is something evil about just doing this without much interest
    return this.createAssessmentControllerForType(AssessmentType.IntroductoryTraining);
  }

  getCertificationTrainingAssessmentController() {
    return this.createAssessmentControllerForType(AssessmentType.CertificationTraining);
  }

  getRefresherTrainingAssessmentController() {
    return this.createAssessmentControllerForType(AssessmentType.RefresherTraining);
  }

  getDailyTrainingAssessmentController() {
    return this.createAssessmentControllerForType(AssessmentType.DailyTraining);
  }

  getExtraTrainingAssessmentController() {
    return this.createAssessmentControllerForType(AssessmentType.ExtraTraining);
  }

  getFormalExamAssessmentController() {
    return this.createAssessmentControllerForType(AssessmentType.FormalExamTraining);
  }

  getDeepLinkingAssessmentInitiatorController() {
    if (deepLinkingAssessmentController == null) {
      deepLinkingAssessmentController = new DeepLinkingAssessmentInitiator(this, {
        sessionModel: this.session,
        assessmentFactory: this.assessmentFactory,
        getDailyTrainingSessionInitiatorProvider: () => {
          return this.trainingInitiatorProvider;
        }
      });
    }

    return deepLinkingAssessmentController;
  }

  getSelfDirectedAssessmentInitiatorController() {
    if (selfDirectedController == null) {
      selfDirectedController = new SelfDirectedAssessmentInitiatorController(this, {
        sessionModel: this.session,
        assessmentFactory: this.assessmentFactory
      });
    }

    return selfDirectedController;
  }

  getCurrentAssessmentControllerConfig() {
    const currentAssessment = this.session.getCurrentAssessment();
    return this.assessmentInitiatorTracker.findAssessmentInitiator(currentAssessment) || this._getFallbackAssessmentControllerConfig(currentAssessment);
  }

  resumeAssessment(assessmentOptions) {
    return Promise.resolve(this.assessmentFactory.createTrainingAssessmentOfAssessmentType(assessmentOptions.getForAssessmentType(), assessmentOptions.toAssessmentRequestJson()).then(() => {
      return this.processSequenceFlow();
    }));
  }

  _getFallbackAssessmentControllerConfig(assessment) {
    const assessmentType = assessment.getType();

    if (fallbackControllers[assessmentType] != null) {
      return fallbackControllers[assessmentType];
    }

    let initiator;

    // First two cases are the only assessment types that are guaranteed to be matched to their own initiator implementation.
    // The rest can fallback to the Headless initiator for now.
    if (assessmentType === AssessmentType.FormalExamTraining) {
      initiator = this.getFormalExamAssessmentController();
    } else if (assessmentType === AssessmentType.RefresherTraining) {
      initiator = this.getRefresherTrainingAssessmentController();
    } else {
      // Create a generic controller for the assessment type if we can't find something (no storage available)
      initiator = this.createHeadlessControllerForType(assessmentType);
    }

    const context = initiator.createInitiatorContext({ assessmentType });

    fallbackControllers[assessmentType] = {
      initiator,
      context
    };
    return fallbackControllers[assessmentType];
  }

  getCurrentAssessmentController() {
    const {initiator} = this.getCurrentAssessmentControllerConfig();
    return initiator;
  }

  createHeadlessControllerForType(assessmentType) {
    return new HeadlessAssessmentInitiatorController(this, {
      assessmentType,
      sessionModel: this.session,
      assessmentFactory: this.assessmentFactory
    });
  }

  createAssessmentControllerForType(assessmentType) {
    const existingController = assessmentControllers[assessmentType];
    if (existingController) {
      return existingController;
    }

    const controller = AssessmentControllerFactory.create(
      assessmentType,
      this,
      this.session,
      this.assessmentFactory
    );

    assessmentControllers[assessmentType] = controller;

    return controller;
  }

  getUserMessagesController() {
    if (userMessagesController == null) {
      userMessagesController = new UserMessagesController(this);
    }

    return userMessagesController;
  }

  getHubController() {
    if (hubController == null) {
      hubController = new HubController(this, {
        sessionModel: this.session,
        eventCheckinModel: this.eventCheckinModel,
        topicProgressItemList: this.topicProgressItemList,
        messageCollection: this.messageCollection,
        continueTrainingItems: this.continueTrainingItems,
        rewardsCollection: this.rewardsCollection,
        eventsCollection: this.eventsCollection,
        achievementsCongratulationCollection: this.achievementsCongratulationCollection,
        recommendedArticlesList: this.recommendedArticlesList,
        recommendedArticlesEnabled: this.recommendedArticlesEnabledOnHubPage,
        brandingChannelModel: this.brandingChannelModel,
        startCurrentSessionTraining: this.startTraining,
        topicTrainingCardsCollection: this.topicTrainingCardsCollection,
        pathTrainingCardsCollection: this.pathTrainingCardsCollection,
        catalogTrainingCardsCollection: this.catalogTrainingCardsCollection,
        catalogFilterSubjectList: this.catalogFilterSubjectList,
        timelineNotifier: this.timelineNotifier,
        quickLinksList: this.quickLinksList,
        removeBroadcastFromQueue: this.getBroadcastMessageController().removeBroadcastFromQueue.bind(this.getBroadcastMessageController())
      });
    }

    return hubController;
  }

  //
  // Session management
  //

  // Ask the native wrapper for the push token.
  requestPushData() {
    if (this.nbChannel.reqres.request('isInApp')) {
      this.nbChannel.reqres
        .request('requestPushData')
        .then(apps.auth.setPushData, (data) => {
          return logging.error(`Invalid response to \`requestPushData\`: ${ JSON.stringify(data) }`);
        });
    }

    toggleSensitiveContent(false);
  }

  performTrackingPruning() {
    AssessmentInitiatorPruner.prune(this.assessmentInitiatorTracker, this.session);
  }

  getSelfCheckinEvents() {
    return Promise.resolve(this.eventCheckinModel.fetch());
  }

  setSelfCheckinModel(options = {}) {
    this.eventCheckinModel.set(options);
  }

  getRecommendedArticlesList() {
    // XXX: hasSomeDiscoveryZoneCommunityAvailable happenes to return the correct value without a race condition.
    // See HubController for details.
    this.discoveryZoneEnabled = window.apps.base.getKnowledgeHref(this.getHubController().hasSomeDiscoveryZoneCommunityAvailable()) != null;
    const recommendedArticlesVisiblePreTraining = !this.isMinDailyTrainingFulfilled() && this.recommendedArticlesEnabledOnStartPage;
    const recommendedArticlesVisiblePostTraining = this.getHubController().isHubMenuEnabled() && this.recommendedArticlesEnabledOnHubPage;

    if (this.discoveryZoneEnabled && (recommendedArticlesVisiblePostTraining || recommendedArticlesVisiblePreTraining) ) {
      return this._getRecommendedArticlesList();
    }

    return (new $.Deferred()).resolve()
      .promise();
  }

  _fetchRecommendedArticles() {
    return this.recommendedArticlesList.fetch({
      showSpinner: false
    }).fail(() => {
      logging.error('Failed to load recommended articles data');
      return (new $.Deferred()).resolve()
        .promise();
    });
  }

  isMinDailyTrainingFulfilled() {
    return this.session.isMinDailyTrainingFulfilled();
  }

  isHubMenuEnabled() {
    const isHubMenuEnabled = this.getHubController().isHubMenuEnabled();
    if (this.isTrainingPaywallDisabled) {
      return isHubMenuEnabled;
    }

    return isHubMenuEnabled
      && this.isMinDailyTrainingFulfilled()
      && !this.session.hasCurrentAssessment();
  }

  showUnsupportedBrowserWarning() {
    const warningI18nKey = BrowserHelpers.getUnsupportedBrowserWarning();
    if (warningI18nKey === 'flash.browserNotSupported') {
      window.app.layout.flash.warning(I18n.t(warningI18nKey), 0);
    }
  }

  _loadIndex() {
    if (apps.auth.session.user.id == null) {
      return;
    }

    this.stashedGL = this._parseGLHash(window.location.hash);
    this.stashedHubTile = this._parseHubHash(window.location.hash);

    this.setupInactivityMonitor();
    this.setupCMSPoller();
    this.showUnsupportedBrowserWarning();
    this.requestPushData();

    window.app.layout.showSpinner();
    Promise.all([
      this.loadSession(),
      this.getHubController().getIfHasAvailableCommunitiesWithPages(),
      this.getHubController().getHubTiles(),
      this.getSelfCheckinEvents(),
      this.getQuickLinksList()
    ])
      .then(() => {
        return this.getRecommendedArticlesList();
      })
      .then(() => {
        this.prefetchSessionStepData();
        this.startSession();
        window.app.layout.hideSpinner();
      })
      .then(() => {
        if (this.getHubController().isHubTileEnabled(TIMELINE)) {
          this.timelineNotifier.fetch().done((resp) => {
            if (resp.hasUpdates === true) {
              window.apps.base.timeLogController.createStamp(TimeLogConfig.TimelineUpdateIsUpdate);
            }
          });
        }
        if (TenantPropertyProvider.get().getProperty('tasksEnabled') && this.getHubController().isHubTileEnabled(EXECUTION_TASKS)) {
          this.tasksNotifier.fetch();
        }
      });
  }

  _parseHubHash(hash = '') {
    if (hash.indexOf('#hub/') === 0) {
      return hash;
    }

    return null;
  }

  _parseGLHash(hash = '') {
    if (hash.indexOf('#guided/') === 0) {
      return hash;
    }

    return null;
  }

  prefetchSessionStepData() {
    const cacheFns = [
      () => {
        return this.getBroadcastMessageController().getBroadcastMessages();
      },
      () => {
        return this.getUserMessagesController().getUserMessages();
      },
      () => {
        return this.getEventsNotificationsController().getNotifications();
      },
      () => {
        return this.getHubController().getReferralStatus();
      }
    ];

    prefetchControllerData(cacheFns);
  }

  loadHub(options = {}) {
    if (this.updateSessionEnabled) {
      return this.processSequenceFlow(options);
    }

    return this.loadIndex();
  }

  loadSession() {
    return this.session.fetch({
      apiEndpoint: '/sessions/current',
      error(model, xhr) {
        if (xhr.status === 403) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.success(I18n.t('flash.sessionCompleted'));
          window.apps.auth.logout();
          return;
        } else if (xhr.status === 401) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('flash.sessionExpired'));
          window.apps.auth.logout();
          return;
        }
        const errStr = `SessionController::loadSession xhr error. status = ${ xhr.status }, statusText = ${ xhr.statusText }`;
        logging.error(errStr);
        window.app.layout.flash.error(I18n.t('flash.serviceDown'));
      }
    });
  }

  startSession() {
    logging.debug(`Start session for user with id: ${ window.apps.auth.session.user.id }`);

    this.initiatorRegistry = this.setupRegistry();
    this.assessmentInitiatorTracker = this.createAssessmentInitiatorTracker();
    this.assessmentFactory = this.createAssessmentFactory();
    this.registerInitiatorsForRegistry(this.initiatorRegistry);
    this.registerSessionTypeTrainingInitiatorProvider();

    this.setSelfDirectedPathConfiguration();

    this.updateSessionEnabled = true;

    this._hadCompletedAssessmentWhenStartedThisInstance = this.session.hasCompletedSomeAssessment();

    window.apps.base.timeLogController.setUser(apps.auth.session.user);
    this.startTimeLogController();

    if (this.inactivityMonitor != null) {
      this.inactivityMonitor.setIdleTimout(TenantPropertyProvider.get().getProperty('inactivityTimeoutMinutes'));
      this.inactivityMonitor.start();
    }

    this.listenTo(apps.auth.session.user, 'change:isOnTheClock', (user, isOnTheClock) => {
      if (!isOnTheClock && this.session.hasCurrentAssessment()) {
        const assessmentProcessor = this.getCurrentAssessmentController().getAssessmentProcessor();
        assessmentProcessor.pauseAssessment().then(assessmentProcessor.finishedProcessing);
        const {
          string,
          timeout
        } = AxonifyExceptionCode.onTheClockRequiredErrorFlashOptions();
        window.app.layout.flash.error(string, timeout);
      }
    });

    // Run through the initial user setup if needed
    this.processUserSetup();
  }

  resumeSession() {
    this.startTimeLogController();
  }

  startTimeLogController() {
    window.apps.base.timeLogController.startTimeLogInterval();

    // Start session timer
    window.apps.base.timeLogController.startSession(this.session.get('id'));

    // Start the time spent tracking time log
    window.apps.base.timeLogController.startTimeSpentTracker(this.session.get('id'));
  }

  processUserSetup() {
    const language = window.apps.auth.session.user.get('language');
    const coach = window.apps.auth.session.user.get('coach');

    window.app.layout.resetLeftHeaderView();
    this.performTrackingPruning();

    if (!language || language === 'XX') {
      // Ask the user to complete profile if language preference is not set
      this.showCompleteProfile(this.processUserSetup);
    } else if (coach == null) {
      // Ask the user to select their coach if one's available
      this.showSelectCoach(this.processSequenceFlow);
    } else {
      window.app.layout.showChat();

      // Provide the user with the hub sequence of the nav menu if the training paywall is turned off or the user has
      // completed their minimum daily training
      if (this.isTrainingPaywallDisabled
        || (this.isMinDailyTrainingFulfilled() && !this.session.hasCurrentAssessment())) {
        this.getHubController().toggleHubMenuEnabled();
      }

      window.app.layout.showNotificationCenter();
      // Start the sequence flow for which page or activity to display
      this.processSequenceFlow();
    }
  }

  processSequenceFlow(options = {}) {
    logging.debug('Processing session sequence flow...');

    window.app.layout.showSpinner();
    return this._attemptToLookForAssessmentDeepLink()
      .then(() => {
        return this.getMobileNotificationsController().processSequenceFlow()
          .catch(this._onSequenceFlowError.bind(this));
      })
      .then(() => {
        return this.getDiscoverController().processSequenceFlow(options)
          .catch(this._onSequenceFlowError.bind(this));
      })
      .then(() => {
        return this.getTimelineController().processSequenceFlow(options)
          .catch(this._onSequenceFlowError.bind(this));
      })
      .then(() => {
        return this._attemptToProcessCurrentAssessement();
      })
      .then(() => {
        return this._loadAllowablePreTrainingHubPage(options)
          .catch(this._onSequenceFlowError.bind(this));
      })
      .then(() => {
        return this.getBroadcastMessageController().processSequenceFlow();
      })
      .then(() => {
        return this.getUserMessagesController().processSequenceFlow();
      })
      .then(() => {
        return this.getEventsNotificationsController().processSequenceFlow();
      })
      .then(() => {
        return this._attemptRestorePreTrainingGL();
      })
      .then(() => {
        return this._attemptToLookForTrainingToPerform();
      })
      .then(() => {
        return this.showPreliminaryReferral(options);
      })
      .then(() => {
        return this._attemptRestoreHubSubPage();
      })
      .then(() => {
        // This is the attempt to hit timeline as the landing page
        return this.getTimelineController().attemptToLandOnTimelineView(options)
          .catch(this._onSequenceFlowError.bind(this));
      })
      .then(() => {
        return this.getHubController().processSequenceFlow(options)
          .catch(this._onSequenceFlowError.bind(this));
      })
      .catch(Promise.OperationalError, () => {
        logging.debug('Sequence flow match found. We will no longer search for matches..');
      })
      .catch((error) => {
        sendWarnLog({
          logData: {
            logMessage: 'There was an exception processing the sequence flow. It\'s possible that something went wrong and the user is stuck.',
            error: error
          }
        });
      })
      .finally(() => {
        return window.app.layout.hideSpinner();
      });
  }

  startTraining(assessmentOption) {
    const selectedType = assessmentOption.get('sessionType');
    return this.trainingInitiatorProvider.getInitiatorAsync(undefined, selectedType).then((candidateAssessmentInitiator) => {
      if (candidateAssessmentInitiator != null) {
        return candidateAssessmentInitiator.processTraining(assessmentOption);
      }

      return undefined;
    });
  }

  skipTraining() {
    this.session.skipTraining().done(() => {
      this.processSequenceFlow();
    });
  }

  _onSequenceFlowError(e = {}) {
    if (e.cause === ProcessSequenceMessageCode.HANDLING) {
      this.stashedHubTile = null;
    }
    return Promise.reject(e);
  }

  _attemptToLookForTrainingToPerform() {
    if (this.isTrainingPaywallDisabled || this.session.isMinDailyTrainingFulfilled()) {
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    }

    return this.trainingInitiatorProvider.getInitiatorAsync().then((candidateAssessmentInitiator) => {
      if (candidateAssessmentInitiator) {
        const controller = candidateAssessmentInitiator;
        return controller.processSequenceFlow({});
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  _loadAllowablePreTrainingHubPage(options = {}) {
    const hash = window.location.hash;
    const hubControllerRef = this.getHubController();
    const isDynamicHash = this._isPreTrainingDynamicHash(hash);

    if (PRE_TRAINING_HASHES.includes(hash) || isDynamicHash) {
      if (options.pageId == null) {
        _.defer(() => {
          history.loadUrl(hash);
        });
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }

      return hubControllerRef.getHubTiles()
        .then(() => {
          logging.debug('Processing hub tiles');
          hubControllerRef.showPage(options);
          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        });
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  _isPreTrainingDynamicHash(hash) {
    return _.find(PRE_TRAINING_DYNAMIC_HASH_PREFIXES, (dynamicHash) => {
      return hash.includes(dynamicHash);
    });
  }

  _isDeepLinkAssessmentUnderway(queryParams) {
    const currentAssessmentAttr = this.session.getCurrentAssessment().toJSON();
    let assessmentQueryParamMapping = {
      topicId: parseInt(queryParams.topicId, 10),
      level: parseInt(queryParams.level, 10),
      type: queryParams.assessmentType,
      programId: parseInt(queryParams.programId, 10)
    };

    // programId can be found in one of two places on the currentAssessment model depending on the assessment type
    if (currentAssessmentAttr.program != null && currentAssessmentAttr.program.id != null) {
      currentAssessmentAttr.programId = currentAssessmentAttr.program.id;
    }

    // Remove undefined values from queryParams object. This occurs if the optional queryParams weren't provied in URL
    assessmentQueryParamMapping = _.pick(assessmentQueryParamMapping, (value) => {
      return !_.isNull(value) && !_.isUndefined(value) && !isNaN(value);
    });

    return _.isMatch(currentAssessmentAttr, assessmentQueryParamMapping);
  }

  _attemptToLookForAssessmentDeepLink() {
    if (!window.location.hash.includes('#assessmentLink')) {
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    }

    const queryParams = UrlHelpers.getQueryParams(window.location.hash);
    const deepLinkingAssessmentInitiatorController = this.getDeepLinkingAssessmentInitiatorController();

    // If there is an assessment in progress, we must decide to bail on it or continue if it's the same deep-link
    if (this.session.hasCurrentAssessment()) {
      // Attempt to process deep link if it is already in progress
      if (this._isDeepLinkAssessmentUnderway(queryParams)) {
        return this._attemptToProcessCurrentAssessement();
      }

      // Bail on any existing assessment before presenting the deep-linked assessment
      return this.pauseCurrentAssessment().then(() => {
        return deepLinkingAssessmentInitiatorController.processSequenceFlow({queryParams});
      });
    }

    return deepLinkingAssessmentInitiatorController.processSequenceFlow({queryParams});
  }

  pauseCurrentAssessment() {
    if (this.session.hasCurrentAssessment()) {
      const assessmentInitiator = this.getCurrentAssessmentController();

      if (assessmentInitiator.assessmentController != null) {
        return assessmentInitiator.assessmentController.pauseAssessment();
      }
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  _attemptToProcessCurrentAssessement() {
    if (this.session.hasCurrentAssessment()) {
      const {
        initiator,
        context
      } = this.getCurrentAssessmentControllerConfig();

      initiator.processAssessment(this.session.getCurrentAssessment(), context);

      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }
    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  _attemptRestoreHubSubPage() {
    if (this.stashedHubTile != null) {
      const hash = this.stashedHubTile;
      this.stashedHubTile = null;
      this.getHubController().toggleHubMenuEnabled();
      history.navigate(hash, { trigger: true });
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  _attemptRestorePreTrainingGL() {
    if (this.session.get('sessionTrainingType') === SessionTrainingType.GuidedLearningTraining
          && this.stashedGL != null) {
      const hash = this.stashedGL;
      this.stashedGL = null;
      history.navigate(hash, { trigger: true });
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  showPreliminaryReferral(options) {
    const controller = this.getHubController();

    return controller.shouldSeePreliminaryReferral()
      .then((shouldShowReferralPage) => {
        if (shouldShowReferralPage) {
          controller.showPreliminaryReferralPage({
            onComplete: this.processSequenceFlow.bind(this, options)
          });

          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }

        return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
      });
  }

  finishedProcessing() {
    logging.debug('Finished processing session');
    return this.endSession();
  }

  stopAllTimersAndSendLog() {
    // Stop all the started timers and purge the time log
    window.apps.base.timeLogController.stopTimeLogInterval();
    window.apps.base.timeLogController.stopAll();

    return window.apps.base.timeLogController.sendTimeLog({
      useBeacon: true,
      skipGlobalHandler: true
    });
  }

  sendUpdateSession() {
    if (apps.auth.session && window.apps.auth.session.user.id && this.updateSessionEnabled) {
      const options = {
        async: false,
        skipGlobalHandler: true
      };
      this.session.currentAssessment.activities.stopInProgressCompletedActivityTimer();
      this.session.currentAssessment.stopGamePlay(options);
      this.session.currentAssessment.saveFinalTimeSpent(options);
      this.stopAllTimersAndSendLog();
    }
  }

  sendBadgeUpdate() {
    return this.session.sendBadgeUpdate({ async: false });
  }

  endSession(onComplete) {
    // Stop all timers (including session) and send the time log
    logging.info('Stopping the login time for training console');

    this.sendUpdateSession();

    $(window).off('unload');
    $(window).off('beforeunload');

    if (this.inactivityMonitor != null) {
      this.inactivityMonitor.stop();
    }

    if (this.cmsSessionPoller != null) {
      EntityPoller.stopPoll(this.cmsSessionPoller);
    }

    if (onComplete == null) {
      const onCompleteDefault = () => {
        return logging.debug('endSession - onComplete');
      };
      return onCompleteDefault();
    }

    return onComplete();
  }

  endSessionAndWipe(params = {}) {
    // unregister the user's mobile device before signing them out.
    logging.debug('endSessionAndWipe');

    this.endSession(() => {
      logging.debug('endSessionAndWipe - onComplete');
      window.apps.auth.unregisterDeviceAndLogout(params);
    });
  }

  setupInactivityMonitor() {
    if (this.nbChannel.reqres.request('isInApp')) {
      return;
    }

    this.inactivityMonitor = new InactivityMonitor({
      events: 'heartbeat'
    });

    this.inactivityMonitor.registerTimeoutCallback('session-controller', () => {
      if (MsTeamsHelpers.tryRedirectToMsTeamsContentUrl()) {
        //If we are in MsTeams (in chrome) and we switch tabs, the tab freezes and stops sending hearbeat pings
        //This if statement allows us to reload the page to go through the auth flow again rather than logout
      } else {
        logging.info('Ending session due to inactivity!');
        this.endSessionAndWipe({ redirectTo: window.location.href });
      }
    });
  }

  setupCMSPoller() {
    const user = window.apps.auth.session.user;
    if (user.hasNudgeAccess()) {
      this.cmsSessionPoller = new CMSPoller();
      EntityPoller.startPoll(this.cmsSessionPoller, {
        pollPredicate: () => {
          return user.isLoggedIn();
        },
        timeout: NUDGE_TIMEOUT,
        totalTimeout: NUDGE_TIMEOUT * 10
      });
    }
  }

  //
  // Activity management
  //

  showCompleteProfile(complete) {
    const v = new CompleteProfilePage({ complete });
    window.apps.base.timeLogController.bindPageViewLog(v, 'LanguageSelection');
    return window.app.layout.setView(v);
  }

  showSelectCoach(onComplete) {
    const coachList = new CoachesList();
    coachList.fetch({
      showSpinner: false
    }).then(() => {
      if (coachList.length === 0) {
        onComplete();
      } else {
        window.app.layout.setView({
          ViewControllerClass: SelectCoachController,
          actionBar: window.app.layout.actionBar,
          actionButtonType: ActionBarType.CONTINUE,
          pageTitle: I18n.t('coaches.title'),
          coachList,
          onComplete
        });
      }
    });
  }

  //
  // Native Bridge Event Handling
  //
  appDidBecomeActive() {
    logging.info('appDidBecomeActive');
    this.didBecomeActive = true;

    const checkTrainingSessionComplete = () => {
      if (this.session.isExpired()) {
        logging.debug('SessionController - session isExpired is true');
        window.location.reload();
        return;
      }

      this.resumeSession();
    };

    if (apps.auth && window.apps.auth.session && window.apps.auth.session.user && window.apps.auth.session.user.id && this.backgroundTime) {
      logging.debug('SessionController - has auth session');

      if (Math.abs(dateHelpers.getTime() - this.backgroundTime) > window.apps.auth.session.suggestedMobileLoginIntervalMillis) {
        logging.debug('SessionController - loginExpired');

        window.apps.auth.reauthenticate()
          .then(() => {
            logging.debug('SessionController - reauthenticate success');
            checkTrainingSessionComplete();
          })
          .catch(() => {
            logging.error('SessionController - reauthenticate failed');
          });
      }

      logging.debug('SessionController - has not exceeded suggestedMobileLoginIntervalMillis');

      checkTrainingSessionComplete();
    }

    NativeBridgeHelpers.requestNotificationPayload();
  }

  appDidEnterBackground() {
    logging.debug('appDidEnterBackground');

    this.sendBadgeUpdate();
    this.sendUpdateSession();

    this.backgroundTime = dateHelpers.getTime();
    this.didBecomeActive = false;
  }

  getNotificationPayload({
    navigate: {
      type,
      id,
      ...otherProps
    }
  } = {}) {
    if (!type) {
      return;
    }

    this.currentNotificationData = {
      type,
      id,
      ...otherProps
    };

    if (this.didBecomeActive) {
      this.getMobileNotificationsController().processSequenceFlow();
    }
  }

  isExtraTrainingAvailable() {
    const maxExtraTrainingSessions = TenantPropertyProvider.get().getProperty('extraTrainingLimitPerSession');
    const assessments = this.session.get('assessments');

    // There are two kinds of assessments that need to count towards the number of extra training assessments:
    //  * assessments of type 'ExtraTrainingQuestion'
    //  * Self-Directed Topics (which are implemented as optional introductory training topics)
    //
    // here we check for both kinds of assessments and factor that into the number of extra training sessions available.
    const isExtraTrainingAssessment = (assessment) => {
      const isExtraTrainingType = [AssessmentType.ExtraTraining].includes(assessment.type);
      const isOptionalIntroAssessment = [AssessmentType.IntroductoryTraining].includes(assessment.type) && ['OPTIONAL'].includes(assessment.assessmentReason);

      return isExtraTrainingType || isOptionalIntroAssessment;
    };

    const extraTrainingCount = _.filter(assessments, isExtraTrainingAssessment).length;
    const userDidntReachMaxExtraTrainingSessions = extraTrainingCount < maxExtraTrainingSessions;

    const isExtraTrainingEnabled = this.isTrainingPaywallDisabled ? this.getHubController().isExtraTrainingEnabled() && this.isMinDailyTrainingFulfilled() : this.getHubController().isExtraTrainingEnabled();

    return isExtraTrainingEnabled && userDidntReachMaxExtraTrainingSessions;
  }

  _getBrandingChannelModel() {
    const brandingChannelModel = new Model({
      bannerFullResPath: null
    });

    const brandingChannel = Wreqr.radio.channel('branding');
    this.listenTo(brandingChannel.vent, 'style:updated', (brandingInfo) => {
      brandingChannelModel.set({
        bannerFullResPath: brandingInfo.bannerImageFullResPath
      });
    });

    return brandingChannelModel;
  }

  redirectGL(options) {
    if (this.isHubMenuEnabled()) {
      this.getHubController().showGuidedLearningPage(options);
    } else {
      this.getGuidedLearningController().showPageFlow(options);
    }
  }
}

module.exports = SessionController;
