const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const { LayoutView } = require('Marionette');
const BusinessUnitRepository = require('@common/data/collections/businessUnit/BusinessUnitRepository');
const COMMUNITY_MANAGEMENT_PATH = '#hub/search/articles/communityManagement/new?fromCommunitiesManagement=true';
const CHANNELS_MANAGEMENT_PATH = '#hub/timeline/channelsManagement/new';

const { axFormSelectors } = require('@common/components/axForm/AxFormTemplateHelpers');

class CommunitiesSearchView extends LayoutView {
  initialize(options = {}) {
    ({
      managementPageState: this.managementPageState,
      isCommsEnabled: this.isCommsEnabled,
      shouldShowEllipsisMenu: this.shouldShowEllipsisMenu,
      isChannels: this.isChannels
    } = options);

    this.canAddCommunity = !window.apps.auth.session.user.isBuAdminUser();
    if (window.apps.auth.session.user.isBuAdminUser()) {
      new BusinessUnitRepository(window.apps.auth.session.user, true)
        .getAll({ fetch: true }, (businessUnits) => {
          this.canAddCommunity = businessUnits.length > 0;
          this.$('.js-add-community-button').toggleClass('invisible', !this.canAddCommunity);
        });
    }

    this.communityList = this.managementPageState.get('communityList');
    this.searchData = this.managementPageState.get('searchData');

    if (this.isChannels) {
      this.searchData.set('isChannels', true);
    }

    this.listenTo(this.communityList, 'sync', this._updateCommunitiesLabel);
    this.listenTo(this.communityList, 'update', this._updateCommunitiesCount);
  }

  className() {
    return 'communities-search';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/communities/CommunitiesSearchView.html');
  }

  templateHelpers() {
    return {
      isCommsEnabled: this.isCommsEnabled,
      searchQuery: this.searchData.get('searchString'),
      placeholderText: this.isChannels ? I18n.t('channels.management.inputPlaceholder') : I18n.t('communitiesManagement.communities.inputPlaceholder'),
      clearSearchClass: this.getClearSearchClass(),
      communityManagementPath: this.isChannels ? CHANNELS_MANAGEMENT_PATH : COMMUNITY_MANAGEMENT_PATH,
      shouldShowEllipsisMenu: this.shouldShowEllipsisMenu,
      createButtonText: this.isChannels ? I18n.t('channels.management.create') : I18n.t('communitiesManagement.communities.add')
    };
  }

  events() {
    return {
      'keyup @ui.searchInput': _.debounce(_.bind(this.onSearch, this), 300),
      'focus @ui.searchInput': this.onSearchFocus,
      'blur @ui.searchInput': this.onSearchBlur,
      'click @ui.removeIcon': this.clearSearchString,
      'click @ui.includeLocationCommunities': this.onClickTeamCommunitiesFilter,
      'click @ui.includeArchived': this.onClickArchivedFilter
    };
  }

  ui() {
    return {
      searchInputView: '.search-input-view',
      searchInput: axFormSelectors.input,
      removeIcon: '.js-remove',
      includeLocationCommunities: '.js-team-communities-filter',
      includeArchived: '.js-archived-filter',
      communitiesCountText: '.communities-count-text'
    };
  }

  onShow() {
    this.ui.includeArchived.prop('checked', this.searchData.get('includeArchived'));
    this.ui.includeLocationCommunities.prop('checked', this.searchData.get('includeLocationCommunities'));

    this.$('.js-add-community-button').toggleClass('invisible', !this.canAddCommunity);

    this._updateCommunitiesLabel();
  }

  getClearSearchClass() {
    return this.searchData.get('searchString') ? '' : 'hidden';
  }

  onSearchFocus() {
    this.ui.searchInputView.toggleClass('focused', true);
  }

  onSearchBlur() {
    this.ui.searchInputView.toggleClass('focused', false);
  }

  onSearch() {
    const searchString = this.ui.searchInput.val().trim();
    const previousSearch = this.searchData.get('searchString');

    if (searchString !== previousSearch) {
      this.updateSearch(searchString);

      if (searchString) {
        this.ui.removeIcon.toggleClass('hidden', false);
      } else {
        this.ui.removeIcon.toggleClass('hidden', true);
      }
    }
  }

  clearSearchString() {
    this.ui.searchInput.val('');
    this.ui.removeIcon.toggleClass('hidden', true);

    this.updateSearch();
  }

  updateSearch(searchString = '') {
    this.managementPageState.set({
      pageNum: 1
    });
    this.searchData.set('searchString', searchString);
    this.communityList.search(this.searchData, 0);
  }

  onClickTeamCommunitiesFilter() {
    this._updateFilter('includeLocationCommunities');
  }

  onClickArchivedFilter() {
    this._updateFilter('includeArchived');
  }

  _updateFilter(filterName) {
    const searchString = this.ui.searchInput.val().trim();

    this.searchData.set(filterName, this.ui[filterName][0].checked);

    this.updateSearch(searchString);
  }

  _updateCommunitiesCount() {
    this.communityList.state.totalRecords = this.communityList.state.totalRecords - 1;
    this._updateCommunitiesLabel();
  }

  _updateCommunitiesLabel() {
    const countText = this.isChannels ? 'channels.management.count' : 'communitiesManagement.communities.communitiesCount';
    this.ui.communitiesCountText.text(I18n.t(countText, {
      currentCommunitiesList: this.communityList.length ?? 0,
      totalCommunities: this.communityList.state.totalRecords ?? 0
    }));
  }
}

module.exports = CommunitiesSearchView;
