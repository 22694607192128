import HubTileIdentifiers from '@common/data/enums/HubTileIdentifiers';
import UserMetadata from '@common/data/models/UserMetadata';
import type { TimelineId } from '@common/data/types/Timeline';
import I18n from '@common/libs/I18n';
import { ReactControllerDefinitionFactory } from '@common/modules/react';
import logging from '@common/prerequisites/libs/logging/logging';
import AxonifyExceptionCode from '@common/services/error/AxonifyExceptionCode';
import AxonifyExceptionFactory from '@common/services/error/AxonifyExceptionFactory';
import TenantPropertyProvider from '@common/services/TenantPropertyProvider';
import FlowController from '@training/apps/training/controllers/FlowController';
import type HubController from '@training/apps/training/controllers/HubController';
import ProcessSequenceMessageCode from '@training/apps/training/controllers/ProcessSequenceMessageCode';
import TimelinePageEnum from '@training/apps/training/enums/TimelinePageEnum';
import Backbone from 'backbone';
import Promise from 'bluebird';
import _ from 'underscore';

interface TimelineDetailsPageOptions {
  postId?: string;
  queryParams?: Record<string, unknown>;
}

interface PostPageOptions {
  postId: string;
}

interface ChannelPageOptions {
  channelId: string;
  targetReferenceItemId?: TimelineId<'PAGE'>;
  targetSearchQuery?: string;
};


interface ChannelManagementOptions {
  initialTab: string;
  pageNum: number;
}

interface SequenceFlowOptions {
  pageId: string;
  pageOptions: TimelineDetailsPageOptions | ChannelPageOptions;
  postId?: string;
  queryParams?: Record<string, unknown>;
}

interface AjaxErrorWithSkip extends JQuery.jqXHR {
  skipGlobalHandler: boolean;
}

class TimelineController extends FlowController {
  // lint error "Promise-returning method provided where a void return was expected by extended/implemented type 'FlowController'"
  // this is because FlowController was designed to be a base class where subclasses are always required to override
  // processSequenceFlow and return a Promise, so we ignore the error here
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  processSequenceFlow(options: SequenceFlowOptions = {
    pageId: '',
    pageOptions: {}
  }) {
    const hash = window.location.hash;

    if (hash.includes('#hub/timeline')) {
      const hubControllerRef = this.parentProcessor.getHubController();

      if (options.pageId == null) {
        _.defer(() => {
          Backbone.history.loadUrl(hash);
        });
        return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }

      const dzUserMetadata = new UserMetadata();
      const metadataFetchOptions = {
        data: { communityType: 'channel' },
        error: this._onMetadataFetchError
      };

      return Promise.all([
        hubControllerRef.getHubTiles(),
        dzUserMetadata.fetch(metadataFetchOptions)
      ])
        .then(() => {
          logging.debug('Processing hub tiles');

          // Prevent any possibility of timeline hash becoming a stashedHubTile when user has insufficient permission
          this.parentProcessor.stashedHubTile = null;

          if (hubControllerRef.isTimelineEnabled()) {
            Object.assign(options.pageOptions, { userMetadata: dzUserMetadata });

            return this._showTimelinePage(options, hubControllerRef);
          }

          Backbone.history.loadUrl('');
          return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
        });
    }
    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  // This is the attempt to hit timeline as the landing page after login, not to navigate to the Timeline otherwise
  attemptToLandOnTimelineView(options: SequenceFlowOptions = {
    pageId: '',
    pageOptions: {}
  }) {
    const hubController = this.parentProcessor.getHubController();
    return hubController.getHubTiles().then(() => {
      if (options.pageId == null
        && TenantPropertyProvider.get().getProperty('landOnTimelineAfterLogin')
        && hubController.isTimelineEnabled()
        && hubController.isConsolidatedCommsApplicable()) {
        options.pageId = HubTileIdentifiers.TIMELINE;
        Backbone.history.navigate('#hub/timeline', { replace: true });
        return this.processSequenceFlow(options);
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  _showTimelinePage(options: SequenceFlowOptions, hubControllerRef: HubController) {
    const { pageId } = options;

    switch (pageId) {
      case TimelinePageEnum.TIMELINE_POST_DETAILS:
        return this._showPostDetailsPage(options.pageOptions as PostPageOptions);
      case TimelinePageEnum.CHANNEL_DETAILS:
        return this._showChannelDetailsPage(options.pageOptions as ChannelPageOptions, hubControllerRef);
      case TimelinePageEnum.CHANNEL_MANAGEMENT:
        return this._showChannelManagementPage(options.pageOptions as ChannelManagementOptions, hubControllerRef);
      case TimelinePageEnum.CHANNEL_SETTINGS:
        return this._showChannelSettingsPage(options.pageOptions as ChannelPageOptions, hubControllerRef);
      default:
        hubControllerRef.showPage(options);
        return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }
  }

  _showPostDetailsPage(options: PostPageOptions) {
    const { postId } = options;

    window.app.layout.setView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/PostCardDetailsLayout'),
      props: { postId: Number(postId) }
    }));

    return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
  }

  _showChannelDetailsPage(options: ChannelPageOptions, hubControllerRef: HubController) {
    if (!hubControllerRef.isChannelsEnabled()) {
      this._handleChannelsUnavailable(hubControllerRef, options);
      return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    const {
      channelId,
      targetReferenceItemId,
      targetSearchQuery
    } = options;

    window.app.layout.setView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/channels/channelDetails/ChannelDetailsLayout'),
      props: {
        channelId,
        targetReferenceItemId,
        scrollParent: window.app.layout.getMainScrollContainer(),
        targetSearchQuery,
        timelineNotifier: hubControllerRef.getTimelineNotifier()
      }
    }));
    return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
  }

  _showChannelManagementPage(options: ChannelManagementOptions, hubControllerRef: HubController) {
    // @ts-expect-error - auth not typed on apps
    if (!hubControllerRef.isChannelsEnabled() || (!window.apps.auth.session.user.isAdminUser() && !window.apps.auth.session.user.isBuAdminUser())) {
      this._handleChannelsUnavailable(hubControllerRef);
      Backbone.history.navigate('#hub/timeline', { replace: true });
      return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    const {
      initialTab,
      pageNum
    } = options;

    window.app.layout.setView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/channels/ChannelManagement'),
      props: {
        initialTab,
        pageNum
      }
    }));
    return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
  }

  _showChannelSettingsPage(options: ChannelPageOptions, hubControllerRef: HubController) {
    // @ts-expect-error - auth not typed on apps
    if (!hubControllerRef.isChannelsEnabled() || (!window.apps.auth.session.user.isAdminUser() && !window.apps.auth.session.user.isBuAdminUser())) {
      this._handleChannelsUnavailable(hubControllerRef, options);
      Backbone.history.navigate('#hub/timeline', { replace: true });
      return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    const channelId = Number(options?.channelId);

    window.app.layout.setView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/channels/settings/ChannelSettingsView'),
      props: { channelId: channelId || undefined }
    }));
    return Promise.reject(new Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
  }

  _handleChannelsUnavailable(hubControllerRef: HubController, options?: ChannelPageOptions) {
    window.app.layout.flash.error(I18n.t('timeline.channels.errors.featureUnavailable'));
    hubControllerRef.showPage(Object.assign({}, options, { pageId: HubTileIdentifiers.TIMELINE }));
  }

  _onMetadataFetchError(dataClass: UserMetadata, xhr: AjaxErrorWithSkip) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.access.error.2012'));
    }
    Backbone.history.loadUrl('');
  }
}

export default TimelineController;
