const Page = require('@common/components/discover/models/Page');
const Article = require('@common/components/discover/models/Article');
const TrainingArticle = require('@common/components/discover/models/TrainingArticle');
const Reference = require('@common/components/discover/models/Reference');
const Question = require('@common/components/discover/models/Question');
const PostModel = require('@common/components/discover/models/PostModel');

const PageType = require('@common/components/discover/enums/PageType').default;

const PageTypeClassMap = {
  [PageType.ARTICLE]: Article,
  [PageType.QUESTION]: Question,
  [PageType.REFERENCE]: Reference,
  [PageType.TRAINING]: TrainingArticle,
  [PageType.POST]: PostModel
};

class PageFactory {

  getTypedPage(page) {
    const PageClass = PageTypeClassMap[page.get('type')] || Page;

    const attr = $.extend(true, {}, page.attributes);
    return new PageClass(attr, {
      parse: true
    });
  }
}

module.exports = PageFactory;
