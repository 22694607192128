const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const PeriodicCollectionPoller = require('@common/libs/PeriodicCollectionPoller');
const {
  getTableHeaders,
  getTableHeaderClasses
} = require('@training/apps/search/communitiesManagement/CommunityManagementTableHelper');
const CommunitiesManagementTableEnum = require('@training/apps/training/enums/CommunitiesManagementTableEnum');
const ImportHistoryTableItemView = require('@training/apps/search/communitiesManagement/ImportHistoryTableItemView');
const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const PageableTableController = require('@common/components/pageableList/PageableTableController');
const ImportHistoryCollection = require('@training/apps/search/communitiesManagement/collections/ImportHistoryCollection');

const {
  getImportPageNumberFromUrl,
  updateImportHistoryPageNumberInUrl
} = require('@training/apps/search/SearchUrlHelper');

class ImportHistoryController extends LayoutController {
  initialize() {
    this.isChannels = this.options.isChannels;
    this.pageNum = getImportPageNumberFromUrl();

    this.importHistoryCollection = new ImportHistoryCollection(null, {
      isChannels: this.isChannels
    });
    this.importHistoryCollection.getPage(this.pageNum - 1);

    this.poller = new PeriodicCollectionPoller({
      collection: this.importHistoryCollection,
      predicate: this.importHistoryCollection.hasProcessingFiles,
      fetchingStrategy: () => {
        return this.importHistoryCollection.getPage(this.pageNum - 1);
      }
    });

    this.listenTo(this.importHistoryCollection, 'sync', this._onCollectionSync);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: '<div class="js-import-history-region import-history"></div>',
      regions: {
        importHistoryRegion: '.js-import-history-region'
      }
    };
  }

  regionControllers() {
    const headerNames = getTableHeaders(CommunitiesManagementTableEnum.IMPORT_HISTORY);
    const headerClasses = getTableHeaderClasses(CommunitiesManagementTableEnum.IMPORT_HISTORY);

    const regions = {
      importHistoryRegion: {
        ViewControllerClass: PageableTableController,
        itemViewClass: TableLayoutView,
        rowItemView: ImportHistoryTableItemView,
        collection: this.importHistoryCollection,
        headerNames,
        headerClasses,
        delegateEvents: {
          'view:render': () => {
            triggerResize(true);
          }
        }
      }
    };

    return regions;
  }

  onDeflateView() {
    this.poller.stopPolling();
  }

  _onCollectionSync() {
    updateImportHistoryPageNumberInUrl(this.importHistoryCollection, this.isChannels);

    // If user has changed pages, stop any current polling for the previous page that may be ongoing
    if (this.pageNum !== this.importHistoryCollection.getCurrentPage()) {
      this.pageNum = this.importHistoryCollection.getCurrentPage();
      this.poller.stopPolling();
    }

    if (this.importHistoryCollection.hasProcessingFiles()) {
      this.poller.startPolling();
    }
  }
}

module.exports = ImportHistoryController;
