const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ReactionsView = require('@training/apps/search/ReactionsView');
const FavoriteItemView = require('@training/apps/search/FavoriteItemView');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const PageType = require('@common/components/discover/enums/PageType').default;
const { POST_COMMENTS_TAG } = require('@training/apps/timeline/channels/api');
const { channelsApi } = require('@training/apps/timeline/channels/api/ChannelsApiService');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const I18n = require('@common/libs/I18n');

class ReactionsController extends LayoutController {

  initialize(options) {
    this.listenTo(this, 'view:register:reaction', (controller, view, reactionType) => {
      this.updateReactions(reactionType.toLowerCase());
    });

    ({
      favoriteModel: this.favoriteModel,
      reactionsModel: this.reactionsModel,
      showComments: this.showComments,
      showFavorites: this.showFavorites,
      showReactionsModal: this.showReactionsModal,
      itemViewOptions: this.itemViewOptions
    } = options);
    this.commentsEnabled = this.isCommsEnabled() && this.reactionsModel.get('isCommentable');
    this.reactionsEnabled = this.reactionsModel.get('permittedPageActions').REACT;
    this.isComment = this.reactionsModel.get('type') === PageType.COMMENT;

    if (this.isCommsEnabled()) {
      if (!this.isGuestOrSuperuser() && !this.reactionsModel.get('reactions') && this.reactionsEnabled) {
        this.getReactions();
      }

      this.userCommentCount = this.reactionsModel.get('userCommentCount');
    }

    this.viewDefinition = this.viewDefinition.bind(this);
    this.regionControllers = this.regionControllers.bind(this);

    // pageId is undefined and id is set to pageId when we are visiting a page
    if (this.reactionsModel.get('pageId') == null || this.isComment) {
      this.pageId = this.reactionsModel.get('id');
    } else {
      this.pageId = this.reactionsModel.get('pageId');
    }
  }

  viewDefinition() {
    return {
      ViewClass: ReactionsView,
      model: this.reactionsModel,
      showComments: this.showComments,
      showFavorites: this.showFavorites,
      shouldShowFavorites: this.shouldShowFavorites,
      showReactionsModal: this.showReactionsModal,
      commentsEnabled: this.commentsEnabled,
      userCommentCount: this.userCommentCount,
      itemViewOptions: this.itemViewOptions,
      isCommsEnabled: this.isCommsEnabled,
      className: 'reaction-view-container'
    };
  }

  regionControllers() {
    let regions = {};

    if (this.shouldShowFavorites()) {
      regions = {
        favoriteRegion: {
          viewDefinition: {
            ViewClass: FavoriteItemView,
            model: this.favoriteModel,
            className: 'reaction-bar-button-container',
            onFavoriteItem: this.itemViewOptions?.onFavoriteItem
          }
        }
      };
    }

    return regions;
  }

  isGuestOrSuperuser() {
    return window.apps.auth.session.user.isGuestOrSuperuser();
  }

  getReactions() {
    const pageId = this.reactionsModel.get('id');
    $.ajax({
      type: 'GET',
      apiEndpoint: `/pages/${ pageId }/reaction`,
      success: (resp) => {
        this.reactionsModel.set({ reactions: resp.entity });
      }
    });
  }

  // So that we can hide favorites as an option to the whole controller, but we also
  // shouldn't show them to Guests
  shouldShowFavorites() {
    return this.showFavorites && !this.isGuestOrSuperuser();
  }

  updateReactions(reaction) {
    const {
      currentUserReaction,
      totalReactions
    } = this.reactionsModel.get('reactions');
    const totalReactionsCopy = { ...totalReactions };
    let currentUserReactionCopy = { ...currentUserReaction };
    const pageId = this.pageId;
    const currentUserId = window.apps.auth.session.user.id;
    const shouldDelete = currentUserReaction && currentUserReaction.type === reaction;
    const apiEndpoint = this.isComment ? `/commentReaction` : `/userReaction`;
    const data = this.isComment ? {
      commentId: pageId,
      type: reaction
    } : {
      userId: currentUserId,
      pageId: pageId,
      type: reaction
    };
    return $.ajax({
      type: shouldDelete ? 'DELETE' : 'PUT',
      apiEndpoint,
      data: JSON.stringify(data),
      dataType: 'text', //No response from this endpoint, so avoid parsing
      success: () => {
        if (currentUserReactionCopy && currentUserReactionCopy.type) {
          totalReactionsCopy[currentUserReactionCopy.type] -= 1;
        }
        if (!shouldDelete) {
          totalReactionsCopy[reaction] += 1;
          currentUserReactionCopy = {
            userId: currentUserId,
            pageId: pageId,
            type: reaction
          };
        } else {
          currentUserReactionCopy = null;
        }

        const reactionsData = {
          currentUserReaction: currentUserReactionCopy,
          totalReactions: totalReactionsCopy,
          pageId
        };
        this.reactionsModel.set({reactions: reactionsData });
        this.itemViewOptions?.onReactToItem?.(reactionsData);

        // Invalidate comments cache when reactions are updated
        if (this.isComment) {
          window.apps.react.store.dispatch(channelsApi.util.invalidateTags([POST_COMMENTS_TAG]));
        }
      },
      error: (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);

        xhr.skipGlobalHandler = true;
        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          window.app.layout.flash.error(I18n.t('discover.reactions.POST.error.3017'));
        } else {
          window.app.layout.flash.error(I18n.t('discover.reactions.POST.error.default'));
        }
      }
    });
  }

  isCommsEnabled() {
    return TenantPropertyProvider.get().getProperty('communicationsEnabled');
  }

}

module.exports = ReactionsController;
