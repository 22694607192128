import type {
  InferApiParamType,
  InitialState
} from '@common/configs/redux/UtilTypes';
import type {
  BaseQueryArgType,
  BaseResultType,
  OtherMergeArgs,
  UseResultsHookOptions
} from '@common/data/paging';
import type { AjaxBaseQueryFn } from '@common/libs/ajax/AjaxBaseQuery';
import { getValue } from '@common/libs/helpers/types/ObjectHelpers';
import { capitalizeFirstCharacter } from '@common/libs/helpers/types/StringHelpers';
import env from '@common/prerequisites/config/env/EnvironmentSettings';
import type {
  Draft,
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import type {
  BaseQueryArg,
  BaseQueryError,
  BaseQueryMeta
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { ReactHooksModule } from '@reduxjs/toolkit/dist/query/react/module';
import type {
  MaybePromise,
  OmitFromUnion
} from '@reduxjs/toolkit/dist/query/tsHelpers';
import type {
  Api,
  CoreModule,
  QueryDefinition,
  SerializeQueryArgs
} from '@reduxjs/toolkit/query';
import {
  compact,
  find,
  flatten,
  forEach,
  get,
  has,
  identity,
  isEqual,
  isMatch,
  isNumber,
  isObject,
  keys,
  map,
  merge,
  omit,
  reject
} from 'lodash';
import {
  useCallback,
  useMemo,
  useRef
} from 'react';
import {
  useDispatch,
  useStore
} from 'react-redux';
import { useMemoOne } from 'use-memo-one';

// A type that restricts the number type to only 0 and positive numbers
export type PageIndex = number extends (infer U extends number)
  ? U extends `-${ number }`
    ? never
    : U
  : never;

export type PageIndexParam = PageIndex | ResetPageIndex;

export const INVALID_PAGE_INDEX = -1 as const;
export type InvalidPageIndex = typeof INVALID_PAGE_INDEX;

export const RESET_PAGE_INDEX = -2 as const;
export type ResetPageIndex = typeof RESET_PAGE_INDEX;

export type PagedIndexResultsPerPageParams = {
  rpp: number
};

export type PagedIndexApiParams<T extends BaseQueryArgType = BaseQueryArgType> = T & PagedIndexResultsPerPageParams & {
  p: PageIndexParam
};

export type PagedIndexResponseType<
  ResultsType extends BaseResultType<ResultItemIdKey>,
  ResponseType extends object | ResultsType[],
  ResultItemIdKey extends string
> = {
  results?: (ResponseType extends Array<infer ResponseResultType extends ResultsType> ? ResponseResultType : ResultsType)[];
  total?: number;
  nextPageIndex?: InvalidPageIndex | PageIndex;
  preceedingPageIndex?: InvalidPageIndex | PageIndex;
};

export type PagedIndexApiResult<
  ResultsType extends BaseResultType<ResultItemIdKey>,
  ResponseDataType extends object,
  ResultItemIdKey extends string
> = {
  pagedIndexPageResults: ResultsType[][];
  results?: ResultsType[];
  total?: number;
  nextPageIndex: InvalidPageIndex | PageIndex;
  preceedingPageIndex: InvalidPageIndex | PageIndex;
  requestMetadata: Record<PageIndexParam, {
    responseData: ResponseDataType | EmptyObject,
  }>;
};

export function injectPagedIndexApiEndpoint<
  // Result type for the collection data that the endpoint returns
  ResultType extends BaseResultType<ResultItemIdKey>,

  // Response type that the endpoint returns from the server
  ResponseType extends object | ResultType[] = { results: ResultType[] },

  // Query arg type that the endpoint supports
  QueryArg extends BaseQueryArgType = BaseQueryArgType,

  ResultItemIdKey extends string = 'id',

  InjectedTagType extends string = string,

  // Super generic API type that we're going to constrain to a more specific type once we extract the types we need below
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  API extends Api<AjaxBaseQueryFn, NonNullable<unknown>, any, any, CoreModule | ReactHooksModule> = Api<AjaxBaseQueryFn, NonNullable<unknown>, any, any, CoreModule | ReactHooksModule>,

  // Extracted types from the API definition
  BaseQuery extends AjaxBaseQueryFn = InferApiParamType<API, 'BaseQuery'>,
  ReducerPath extends string = InferApiParamType<API, 'ReducerPath'>,
  TagTypes extends string = InferApiParamType<API, 'TagTypes'>
>(options: {
  api: API
  endpointName: string
  endpointDefinition: Override<OmitFromUnion<QueryDefinition<QueryArg, BaseQuery, InjectedTagType | TagTypes, ResultType, ReducerPath>, 'type'>, {
    merge?: never;
    queryFn?: never;

    query: (args: PagedIndexApiParams<QueryArg>) => BaseQueryArg<AjaxBaseQueryFn>;

    mergeResults?: (pageIndexPagedResults: ResultType[][], otherArgs: OtherMergeArgs<PagedIndexApiParams<QueryArg>, BaseQuery>) => ResultType[];

    transformResponse?: (baseQueryReturnValue: ResponseType, meta: BaseQueryMeta<BaseQuery>, args: PagedIndexApiParams<QueryArg>) => MaybePromise<Partial<PagedIndexResponseType<ResultType, ResponseType, ResultItemIdKey>>>;
    transformErrorResponse?: (baseQueryReturnValue: ResponseType, meta: BaseQueryMeta<BaseQuery>, args: PagedIndexApiParams<QueryArg>) => MaybePromise<Partial<PagedIndexResponseType<ResultType, ResponseType, ResultItemIdKey>>>;

    serializeQueryArgs?: SerializeQueryArgs<PagedIndexApiParams<QueryArg>, string | PagedIndexApiResult<ResultType, ResponseType, ResultItemIdKey>>;

    providesTags?: ResultDescription<TagTypes, PagedIndexApiResult<ResultType, ResponseType, ResultItemIdKey>, PagedIndexApiParams<QueryArg>, BaseQueryError<BaseQuery>, BaseQueryMeta<BaseQuery>>
  }>
  tagType?: InjectedTagType,
  resultItemIdKey?: ResultItemIdKey
}) {
  type PagedIndexQueryArg = PagedIndexApiParams<QueryArg>;
  type PagedIndexResultType = PagedIndexApiResult<ResultType, ResponseType, ResultItemIdKey>;
  type PagedIndexTagTypes = InjectedTagType | TagTypes;
  type PagedIndexQueryDefinition = QueryDefinition<PagedIndexQueryArg, BaseQuery, PagedIndexTagTypes, PagedIndexResultType, ReducerPath>;

  const {
    api: API,
    endpointName,
    endpointDefinition: {
      query,
      mergeResults = (pageIndexPagedResults) => {
        return compact(flatten(pageIndexPagedResults));
      },
      transformResponse = identity,
      transformErrorResponse = identity,
      serializeQueryArgs,
      providesTags,
      ...definitionOptions
    },
    tagType = `${ endpointName }-PagedIndex` as InjectedTagType,
    resultItemIdKey = 'id' as ResultItemIdKey
  } = options;

  const enhancedApi = API.enhanceEndpoints({
    addTagTypes: [tagType]
  });

  const pagedIndexTransformResponse = async (
    responseData: ResponseType,
    meta: BaseQueryMeta<BaseQuery>,
    args: PagedIndexQueryArg
  ): Promise<PagedIndexResultType> => {
    const {
      results,
      total,
      nextPageIndex = INVALID_PAGE_INDEX,
      preceedingPageIndex = INVALID_PAGE_INDEX
    } = await Promise.resolve(transformResponse?.(responseData, meta, args) ?? {});

    // This is required because the transformed response of the first request is automatically used as the initial
    // cache entry instead of running the results from here through the merge config function.
    return pagedIndexMergeCacheResults({}, {
      results,
      total,
      nextPageIndex,
      preceedingPageIndex,
      pagedIndexPageResults: [],
      requestMetadata: {
        [args.p]: {
          responseData
        }
      }
    }, {
      arg: args,
      baseQueryMeta: meta
    });
  };

  const pagedIndexMergeCacheResults = (
    currentCacheData: EmptyObject | PagedIndexResultType,
    newResponseData: null | PagedIndexResultType,
    otherArgs: OtherMergeArgs<PagedIndexQueryArg, BaseQuery>
  ): PagedIndexResultType => {
    // newResponseData will be null if the data was manually being reset with `resetState()`
    if (newResponseData == null) {
      return {
        results: [],
        total: 0,
        nextPageIndex: INVALID_PAGE_INDEX,
        preceedingPageIndex: INVALID_PAGE_INDEX,
        pagedIndexPageResults: [],
        requestMetadata: {}
      };
    }

    const {
      results: newResults = [],
      total: newTotal,
      requestMetadata: newRequestMetadata
    } = newResponseData;

    const {
      arg: {
        p,
        rpp
      }
    } = otherArgs;

    if (p === 0 || p === RESET_PAGE_INDEX) {
      const nextPageIndex = newResults.length === rpp ? 1 : INVALID_PAGE_INDEX;

      return {
        nextPageIndex,
        pagedIndexPageResults: [newResults],
        results: mergeResults([newResults], otherArgs),
        preceedingPageIndex: INVALID_PAGE_INDEX,
        total: newTotal ?? newResults.length,
        requestMetadata: newRequestMetadata
      };
    }

    const {
      nextPageIndex: currentNextPageIndex = INVALID_PAGE_INDEX,
      preceedingPageIndex: currentPreceedingPageIndex = INVALID_PAGE_INDEX,
      pagedIndexPageResults: currentPagedIndexPageResults = [],
      requestMetadata: currentRequestMetadata = {}
    } = currentCacheData;

    const pagedIndexPageResults = [...currentPagedIndexPageResults];
    pagedIndexPageResults[p] = newResults;

    const results = mergeResults(pagedIndexPageResults, otherArgs);
    let nextPageIndex = currentNextPageIndex;
    let preceedingPageIndex = currentPreceedingPageIndex;
    const requestMetadata = {
      ...currentRequestMetadata,
      ...newRequestMetadata
    };

    if (newResults.length < rpp) {
      nextPageIndex = INVALID_PAGE_INDEX;
    } else {
      for (let i = p + 1; i <= pagedIndexPageResults.length; i++) {
        if (pagedIndexPageResults[i] == null) {
          nextPageIndex = i;
          break;
        }
      }
    }

    for (let i = p - 1; i >= -1; i--) {
      if (i < 0) {
        preceedingPageIndex = INVALID_PAGE_INDEX;
        break;
      } else if (pagedIndexPageResults[i] == null) {
        preceedingPageIndex = i;
        break;
      }
    }

    return {
      pagedIndexPageResults,
      results,
      nextPageIndex,
      preceedingPageIndex,
      total: newTotal ?? results.length,
      requestMetadata
    };
  };

  const pagedIndexApi = enhancedApi.injectEndpoints({
    endpoints: (builder) => {
      return {
        [endpointName]: builder.query<PagedIndexResultType, PagedIndexQueryArg>({
          ...definitionOptions as unknown as OmitFromUnion<PagedIndexQueryDefinition, 'type'>,
          queryFn: async (args, queryApi, extraOptions, baseQuery) => {
            const {
              p,
              rpp
            } = args;

            const {
              data,
              ...baseQueryArgs
            } = query?.(args) ?? { data: {} };

            const requestData = isObject(data) ? data : {};

            const response = await Promise.resolve(baseQuery({
              ...baseQueryArgs,
              data: {
                p,
                rpp,
                ...requestData
              }
            }));

            if (response.error != null) {
              return {
                ...response,
                error: await Promise.resolve(transformErrorResponse(response.error as ResponseType, response.meta, args))
              };
            }

            return {
              ...response,
              data: await Promise.resolve(pagedIndexTransformResponse(response.data as ResponseType, response.meta, args))
            };
          },
          serializeQueryArgs: (data) => {
            const serializedQueryArgs = serializeQueryArgs?.(data) ?? data.queryArgs;

            if (isObject(serializedQueryArgs)) {
              return omit(serializedQueryArgs, 'p', 'rpp');
            }

            return serializedQueryArgs;
          },
          merge: pagedIndexMergeCacheResults,
          providesTags: (response, ...args) => {
            const tags = getValue(providesTags, null, response, ...args) ?? [];

            if (tagType != null) {
              return [
                ...tags,
                ...map(response?.results, ({ [resultItemIdKey]: id }) => {
                  return {
                    type: tagType,
                    id
                  };
                }),
                {
                  type: tagType,
                  id: 'LIST'
                }
              ];
            }

            return tags;
          },
          forceRefetch(params) {
            return !isEqual(params.currentArg, params.previousArg);
          }
        })
      };
    }
  });

  const capitalizedEndpointName = capitalizeFirstCharacter(endpointName);
  const lazyHookKey = `useLazy${ capitalizedEndpointName }Query` as const;
  const resultsHookKey = `use${ capitalizedEndpointName }ResultsApi` as const;

  const useLazyQuery = pagedIndexApi[lazyHookKey];
  const useQueryState = pagedIndexApi.endpoints[endpointName].useQueryState;

  type State = InitialState<[typeof pagedIndexApi]>;

  const resultsHookFn = (hookOptions: UseResultsHookOptions<QueryArg, PagedIndexQueryDefinition, PagedIndexResultsPerPageParams> = {}) => {
    const {
      resultsPerPage,
      queryArgs: queryParams,
      ...lazyHookOptions
    } = hookOptions;

    const dispatch = useDispatch<ThunkDispatch<State, unknown, UnknownAction>>();
    const store = useStore();

    const [fetch] = useLazyQuery(lazyHookOptions);

    const queryArgs = useMemoOne(() => {
      return queryParams ?? {} as Exclude<QueryArg, undefined>;
    }, Object.values(queryParams ?? {}));

    const queryState = useQueryState(queryArgs as PagedIndexQueryArg);
    const {
      data = {} as PagedIndexResultType
    } = queryState;

    const isFetchingRef = useRef<Record<string, ReturnType<typeof fetch>>>({});

    const dataRef = useRef<PagedIndexResultType>(data);
    dataRef.current = data;

    const rppArgs = useMemo(() => {
      return {
        rpp: env.settings.pageSize,
        ...(isNumber(resultsPerPage) ? { rpp: resultsPerPage } : resultsPerPage)
      };
    }, [resultsPerPage]);

    const resetState = useCallback(async () => {
      forEach(isFetchingRef.current, (fetchResult) => {
        fetchResult.abort();
      });

      isFetchingRef.current = {};

      const args = pagedIndexApi.util.selectCachedArgsForQuery(store.getState(), endpointName);

      return Promise.all(args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedIndexApi.util.upsertQueryData(endpointName, arg, null as unknown as PagedIndexResultType));
        }

        return undefined;
      }));
    }, [queryArgs, store]);

    const removeResults = useCallback(<I extends ResultType>(targetItem: I) => {
      const args = pagedIndexApi.util.selectCachedArgsForQuery(store.getState(), endpointName) ?? [];

      return args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedIndexApi.util.updateQueryData(endpointName, arg, (draft) => {
            const item = find(draft.results, targetItem) as ResultType | undefined;

            if (item != null) {
              const results = reject(draft.results, item);

              const pagedIndexPageResults = map(draft.pagedIndexPageResults, (pageResults) => {
                return reject(pageResults, item);
              });

              const total = Math.max(0, (draft?.total ?? 0) - (item ? 1 : 0));

              return merge(draft, {
                results,
                pagedIndexPageResults,
                total
              });
            }

            return draft;
          }));
        }

        return undefined;
      });
    }, [queryArgs, store]);

    const updateResults = useCallback(<I extends ResultType>(targetItem: I, updateFn: (item: ResultType) => ResultType) => {
      const args = pagedIndexApi.util.selectCachedArgsForQuery(store.getState(), endpointName) ?? [];

      return args.map((arg) => {
        if (isMatch(arg, queryArgs)) {
          return dispatch(pagedIndexApi.util.updateQueryData(endpointName, arg, (draft) => {
            const item = find(draft.results, targetItem) as ResultType | undefined;

            if (item != null) {
              const updatedItem = updateFn(item);

              const results = updateItem(draft.results, item, updatedItem);

              const pagedIndexPageResults = map(draft.pagedIndexPageResults, (pageResults) => {
                return updateItem(pageResults, item, updatedItem);
              });

              return merge(draft, {
                results,
                pagedIndexPageResults
              });
            }

            return draft;
          }));
        }

        return undefined;
      });
    }, [queryArgs, store]);

    const fetchResults = useCallback((args: Partial<Pick<PagedIndexApiParams, 'p'>> = {}, preferCacheValue?: boolean) => {
      const fetchArgs: PagedIndexQueryArg = {
        p: INVALID_PAGE_INDEX,
        ...rppArgs,
        ...queryArgs,
        ...args
      };

      if (fetchArgs.p === INVALID_PAGE_INDEX) {
        return undefined;
      }

      if (fetchArgs.p === RESET_PAGE_INDEX) {
        fetchArgs.p = 0;
      }

      const pageIndexString = String(JSON.stringify(fetchArgs));
      let result = isFetchingRef.current[pageIndexString];

      if (result != null) {
        return result;
      }

      result = fetch(fetchArgs, preferCacheValue);

      isFetchingRef.current[pageIndexString] = result;

      result.finally(() => {
        delete isFetchingRef.current[pageIndexString];
      });

      return result;
    }, [fetch, rppArgs, queryArgs]);

    const fetchResetResults = useCallback(async (preferCacheValue?: boolean) => {
      await resetState();

      return fetchResults({
        p: RESET_PAGE_INDEX
      }, preferCacheValue);
    }, [fetchResults, resetState]);

    const fetchNextResults = useCallback((preferCacheValue?: boolean) => {
      return fetchResults({
        p: dataRef.current.nextPageIndex
      }, preferCacheValue);
    }, [fetchResults]);

    const fetchPreceedingResults = useCallback((preferCacheValue?: boolean) => {
      return fetchResults({
        p: dataRef.current.preceedingPageIndex
      }, preferCacheValue);
    }, [fetchResults]);

    const arePageResultsFetching = useCallback((args?: Partial<PagedIndexApiParams>) => {
      if (args == null) {
        return keys(isFetchingRef.current).length > 0;
      }

      const fetchArgs: PagedIndexQueryArg = {
        p: INVALID_PAGE_INDEX,
        ...rppArgs,
        ...queryArgs,
        ...args
      };

      if (fetchArgs.p === INVALID_PAGE_INDEX) {
        return false;
      }

      return isFetchingRef.current[String(JSON.stringify(fetchArgs))] != null;
    }, [isFetchingRef, rppArgs, queryArgs]);

    const areResetResultsFetching = useCallback(() => {
      return arePageResultsFetching({ p: RESET_PAGE_INDEX });
    }, [arePageResultsFetching]);

    const areNextResultsFetching = useCallback(() => {
      return arePageResultsFetching({ p: dataRef.current.nextPageIndex });
    }, [arePageResultsFetching]);

    const arePreceedingResultsFetching = useCallback(() => {
      return arePageResultsFetching({ p: dataRef.current.preceedingPageIndex });
    }, [arePageResultsFetching]);

    const hasPageData = useCallback((pageIndex: PageIndex) => {
      return has(dataRef.current, ['pagedIndexPageResults', String(pageIndex)]);
    }, []);

    const getPageData = useCallback((pageIndex: PageIndex): ResultType[] => {
      return get(dataRef.current, ['pagedIndexPageResults', String(pageIndex)], []);
    }, []);

    return {
      queryState,

      resetState,
      removeResults,
      updateResults,

      fetchResults,
      fetchResetResults,
      fetchNextResults,
      fetchPreceedingResults,

      arePageResultsFetching,
      areResetResultsFetching,
      areNextResultsFetching,
      arePreceedingResultsFetching,

      hasPageData,
      getPageData,

      hasNextPage: dataRef.current.nextPageIndex !== INVALID_PAGE_INDEX,
      hasPreceedingPage: dataRef.current.preceedingPageIndex !== INVALID_PAGE_INDEX
    };
  };

  return {
    ...pagedIndexApi,
    [resultsHookKey]: resultsHookFn
  } as typeof pagedIndexApi & {
    [key: typeof resultsHookKey]: typeof resultsHookFn
  };
}

function updateItem<T>(
  collection: undefined | Array<T | Draft<T>>,
  existingItem: T,
  newItem: T
): undefined | Array<T | Draft<T>> {
  return collection
    ? map(collection, (item) => {
      return item === existingItem ? merge(item, newItem) : item;
    })
    : collection;
}
