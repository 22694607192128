// Module
var code = `<div class="reaction-buttons <%- isCommentType ? 'comment-type' : '' %> js-reaction-buttons<%- advancedCommsEnabled ? ' comms-enabled' : '' %><%- hiddenToGuest %> ax-grid ax-grid--no-gutter ax-grid--align-items-center">
  <div class="reaction-info-group ax-grid__col--12 ax-grid__col--m-auto-size">
    <div class="reaction-info-group-inner js-reaction-info-group-inner ax-grid ax-grid--no-gutter ax-grid--align-items-center <%- groupInfoHidden %>">
      <% if (showSentiments) { %>
        <div class="js-reaction-summary reaction-info-group-left ax-grid__col--auto-size ax-grid ax-grid--align-items-center ax-grid--no-gutter"
          <% if (canShowReactionsModal) { %>
            aria-label="<%- t('discover.insights.showReactionsModal') %>"
            role="button"
            tabindex="0"
          <% } %>
        >
          <span class="most-liked<%- mostLiked1Hidden %> ax-grid__col--auto-size">
            <span class="js-most-liked-one reaction-icon filled">
              <span class="reaction-icon__filled--<%- mostLikedOne %> filled" aria-hidden="true"></span>
              <span class="reaction-icon__outline--<%- mostLikedOne %> outline" aria-hidden="true"></span>
            </span>
          </span>
          <span class="most-liked<%- mostLiked2Hidden %> ax-grid__col--auto-size">
            <span class="js-most-liked-two reaction-icon filled">
              <span class="reaction-icon__filled--<%- mostLikedTwo %> filled" aria-hidden="true"></span>
              <span class="reaction-icon__outline--<%- mostLikedOne %> outline" aria-hidden="true"></span>
            </span>
          </span>
          <p class="off-screen"><%- t('discover.insights.totalReactionsAriaLabel', { totalReactions: totalReactions }) %></p>
          <span class="reaction-count js-reaction-count <%- hideTotalReactions %> ax-grid__col--auto-size" aria-hidden="true"><%- totalReactions %></span>
          <span class="middot<%- midDotHidden %><%- forPageViews %> ax-grid__col--auto-size" aria-hidden="true">&middot;</span>
        </div>
      <% } %>
      <% if (pageViews) { %>
      <div class="view-metrics js-view-metrics">
        <%- t('discover.insights.viewCount', { pageViews: pageViews }) %>
      </div>
      <% } %>
      <% if (showTrayCommentCount) { %>
        <div class="reaction-info-group-right ax-grid__col--auto-size">
          <div class="comment-count"><%- userCommentCount %></div>
        </div>
      <% } %>
    </div>
  </div>

  <div class="reaction-buttons-group <%- showButtonsGroupTopBorder ? 'show-top-border' : '' %> <%- !commentsEnabled ? 'comments-disabled' : '' %>
    <%- isGuestOrSuperuser ? 'hidden' : '' %> ax-grid__col--12 ax-grid__col--m-auto-size ax-grid ax-grid--no-gutter ax-grid--align-items-center ax-grid--justify-content-end">
    <% if (showSentiments) { %>
      <div class="reaction-bar-button-container reaction-popover-group ax-grid__col--auto-size">
        <button class="reaction-bar-button reaction-anchor js-reaction-clickable" aria-label="<%- reactionBarAriaLabel %>" aria-expanded="false" aria-pressed="<%- reactionAriaPressed %>">
          <span class="reaction-bar-button-icon js-default-icon reaction-icon reaction-button-icon <%- userHasReaction ? ' filled' : '' %>">
            <span class="reaction-icon__filled--<%- reactionIcon %> filled" aria-hidden="true"></span>
            <span class="reaction-icon__outline--<%- reactionIcon %> outline" aria-hidden="true"></span>
          </span>
          <span class="reaction-bar-button-text qa-reaction-bar-button-text js-default-text reaction-default-text reaction-button-label"><%- reactionName %></span>
        </button>
        <div class="ie11-stacking-context-fix <%- !commentsEnabled ? 'comments-disabled' : '' %> <%- !showFavorites ? 'favorites-disabled' : '' %> hidden">
          <div class="reaction-tray <%- !showComments ? 'comments-disabled' : '' %>">
            <% const keys = ReactionsEnum.keys(); %>
            <% for (i = 0; i < keys.length; i++) { %>
              <% const currentReactionName = t('reaction.reactionType.' + keys[i] + '.name'); %>
              <div class="reaction-button-with-tooltip">
                <button class="reaction-button" aria-label="<%- currentReactionName %>" data-sentiment="<%- keys[i] %>" tabindex="0">
                  <title><%- currentReactionName %></title>
                  <span class="reaction-icon filled">
                    <span class="reaction-icon__filled--<%- ReactionsEnum[keys[i]] %> filled" aria-hidden="true"></span>
                    <span class="reaction-icon__outline--<%- ReactionsEnum[keys[i]] %> outline" class="outline" aria-hidden="true"></span>
                  </span>
                  </button>
                <span class="tooltip"><%- currentReactionName %></span>
              </div>
            <% } %>
          </div>
        </div>
      </div>
    <% } %>

    <% if (commentsEnabled && !isGuestOrSuperuser) { %>
      <div class="reaction-bar-button-container reaction-anchor ax-grid__col--auto-size">
        <button class="reaction-bar-button comment-button" aria-label="<%- commentAriaLabel %>">
          <i class="reaction-bar-button-icon icon-comment_alt reaction-button-icon"></i>
          <span class="reaction-bar-button-text qa-reaction-bar-button-text reaction-button-label"><%- t('discover.comments.button.label') %></span>
        </button>
      </div>
    <% } %>

    <% if (showFavorites) { %>
      <div class="favorite-region ax-grid__col--auto-size"></div>
    <% } %>
  </div>

</div>
`;
// Exports
module.exports = code;