import uiKitAvatar from '@common/modules/react/themes/UIKitAvatar';
import uikitBackdrop from '@common/modules/react/themes/UIKitBackdrop';
import uiKitBadge from '@common/modules/react/themes/UIKitBadge';
import uiKitBottomNavigation from '@common/modules/react/themes/UIKitBottomNavigation';
import uiKitDatePicker from '@common/modules/react/themes/UIKitDatePicker';
import uiKitDivider from '@common/modules/react/themes/UIKitDivider';
import uiKitFeatures from '@common/modules/react/themes/UIKitFeatures';
import uiKitIcon from '@common/modules/react/themes/UIKitIcon';
import uiKitPagination from '@common/modules/react/themes/UIKitPagination';
import uiKitProgressBar from '@common/modules/react/themes/UIKitProgressBar';
import uiKitSkeleton from '@common/modules/react/themes/UIKitSkeleton';
import uiKitTooltip from '@common/modules/react/themes/UIKitTooltip';
import uiKitAppHeader from '@common/modules/react/themes/UiKitAppHeader';
import uiKitButton from '@common/modules/react/themes/UiKitButton';
import uiKitCard from '@common/modules/react/themes/UiKitCard';
import uiKitPageHeader from '@common/modules/react/themes/UiKitPageHeader';
import uiKitRadio from '@common/modules/react/themes/UiKitRadio';
import uiKitSwitch from '@common/modules/react/themes/UiKitSwitch';
import uiKitTable from '@common/modules/react/themes/UiKitTable';
import uiKitTabs from '@common/modules/react/themes/UiKitTabs';
import uiKitTag from '@common/modules/react/themes/UiKitTag';
import uiKitTextField from '@common/modules/react/themes/UiKitTextField';
import _ from 'lodash';

/**
 * Get the value of a CSS variable
 *
 * @param propertyName The name of the CSS variable
 * @param cssVariables The CSSStyleDeclaration object to search for the propertyName
 * @param fallbackProperty Default value to use if the propertyName is not found
 * @returns The value of the CSS variable
 *
 */
export function getCssValue(propertyName: string, cssVariables: CSSStyleDeclaration, fallbackProperty = ''): string {
  const value = cssVariables.getPropertyValue(propertyName).trim();

  if (_.isEmpty(value) && !_.isEmpty(fallbackProperty)) {
    return cssVariables.getPropertyValue(fallbackProperty).trim();
  }

  return value;
}

export function getUiKitValues() {
  const cssVars = getComputedStyle(document.documentElement);

  return {
    borderRadiusS: getCssValue('--ax-border-radius-s', cssVars),
    borderRadiusM: getCssValue('--ax-border-radius-m', cssVars),
    borderRadiusL: getCssValue('--ax-border-radius-l', cssVars),
    borderRadiusXL: getCssValue('--ax-border-radius-xl', cssVars),

    boxShadowS: getCssValue('--ax-box-shadow-s', cssVars),
    boxShadowM: getCssValue('--ax-box-shadow-m', cssVars),
    boxShadowL: getCssValue('--ax-box-shadow-l', cssVars),

    colorBlack: getCssValue('--ax-color-black', cssVars),
    colorWhite: getCssValue('--ax-color-white', cssVars),

    colorCtaActionBackground: getCssValue('--axButton-branded-background', cssVars, '--ax-color-green-60'),
    colorAppBackground: getCssValue('--ax-color-app-background', cssVars),

    colorBlue40: getCssValue('--ax-color-blue-40', cssVars),
    colorBlue60: getCssValue('--ax-color-blue-60', cssVars),
    colorBlue80: getCssValue('--ax-color-blue-80', cssVars),
    colorBlue100: getCssValue('--ax-color-blue-100', cssVars),

    breakpointMinS: getCssValue('--ax-breakpoint-min-s', cssVars),
    breakpointMinM: getCssValue('--ax-breakpoint-min-m', cssVars),
    breakpointMinL: getCssValue('--ax-breakpoint-min-l', cssVars),

    colorGreen40: getCssValue('--ax-color-green-40', cssVars),
    colorGreen60: getCssValue('--ax-color-green-60', cssVars),
    colorGreen80: getCssValue('--ax-color-green-80', cssVars),
    colorGreen100: getCssValue('--ax-color-green-100', cssVars),

    colorGrey10: getCssValue('--ax-color-grey-10', cssVars),
    colorGrey20: getCssValue('--ax-color-grey-20', cssVars),
    colorGrey30: getCssValue('--ax-color-grey-30', cssVars),
    colorGrey40: getCssValue('--ax-color-grey-40', cssVars),
    colorGrey50: getCssValue('--ax-color-grey-50', cssVars),
    colorGrey60: getCssValue('--ax-color-grey-60', cssVars),
    colorGrey70: getCssValue('--ax-color-grey-70', cssVars),
    colorGrey80: getCssValue('--ax-color-grey-80', cssVars),
    colorGreySmoke: getCssValue('--ax-color-grey-smoke', cssVars),
    colorGreyAccent: getCssValue('--ax-color-grey-accent', cssVars),

    colorOrange40: getCssValue('--ax-color-orange-40', cssVars),
    colorOrange60: getCssValue('--ax-color-orange-60', cssVars),
    colorOrange80: getCssValue('--ax-color-orange-80', cssVars),
    colorOrange100: getCssValue('--ax-color-orange-100', cssVars),

    colorGold: getCssValue('--ax-color-gold', cssVars),
    colorSilver: getCssValue('--ax-color-silver', cssVars),
    colorBronze: getCssValue('--ax-color-bronze', cssVars),

    colorRed20: getCssValue('--ax-color-red-20', cssVars),
    colorRed40: getCssValue('--ax-color-red-40', cssVars),
    colorRed60: getCssValue('--ax-color-red-60', cssVars),
    colorRed80: getCssValue('--ax-color-red-80', cssVars),
    colorRed100: getCssValue('--ax-color-red-100', cssVars),

    colorSemanticInformational: getCssValue('--ax-color-semantic-informational', cssVars),
    colorSemanticPositive: getCssValue('--ax-color-semantic-positive', cssVars),
    colorSemanticWarning: getCssValue('--ax-color-semantic-warning', cssVars),
    colorSemanticNegative: getCssValue('--ax-color-semantic-negative', cssVars),

    colorTrainingZone: getCssValue('--ax-color-training-zone', cssVars),
    colorManagerZone: getCssValue('--ax-color-manager-zone', cssVars),
    colorAdminZone: getCssValue('--ax-color-admin-zone', cssVars),

    colorHeaderFocus: getCssValue('--ax-color-header-focus', cssVars),
    boxShadowHeaderFocus: getCssValue('--ax-box-shadow-header-focus', cssVars),

    fontSizeXS: getCssValue('--ax-font-size-xs', cssVars),
    fontSizeS: getCssValue('--ax-font-size-s', cssVars),
    fontSizeM: getCssValue('--ax-font-size-m', cssVars),
    fontSizeL: getCssValue('--ax-font-size-l', cssVars),
    fontSizeXL: getCssValue('--ax-font-size-xl', cssVars),

    fontWeightBold: getCssValue('--ax-font-weight-bold', cssVars),
    fontWeightMedium: getCssValue('--ax-font-weight-medium', cssVars),
    fontWeightRegular: getCssValue('--ax-font-weight-regular', cssVars),

    lineHeightXS: getCssValue('--ax-line-height-xs', cssVars),
    lineHeightS: getCssValue('--ax-line-height-s', cssVars),
    lineHeightM: getCssValue('--ax-line-height-m', cssVars),
    lineHeightL: getCssValue('--ax-line-height-l', cssVars),
    lineHeightXL: getCssValue('--ax-line-height-xl', cssVars),

    spacingXXS: getCssValue('--ax-spacing-xxs', cssVars),
    spacingXS: getCssValue('--ax-spacing-xs', cssVars),
    spacingS: getCssValue('--ax-spacing-s', cssVars),
    spacingM: getCssValue('--ax-spacing-m', cssVars),
    spacingL: getCssValue('--ax-spacing-l', cssVars),
    spacingXL: getCssValue('--ax-spacing-xl', cssVars),
    spacingXXL: getCssValue('--ax-spacing-xxl', cssVars),

    transitionSpeed: getCssValue('--ax-transition-speed', cssVars),
    transitionEase: getCssValue('--ax-transition-ease', cssVars),

    opacityXL: getCssValue('--ax-opacity-xl', cssVars),
    opacityL: getCssValue('--ax-opacity-l', cssVars),
    opacityM: getCssValue('--ax-opacity-m', cssVars),
    opacityS: getCssValue('--ax-opacity-s', cssVars),
    linkColor: getCssValue('--ax-link-color', cssVars, '--ax-color-blue-60'),

    pageWidthTimeline: getCssValue('--ax-page-width-timeline', cssVars),
    pageWidthS: getCssValue('--ax-page-width-s', cssVars),
    pageWidthL: getCssValue('--ax-page-width-l', cssVars),

    maxCommunicationsContentWidth: getCssValue('--ax-max-communications-content-width', cssVars),

    // Typography Color
    colorText: getCssValue('--ax-color-text', cssVars),
    colorTextSecondary: getCssValue('--ax-color-text-secondary', cssVars),

    // Focus styles
    focusRingStyle: getCssValue('--ax-focus-ring-style', cssVars),
    focusRingOffset: getCssValue('--ax-focus-ring-offset', cssVars),

    ...uiKitTag(cssVars),
    ...uiKitButton(cssVars),
    ...uiKitCard(cssVars),
    ...uiKitSwitch(cssVars),
    ...uiKitTextField(cssVars),
    ...uiKitDivider(cssVars),
    ...uiKitSkeleton(cssVars),
    ...uiKitProgressBar(cssVars),
    ...uiKitDatePicker(cssVars),
    ...uiKitFeatures(cssVars),
    ...uiKitAvatar(cssVars),
    ...uiKitIcon(cssVars),
    ...uiKitBadge(cssVars),
    ...uiKitTooltip(cssVars),
    ...uiKitPagination(cssVars),
    ...uikitBackdrop(cssVars),
    ...uiKitAppHeader(cssVars),
    ...uiKitPageHeader(cssVars),
    ...uiKitBottomNavigation(cssVars),
    ...uiKitTabs(cssVars),
    ...uiKitRadio(cssVars),
    ...uiKitTable()
  };
}

const uiKit = getUiKitValues();

export type uiKitType = typeof uiKit;
export type UIKitKeys<E extends `${ string }` = `${ string }`> = StringKeys<uiKitType, E>;

declare module '@mui/material/styles' {
  interface Theme {
    uiKit: uiKitType
  }
  interface ThemeOptions {
    uiKit: uiKitType
  }
}

export default uiKit;
