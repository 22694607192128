const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const MediaPreview = require('@common/components/mediaPreview/MediaPreview');

class ImageRegionController extends LayoutController {
  initialize(options = {}) {
    ({
      media: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: MediaPreview,
      videoPlayerOptions: {
        userLanguage: window.apps.auth.session.user.get('language')
      },
      model: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight
    };
  }
}

module.exports = ImageRegionController;
