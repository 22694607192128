const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const { parseBreadcrumbsFromSearchPageState } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const CreateMenuDefinitionHelper = require('@training/apps/search/CreateMenuDefinitionHelper');
const CommunityHeaderView = require('@training/apps/search/CommunityHeaderView');

class CommunityHeaderController extends LayoutController {

  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState,
      community: this.community
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: CommunityHeaderView,
      searchPageState: this.searchPageState,
      community: this.community
    };
  }

  regionControllers() {
    const regionControllers = {
      breadcrumb: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: parseBreadcrumbsFromSearchPageState(this.searchPageState),
          searchPageState: this.searchPageState,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: true
        }
      },
      communityIcon: {
        delegateEvents: this._getCommunityIcon()
      }
    };

    if (this.community != null) {
      regionControllers.createButtonRegion = CreateMenuDefinitionHelper.getCreateMenuRegionController(
        this.searchPageState.get('community').get('id'),
        this.community
      );
    }

    return regionControllers;
  }

  _getCommunityIcon() {
    return {
      'view:attach': () => {
        const $communityIcon = this.getView().communityIcon.$el;
        const media = this.community.get('thumbnailImage');

        if (media != null) {
          const imageViewer = ImageViewerFactory.createViewerInstance({
            $el: $communityIcon,
            maxWidth: 72,
            maxHeight: 72,
            media
          });

          imageViewer.render();
        } else {
          $communityIcon.addClass('icon-folder_open');
        }
      }
    };
  }
}

module.exports = CommunityHeaderController;
