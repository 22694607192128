const _ = require('underscore');
const Backbone = require('Backbone');
const logging = require('logging');
const { sumOmittedValues } = require('@common/libs/helpers/types/ObjectHelpers');
const { timelineUpdateApi } = require('@training/apps/timeline/api/TimelineUpdates');
/**
 * The API for timeline/update is a bit different than what you'd usually encounter.
 * I'm writing up some notes for reference here.
 *
 * /timeline/update is a notifier endpoint for the timeline. it exposes two methods,
 * GET and PUT. Neither call requires arguments.
 *
 * GET - returns a bare boolean indicating whether there have been updates to the
 *       timeline since the user last visited it.
 *
 * PUT - returns a 204 No Content. This call tells the server the user arrived on the
 *       Timeline page and has seen the updates.
 *
 * This is very rudimentary and we plan to learn more about what our customers need,
 * and feed that into a better-designed notification system.
 *
 * Now that you understand the API, the purpose of this class is clearer. The only
 * state we need to track is whether to show or hide a `hidden` class in the UI.
 *
 */
const sumOmitAvailablePoints = (obj) => {
  return sumOmittedValues(obj, ['availablePoints']);
};

class TimelineNotifierModel extends Backbone.Model {
  apiEndpoint() {
    return 'timeline/update';
  }

  parse(resp = {}) {
    const response = (typeof resp === 'string') ? JSON.parse(resp) : resp;
    if (_.isEmpty(response)) {
      return {
        priorityUpdateCounts: {
          announcement: 0,
          poll: 0,
          survey: 0,
          availablePoints: 0
        },
        aggregatePriorityCount: 0,

        channelUpdateCounts: {
          newMessage: 0,
          newChannel: 0,
          availablePoints: 0
        },
        aggregateChannelCount: 0,

        nonPriorityUpdateCounts: {
          announcement: 0,
          poll: 0,
          survey: 0,
          availablePoints: 0
        },
        aggregateNonPriorityCount: 0,

        hasUpdates: false
      };
    }

    const aggregateNonPriorityCount = sumOmitAvailablePoints(response.nonPriorityUpdateCounts);
    const aggregatePriorityCount = sumOmitAvailablePoints(response.priorityUpdateCounts);
    const aggregateChannelCount = sumOmitAvailablePoints(response.channelUpdateCounts);

    const modifiedResponse = {
      ...response,
      aggregatePriorityCount,
      aggregateChannelCount,
      aggregateNonPriorityCount,
      hasUpdates: (aggregatePriorityCount > 0 || aggregateChannelCount > 0 || aggregateNonPriorityCount > 0)
    };

    window.apps.react.store.dispatch(timelineUpdateApi.util.updateQueryData('getTimelineNotifier', undefined, (draft) => {
      Object.assign(draft, modifiedResponse);
    }));

    return modifiedResponse;
  }

  fetch(options = {}) {
    const opts = _.extend({
      data: {
        pageStatus: 'fact'
      }
    }, options);

    return super.fetch(opts);
  }

  save(attributes = {}, options = {}) {
    const channelId = attributes.channelId ?? null;
    const url = channelId ? `${ this.apiEndpoint() }?channelId=${ channelId }` : this.apiEndpoint();
    const opts = _.extend({
      type: 'PUT',
      dataType: 'text',
      apiEndpoint: url
    }, options);

    return super.save(attributes, opts);
  }

  sync(method, model, options = {}) {
    options.error = _.wrap(options.error, (originalOptionError = () => {}, xhr, status, error, ...args) => {
      this.onServerError(xhr);
      originalOptionError(xhr, status, error, ...args);
    });

    return super.sync(method, model, options);
  }

  onServerError(xhr) {
    if (xhr.status === 500) {
      xhr.skipGlobalHandler = true;
      logging.devWarn(`Error fetching Timeline Notification decorator. Try connecting to the VPN and reloading the page. If you're already connected, try disconnecting.`);
    }
  }
}

module.exports = TimelineNotifierModel;
