// Module
var code = `<form class="content-settings-form ax-grid ax-grid--no-gutter">
  <h3 class="ax-grid__col--12 content-settings__subheading"><%- t('communitiesManagement.community.contentCreation') %></h3>
  <div class="js-publish-dropdown publish-dropdown ax-grid__col--12">
    <%= axSelect({
      classNameSelect: 'js-change-selection qa-publish-access-select',
      data: {
        editor: 'AxonSelect',
        field: 'publishAccessLevel',
        options: 'publishAccessLevelOptions',
        shallow: 'true'
      },
      id: 'publish-dropdown',
      label: t('communitiesManagement.community.whoCanPublishArticles'),
      name: 'publishAccessLevel',
      noFormEditorDefault: 'true'
     }) %>
    <span class="content-settings__text--secondary"></span>
  </div>
  <div class="js-enable-recommendations switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.enableRecommendations') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="recommendationsEnabled" data-options="enableRecommendationsOptions" class="recommendationsEnabled"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"></span>
  </div>
  <div class="js-enable-question switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.enableAskAQuestion') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="questionsEnabled" data-options="enableQuestionOptions" class="questionsEnabled"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"></span>
  </div>

  <% if (isCommsEnabled) { %>
  <div class="js-enable-comments switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <h3 class="ax-grid__col--12 content-settings__subheading"><%- t('discover.insights.engagements') %></h3>
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.enableCommenting') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="commentsEnabled" data-options="enableCommentsOptions" class="commentsEnabled"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"></span>
  </div>
  <div class="js-enable-reactions switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.enableReactions') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="reactionsEnabled" data-options="enableReactionsOptions" class="reactionsEnabled switch-container"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"></span>
  </div>
  <div class="js-hide-content-from-timeline switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <h3 class="ax-grid__col--12 content-settings__subheading"><%- t('communitiesManagement.communityType.none') %></h3>
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.hideContentFromTimeline') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="showOnTimeline" data-options="hideContentFromTimelineOptions" class="hideFromGlobalTimeline switch-container"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"><%- t('communitiesManagement.community.hideFromGlobalTimeline.can') %></span>
  </div>
  <% } %>
  <% if (isConversationalSearchEnabled) { %>
  <div class="js-include-in-conversational-search switch-container-wrapper ax-grid__col--12">
    <hr aria-hidden="true">
    <div class="ax-grid ax-grid--no-gutter ax-grid__col--12">
      <label class="content-settings__text ax-grid__col--11"><%- t('communitiesManagement.community.includeInConversationalSearch.title') %></label>
      <div class="ax-grid__col--1">
        <div data-editor="Switch" data-field="includeInConversationalSearch" data-options="includeInConversationalSearchOptions" class="includeInConversationalSearch switch-container"></div>
      </div>
    </div>
    <span class="content-settings__text--secondary"></span>
  </div>
  <% } %>
</form>
`;
// Exports
module.exports = code;