const { LayoutView } = require('Marionette');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

class CommunityHeaderSimpleView extends LayoutView {
  initialize() {
    this.listenTo(this.model, 'sync', () => {
      this._getCommunityIcon();
      this._getCommunityName();
    });

    const queryParams = UrlHelpers.getQueryParams(UrlHelpers.getQueryString(window.location.hash));
    this.fromCommunitiesManagement = (queryParams.fromCommunitiesManagement === 'true');
  }

  getTemplate() {
    return `
      <div class="js-community-icon community-header__icon ax-grid__col ax-grid__col--auto-size ax-grid__col--align-self-start" aria-hidden="true"></div>
      <div class="ax-grid ax-grid--direction-column mobile-breadcrumb-flex">
        <div class="breadcrumb-path <%- shouldHideBreadCrumb %>" role="navigation">
          <div class="js-community-breadcrumb community-breadcrumb header-breadcrumbs"></div>
        </div>
        <h1 class="js-community-name ax-font--no-margin"></h1>
      </div>`;
  }

  templateHelpers() {
    return {
      shouldHideBreadCrumb: this.fromCommunitiesManagement ? 'hidden' : ''
    };
  }

  className() {
    return 'community-header ax-grid ax-grid--align-items-center';
  }

  ui() {
    return {
      communityIcon: '.js-community-icon',
      communityName: '.js-community-name'
    };
  }

  regions() {
    return {
      breadcrumb: '.js-community-breadcrumb'
    };
  }

  onAttach() {
    this._getCommunityIcon();
    this._getCommunityName();
  }

  _getCommunityIcon() {
    const communityIcon = this.ui.communityIcon;
    const media = this.model.get('thumbnailImage');

    if (media != null) {
      communityIcon.removeClass('icon-folder_open');

      const imageViewer = ImageViewerFactory.createViewerInstance({
        $el: communityIcon,
        maxWidth: 72,
        maxHeight: 72,
        media
      });

      imageViewer.render();
    } else {
      communityIcon.addClass('icon-folder_open');
    }
  }

  _getCommunityName() {
    this.ui.communityName.text(this.model.getName());
  }
}

module.exports = CommunityHeaderSimpleView;
