// NOTE - Once a TimeLogType string is configured here, it should NEVER be removed. Even if it's not being used
// anymore. Removing them could break historical audit data in Admin

module.exports = {
  training: {
    Session: {
      Start: 'Session_StartTime',
      End: 'Session_EndTime'
    },
    Assessment: {
      Start: 'Assessment_StartTime',
      End: 'Assessment_EndTime'
    },
    Game: {
      Start: 'Game_StartTime',
      End: 'Game_EndTime'
    },
    DeepLinkTraining: 'DeepLinkTraining',
    ClickCommunicationsNudgehyperlink: 'ClickCommunicationsNudgehyperlink',
    Question: 'Question',
    SurveyQuestion: 'SurveyQuestion',
    TimeSpent: 'TimeSpent',
    TrainingModule: 'TrainingModule',
    TimelineUpdateIsUpdate: 'Timeline_Update_IsUpdate',
    PageView: {
      Achievements: 'PageView_Achievements',
      AutomaticCertificationTraining: 'PageView_AutomaticCertificationTraining',
      AutomaticIntroductoryTraining: 'PageView_AutomaticIntroductoryTraining',
      AutomaticRefresherTraining: 'PageView_AutomaticRefresherTraining',
      AutomaticFormalExamTraining: 'PageView_AutomaticFormalExamTraining',
      AutomaticTellAFriend: 'PageView_AutomaticTellAFriend',
      BroadcastMessage: 'PageView_BroadcastMessage',
      ExtraTraining: 'PageView_ExtraTraining',
      LearnerTraining: 'PageView_LearnerTraining',
      Training: 'PageView_Training',
      GameScores: 'PageView_GameScores',
      HubCertificationTraining: 'PageView_HubCertificationTraining',
      HubIntroductoryTraining: 'PageView_HubIntroductoryTraining',
      HubFormalExamTraining: 'PageView_HubFormalExamTraining',
      HubRefresherTraining: 'PageView_HubRefresherTraining',
      Feed: 'PageView_News',
      FeedMessages: 'PageView_Messages',
      RecognitionPinNotifications: 'PageView_RecognitionPinNotifications',
      RecognitionPinSendModal: 'PageView_RecognitionPinSendDialog',
      RecognitionPinSummaryList: 'PageView_RecognitionPinSummaryList',
      RecognitionPinPinHistory: 'PageView_RecognitionPinPinHistoryDialog',
      ReportCard: 'PageView_ReportCard',
      Rewards: 'PageView_Rewards',
      SuggestQuestion: 'PageView_SuggestQuestion',
      TellAFriend: 'PageView_TellAFriend',
      TopPerformers: 'PageView_TopPerformers',
      UserMessage: 'PageView_UserMessage',
      LearningEvents: 'PageView_LearningEvents',
      GuidedLearningProgramsPage: 'PageView_GuidedLearning_ProgramsPage',
      GuidedLearningProgramDetailsPage: 'PageView_GuidedLearning_ProgramDetailsPage',
      TaskSummaryPage: 'PageView_Tasks_ListingViewPage',
      TaskDetailsPage: 'PageView_Tasks_TaskDetailView',
      Profile: 'PageView_Profile',
      GuidedLearning: 'PageView_GuidedLearning',
      LanguageSelection: 'PageView_LanguageSelection',
      CoachSelection: 'PageView_CoachSelection',
      PreTrainingHub: 'PageView_PreTrainingHub',
      GameSelection: 'PageView_GameSelection',
      GameDetails: 'PageView_GameDetails',
      GameChallengeSelection: 'PageView_GameChallengeSelection',
      GameChallengeDetails: 'PageView_GameChallengeDetails',
      PostTrainingHub: 'PageView_PostTrainingHub',
      TopicDetailsPage: 'PageView_Topic_Details',
      Timeline: {
        main: 'PageView_Timelines_Page',
        priority: 'PageView_Timeline_PriorityMessages_Page',
        all: 'PageView_Timeline_AllMessages_Page',
        channels: 'PageView_Timeline_Channels_Page',
        channelDetails: 'PageView_Timeline_ChannelDetails_Page'
      },
      TimelineToBroadcasts: 'PageView_Timelines_BroadcastMessage',
      Discovery: 'PageView_Discovery_AllTab',
      DiscoveryArticlesTab: 'PageView_Discovery_ArticlesTab',
      DiscoveryTopicsTab: 'PageView_Discovery_TopicsTab',
      DiscoveryPathsTab: 'PageView_Discovery_PathsTab',
      DiscoveryCommunity: 'PageView_Discovery_Community',
      ArticleDetailsPage: 'PageView_Article_Details',
      // New Task
      NewTaskMyTasks: 'PageView_NewTask_MyTasks',
      NewTaskDetailsMyTasks: 'PageView_NewTaskDetails_MyTasks',
      NewTaskMyTeams: 'PageView_NewTask_MyTeams',
      NewTaskDetailsMyTeams: 'PageView_NewTaskDetails_MyTeams',
      // Nudge integration related timelogs
      TaskListView: 'PageView_TaskListView',
      TaskCreation: 'PageView_TaskCreation',
      TaskDetails: 'PageView_TaskDetails',
      NudgeDetails: 'PageView_NudgeDetails',
      CompletedNudges: 'PageView_CompletedNudges',
      GroupSelectionView: 'PageView_GroupSelectionView',
      TaskCompletion: 'PageView_TaskCompletion',
      // PathDetailsPage: 'PageView_Path_Details'
      ConversationalSearch: 'PageView_ConversationalSearch'
    }
  },
  manager: {
    Session: {
      Start: 'LeaderZone_Session_StartTime',
      End: 'LeaderZone_Session_EndTime'
    },
    TimeSpent: 'TimeSpent',
    Module: {
      Metrics: {
        Start: 'LeaderZone_Module_Metrics_StartTime',
        End: 'LeaderZone_Module_Metrics_EndTime'
      },
      Messages: {
        Start: 'LeaderZone_Module_Messages_StartTime',
        End: 'LeaderZone_Module_Messages_EndTime'
      },
      People: {
        Start: 'LeaderZone_Module_People_StartTime',
        End: 'LeaderZone_Module_People_EndTime'
      },
      Compare: {
        Start: 'LeaderZone_Module_Compare_StartTime',
        End: 'LeaderZone_Module_Compare_EndTime'
      },
      TeamMetrics: {
        Start: 'LeaderZone_Module_TeamMetrics_StartTime',
        End: 'LeaderZone_Module_TeamMetrics_EndTime'
      },
      Behaviors: {
        Start: 'LeaderZone_Module_Behaviors_StartTime',
        End: 'LeaderZone_Module_Behaviors_EndTime'
      },
      Inspections: {
        Start: 'LeaderZone_Module_Inspections_StartTime',
        End: 'LeaderZone_Module_Inspections_EndTime'
      },
      Exports: {
        Start: 'LeaderZone_Module_Exports_StartTime',
        End: 'LeaderZone_Module_Exports_EndTime'
      },
      LearningEvents: {
        Start: 'LeaderZone_Module_LearningEvents_StartTime',
        End: 'LeaderZone_Module_LearningEvents_EndTime'
      },
      Impacts: {
        Start: 'LeaderZone_Module_Impacts_StartTime',
        End: 'LeaderZone_Module_Impacts_EndTime'
      },
      Insights: {
        Start: 'LeaderZone_Module_Insights_StartTime',
        End: 'LeaderZone_Module_Insights_EndTime'
      },
      ManageTraining: {
        Start: 'LeaderZone_Module_ManageTraining_StartTime',
        End: 'LeaderZone_Module_ManageTraining_EndTime'
      }
    },
    PageView: {
      // User Metrics Pages
      MetricsIndex: 'LeaderZone_PageView_MetricsIndex',
      NonParticipants: 'LeaderZone_PageView_NonParticipants',
      NeedsCoaching: 'LeaderZone_PageView_NeedsCoaching',
      Overconfident: 'LeaderZone_PageView_Overconfident',
      Underconfident: 'LeaderZone_PageView_Underconfident',
      Experts: 'LeaderZone_PageView_Experts',
      Graduates: 'LeaderZone_PageView_Graduates',
      TopPerformers: 'LeaderZone_PageView_TopPerformers',
      PrizeWinners: 'LeaderZone_PageView_PrizeWinners',
      OverdueCertifications: 'LeaderZone_PageView_OverdueCertifications',
      OverdueCertificationTopicUsers: 'LeaderZone_PageView_OverdueCertificationTopicUsers',
      DueCertifications: 'LeaderZone_PageView_DueCertifications',
      DueCertificationTopicUsers: 'LeaderZone_PageView_DueCertificationTopicUsers',
      DueIntroductoryTraining: 'LeaderZone_PageView_DueIntroductoryTraining',
      DueIntroTrainingSubjectUsers: 'LeaderZone_PageView_DueIntroTrainingSubjectUsers',
      BehaviorImprovements: 'LeaderZone_PageView_BehaviorImprovements',
      BehaviorDetail: 'LeaderZone_PageView_BehaviorDetail',
      TopicPerformance: 'LeaderZone_PageView_TopicPerformance',
      TopicPerformanceTopicsUsers: 'LeaderZone_PageView_TopicPerformanceTopicsUsers',
      BehaviorSummary: 'LeaderZone_PageView_BehaviorSummary',
      BehaviorSummaryUsers: 'LeaderZone_PageView_BehaviorSummaryUsers',
      MilestonesUserProgress: 'LeaderZone_PageView_MilestonesUserProgress',

      // People Pages
      PeopleIndex: 'LeaderZone_PageView_PeopleIndex',
      UserProfile: 'LeaderZone_PageView_UserProfile',
      KnowledgeProfile: 'LeaderZone_PageView_KnowledgeProfile',
      AnswerHistory: 'LeaderZone_PageView_AnswerHistory',
      UserBehaviorProfile: 'LeaderZone_PageView_UserBehaviorProfile',
      UserBehaviorForm: 'LeaderZone_PageView_UserBehaviorForm',
      UserBehaviorDetails: 'LeaderZone_PageView_UserBehaviorDetails',
      MessageHistory: 'LeaderZone_PageView_MessageHistory',

      // Message Pages
      UserMessage: 'LeaderZone_PageView_UserMessage',
      TeamMessage: 'LeaderZone_PageView_TeamMessage',

      // Team Metrics Pages
      TeamMetricsIndex: 'LeaderZone_PageView_TeamMetricsIndex',
      ParticipationTeamStats: 'LeaderZone_PageView_ParticipationTeamStats',
      FrequencyTeamStats: 'LeaderZone_PageView_FrequencyTeamStats',
      CertificationTeamStats: 'LeaderZone_PageView_CertificationTeamStats',
      KnowledgeSubjectStats: 'LeaderZone_PageView_KnowledgeSubjectStats',
      KnowledgeTopicStats: 'LeaderZone_PageView_KnowledgeTopicStats',
      KnowledgeTeamStats: 'LeaderZone_PageView_KnowledgeTeamStats',
      MilestonesTeamStats: 'LeaderZone_PageView_MilestonesTeamStats',

      // Compare Pages
      CompareIndex: 'LeaderZone_PageView_CompareIndex',

      // Behavior Pages
      BehaviorsIndex: 'LeaderZone_PageView_BehaviorsIndex',
      CreateBehaviorForm: 'LeaderZone_PageView_CreateBehaviorForm',
      RecordBehaviorObservations: 'LeaderZone_PageView_RecordBehaviorObservations',

      // Inspection Pages
      InspectionsIndex: 'LeaderZone_PageView_InspectionsIndex',
      CreateInspectionForm: 'LeaderZone_PageView_CreateInspectionForm',
      InspectionFormTopicSummary: 'LeaderZone_PageView_InspectionFormTopicSummary',
      RecordInspectionObservations: 'LeaderZone_PageView_RecordInspectionObservations',

      // Export Pages
      ExportIndex: 'LeaderZone_PageView_ExportIndex',

      // LearningEvent Pages
      LearningEventsIndex: 'LeaderZone_PageView_LearningEventsIndex',
      LearningEventsEnrollUsers: 'LeaderZone_PageView_LearningEventsEnrollUsers',

      // Impacts Pages
      ImpactsIndex: 'LeaderZone_PageView_ImpactsIndex',
      ImpactsMetrics: 'LeaderZone_PageView_ImpactsMetrics',

      // Insights Pages
      InsightsIndex: 'LeaderZone_PageView_InsightsIndex',

      // Manage Training Pages
      ManageTrainingIndex: 'LeaderZone_PageView_ManageTrainingIndex'
    }
  }
};
