// Module
var code = `<div class="js-team-selector team-selector"></div>
<form class="js-subteam-form">
  <div class="include-all-subteams ax-grid ax-grid--align-items-center">
    <div class="ax-grid__col">
      <p class="include-all-subteams__primary-text"><%- t('communitiesManagement.accessSettings.includeSubTeamsSwitchLabel') %></p>
      <p class="ax-font--secondary include-all-subteams__secondary-text"><%- includeSubTeamsDescription %></p>
    </div>
    <div
      class="ax-grid__col--auto-size switch-container"
      data-editor="Switch"
      data-field="includeSubteams"
      data-options="includeSubTeamsOptions">
    </div>
  </div>
</form>
<div class="js-warning-banner"></div>
<div class="js-team-table-region"></div>
`;
// Exports
module.exports = code;