const { Collection } = require('Backbone');
const I18n = require('@common/libs/I18n');
const CommunitiesManagementTableEnum = require('@training/apps/training/enums/CommunitiesManagementTableEnum');
const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');

const EMPTY_HEADER = '';

const tableHeadersMap = {
  [CommunitiesManagementTableEnum.CHANNELS]: [
    'channels.settings.channelCode',
    'channels.settings.channelName',
    'communitiesManagement.status',
    'communitiesManagement.actions'
  ],
  [CommunitiesManagementTableEnum.COMMUNITIES]: [
    'communitiesManagement.communityCode',
    'communitiesManagement.communityName',
    'communitiesManagement.status',
    'communitiesManagement.actions'
  ],
  [CommunitiesManagementTableEnum.GROUP_ACCESS]: [
    'groups.header',
    'communitiesManagement.accessSettings.member',
    'communitiesManagement.accessSettings.creator',
    'communitiesManagement.accessSettings.editor',
    'communitiesManagement.accessSettings.moderator',
    'communitiesManagement.accessSettings.removeGroup'
  ],
  [CommunitiesManagementTableEnum.TEAM_ACCESS]: [
    'communitiesManagement.accessSettings.teamUserTypesHeader',
    'communitiesManagement.accessSettings.member',
    'communitiesManagement.accessSettings.creator',
    'communitiesManagement.accessSettings.editor',
    'communitiesManagement.accessSettings.moderator'
  ],
  [CommunitiesManagementTableEnum.IMPORT_HISTORY]: [
    'communitiesManagement.importHistory.tableHeading.file',
    'communitiesManagement.importHistory.tableHeading.created',
    'communitiesManagement.importHistory.tableHeading.date',
    'communitiesManagement.importHistory.tableHeading.uploadStatus',
    'communitiesManagement.importHistory.tableHeading.uploadResults',
    'communitiesManagement.importHistory.tableHeading.uploadLogs'
  ]
};

const tableHeaderClassesMap = {
  [CommunitiesManagementTableEnum.GROUP_ACCESS]: [
    '',
    '',
    '',
    '',
    '',
    'off-screen'
  ],
  [CommunitiesManagementTableEnum.IMPORT_HISTORY]: [
    '',
    '',
    '',
    '',
    'off-screen',
    'off-screen'
  ]
};

const teamAccessLevelRowData = (model) => {
  return [
    {
      id: -1,
      name: 'communities.layout.content.access.everyone',
      subtext: 'communitiesManagement.accessSettings.teamAccessRows.everyoneSubtext',
      accessLevel: CommunitiesManagementAccessLevelHelpers.toInteger(model.get('memberAccessLevel'))
    },
    {
      name: 'communitiesManagement.accessSettings.teamAccessRows.leadersLabel',
      subtext: 'communitiesManagement.accessSettings.teamAccessRows.leadersSubtext',
      accessLevel: CommunitiesManagementAccessLevelHelpers.toInteger(model.get('leaderAccessLevel'))
    },
    {
      name: 'communitiesManagement.accessSettings.teamAccessRows.managerLabel',
      subtext: 'communitiesManagement.accessSettings.teamAccessRows.managerSubtext',
      accessLevel: CommunitiesManagementAccessLevelHelpers.toInteger(model.get('managerAccessLevel'))
    }
  ];
};

const getTableHeaders = (tableType, isCommsEnabled = false, isTypeChannel = false) => {
  const CommunitiesListHeaders = tableHeadersMap[tableType];
  const isEditorColumnVisible = (tableHeadersMap[tableType].length === 6 && tableType === CommunitiesManagementTableEnum.GROUP_ACCESS)
    || (tableHeadersMap[tableType].length === 5 && tableType === CommunitiesManagementTableEnum.TEAM_ACCESS);

  if (isCommsEnabled && CommunitiesListHeaders.indexOf('communitiesManagement.accessType') < 0) {
    CommunitiesListHeaders.splice(2, 0, 'communitiesManagement.accessType');
  }

  if (isTypeChannel && isEditorColumnVisible) {
    tableHeadersMap[tableType].splice(tableHeadersMap[tableType].indexOf('communitiesManagement.accessSettings.editor'), 1);
    // add empty header as otherwise header is off
    tableHeadersMap[tableType].push('');
  }

  return tableHeadersMap[tableType].map((i18nKey) => {
    switch (i18nKey) {
      case EMPTY_HEADER:
        return EMPTY_HEADER;
      default:
        return I18n.t(i18nKey);
    }
  });
};

const getTableHeaderClasses = (tableType, isCommsEnabled = false, isTypeChannel = false) => {
  const CommunitiesListHeaders = tableHeaderClassesMap[CommunitiesManagementTableEnum.COMMUNITIES];

  if (isCommsEnabled && CommunitiesListHeaders.indexOf('communitiesManagement.accessType') < 0) {
    CommunitiesListHeaders.splice(2, 0, '');
  }

  const headerClasses = tableHeaderClassesMap[tableType];

  if (isTypeChannel && headerClasses.length === 6) {
    headerClasses.splice(3, 1);
  }

  return headerClasses;
};

const getTeamAccessLevelCollection = (model) => {
  return new Collection(teamAccessLevelRowData(model));
};

module.exports = {
  getTableHeaders,
  getTableHeaderClasses,
  getTeamAccessLevelCollection
};
