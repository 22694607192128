import StackedLayoutViewController from '@common/libs/UI/controllers/StackedLayoutViewController';
import { ReactControllerDefinitionFactory } from '@common/modules/react';
import { memoize } from 'lodash';

export const NotificationCenterDefinitionFactory = memoize((closeChat) => {
  return ReactControllerDefinitionFactory({
    component: import('@training/apps/notificationCenter/NotificationCenterButton'),
    props: {
      closeChat
    },
    loadingControllerDefinition: {
      ViewControllerClass: StackedLayoutViewController,
      viewDefinition: {
        tag: 'ul',
        className: 'menu-list menu-list-top'
      },
      childControllers: [
        {
          viewDefinition: {
            template: false
          }
        }
      ]
    }
  });
});
