const {
  Model,
  Collection
} = require('Backbone');

const { LayoutView } = require('Marionette');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const MultiselectKeyboardNavigationHelper = require('@training/apps/training/multiselectFilterableCommunity/MultiselectKeyboardNavigationHelper');

const InsightsFilterableCommunityList = require('@training/apps/training/communitiesInsights/InsightsFilterableCommunityList');
const CommittedCommunitySelectionCollection = require('@common/data/models/CommittedCommunitySelectionCollection');
const CommunityAction = require('@common/data/enums/CommunityAction').default;
const CommunityAccessType = require('@training/apps/training/enums/CommunityAccessType');

const { CommunityItemEmptyView } = require('@common/components/filterableCommunity/CommunityItemView');
const MultiselectCommunityItemView = require('@training/apps/training/multiselectFilterableCommunity/MultiselectCommunityItemView');
const MultiselectCommunityCollectionView = require('@training/apps/training/multiselectFilterableCommunity/MultiselectCommunityCollectionView');
const MultiselectFilterControlView = require('@training/apps/training/multiselectFilterableCommunity/MultiselectFilterControlView');
const MultiselectCommunityActionView = require('@training/apps/training/multiselectFilterableCommunity/MultiselectCommunityActionView');


const ON = true;
const OFF = false;

class MultiselectFilterableCommunityController extends LayoutController {
  initialize(options = {}) {
    ({
      setSelectionsHandler: this._setSelectionsHandler
    } = options);

    this.dropdownState = OFF;

    // The collection of communities that populates the dropdown options
    this.filteredCommunityList = new InsightsFilterableCommunityList();
    // The collection of communities that have been committed as selected after a click of the 'Apply' button
    this.committedSelections = new CommittedCommunitySelectionCollection();
    // The collection of community IDs that have been selected, but 'Apply' has not been clicked to commit them yet
    this.inProgressSelections = new Collection();

    this.fetchDataOptions = new Model({
      p: 0,
      rpp: 10,
      communityAction: CommunityAction.VIEW_REPORTS,
      includeLocationCommunities: options.includeTeamBasedCommunities
    });

    $('body').on('click', this._bodyHandler.bind(this));

    this._switchDropdown = this._switchDropdown.bind(this);
    this.viewDefinition = this.viewDefinition.bind(this);

    this.keyboardNavigationHelper = new MultiselectKeyboardNavigationHelper(
      this.filteredCommunityList,
      this._switchDropdown,
      this._onCommunityClicked.bind(this)
    );

    this.listenTo(this.inProgressSelections, 'reset', () => {
      if (this._isSearching()) {
        this.fetchDataOptions.set('query', '');
      }
    });
    this.listenTo(this.filteredCommunityList, 'sync', this._onCommunitiesSync);
  }

  _getTemplate() {
    return `
      <div class="filterable-community">
      <label><%= t('discover.insights.selectCommunityLabel') %></label>
        <div class="js-filter-controls filter-controls"></div>
        <div class="js-dropdown-menu dropdown-menu dropdown-menu__multiselect hidden">
          <div class="filtered-communities multiselect-communities">
            <div class="js-filtered-communities-list">
          </div>
        </div>
      </div>\
    `;
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'ax-grid ax-grid--no-gutter',
      template: this._getTemplate(),
      regions: {
        filterControls: '.js-filter-controls',
        filteredList: '.js-filtered-communities-list'
      }
    };
  }

  regionControllers() {
    return {
      filterControls: {
        viewDefinition: {
          ViewClass: MultiselectFilterControlView,
          switchDropdown: this._switchDropdown,
          committedSelections: this.committedSelections,
          inProgressSelections: this.inProgressSelections,
          placeholderText: I18n.t('components.communityPicker.heading.allCommunities'),
          keyboardNavigationHandler: this.keyboardNavigationHelper.navHandler,
          updateFilter: this._updateFilter.bind(this)
        }
      },
      filteredList: {
        ViewControllerClass: StackedLayoutViewController,
        childControllers: [
          StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('filtered-communities-list__wrapper'), {
            id: 'js-community-collection-view',
            viewDefinition: {
              ViewClass: MultiselectCommunityCollectionView,
              collection: this.filteredCommunityList,
              comittedSelections: this.committedSelections,
              inProgressSelections: this.inProgressSelections,
              childView: MultiselectCommunityItemView,
              fetchDataOptions: this.fetchDataOptions,
              emptyView: CommunityItemEmptyView,
              childEvents: {
                'community:clicked': this._onCommunityClicked.bind(this)
              },
              delegateEvents: {
                'community:sync': this._onCommunitiesSync
              }
            }
          }),
          StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('filter-actions'), {
            id: 'js-community-action-view',
            viewDefinition: {
              ViewClass: MultiselectCommunityActionView
            },
            delegateEvents: {
              'view:click:apply': this._onApplyClicked.bind(this),
              'view:click:cancel': this._onCancelClicked.bind(this),
              'view:blur:cancel': this._onBlur.bind(this)
            }
          })
        ],
        delegateEvents: {
          'view:attach': () => {
            this.keyboardNavigationHelper.setViewReference(this._getCollectionView());
          }
        }
      }
    };
  }

  _onBlur() {
    _.defer(() => {
      // check if Apply button is focused, otherwise close dropdown
      const isApplyFocused = this._getActionView().isApplyFocused();
      if (!isApplyFocused) {
        this._switchDropdown(OFF);
      }
    });
  }

  // Update whether to display team based communities in the collectionview.
  setIncludeLocationCommunities(includeLocationCommunities) {
    if (includeLocationCommunities) {
      this.fetchDataOptions.unset({ includeLocationCommunities });
      this.fetchDataOptions.set({ p: 0 });
    } else {
      this.fetchDataOptions.set({
        includeLocationCommunities,
        p: 0
      });

      // Filter out team based communities from committed selections
      const groupBasedSelections = this.committedSelections.filter((community) => {
        return community.get('communityAccessType') === CommunityAccessType.GROUP;
      });

      if (groupBasedSelections.length < this.committedSelections.length) {
        window.app.layout.flash.warning(I18n.t('components.communityPicker.selectionsRemoved'));
      }

      this.committedSelections.reset(groupBasedSelections);
      this.inProgressSelections.reset(groupBasedSelections);
    }

    // Update the table data with the new array of selections.
    this._setSelectionsHandler(this.committedSelections);

    this._updateFilter();
    this.filteredCommunityList.reset([], { includeLocationCommunities });
  }

  resetSelections() {
    this.inProgressSelections.reset();
    this.committedSelections.reset();
    this._getCollectionView().checkSelected();
  }

  _onCommunityClicked(itemView, community) {
    const isAlreadySelected = this.inProgressSelections.get(community.get('id')) != null;

    // Toggle the presence of the community ID in the inProgressSelections array when a checkbox is clicked
    if (isAlreadySelected) {
      this.inProgressSelections.remove(community);
    } else {
      this.inProgressSelections.add(community);
    }
  }

  _onApplyClicked() {
    this.committedSelections.set(this.inProgressSelections.models);
    this._setSelectionsHandler(this.committedSelections);
    this._switchDropdown(OFF);
  }

  _onCancelClicked() {
    this._switchDropdown(OFF);
  }

  _onCommunitiesSync() {
    if (!this._isSearching()) {
      const collectionView = this._getCollectionView();

      // Filter out any communities from the fetch that are already committed selections so we can pin them at the top of
      // the dropdown list
      this._filterOutSelections();

      // If the entire page of results fetched was removed because these communities are already selected, we fetch the
      // next page so that new results can be found in the dropdown until there are no unselected communities left
      if (this.filteredCommunityList.length === 0 && !collectionView.noMorePages
        && collectionView != null
        && !collectionView.noMorePages) {
        // Defer so that sync/success logic for current fetch can finish before we fetch the next page
        _.defer(collectionView.fetchNext.bind(collectionView));
        return;
      }
    }

    this._sendSelectionsToTop();
  }

  _getCollectionView() {
    const listRegion = this.findControllerByRegion('filteredList');
    const controller = listRegion != null ? listRegion.findControllerById('js-community-collection-view') : undefined;

    if (controller != null) {
      return controller.getView();
    }

    return undefined;
  }

  _getActionView() {
    const listRegion = this.findControllerByRegion('filteredList');
    const controller = listRegion != null ? listRegion.findControllerById('js-community-action-view') : undefined;

    if (controller != null) {
      return controller.getView();
    }

    return undefined;
  }

  _isSearching() {
    return this.fetchDataOptions.get('query') !== '' && this.fetchDataOptions.get('query') != null;
  }

  _filterOutSelections() {
    this.filteredCommunityList.remove(this.filteredCommunityList.filter((community) => {
      return this.committedSelections.get(community.get('id')) != null;
    }));
  }

  _sendSelectionsToTop() {
    // Shift the committed selections to the top of the dropdown list when a search is not being made
    if (!this._isSearching()) {
      this.filteredCommunityList.unshift(this.committedSelections.models);
    }

    // Ensure our selections are checked
    this._getCollectionView().checkSelected();
  }

  _updateFilter(query = '') {
    if (query) {
      this.fetchDataOptions.set({
        query,
        reset: true
      });
    } else {
      this.fetchDataOptions.set({
        query: '',
        reset: true
      });
    }
  }

  _bodyHandler(e) {
    // - Ignore if dropdown state is collapsed
    // - Prevent closing dropdown during fetch by looking for an ancestor with the class,
    //   since you can click on the blanket or the spinner img.
    if (this.dropdownState === OFF || $(e.target).closest('.spinner-wrapper').length !== 0) {
      return;
    }

    if (!$(e.target).hasClass('js-filter-input')
      && !$(e.target).hasClass('js-trigger-dropdown')
      && $(e.target).closest('.js-dropdown-menu').length === 0) {
      this._switchDropdown(OFF);
    }
  }

  _switchDropdown(state) {
    this.dropdownState = state;

    const $dropdown = $('.js-dropdown-menu');
    $('.js-filter-controls .js-trigger-dropdown').attr('aria-expanded', state === ON);

    if (state === OFF) {
      this.keyboardNavigationHelper.reset();
      this.inProgressSelections.reset(this.committedSelections.models);
      $dropdown.toggleClass('hidden', true);
    } else {
      // Only filter and send to top when the dropdown isn't already open
      if ($dropdown.hasClass('hidden')) {
        this.filteredCommunityList.sort();
        this._filterOutSelections();
        this._sendSelectionsToTop();
        $dropdown.toggleClass('hidden', false);
        this._getCollectionView().$scrollContainer.scrollTop(0);
        return;
      }

      this._getCollectionView().checkSelected();
      $dropdown.toggleClass('hidden', false);
    }
  }

  onBeforeDestroy() {
    $('body').off('click', this._bodyHandler);
  }
}

module.exports = MultiselectFilterableCommunityController;
