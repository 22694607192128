import LocalStorageHelpers from '@common/libs/helpers/app/LocalStorageHelpers';
import UrlHelpers from '@common/libs/helpers/app/UrlHelpers';
import Backbone from 'backbone';

type PersistTimelinePageDataOptions = {
  timelineId: string;
  timelineTab?: string;
  searchQuery?: string;
  userId: number;
};

export function persistTimelinePageData(options: PersistTimelinePageDataOptions, key: 'timelineReferenceItemIds' | 'channelDetailsItemId') {
  const {
    timelineId,
    timelineTab,
    searchQuery = '',
    userId
  } = options;

  // Store the timeline ID and tab in the URL hash as a primary reference, local storage as a backup
  // Search query only goes in the URL
  let url = `${ UrlHelpers.getLocationHash() }?id=${ timelineId }`;
  url += timelineTab != null ? `&tab=${ timelineTab }` : '';

  if (searchQuery !== '') {
    url += `&searchQuery=${ searchQuery }`;
  } else {
    const data = { item: timelineId };
    if (timelineTab != null) {
      Object.assign(data, { tab: timelineTab });
    }

    // Store pageID in local storage, but only if a search wasn't performed
    LocalStorageHelpers.setItem(key, {
      [userId]: data
    });
  }

  Backbone.history.navigate(url, {
    trigger: false,
    replace: true
  });
}
