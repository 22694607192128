const { ItemView } = require('Marionette');

require('@common/libs/behaviors/scrollable/Scrollable');

const MenuControllerDefinitionFactory = require('@common/modules/main/menu/MenuControllerDefinitionFactory');
const MenuPosition = require('@common/modules/main/menu/MenuPosition');
const HeaderContextConfig = require('@common/modules/main/header/HeaderContextConfig');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const OffscreenMenuControllerDefinitionFactory = require('@common/modules/main/menu/offscreenMeasurement/OffscreenMenuControllerDefinitionFactory');
const HeaderItemFactory = require('@common/modules/main/header/HeaderItemFactory');

module.exports = (contextModel) => {
  const overflowMenuItems = MenuContextConfig.getItemsOverflow(contextModel);

  return {
    leftHeaderRegion: () => {
      return HeaderContextConfig.getLeftControllerDefinition(contextModel);
    },
    rightHeaderRegion: () => {
      return HeaderContextConfig.getRightControllerDefinition(contextModel);
    },
    chatHeaderNavButtonRegion: () => {
      return HeaderContextConfig.getChatControllerDefinition(contextModel);
    },
    notificationsCenterButtonRegion: () => {
      return HeaderContextConfig.getNotificationCenterControllerDefinition(contextModel);
    },
    menuRegion: MenuControllerDefinitionFactory(contextModel, MenuPosition.Top),
    offscreenMenuMeasurements: OffscreenMenuControllerDefinitionFactory(contextModel),
    menuOverflow: HeaderItemFactory.createDropdown({
      buttonContentsViewDefinition: {
        ViewClass: ItemView,
        className: 'menu-overflow-wrapper',
        template: `
            <span class="item-icon icon-ellipsis_horizontal"></span>
            <span class="item-text"><%- t('menu.moreButton') %></span>`
      },
      dropdownMenuItems: overflowMenuItems,
      hideDownIndicator: true
    })
  };
};
