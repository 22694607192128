import { createAppStoreConfigurator } from '@common/configs/redux/createAppStoreConfigurator';
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook
} from 'react-redux';

// Types and slice implementations for initial store state
import {
  default as AuthSliceImpl,
  type AuthSlice
} from '@common/modules/auth/AuthSlice';

// Types from common folder
import type { InitiativesApi } from '@admin/apps/common/components/initiatives/InitiativesApiService';
import type { ReassignTrainingApi } from '@common/components/guidedLearning/objectives/reassignTraining/ReassignTrainingApiService';
import type { UnassignPathApi } from '@common/components/guidedLearning/programs/assignedBadge/UnassignPathApiService';
import type { TaskApi } from '@common/components/opsTasks/requests';
import type { MainStatsApi } from '@common/components/stats/MainStatsApiService';
import type { TeamSelectorApi } from '@common/components/TeamSelector/TeamSelectorApi';
import type { GroupsUsageApi } from '@common/data/api/GroupsApiService';
import type { ProfanityFilterApi } from '@common/modules/profanityFilter/ProfanityFilterApi';
import type { SimpleFileApi } from '@common/services/simpleFile/SimpleFileApiService';

const configureAppStore = createAppStoreConfigurator<[
  AuthSlice
], [
  MainStatsApi,
  ProfanityFilterApi,
  SimpleFileApi,
  TeamSelectorApi,
  ReassignTrainingApi,
  UnassignPathApi,
  TaskApi,
  InitiativesApi,
  GroupsUsageApi
]>(AuthSliceImpl);

export const {
  store: commonStore
} = configureAppStore();

export type CommonRootState = ReturnType<typeof commonStore.getState>;
export type CommonStateDispatch = typeof commonStore.dispatch;

export const useCommonStateSelector: TypedUseSelectorHook<CommonRootState> = useSelector;
export const useCommonStateDispatch: () => CommonStateDispatch = useDispatch;

