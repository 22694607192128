import { injectPagedCursorApiEndpoint } from '@common/data/paging';
import type {
  TimelineAllChannelsHeadingResultItem,
  TimelineChannelsResultItem,
  TimelineDividerResultItem,
  TimelineMissingResultItem,
  TimelineNewActivityHeadingResultItem,
  TimelineEmptyChannelsFeedHeaderResultItem
} from '@training/apps/timeline/api';
import { channelsApi } from '@training/apps/timeline/channels/api';

export type ChannelsResponseItem = TimelineChannelsResultItem | TimelineMissingResultItem;
export type ChannelsResultItem = TimelineChannelsResultItem | TimelineDividerResultItem | TimelineNewActivityHeadingResultItem | TimelineAllChannelsHeadingResultItem | TimelineEmptyChannelsFeedHeaderResultItem;

type GetChannelsResponse = {
  results: ChannelsResponseItem[]
  totalSearchResults: number;
};

export const channelsListApi = injectPagedCursorApiEndpoint<ChannelsResultItem, GetChannelsResponse>({
  api: channelsApi,
  endpointName: 'getAllChannelsList',
  tagType: 'channels-ALL',
  endpointDefinition: {
    keepUnusedDataFor: Infinity,
    query: (args) => {
      return {
        apiEndpoint: '/pageCommunities/channels',
        data: {
          ...args,
          referenceItemId: args.referenceItemId == null ? undefined : encodeURIComponent(args.referenceItemId)
        }
      };
    }
  }
});

export const { useGetAllChannelsListResultsApi } = channelsListApi;

export type ChannelsListApi = typeof channelsListApi;

export type UseGetAllChannelsListApiResult = ReturnType<typeof useGetAllChannelsListResultsApi>;
