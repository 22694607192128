const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const TeamFilterView = require('@training/apps/training/communitiesInsights/components/TeamFilterView');
const TabController = require('@common/components/tabbed_layout/TabController');
const CommunityInsightsReportsCollection = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsCollection');
const CurrentInsightsLayoutController = require('@training/apps/training/communitiesInsights/components/CurrentInsightsLayoutController');
const InitialInsightsLayoutController = require('@training/apps/training/communitiesInsights/components/InitialInsightsLayoutController');
const CommunityInsightsReportsTimeFrameEnum = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsTimeFrameEnum');
const MultiselectFilterableCommunityController = require('@training/apps/training/multiselectFilterableCommunity/MultiselectFilterableCommunityController');
const CommunityAction = require('@common/data/enums/CommunityAction').default;
const { getBreadcrumbs } = require('@training/apps/training/communitiesInsights/components/CommunityInsightsBreadcrumbFactory');
const CommunityInsightsHowItWorksView = require('@training/apps/training/communitiesInsights/components/CommunityInsightsHowItWorksView');
const TypeFilterView = require('@training/apps/training/communitiesInsights/components/TypeFilterView');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const EntityPoller = require('@common/libs/EntityPoller');
const FileHelpers = require('@common/libs/file/FileHelpers');
const CommunityInsightsReportsDownload = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsDownload');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');

class CommunityInsightsReportsController extends LayoutController {
  initialize(options) {
    ({
      includeTeamBasedCommunities: this.includeTeamBasedCommunities = false,
      currentTabName: this.currentTabName
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);

    this.isExportInProgress = false;
    this.filtersModel = new Backbone.Model({
      periodLength: CommunityInsightsReportsTimeFrameEnum.LAST7,
      includeTeamBasedCommunities: this.includeTeamBasedCommunities,
      communityIds: []
    });
    this.initialTabCollection = new CommunityInsightsReportsCollection([], {
      datasetEndpoint: 'initial',
      fetchOptions: this.filtersModel.attributes
    });
    this.currentTabCollection = new CommunityInsightsReportsCollection([], {
      datasetEndpoint: 'current',
      fetchOptions: this.filtersModel.attributes
    });

    this.initialTabCollection.getPage(0);
    this.currentTabCollection.getPage(0);

    // The collection of button configs for the time filter on the current tab
    this.timeFilterCollection = new Backbone.Collection([
      {
        value: CommunityInsightsReportsTimeFrameEnum.LAST7,
        title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 7 }),
        ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 7 }),
        isActive: true
      },
      {
        value: CommunityInsightsReportsTimeFrameEnum.LAST30,
        title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 30 }),
        ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 30 })
      },
      {
        value: CommunityInsightsReportsTimeFrameEnum.LAST90,
        title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 90 }),
        ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 90 })
      },
      {
        value: CommunityInsightsReportsTimeFrameEnum.ALL,
        title: I18n.t('discover.insights.allTime'),
        ariaLabel: I18n.t('discover.insights.allTimeAriaLabel')
      }
    ]);

    this.listenTo(this.filtersModel, 'change', this._handleFiltersChanged.bind(this));
    this.listenTo(this.initialTabCollection, 'sync', () => {
      BrowserHelpers.triggerResize(true);
    });

    this._handleTeamBasedCommunityFilterChange = this._handleTeamBasedCommunityFilterChange.bind(this);
    this._handleCommunityFilterChange = this._handleCommunityFilterChange.bind(this);
    this._handleFiltersChanged = this._handleFiltersChanged.bind(this);
    this._handleFilterByTypeViewChange = this._handleFilterByTypeViewChange.bind(this);
    this._handleError = this._handleError.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'community-insights-reports ax-container',
      template: require('@training/apps/training/communitiesInsights/CommunityInsightsReportsTemplate.html'),
      regions: {
        breadCrumbs: '.js-community-insights__breadcrumbs',
        teamBasedFilter: '.js-community-insights__filters',
        howItWorks: '.js-community-insights__how-it-works',
        filterByType: '.js-community-insights__filter-by-type',
        communities: '.js-community-insights__communities',
        communitesModalLaunch: '.js-community-insights__communities-modal-launch',
        tabContainer: '.js-community-insights__tab-container'
      },
      events: {
        'click .community-insights__communities-modal-launch': this.showCommunitiesModal,
        'click .js-community-insights__reset': this._onResetFiltersClicked.bind(this)
      }
    };
  }

  regionControllers() {
    return {
      breadCrumbs: this._getBreadcrumbsDefinition(),
      teamBasedFilter: this._getTeamFilterDefinition(),
      howItWorks: this._getHowItWorksDefinition(),
      filterByType: this._getfilterByTypeDefinition(),
      communities: this._getCommunitiesDefinition(),
      tabContainer: this._getTabAreaDefinition()
    };
  }

  _getBreadcrumbsDefinition() {
    return getBreadcrumbs('', (model) => {
      const target = (() => {
        return typeof model.get('target') == 'function' ? model.get('target')(this.includeTeamBasedCommunities) : model.get('target');
      })();
      Backbone.history.navigate(target, { trigger: true });
    });
  }

  _getTeamFilterDefinition() {
    return {
      viewDefinition: {
        ViewClass: TeamFilterView,
        value: this.includeTeamBasedCommunities
      },
      delegateEvents: {
        'view:changeTeamBasedSelect': this._handleTeamBasedCommunityFilterChange
      }
    };
  }

  _getHowItWorksDefinition() {
    return {
      viewDefinition: {
        ViewClass: CommunityInsightsHowItWorksView
      }
    };
  }

  _getfilterByTypeDefinition() {
    return {
      viewDefinition: {
        ViewClass: TypeFilterView,
        filtersModel: this.filtersModel
      }
    };
  }

  _getCommunitiesDefinition() {
    return {
      ViewControllerClass: MultiselectFilterableCommunityController,
      communityAction: CommunityAction.VIEW_REPORTS,
      includeTeamBasedCommunities: this.includeTeamBasedCommunities,
      setSelectionsHandler: this._handleCommunityFilterChange
    };
  }

  _getTabAreaDefinition() {
    return {
      ViewControllerClass: TabController,
      initialTab: this.currentTabName || 'initial',
      tabConfigs: [
        {
          tab: {
            tabName: 'initial',
            tabText: I18n.t('discover.insights.initial')
          },
          tabContentController: () => {
            return {
              ViewControllerClass: InitialInsightsLayoutController,
              collection: this.initialTabCollection,
              delegateEvents: {
                'request:export': () => {
                  this._requestExport(this.filtersModel);
                },
                'view:before:destroy': () => {
                  EntityPoller.stopPoll(this.insightsReportExport);
                }
              }
            };
          }
        },
        {
          tab: {
            tabName: 'current',
            tabText: I18n.t('discover.insights.current')
          },
          tabContentController: () => {
            return {
              ViewControllerClass: CurrentInsightsLayoutController,
              collection: this.currentTabCollection,
              filtersModel: this.filtersModel,
              timeFilterCollection: this.timeFilterCollection,
              delegateEvents: {
                'request:export': () => {
                  this._requestExport(this.filtersModel);
                },
                'view:before:destroy': () => {
                  EntityPoller.stopPoll(this.insightsReportExport);
                }
              }
            };
          }
        }
      ],
      delegateEvents: {
        'view:show:tab': (view, controller, shownTab) => {
          this.currentTabName = shownTab;
          Backbone.history.navigate(this._buildURL(), {
            trigger: false,
            replace: true
          });
        }
      }
    };
  }

  _onResetFiltersClicked() {
    const defaultFilters = {
      periodLength: CommunityInsightsReportsTimeFrameEnum.LAST7,
      includeTeamBasedCommunities: false,
      communityIds: [],
      pageType: -1
    };

    if (this.filtersModel.get('periodLength') === defaultFilters['periodLength']
        && this.filtersModel.get('includeTeamBasedCommunities') === defaultFilters['includeTeamBasedCommunities']
        && this.filtersModel.get('communityIds').length === 0
        && !this.filtersModel.get('pageType')) {
      return;
    }

    const communityController = this.findControllerByRegion('communities');
    communityController.resetSelections();

    this.findControllerByRegion('filterByType').getView()
      .typeFilter.setValue(-1);
    this.findControllerByRegion('teamBasedFilter').getView()
      .reset();

    const previousActive = this.timeFilterCollection.findWhere({isActive: true});
    if (previousActive) {
      previousActive.set('isActive', false);
    }
    this.timeFilterCollection.findWhere({ value: CommunityInsightsReportsTimeFrameEnum.LAST7 }).set('isActive', true);

    this._resetTeamBasedCommunitiesState(false);

    this.filtersModel.set(defaultFilters);
  }

  _handleTeamBasedCommunityFilterChange(view, controller, includeTeamBasedCommunities) {
    this.filtersModel.set('includeTeamBasedCommunities', includeTeamBasedCommunities);
    this._resetTeamBasedCommunitiesState(includeTeamBasedCommunities);
  }

  _handleCommunityFilterChange(communities) {
    const communityIds = communities.pluck('id');

    if (communityIds.length > 0) {
      this.filtersModel.set('communityIds', communityIds);
    } else {
      this.filtersModel.set('communityIds', []);
    }
  }

  _handleFilterByTypeViewChange(view, controller, type) {
    this.filtersModel.set('pageType', type);
  }

  _handleFiltersChanged(changedModel) {
    if (changedModel.attributes['pageType'] === -1) {
      changedModel.unset('pageType', { silent: true });
    }

    this.initialTabCollection.fetch();
    this.currentTabCollection.fetch();
  }

  _resetTeamBasedCommunitiesState(includeTeamBasedCommunities) {
    this.includeTeamBasedCommunities = includeTeamBasedCommunities;
    this.findControllerByRegion('communities').setIncludeLocationCommunities(includeTeamBasedCommunities);
    Backbone.history.navigate(this._buildURL(), {
      trigger: false,
      replace: true
    });
  }

  _handleError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);

    switch (exception.getErrorCode()) {
      case AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED:
        window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.3017'));
        break;
      case AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY:
        window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.3001'));
        break;
      case AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE:
        window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.2012'));
        break;
      default:
        window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.general'));
        break;
    }

    this.isExportInProgress = false;
  }

  _requestExport(filtersModel) {
    if (this.isExportInProgress) {
      window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.inProgressExport'));
      return;
    }

    this.isExportInProgress = true;

    this.insightsReportExport = new CommunityInsightsReportsDownload({
      query: {
        periodLength: filtersModel.get('periodLength'),
        includeTeamBasedCommunities: filtersModel.get('includeTeamBasedCommunities'),
        communityIds: filtersModel.get('communityIds'),
        pageType: filtersModel.get('pageType')
      }
    });

    this.insightsReportExport.save()
      .done(() => {
        window.app.layout.showSpinner();

        EntityPoller.startPoll(this.insightsReportExport, {
          pollPredicate: (model) => {
            return model.get('status') !== 'success' && model.get('status') !== 'failure';
          },
          successCallback: (model) => {
            if (model.get('status') === 'success') {
              FileHelpers.downloadFileByUUID(model.get('file')['uuid']).then(() => {
                window.app.layout.hideSpinner();
              });
            } else {
              window.app.layout.flash.error(I18n.t('discover.insights.errors.exports.general'));
              window.app.layout.hideSpinner();
            }

            this.isExportInProgress = false;
          },
          errorCallback: (model, xhr) => {
            window.app.layout.hideSpinner();
            this._handleError(xhr);
          },
          timeout: 1000,
          totalTimeout: 120000
        });
      })
      .fail(this._handleError);
  }

  _setFilters(key, value) {
    this.initialTabCollection.setExtraParams(key, value);
    this.currentTabCollection.setExtraParams(key, value);
  }

  _buildURL(currentTab = this.currentTabName, includeTeamBasedCommunities = this.includeTeamBasedCommunities ) {
    return SearchUrlHelper.BASE_SEARCH_HASH + `/communicationsInsights${ includeTeamBasedCommunities ? '-includeTeamBasedCommunities' : '' }/reports/${ currentTab }`;
  }
}

module.exports = CommunityInsightsReportsController;
