const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const PageInsightsView = require('@training/apps/insights/PageInsightsView');
const PageInsightsEngagementController = require('@training/apps/insights/engagement/PageInsightsEngagementController');
const PageInsightsOverviewController = require('@training/apps/insights/overview/PageInsightsOverviewController');
const PageInsightsOverviewModel = require('@training/apps/insights/models/PageInsightsOverviewModel');
const PageInsightsEngagementModel = require('@training/apps/insights/models/PageInsightsEngagementModel');

const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const BreadcrumbCollection = require('@common/data/collections/BreadcrumbCollection');
const PageMetadataModel = require('@common/components/discover/models/PageMetadataModel');
const { parseBreadcrumbsForInsights } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const PageableTableController = require('@common/components/pageableList/PageableTableController');
const PageInsightsSubteamsTableItemView = require('@training/apps/insights/subteams/PageInsightsSubteamsTableItemView');
const PageInsightsSubteamsCollection = require('@training/apps/insights/subteams/PageInsightsSubteamsCollection');

const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class PageInsightsController extends LayoutController {
  initialize(options = {}) {
    ({
      pageId: this.pageId,
      teamId: this.teamId
    } = options);

    this.breadcrumbCollection = new BreadcrumbCollection([]);

    this.pageMetadata = new PageMetadataModel({
      pageId: this.pageId
    });

    const timeZone = TenantPropertyProvider.get().getProperty('timeZone');
    this.overviewModel = new PageInsightsOverviewModel({
      pageId: this.pageId,
      tenantTimeZone: timeZone
    });
    this.engagementModel = new PageInsightsEngagementModel({
      pageId: this.pageId,
      teamId: this.teamId
    });

    this.subteamsList = new PageInsightsSubteamsCollection(
      null,
      {
        pageId: this.pageId,
        teamId: this.teamId
      }
    );

    this.hasRedirectedOnError = false;
  }

  viewDefinition() {
    return {
      ViewClass: PageInsightsView,
      className: 'page-insights'
    };
  }

  regionControllers() {
    return {
      breadcrumbRegion: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: this.breadcrumbCollection,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: false,
          linkLastCrumb: true
        }
      },
      overviewRegion: {
        ViewControllerClass: PageInsightsOverviewController,
        viewDefinition: {
          className: 'page-insights-overview'
        },
        model: this.overviewModel
      },
      engagementRegion: {
        ViewControllerClass: PageInsightsEngagementController,
        pageId: this.pageId,
        teamId: this.teamId,
        viewDefinition: {
          className: 'page-insights-engagement ax-grid ax-grid__col--12'
        },
        model: this.engagementModel,
        reactionsErrorHandler: this._onServerError.bind(this)
      },
      subteamsRegion: {
        id: 'insights-subteams',
        ViewControllerClass: PageableTableController,
        itemViewClass: TableLayoutView,
        rowItemView: PageInsightsSubteamsTableItemView,
        itemViewOptions: {
          pageId: this.pageId
        },
        collection: this.subteamsList,
        headerNames: this.getHeaderNames(),
        headerClasses: this.getHeaderClasses(),
        hideSinglePage: true // indicates that the pagination controls should be hidden if there is only one page.
      }
    };
  }

  onViewRender() {
    this._fetchData();
  }

  getHeaderNames() {
    const headerNames = [
      I18n.t('discover.insights.impressions'),
      I18n.t('discover.insights.views'),
      I18n.t('discover.insights.viewers'),
      I18n.t('discover.insights.viewInsightsByTeam')
    ];
    if (this.teamId === null) {
      headerNames.unshift(I18n.t('discover.insights.teams'));
    } else {
      headerNames.unshift(I18n.t('discover.insights.subteams'));
    }
    return headerNames;
  }

  getHeaderClasses() {
    const headerClasses = [
      '',
      '',
      '',
      '',
      'off-screen'
    ];
    return headerClasses;
  }

  _fetchData() {
    this._fetchCommunityDataForBreadcrumbs();
    this._fetchOverviewModel();
    this._fetchEngagementModel();
    this._fetchSubteamsCollection();
  }

  _fetchCommunityDataForBreadcrumbs() {
    this.pageMetadata.fetch()
      .done(() => {
        if (this.isDestroyed) {
          return;
        }
        parseBreadcrumbsForInsights(this.pageMetadata, this.breadcrumbCollection);
      })
      .fail(this._onServerError.bind(this));
  }

  _fetchOverviewModel() {
    this.overviewModel.fetch().fail(this._onServerError.bind(this));
  }

  _fetchEngagementModel() {
    this.engagementModel.fetch()
      .done(() => {
        if (this.isDestroyed) {
          return;
        }
        const engagementRegion = this.getRegion('engagementRegion');
        // Show the reactions tile once we know reactions are enabled from the API
        if (this.engagementModel.get('reactionsEnabled')) {
          engagementRegion.$el.find('.page-insights-reactions__wrapper').removeClass('hidden');
        } else {
          // Otherwise allow the engagement tile to stretch to full width
          engagementRegion.$el.find('.page-insights-engagement__wrapper').removeClass('ax-grid__col--l-6');
        }
      })
      .fail(this._onServerError.bind(this));
  }

  _fetchSubteamsCollection() {
    this.subteamsList.fetch()
      .done(() => {
        if (this.isDestroyed) {
          return;
        }
        if (this.subteamsList.state.totalRecords > 0) {
          this._showRegion('subteamsRegion');
        } else {
          this._hideRegion('subteamsRegion');
        }
      })
      .fail(this._onServerError.bind(this));
  }

  _onServerError(xhr) {
    xhr.skipGlobalHandler = true;
    if (this.isDestroyed) {
      return;
    }
    const exception = AxonifyExceptionFactory.fromResponse(xhr);

    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      window.app.layout.flash.error(I18n.t('discover.insights.errors.3017'));
      this._navigateBack();
    } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      window.app.layout.flash.error(I18n.t('discover.insights.errors.3001'));
      this._navigateBack();
    } else if (exception.getErrorCode() === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
      window.app.layout.flash.error(I18n.t('discover.access.error.2012'));
      this._navigateBack();
    } else if (exception.getErrorCode() === AxonifyExceptionCode.INPUT_VALIDATION_FAILURE) {
      window.app.layout.flash.error(I18n.t('discover.insights.errors.3075'));
      this._navigateBack();
    } else {
      xhr.skipGlobalHandler = false;
    }
  }

  _hideRegion(regionName) {
    if (regionName != null) {
      this.getRegion(regionName).$el.hide();
      triggerResize(true);
    }
  }

  _showRegion(regionName) {
    if (regionName != null) {
      this.getRegion(regionName).$el.show();
      triggerResize(true);
    }
  }

  _navigateBack() {
    if (!this.hasRedirectedOnError) {
      const url = this.pageMetadata.isChannel()
        ? `#hub/timeline/post/${ this.pageId }`
        : `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.pageId }`;
      Backbone.history.navigate(url, { trigger: true });
    }
    this.hasRedirectedOnError = true;
  }
}

module.exports = PageInsightsController;
