// Module
var code = `<div class="comment-form <%- isGuestOrSuperuser ? 'hidden' : ''%>">
  <div class="comment-form-group">
    <form id="comment-form">
      <textarea data-field="richContent.content" placeholder="<%- t('discover.comments.post.textarea.placeholder') %>"
        class="comment-textarea" rows="3" minrows="3">
      </textarea>
      <div
        class="inappropriate-content-message qa-inappropriate-content-message-article hidden ax-grid ax-grid--no-gutter">
        <span class="icon-exclamation_sign ax-grid__col--auto-size"></span>
        <p><%- t('comms.posts.inappropriateContentMsg') %></p>
      </div>
      <button class="axon-button" type="submit" form="comment-form" value="Submit"><%-
          t('discover.comments.post.button.label') %></button>
    </form>
  </div>
</div>
`;
// Exports
module.exports = code;