import { timelineApi } from '@training/apps/timeline/api/TimelineApiService';

import { sumOmittedValues } from '@common/libs/helpers/types/ObjectHelpers';

interface TimelineUpdateCountsTypes {
  announcement: number;
  poll: number;
  survey: number;
  availablePoints: number;
}

interface ChannelUpdateCountsTypes {
  newMessage: number;
  newChannel: number;
  availablePoints: number;
}

interface TimelineUpdateCountsResponse {
  priorityUpdateCounts: TimelineUpdateCountsTypes;
  nonPriorityUpdateCounts: TimelineUpdateCountsTypes;
  channelUpdateCounts: ChannelUpdateCountsTypes;
}

interface TimelineUpdateCountsTransformedResponse extends TimelineUpdateCountsResponse {
  aggregatePriorityCount: number;
  aggregateChannelCount: number;
  aggregateNonPriorityCount: number;
  hasUpdates: boolean;
}

export const TimelineUpdateCountsTag = 'TimelineUpdateCounts';

const _api = timelineApi.enhanceEndpoints({
  addTagTypes: [TimelineUpdateCountsTag]
});

const sumOmitAvailablePoints = (obj: object) => {
  return sumOmittedValues(obj, ['availablePoints']);
};

export const timelineUpdateApi = _api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getTimelineNotifier: builder.query<TimelineUpdateCountsTransformedResponse, void>({
        providesTags: [TimelineUpdateCountsTag],
        query: () => {
          return {
            apiEndpoint: '/timeline/update'
          };
        },
        transformResponse: (response?: TimelineUpdateCountsResponse): TimelineUpdateCountsTransformedResponse => {
          if (!response) {
            return {
              priorityUpdateCounts: {
                announcement: 0,
                poll: 0,
                survey: 0,
                availablePoints: 0
              },
              aggregatePriorityCount: 0,

              channelUpdateCounts: {
                newMessage: 0,
                newChannel: 0,
                availablePoints: 0
              },
              aggregateChannelCount: 0,

              nonPriorityUpdateCounts: {
                announcement: 0,
                poll: 0,
                survey: 0,
                availablePoints: 0
              },
              aggregateNonPriorityCount: 0,

              hasUpdates: false
            };
          }

          const aggregateNonPriorityCount = sumOmitAvailablePoints(response.nonPriorityUpdateCounts);
          const aggregatePriorityCount = sumOmitAvailablePoints(response.priorityUpdateCounts);
          const aggregateChannelCount = sumOmitAvailablePoints(response.channelUpdateCounts);

          return {
            ...response,
            aggregatePriorityCount,
            aggregateChannelCount,
            aggregateNonPriorityCount,
            hasUpdates: (aggregatePriorityCount > 0 || aggregateChannelCount > 0 || aggregateNonPriorityCount > 0)
          };
        }
      })
    };
  }
});

export type TimelineUpdateCountsApi = typeof timelineUpdateApi;

export const {
  useGetTimelineNotifierQuery,
  useLazyGetTimelineNotifierQuery
} = timelineUpdateApi;
