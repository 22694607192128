const {
  ItemView,
  LayoutView
} = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const $os = require('detectOS');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const PageType = require('@common/components/discover/enums/PageType').default;
const PageStatus = require('@common/components/discover/enums/PageStatus');
const ActionableMenuDropdown = require('@common/components/dropdownButton/ActionableMenuDropdown');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');

const getPageSubmitButtonDefinition = (community = null, page, saveCallback = () => {}, publishAllCallback = () => {}) => {
  const actionConfig = _getActionConfig(community, page, saveCallback, publishAllCallback);
  if (page.getType() === PageType.TRAINING) {
    // we are allowing bundles on Articles, Questions and Links, but not Posts or Training Modules
    actionConfig.publishAllAction = null;
  }

  let mainButtonDefinition;
  if (actionConfig.mainAction == null) {
    // This will happen when creating a new article, and no community has been chosen yet
    mainButtonDefinition = _getDefaultButtonDefinition(saveCallback);
  } else if (actionConfig.secondaryAction != null || actionConfig.publishAllAction != null) {
    mainButtonDefinition = _getMenuDropdownDefinition(actionConfig);
  } else {
    mainButtonDefinition = _getMainButtonDefinition(actionConfig);
  }

  if (!$os.mobile && actionConfig.secondaryAction != null) {
    return _getMainAndSecondaryDefinition(actionConfig, mainButtonDefinition);
  }

  return mainButtonDefinition;
};

const _getMainAndSecondaryDefinition = (actionConfig, mainButtonDefinition) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      className: 'main-with-secondary ax-grid ax-grid--no-gutter',
      ViewClass: LayoutView,
      template: `<div class="qa-page-submit-main-action-region js-main-action-region ax-grid__col--auto-size"></div>
        <div class="qa-secondary-action-region js-secondary-action-region secondary-action-container ax-grid__col--auto-size"></div>
       `,
      regions: {
        mainActionButtonRegion: '.js-main-action-region',
        secondaryActionButtonRegion: '.js-secondary-action-region'
      }
    },
    regionControllers: {
      mainActionButtonRegion: mainButtonDefinition,
      secondaryActionButtonRegion: _getSecondaryButtonDefinition(actionConfig)
    }
  };
};

const _getMenuDropdownDefinition = (actionConfig) => {
  const optionsListConfig = [];

  if (actionConfig.publishAllAction != null) {
    optionsListConfig.push({
      buttonText: actionConfig.publishAllAction.text,
      buttonClass: 'publish-all-action',
      clickCallback: actionConfig.publishAllAction.onClick
    });
  }

  if ($os.mobile && actionConfig.secondaryAction != null) {
    optionsListConfig.push({
      buttonText: actionConfig.secondaryAction.text,
      buttonClass: 'secondary-action',
      clickCallback: actionConfig.secondaryAction.onClick
    });
  }

  return {
    viewDefinition: {
      ViewClass: ActionableMenuDropdown,
      primaryText: actionConfig.mainAction.text,
      primaryOnClick: actionConfig.mainAction.onClick,
      primaryButtonClass: 'ax-button--branded',
      buttonConfig: {
        buttonIcon: 'icon-caret_down',
        buttonClass: 'ax-button--branded inline-block',
        buttonAriaLabel: 'discover.actionText.morePageActions',
        popupAlignment: MenuDropdownPositionEnum.LEFT + MenuDropdownPositionEnum.TOP
      },
      optionsListConfig
    }
  };
};

const _getMainButtonDefinition = (actionConfig) => {
  return _getButtonDefinition(actionConfig.mainAction);
};

const _getSecondaryButtonDefinition = (actionConfig) => {
  return _getButtonDefinition(actionConfig.secondaryAction, true);
};

const _getButtonDefinition = (action, isSecondary) => {
  return {
    viewDefinition: {
      ViewClass: ItemView,
      template: `
        <%= axButton({
          className: 'qa-action-button-${ action.type }',
          label: "${ I18n.t(action.text) }",
          ariaLabel: "${ I18n.t(action.text) }",
          brandedSecondary: ${ isSecondary ? 'true' : 'false' }
        }) %>
      `,
      events: {
        click: action.onClick
      }
    }
  };
};

// this default is shown when no home community has been chosen, eg. when creating a new article
const _getDefaultButtonDefinition = (saveCallback) => {
  return {
    viewDefinition: {
      ViewClass: ItemView,
      template: `
        <%= axButton({
          className: 'qa-action-button-publish js-action-button-publish',
          label: "${ I18n.t('general.publish') }",
          ariaLabel: "${ I18n.t('general.publish') }",
          branded: true
        }) %>
      `,
      events: {
        'click .js-action-button-publish': () => {
          saveCallback();
        }
      }
    }
  };
};

// See https://docs.google.com/spreadsheets/d/19fxwJP5TFd3U5p8OYvbSskdT7Y_B40ero9JRQgz8Kbs/edit#gid=0 for button matrix
// Multi-language Articles: we show "Publish All..." whenever "Publish" is shown
// Secondary action (Save Changes or Send for Review) gets its own button on desktop
const _getActionConfig = (community, page, saveCallback, publishAllCallback) => {
  let mainAction, secondaryAction, publishAllAction;

  if (community != null) {
    // Training articles are only allowed to be published (no review)
    if (page.getType() === PageType.TRAINING) {
      if (community.canPublish() && page.canPublish()) {
        mainAction = _getPublishAction(page, saveCallback);
        publishAllAction = _getPublishAllAction(publishAllCallback);
      }
    } else if (page.getStatus() === PageStatus.REVIEW || page.getStatus() === PageStatus.QUARANTINE) {
      if (community.canPublish() && page.canPublish()) {
        mainAction = _getPublishAction(page, saveCallback);
        publishAllAction = _getPublishAllAction(publishAllCallback);
        secondaryAction = _getSaveAction(page, saveCallback);
      } else if (page.canEdit()) {
        mainAction = _getSaveAction(page, saveCallback);
      }
    } else if (_isCreating(community, page)) {
      // In the create flow, we only need to rely on community publish permission b/c page model is new and empty
      if (community.canPublish()) {
        mainAction = _getPublishAction(page, saveCallback);
        publishAllAction = _getPublishAllAction(publishAllCallback);
        secondaryAction = _getReviewAction(page, community, saveCallback);
      } else {
        mainAction = _getReviewAction(page, community, saveCallback);
      }
    } else if (community.canPublish() && page.canPublish()) {
      mainAction = _getPublishAction(page, saveCallback);
      publishAllAction = _getPublishAllAction(publishAllCallback);
      secondaryAction = _getReviewAction(page, community, saveCallback);
    } else if (page.canEdit()) {
      mainAction = _getReviewAction(page, community, saveCallback);
    }
  }

  return {
    mainAction,
    secondaryAction,
    publishAllAction
  };
};

const _isCreating = (community, page) => {
  return page.getStatus() == null && (community.canAuthor() && !page.canEdit());
};

const _getPublishAction = (page, saveCallback) => {
  return {
    text: 'general.publish',
    type: 'publish',
    onClick: () => {
      page.set('markFact', true);
      saveCallback();
    }
  };
};

const _getPublishAllAction = (publishAllCallback) => {
  return {
    text: 'discover.publishFlow.publishAll',
    type: 'publishAll',
    onClick: () => {
      publishAllCallback();
    }
  };
};

const _getSaveAction = (page, saveCallback) => {
  return {
    text: 'general.saveChanges',
    type: 'saveChanges',
    onClick: () => {
      page.set('markFact', false);
      saveCallback();
    }
  };
};

const _getReviewAction = (page, community, saveCallback) => {
  return {
    text: 'discover.publishFlow.sendForReview',
    type: 'sendForReview',
    onClick: () => {
      const wasAlreadyPublished = page.getStatus() === PageStatus.FACT;
      const canEdit = community.canEdit(page.get('type'));

      if (wasAlreadyPublished || !canEdit) {
        const modalView = new AccessibleModalView({
          id: 'modalview',
          className: 'modal confirm-dialog-view modal--s'
        });

        const confirmationText = wasAlreadyPublished ? I18n.t('discover.publishFlow.memberConfirmPhrase') : I18n.t('discover.publishFlow.reviewConfirmPhrase');

        const modalChildView = new ConfirmDialogView({
          model: page,
          title: I18n.t('discover.publishFlow.sendForReviewConfirm'),
          confirmationText,
          iconClass: 'icon-needsreview blue'
        });

        modalChildView.setButtons(_getReviewModalButtons(modalChildView, page, saveCallback));

        window.app.layout.presentModal(modalView, { closeClick: false });
        modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

        modalView.listenToOnce(modalChildView, 'destroy', () => {
          window.app.layout.dismissModal();
        });

      } else {
        // no modal confirm!
        page.set('markFact', false);
        saveCallback();
      }
    }
  };
};

const _getReviewModalButtons = (modalChildView, page, saveCallback) => {
  return [
    {
      type: 'customText',
      text: I18n.t('discover.publishFlow.continueEditing'),
      className: 'white',
      onClick: () => {
        modalChildView.destroy();
      }
    },
    {
      type: 'customText',
      text: I18n.t('general.send'),
      className: 'blue',
      onClick: () => {
        page.set('markFact', false);
        saveCallback();
        modalChildView.destroy();
      }
    }
  ];
};

module.exports = {
  getPageSubmitButtonDefinition
};
