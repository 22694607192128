const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');
const { ItemView } = require('Marionette');

const ReportsPageIndexLayout = require('@training/apps/search/reports/pages/ReportsPageIndexLayout');
const ReportsTableCollectionView = require('@training/apps/search/reports/pages/dashboard/ReportsTableCollectionView');

const FilterableCommunityController = require('@common/components/filterableCommunity/FilterableCommunityController');
const TimePeriodSelectorView = require('@training/apps/common/TimePeriodSelectorView');
const CommunityAction = require('@common/data/enums/CommunityAction').default;

const TimePeriodList = require('@common/data/TimePeriodList');

const ReportsPageLayoutController = require('@training/apps/search/reports/pages/ReportsPageLayoutController');
const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const { parseBreadcrumbsFromSearchPageState } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const { getAllCommunitiesModel } = require('@common/components/filterableCommunity/ReportsCommunityHelper');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

class TopKeywordsEmptySearchController extends LayoutController {
  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState
    } = options);

    this.filterContext = getAllCommunitiesModel();

    this.timePeriodModel = new Backbone.Model();
    this.timePeriodList = new TimePeriodList();
    this.title = I18n.t('discover.browse.reports.title');
  }

  viewDefinition() {
    return {
      ViewClass: ReportsPageIndexLayout
    };
  }

  regionControllers() {
    this.dashboardSelectedFilter = this.searchPageState.get('filterContext');

    return {
      breadcrumbRegion: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: parseBreadcrumbsFromSearchPageState(this.searchPageState),
          searchPageState: this.searchPageState,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: true
        }
      },
      titleRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'community-title-wrapper',
          template: `
            <a href="${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/reports" class="reports-title">${ this.title }</a>
          `
        }
      },
      time: {
        viewDefinition: {
          ViewClass: TimePeriodSelectorView,
          timePeriodModel: this.timePeriodModel,
          timePeriodList: this.timePeriodList
        }
      },
      community: {
        ViewControllerClass: FilterableCommunityController,
        contextModel: this.filterContext,
        community: this._getCurrentlySelectedCommunity(),
        hasAllCommunitiesOption: true,
        communityAction: CommunityAction.VIEW_REPORTS,
        setCommunityHandler: (model) => {
          this.filterContext.set('community', model);
          this.searchPageState.set('filterContext', this.filterContext);
        }
      },
      topPages: {
        ViewControllerClass: ReportsPageLayoutController,
        pageTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.title',
        tableTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.table.name',
        tableCount: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.table.count',
        collectionClass: ReportsTableCollectionView,
        reportType: 'searchrecords',
        hasResult: false,
        searchPageState: this.searchPageState,
        delegateEvents: {
          'view:attach': (controller) => {
            this.listenTo(this.filterContext, 'change', () => {
              if (this.filterContext.get('community') && this.filterContext.get('community').get('id')) {
                controller._updateTable(this.filterContext, ReportsTableCollectionView);
              }
            });

            this.listenTo(this.timePeriodModel, 'change', () => {
              controller._updateTable(this.timePeriodModel, ReportsTableCollectionView);
            });
          }
        }
      }
    };
  }

  _getCurrentlySelectedCommunity() {
    if (this.dashboardSelectedFilter != null) {
      this.filterContext.set('community', undefined);
      return this.dashboardSelectedFilter.get('community');
    }
    return this.filterContext.get('community');
  }
}

module.exports = TopKeywordsEmptySearchController;
