const I18n = require('@common/libs/I18n');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const NavButtonItemView = require('@common/components/navButton/NavButtonItemView');

class CommunityNavButtonItemView extends NavButtonItemView {
  triggers() {
    return {click: 'item:clicked'};
  }

  attributes() {
    return Object.assign(super.attributes(), {
      'aria-label': `${ this.model.getName() }: ${ this.getMetaData() }`
    });
  }

  getTemplate() {
    return `
      <div class="icon-angle_right row-arrow silver"></div>
      <div class="community-logo-wrapper">
        <div class="community-logo icon icon-folder_open"></div>
      </div>
      <div class="text">
        <div title="<%- name %>" class="name"><%- name %></div>
        <% if (meta) { %>
          <div title="<%- meta %>" class="meta"><%- meta %></div>
        <% } %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      name: this.model.getName(),
      meta: this.getMetaData()
    };
  }

  onShow() {
    const media = this.model.get('thumbnailImage');
    const icon = this.model.get('icon');

    if (media != null) {
      this.ui.logo.removeClass('icon icon-folder_open');
      const imageViewer = ImageViewerFactory.createViewerInstance({
        $el: this.ui.logo,
        media
      });
      imageViewer.render();
    } else if (icon != null) {
      this.ui.logo.removeClass('icon icon-folder_open');
      this.ui.logo.html(`<div class="icon ${ icon }"></div>`);
    } else {
      this.ui.logo.addClass('icon icon-folder_open');
    }
  }

  getMetaData() {
    let articlePhrase = '';
    if (this.model.areArticlesEnabled()) {
      const nonPostCount = this.model.getNonPostCount();
      if (nonPostCount === 0) {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.none');
      } else if (nonPostCount === 1) {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.singular');
      } else {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.multiple', {activePageCount: nonPostCount});
      }
    }

    return articlePhrase;
  }
}

module.exports = CommunityNavButtonItemView;
