import type { AllTimelineResultItem } from '@training/apps/timeline/api';
import type {
  TimelinePageDataType,
  TimelinePageResultItem
} from '@training/apps/timeline/api/TimelineTypes';
import { channelsApi } from '@training/apps/timeline/channels/api';

type ChannelDetailsSearchResponse = {
  results: TimelinePageDataType[]
  totalSearchResults: number;
};

type ChannelDetailsSearchResponseTransformed = {
  results: AllTimelineResultItem[]
  totalSearchResults: number;
};

type ChannelDetailsSearchPayload = {
  query: string;
  communityId?: number;
};

const channelDetailsSearchApi = channelsApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      channelDetailsSearch: builder.query<ChannelDetailsSearchResponseTransformed, ChannelDetailsSearchPayload>({
        keepUnusedDataFor: 0,
        query: ({
          query,
          communityId
        }) => {
          // Search is limited to the first page of a max 25 results
          const data = {
            p: 0,
            rpp: 25,
            query,
            countOnly: false,
            communityId
          };

          return {
            apiEndpoint: `/pages/search`,
            type: 'POST',
            data
          };
        },
        serializeQueryArgs: () => {
          return 'channelDetailsSearch';
        },
        transformResponse: (response: ChannelDetailsSearchResponse) => {
          const transformedResults = response.results.map((item) => {
            const pageItem = item;

            // TODO: If we need to expand on the below, we should explore other solutions. The below response structure should be the the server's responsibility to provide.

            // pages/search endpoint is shared between Discover and Channels. In Channels, we require the below response structure. In Discover, we require the response structure
            // already provided by the server response. To maintain that this API works in both places, it is easier right now to do the below trasnformation on the client.
            // We know that within a channel, we will always expect the response to provide post (page type) results, so the below should be safe.
            return {
              id: `${ pageItem.pageId }-PAGE` as const,
              timelineData: { ...item },
              timelineType: 'PAGE'
            } as TimelinePageResultItem;
          });

          return {
            ...response,
            results: transformedResults
          };
        }
      })
    };
  }
});

export type ChannelDetailsSearchApi = typeof channelDetailsSearchApi;

export const {
  useLazyChannelDetailsSearchQuery
} = channelDetailsSearchApi;
