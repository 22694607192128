const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const I18n = require('@common/libs/I18n');
const logging = require('logging');
const Backbone = require('Backbone');
const _ = require('underscore');
const $os = require('detectOS');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const MultilingualArticleBundledLanguageStatusModel = require('@training/apps/articles/multilingualArticleBundledLanguageChooser/MultilingualArticleBundledLanguageStatusModel');

const Community = require('@common/data/models/Community');
const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  SEARCH,
  TOPIC_DETAILS,
  ARTICLE_DETAILS,
  ARTICLE_ATTACHMENT,
  ARTICLE_EDIT,
  ARTICLE_CREATE,
  ARTICLE_HISTORY,
  SELF_DIRECTED_PATHS
} = require('@common/data/enums/SearchPageEnum');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

const FileFactory = require('@common/data/models/media/FileFactory');
const Page = require('@common/components/discover/models/Page');
const PageFactory = require('@common/components/discover/factories/PageFactory');

const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchPageControllerDefinitionFactory = require('@training/apps/search/SearchPageControllerDefinitionFactory');

const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');

const {
  TopicDetailsModel,
  TeamScoreModel
} = require('@training/apps/topics/TopicDetailsApiModels');
const TopicDetailsPageControllerFactory = require('@training/apps/topics/TopicDetailsPageControllerFactory');

const ArticleDetailsPageControllerFactory = require('@training/apps/articles/ArticleDetailsPageControllerFactory');
const ArticleEditControllerFactory = require('@training/apps/articles/ArticleEditControllerFactory');
const PostDetailsPageControllerFactory = require('@training/apps/articles/PostDetailsPageControllerFactory');

const PdfView = require('@common/components/mediaFilePreview/views/PdfContentShowView');
const PageHistoryControllerFactory = require('@training/apps/articles/PageHistoryControllerFactory');
const PageType = require('@common/components/discover/enums/PageType').default;

const UserMetadata = require('@common/data/models/UserMetadata');
const CommunityList = require('@common/data/collections/CommunityList');

const COMMUNITY_MANAGEMENT_PATHS = [
  '/communityManagement',
  '/communitiesManagement'
];
const INSIGHTS_PATHS = [
  '/insights'
];

const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const FolderType = require('@training/apps/training/enums/FolderType');

class DiscoverController extends FlowController {
  constructor(...args) {
    super(...args);

    this.pageState = {};
    this.initiateTrainingFromTopicSearch = this.parentProcessor.getHubController().initiateTrainingFromTopicSearch;
  }

  processSequenceFlow(options) {
    const isCommunityManagmentPath = COMMUNITY_MANAGEMENT_PATHS.some((path) => {
      return window.location.hash.includes(path);
    });

    const isCommunityInsightsPath = window.location.hash.includes('/communicationsInsights');

    if (isCommunityManagmentPath) {
      return this.parentProcessor.getCommunitiesManagementFlowController().processSequenceFlow(options);
    } else if (isCommunityInsightsPath) {
      return this.parentProcessor.getCommunitiesInsightsFlowController().processSequenceFlow(options);
    }

    if (INSIGHTS_PATHS.some((path) => {
      return window.location.hash.includes(path);
    })) {
      return this.parentProcessor.getInsightsFlowController().processSequenceFlow(options);
    }

    return this.showPage(options).then((handled) => {
      if (handled) {
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  showPage(options = {}) {
    const {
      pageId,
      pageOptions
    } = this._getPageArgs(options);

    if (options.pageId == null) {
      return this._attemptToRestoreDiscoverPage().then(() => {
        return true;
      },
      Promise.resolve.bind(null, false));
    }

    return this._showPageView(pageId, pageOptions).then(() => {
      this._clearPageState();
      return true;
    },
    Promise.resolve.bind(null, false));
  }

  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return Object.assign(options, {
      pageId: this.pageState.pageId,
      pageOptions: _.omit(this.pageState.pageOptions || {}, 'navigate')
    });
  }

  _storePageState(pageId, pageOptions) {
    this.pageState = {
      pageId,
      pageOptions
    };
  }

  _clearPageState() {
    this.pageState = {};
  }

  _showPageView(pageId, pageOptions) {
    switch (pageId) {
      case SEARCH:
        return this.showSearchPage(pageOptions);
      case TOPIC_DETAILS:
        return this.showTopicDetails(pageOptions);
      case ARTICLE_DETAILS:
        // this is the route for Articles, Questions, Posts, Training Modules, and References
        return this.showArticleDetails(pageOptions);
      case ARTICLE_ATTACHMENT:
        return this.showArticleAttachments(pageOptions);
      case ARTICLE_EDIT:
        return this.showEditArticle(pageOptions);
      case ARTICLE_CREATE:
        return this.showCreateArticle(pageOptions);
      case ARTICLE_HISTORY:
        return this.showArticleHistory(pageOptions);
      case SELF_DIRECTED_PATHS:
        return this.showSelfDirectedPaths(pageOptions);
      default:
        return Promise.reject(Promise.OperationalError('No discover flow matched...'));
    }
  }

  _attemptToRestoreDiscoverPage() {
    const hash = UrlHelpers.getLocationHash();

    if (hash.includes('hub/search') || hash.includes('hub/paths')) {
      _.defer(() => {
        Backbone.history.loadUrl(hash);
      });
      return Promise.resolve();
    }
    return Promise.reject(Promise.OperationalError('No discover flow matched...'));

  }

  showTopicDetails(options = {}) {
    const { topicId } = options;

    const topic = new TopicDetailsModel({ id: topicId });
    const teamScoreData = new TeamScoreModel({ id: topicId });

    const startTrainingFn = (action) => {
      this._storePageState(TOPIC_DETAILS, options);
      TopicCTAButtonHelper.handleActionForType(action, AssessmentLaunchContext.TOPIC_DETAILS, this.initiateTrainingFromTopicSearch);
    };

    return Promise.resolve(topic.fetch({
      skipGlobalHandler: true
    }).then(() => {
      teamScoreData.fetch({
        showSpinner: false,
        skipGlobalHandler: true
      });

      window.app.layout.setView(TopicDetailsPageControllerFactory(topic, teamScoreData, startTrainingFn));
    },
    (xhr) => {
      // This is the fail condition - display an error message and navigate to the search page
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.topicDetails.errors.notAvailable', {topicId}));

      return this.showSearchPage({
        searchCategory: SearchCategoryEnum.TOPICS
      });
    }));
  }

  /**
   * Admin users do not get magic redirection to another language, everyone else does.
   * @returns bool
   */
  isAllowedToUseMultingualRedirectionMagic() {
    return !apps.auth.session.user.isAdminUser();
  }

  showArticleDetails(options = {}) {
    const {
      articleId,
      queryParams
    } = options;

    const page = new Page({
      id: articleId
    });

    const fetchOptions = {
      skipGlobalHandler: true
    };

    if (this.isAllowedToUseMultingualRedirectionMagic()) {
      fetchOptions.data = {
        usePreferredLanguagesFallback: true
      };
    }

    fetchOptions.success = (contextArg, modelArg) => {
      // with multilingual articles, it is possible the server will receive a request for one article, but return another
      // in accordance with the user's language preferences. We must detect this and adjust the URL appropriately.
      // notice here that "modelArg" is not a proper Backbone Model. It's just an object.
      if (articleId !== modelArg.id) {
        Backbone.history.navigate(`#hub/search/article/${ modelArg.id }`, {
          trigger: false,
          replace: true
        });
      }

      const pageFactory = new PageFactory();
      const typedPage = pageFactory.getTypedPage(page); // this will be a Model that extends Page

      const viewData = _.pick(queryParams, 'source');
      typedPage.view(viewData).fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        logging.error(exception.getErrorMessage());
        xhr.skipGlobalHandler = true;

        // For now I'm going with a generic error
        window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

        return this.showSearchPage({
          searchCategory: SearchCategoryEnum.ARTICLES
        });
      });

      // TODO: eventually it would be nice to deconvolute the different page types into their own controllers,
      // so that the different Page types can diverge and not be so cyclomatically complex inside. We're
      // doing it with Post now, so someday maybe we'll tease out Questions and TrainingArticles etc
      if (typedPage.get('type') === PageType.POST) {
        window.app.layout.setView(PostDetailsPageControllerFactory(typedPage, window.apps.auth.session.user));
      } else if (this.isAllowedToUseMultingualRedirectionMagic()) {
        // this user may experience magic redirection, thus they can not be shown the fancy language picker, and
        // hence we will not try to fetch the language data (which returns 403 forbidden for non-admins anyways)
        window.app.layout.setView(ArticleDetailsPageControllerFactory(typedPage, window.apps.auth.session.user, null));
      } else {
        // this is a user that is not experiencing magic redirection, which is equivalent to saying they ARE going to
        // be shown the fancy language picker... so we need to fetch the data for that. This is just a convoluted way to
        // verify that the user is an ADMIN type, because otherwise the API would throw a 403 back
        const languageData = new MultilingualArticleBundledLanguageStatusModel({
          bundleId: typedPage.get('bundleId')
        });
        languageData.fetch({
          success: () => {
            window.app.layout.setView(ArticleDetailsPageControllerFactory(typedPage, window.apps.auth.session.user, languageData));
          },
          error: (model, xhr) => {
            this._onLanguageDataFetchError(xhr);
          }
        });
      }
    };

    fetchOptions.error = (xhr) => {
      // This is the fail condition - display an error message and navigate to the search page
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

      return this.showSearchPage({
        searchCategory: SearchCategoryEnum.ARTICLES
      });
    };

    return Promise.resolve(page.fetch(fetchOptions));
  }

  showArticleAttachments(options = {}) {
    const {
      articleId,
      attachmentId
    } = options;

    const page = new Page({
      id: articleId
    });

    return Promise.resolve(page.fetch({
      skipGlobalHandler: true
    }).then(() => {
      const documentJson = _.find( ((page.get('currentVersion') || {}).richContent || {}).media || [], (media) => {
        return media.id === attachmentId;
      });
      if (!documentJson) { // media is invalid.  Possibly deleted
        window.app.layout.flash.error(I18n.t('discover.pageTypes.fetch.error'));
        return;
      }

      const fileFactory = new FileFactory();
      const documentMedia = fileFactory.createMediaFromJSON(documentJson);

      const view = {
        viewDefinition: {
          ViewClass: PdfView,
          model: documentMedia,
          shouldShowPdfjsPrint: !$os.mobile
        },
        delegateEvents: {
          'view:before:attach': () => {
            window.app.layout.toggleFooter(false);
            window.app.layout.toggleFullPage(true);
          },
          'view:before:destroy': () => {
            window.app.layout.toggleFooter(true);
            window.app.layout.toggleFullPage(false);
          },
          'view:show': function (controller, pdfView) {
            ViewHelpers.showBackButtonWithReset({view: pdfView});
          }
        }
      };

      window.app.layout.setView(view);
    },
    (xhr) => {
      // This is the fail condition - display an error message
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // TODO: Make sure this is an appropriate error message
      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', { articleId }));

      return this.showSearchPage({
        searchCategory: SearchCategoryEnum.ARTICLES
      });
    }));
  }

  showEditArticle(options = {}) {
    const { articleId } = options;

    const page = new Page({
      id: articleId
    });

    return Promise.resolve(page.fetch({
      skipGlobalHandler: true
    }).then(() => {
      const pageFactory = new PageFactory();
      const typedPage = pageFactory.getTypedPage(page);

      typedPage.get('community').fetch({
        success: () => {
          const languageData = new MultilingualArticleBundledLanguageStatusModel({
            bundleId: typedPage.get('bundleId')
          });
          languageData.fetch({
            success: () => {
              typedPage.view();
              window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
            },
            error: (model, xhr) => {
              this._onLanguageDataFetchError(xhr);
            }
          });
        },
        error: (model, xhr) => {
          this._onCommunityFetchError(xhr);
        }
      });
    },
    (xhr) => {
      // This is the fail condition - display an error message and navigate to the search page
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

      return this.showSearchPage({
        searchCategory: SearchCategoryEnum.ARTICLES
      });
    }));
  }

  _getEmptyLanguageCollection() {
    const supportedLanguageCodes = TenantPropertyProvider.get().getProperty('languages');
    const languageData = _.mapObject(_.invert(supportedLanguageCodes), () => {
      return null;
    });
    return new MultilingualArticleBundledLanguageStatusModel(languageData);
  }

  fetchPrefilledModelBeforeSettingCreateView(typedPage, existingPage) {
    existingPage.fetch({
      success: () => {
        // there are only certain fields that we will allow to be prefilled
        // NOTE: copying permittedPageActions assumes that these permissions are the same accross all language-variants of an article (this may change)
        _.each(['communities', 'bundleId', 'recommendable', 'community', 'permittedPageActions'], (field) => {
          typedPage.set(field, existingPage.get(field));
        });
        this.fetchLanguagesBeforeSettingCreateView(typedPage);
      },
      error: (model, xhr) => {
        this._onLanguageDataFetchError(xhr);
      }
    });
  }

  fetchLanguagesBeforeSettingCreateView(typedPage) {
    const bundleId = typedPage.get('bundleId');
    if (!bundleId) {
      // this new article is not part of a bundle, so we can set the language data to be a static (sort of)
      // list of supported languages
      const languageData = this._getEmptyLanguageCollection();
      this.fetchCommunityBeforeSettingCreateView(typedPage, languageData);
    } else {
      // this new article is part of a bundle, so we need to fetch the language data for that bundle
      const languageData = new MultilingualArticleBundledLanguageStatusModel({
        bundleId: bundleId
      });
      languageData.fetch({
        success: () => {
          this.fetchCommunityBeforeSettingCreateView(typedPage, languageData);
        },
        error: (model, xhr) => {
          this._onLanguageDataFetchError(xhr);
        }
      });
    }
  }

  fetchCommunityBeforeSettingCreateView(typedPage, languageData) {
    const community = typedPage.get('community');
    if (community != null && community.get('id') != null && community.get('translations') == null) {
      community.fetch({
        success: () => {
          window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
        },
        error: (model, xhr) => {
          this._onCommunityFetchError(xhr);
        }
      });
    } else {
      // when there is no community ID, it is safe to render this view without fetching the community data. The view assumes
      // that the presence of a community ID means the data for it has been fetched
      window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
    }
  }

  showCreateArticle(options = {}) {
    const {
      pageType,
      languageCode
    } = options;
    const queryParams = UrlHelpers.getQueryParams(UrlHelpers.getQueryString(window.location.hash));

    const languageCodeUpper = languageCode ? languageCode.toUpperCase() : null;

    const page = new Page();
    page.set('type', pageType);

    if (queryParams.communityId) {
      page.set('community', new Community({id: queryParams.communityId}));
    }

    if (languageCodeUpper) {
      page.set('language', languageCodeUpper);
    } else {
      const userLanguage = window.apps.auth.session.user.get('language');
      page.set('language', userLanguage);
    }

    const pageFactory = new PageFactory();
    const typedPage = pageFactory.getTypedPage(page);

    typedPage.set('currentVersion', {
      richContent: {
        media: []
      }
    });

    if (queryParams.fromArticle) {
      this.fetchPrefilledModelBeforeSettingCreateView(typedPage, new Page({id: queryParams.fromArticle}));
    } else {
      this.fetchLanguagesBeforeSettingCreateView(typedPage);
    }
  }

  showArticleHistory(options = {}) {
    const { articleId } = options;

    const page = new Page({
      id: articleId
    });

    return Promise.resolve(page.fetch({
      skipGlobalHandler: true
    }).then(() => {
      const pageFactory = new PageFactory();
      const typedPage = pageFactory.getTypedPage(page);

      typedPage.view();

      window.app.layout.setView(PageHistoryControllerFactory(typedPage));
    },
    (xhr) => {
      // This is the fail condition - display an error message and navigate to the search page
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

      return this.showSearchPage({
        searchCategory: SearchCategoryEnum.ARTICLES
      });
    }));
  }

  _handlePathError() {
    // For now I'm going with a generic error
    window.app.layout.flash.error(I18n.t('SelfDirectedPaths.search.notFound'));

    return this.showSearchPage({
      searchCategory: SearchCategoryEnum.PATHS
    });
  }

  _onCommunityFetchError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());

    let error = I18n.t('errors.genericError');
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      error = I18n.t('discover.pageAccess.community.error.3017');
    } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      error = I18n.t('discover.pageAccess.community.error.3001');
    } else if (exception.getErrorCode() === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
      error = I18n.t('discover.access.error.2012');
    }

    window.app.layout.flash.error(error);
    Backbone.history.navigate('#hub/search/type-all/1/', true);
  }

  _onLanguageDataFetchError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());

    // TODO: figure out the errors that could be returned from this and whether we want to handle them individually
    const error = I18n.t('errors.genericError');

    window.app.layout.flash.error(error);
    Backbone.history.navigate('#hub/search/type-all/1/', true);
  }


  showSelfDirectedPaths(options = {}) {
    const {
      pageId,
      pageOptions
    } = options;

    if (pageOptions == null) {
      return this._handlePathError();
    }

    return this.parentProcessor.getSelfDirectedPathsController()
      .showPageFlow({
        pageId,
        pageOptions
      })
      .catch(this._handlePathError);
  }

  showSearchPage(options = {}) {
    let community = null;
    const pageNum = options.pageNum;
    const discoveryZoneEnabled = TenantPropertyProvider.get().getProperty('discoveryZoneEnabled');

    const startTrainingFn = (...args) => {
      this._storePageState(SEARCH, options);
      this.initiateTrainingFromTopicSearch(...args);
    };

    const extraParams = {
      p: !pageNum ? 0 : pageNum - 1
    };
    if (options.folderType && options.folderType !== FolderType.ALL) {
      extraParams.folderType = options.folderType;
    }

    const userMetadata = new UserMetadata();
    const communityList = new CommunityList([], {extraParams});

    const promises = [];
    if (discoveryZoneEnabled) {
      const fetchOptions = {
        error: (model, xhr) => {
          this._onCommunityFetchError(xhr);
        }
      };
      promises.push(communityList.fetch(fetchOptions), userMetadata.fetch(fetchOptions));

      if (options.communityId) {
        community = new Community({ id: options.communityId });
        promises.push(community.fetch(fetchOptions));
      }
    }

    if (!discoveryZoneEnabled) {
      return this._setSearchView(startTrainingFn, null, null, community, pageNum, options);
    }

    Promise.all(promises).then(() => {
      this._setSearchView(startTrainingFn, communityList, userMetadata, community, pageNum, options);
    });

    return Promise.resolve();
  }

  _setSearchView(startTrainingFn, communityList, userMetadata, community, pageNum, options) {
    window.app.layout.setView(SearchPageControllerDefinitionFactory(Object.assign({
      startTrainingFn,
      communityList,
      userMetadata,
      community,
      pageNum
    }, options)));
  }
}

module.exports = DiscoverController;
