const $os = require('detectOS');
const { LayoutView } = require('Marionette');

const TabTrapper = require('@common/libs/behaviors/tabTrapper/TabTrapper');
require('@common/libs/behaviors/androidBackHandler/AndroidBackHandler');

const {
  enableScalingInWebView,
  disableScalingInWebView
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const key = require('keymaster');

require('jquery.velocity');
require('@common/components/mediaZoom/MediaZoom.less');

class MediaZoomLayout extends LayoutView {
  behaviors() {
    return Object.assign(super.behaviors(), {
      TabTrapper: {},
      AndroidBackHandler: {
        handler: '_close'
      }
    });
  }

  className() {
    const {
      isImage,
      isVideo,
      isMediaArray
    } = this.options;
    const cssClassImage = isImage ? 'media-zoom--image' : '';
    const cssClassVideo = isVideo ? 'media-zoom--video' : '';
    const cssClassArray = isMediaArray ? 'media-zoom--array' : '';
    return `media-zoom fit-parent ${ cssClassImage } ${ cssClassVideo } ${ cssClassArray }`;
  }

  attributes() {
    return {
      role: 'dialog'
    };
  }

  regions() {
    return {
      imageRegion: '.media-zoom-region'
    };
  }

  ui() {
    return {
      mediaCloseButton: '.media-close-button',
      prevButton: '.media-nav-prev',
      nextButton: '.media-nav-next',
      mobilePrevButton: '.mobile-nav-prev',
      mobileNextButton: '.mobile-nav-next',
      paginationContainer: '.media-pagination',
      mobilePaginationContainer: '.mobile-pagination',
      mediaZoomRegion: '.media-zoom-region'
    };
  }

  events() {
    const events = {
      'click @ui.mediaCloseButton': 'onClick',
      'click @ui.prevButton': 'onNavButtonClick',
      'click @ui.nextButton': 'onNavButtonClick',
      'click @ui.mobilePrevButton': 'onNavButtonClick',
      'click @ui.mobileNextButton': 'onNavButtonClick',
      'touchstart @ui.mediaZoomRegion': 'onTouchStart',
      'touchmove @ui.mediaZoomRegion': 'onTouchMove',
      'touchend @ui.mediaZoomRegion': 'onTouchEnd'
    };

    if ($os.desktop) {
      events['click'] = 'onClick';
    }

    return events;
  }

  childEvents() {
    return {
      'toggle:zoomable': (childView, isZoomable) => {
        if (isZoomable) {
          enableScalingInWebView();
        } else {
          disableScalingInWebView();
        }
      }
    };
  }

  getTemplate() {
    const { isMediaArray } = this.options;

    return `
      <div class="media-zoom-click-layer fit-parent">
        <div class="desktop-nav-buttons">
          ${ isMediaArray ? '<button class="media-nav-prev clickable" aria-label="<%- t("general.last") %>"><span class="icon-chevron_left"></span></button>' : '' }
          ${ isMediaArray ? '<button class="media-nav-next clickable" aria-label="<%- t("general.next") %>"><span class="icon-chevron_right"></span></button>' : '' }
        </div>
        <div class="media-zoom-vertical-stilt inline-block vertical-align-middle parent-height"></div>
        <div class="media-zoom-wrapper inline-block vertical-align-middle">
          <div class="media-zoom-region"></div>
          ${ isMediaArray ? '<div class="media-pagination"></div>' : '' }
          ${ isMediaArray ? `<div class="mobile-controls-container">
              <button class="mobile-nav-prev clickable" aria-label="<%- t("general.last") %>">
                <span class="icon-chevron_left"></span>
              </button>
              <div class="mobile-pagination"></div>
              <button class="mobile-nav-next clickable" aria-label="<%- t("general.next") %>">
                <span class="icon-chevron_right"></span>
              </button>
              </div>` : '' }
        </div>
        <button class="media-close-button clickable" aria-label="<%- t('general.close') %>">
          <span class="icon-remove"></span>
        </button>
      </div>
    `;
  }

  constructor(...arg) {
    super(...arg);

    this.onEscapeKey = this.onEscapeKey.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.currentIndex = this.options.currentIndex || 0;
    this.mediaArray = this.options.mediaArray || null;
    this.isMediaArray = this.options.isMediaArray || false;

    this.touchStartX = 0;
    this.touchEndX = 0;
    this.isSwiping = false;
    this.minSwipeDistance = 50;

    key('escape', 'MediaZoomLayout', this.onEscapeKey);

    if (this.isMediaArray) {
      key('left', 'MediaZoomLayout', this.onPrevClick);
      key('right', 'MediaZoomLayout', this.onNextClick);
    }

    key.setScope('MediaZoomLayout');
  }

  onAttach() {
    TabTrapper.toggleTrapper(this, true);
    this._fadeIn();

    if (this.isMediaArray) {
      this._updateCounter();
      this._updateNavButtons();
    }
  }

  onEscapeKey() {
    this._close();
    return false;
  }

  onClick(e) {
    // Check if the click was on a navigation button or pagination dot
    if (e && e.target) {
      const isNavigationElement
        = e.target.closest('.media-nav-prev')
        || e.target.closest('.media-nav-next')
        || e.target.closest('.mobile-nav-prev')
        || e.target.closest('.mobile-nav-next');

      if (isNavigationElement) {
        // Don't close the zoom when clicking navigation elements
        e.stopPropagation();
        e.preventDefault();
        return;
      }
    }

    this._close();
  }

  onNavButtonClick(e) {
    // Stop event propagation to prevent the main click handler from closing the zoom
    e.stopPropagation();
    e.preventDefault();

    // Determine which button was clicked and call the appropriate handler
    const isPrevButton
      = e.currentTarget.classList.contains('media-nav-prev')
      || e.currentTarget.classList.contains('mobile-nav-prev');

    if (isPrevButton) {
      this.onPrevClick();
    } else {
      this.onNextClick();
    }
  }

  onPrevClick() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.triggerMethod('media:navigate', {
        media: this.mediaArray[this.currentIndex],
        index: this.currentIndex,
        totalCount: this.mediaArray.length
      });
      this._updateCounter();
      this._updateNavButtons();
    }
  }

  onNextClick() {
    if (this.currentIndex < this.mediaArray.length - 1) {
      this.currentIndex++;
      this.triggerMethod('media:navigate', {
        media: this.mediaArray[this.currentIndex],
        index: this.currentIndex,
        totalCount: this.mediaArray.length
      });
      this._updateCounter();
      this._updateNavButtons();
    }
  }

  _updateCounter() {
    if (!this.isMediaArray || !this.mediaArray) {
      return;
    }

    // Pagination dots
    const dotsHtml = this.mediaArray.map((_, index) => {
      const isActive = index === this.currentIndex;
      const activeClass = isActive ? 'active' : '';
      return `<span class="pagination-dot ${ activeClass }" data-index="${ index }"></span>`;
    }).join('');

    if (this.ui.paginationContainer && this.ui.paginationContainer.length) {
      this.ui.paginationContainer.html(dotsHtml);
    }
    if (this.ui.mobilePaginationContainer && this.ui.mobilePaginationContainer.length) {
      this.ui.mobilePaginationContainer.html(dotsHtml);
    }
  }

  _updateNavButtons() {
    if (!this.isMediaArray || !this.mediaArray) {
      return;
    }

    const isFirstItem = this.currentIndex <= 0;
    const isLastItem = this.currentIndex >= this.mediaArray.length - 1;

    const prevButtons = [this.ui.prevButton, this.ui.mobilePrevButton];
    prevButtons.forEach((button) => {
      if (button && button.length) {
        if (isFirstItem) {
          button.addClass('disabled');
          button.attr('disabled', 'disabled');
        } else {
          button.removeClass('disabled');
          button.removeAttr('disabled');
        }
      }
    });

    const nextButtons = [this.ui.nextButton, this.ui.mobileNextButton];
    nextButtons.forEach((button) => {
      if (button && button.length) {
        if (isLastItem) {
          button.addClass('disabled');
          button.attr('disabled', 'disabled');
        } else {
          button.removeClass('disabled');
          button.removeAttr('disabled');
        }
      }
    });
  }

  _close() {
    this._fadeOut(() => {
      if (this.isDestroyed) {
        return;
      }
      this.destroy();
    });

    if (this.previousActiveElement && this.previousActiveElement.focus) {
      this.previousActiveElement.focus();
    }
  }

  onDestroy() {
    TabTrapper.toggleTrapper(this, false);
    // Prevent pich to zoom and panning
    disableScalingInWebView();
    key.deleteScope('MediaZoomLayout');
  }

  _fadeIn() {
    this.previousActiveElement = document.activeElement;

    this.$el.velocity('stop');
    this.$el.velocity({
      opacity: 1
    }, {
      duration: 300,
      complete: () => {
        this._focusFirstAvailable();
      }
    });
  }

  _fadeOut(complete = $.noop) {
    this.$el.velocity('stop');
    this.$el.velocity({
      opacity: 0
    }, {
      duration: 300,
      complete
    });
  }

  _focusFirstAvailable() {
    this.$el.find(TabTrapper.FOCUSABLE_ELEMENTS_STRING)
      .filter(TabTrapper.VISIBLE_TABBABLE_ELEMENTS_STRING)
      .first()
      .trigger('focus');
  }

  onTouchStart(e) {
    if (!this.isMediaArray || !this.mediaArray || this.mediaArray.length <= 1) {
      return;
    }

    this.touchStartX = e.originalEvent.touches[0].clientX;
    this.touchEndX = this.touchStartX;
    this.isSwiping = true;
  }

  onTouchMove(e) {
    if (!this.isSwiping) {
      return;
    }

    this.touchEndX = e.originalEvent.touches[0].clientX;

    e.preventDefault();
  }

  onTouchEnd() {
    if (!this.isSwiping) {
      return;
    }

    const swipeDistance = this.touchEndX - this.touchStartX;
    const isSignificantSwipe = Math.abs(swipeDistance) > this.minSwipeDistance;

    if (isSignificantSwipe) {
      if (swipeDistance > 0) {
        this.onPrevClick();
      } else {
        this.onNextClick();
      }
    }

    this.isSwiping = false;
  }
}

module.exports = MediaZoomLayout;
