const Marionette = require('Marionette');
const dateHelpers = require('@common/libs/dateHelpers');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');
const {getLanguageDirectionClass} = require('@training/apps/contentTranslations/ContentTranslationHelpers');
const ContentTranslationView = require('@training/apps/contentTranslations/ContentTranslationView');

class ArticleDetailsCommentView extends Marionette.LayoutView {

  getTemplate() {
    return require('@training/apps/articles/ArticleDetailsCommentTemplate.html');
  }

  className() {
    return 'comment-template';
  }

  templateHelpers() {
    return {
      timestamp: dateHelpers.timeFromEvent(this.model.get('createDateMillis')),
      languageDirectionClass: getLanguageDirectionClass(this.model)
    };
  }

  regions() {
    return {
      ellipsisMenuRegion: '.ellipsis-menu-region',
      contentTranslationRegion: '.js-content-translation-region'
    };
  }

  onAttach() {
    const user = {
      name: this.model.get('authorName'),
      id: this.model.get('authorUserId'),
      profileImage: this.model.get('profileImage')
    };

    this._getProfileIcon(user, this.$('.article-comment__avatar'));

    this._setCommentTranslation();
  }

  _setCommentTranslation() {
    const autoTranslatedContent = this.model.get('autoTranslatedContent');

    // when content is deemed untranslatable by the server, or when the translations are not yet available,
    // the API should omit the autoTranslatedContent property and the client should do nothing special
    if (!autoTranslatedContent) {
      return;
    }

    const contentTranslationView = new ContentTranslationView({
      translatedLanguage: autoTranslatedContent.translatedLanguage,
      translatedContent: autoTranslatedContent.translatedContent,
      originalLanguage: autoTranslatedContent.detectedLanguage,
      originalContent: this.model.get('richContent').content,
      $content: this.$('.comment-body p'),
      htmlEncode: true // Comments have no formatting. Server returns it raw (as it should!) so content must be encoded
    });

    this.contentTranslationRegion.show(contentTranslationView);
  }

  _getProfileIcon(user, $profileElement) {
    return new ProfileIconHandler({
      user,
      profileElement: $profileElement
    });
  }
}

module.exports = ArticleDetailsCommentView;
