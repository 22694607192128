const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const MediaZoomLayout = require('@common/components/mediaZoom/MediaZoomLayout');
const ImageRegionController = require('@common/components/mediaZoom/MediaZoomImageRegionController');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const MediaTypesEnum = require('@common/components/mediaPreview/MediaTypesEnum').default;

require('@common/libs/behaviors/resizable/Resizable');

module.exports = (media, maxDimensions = {}, navigationOptions = {}) => {
  // Make sure the model is in the correct MediaModel container to satify MediaPreview's Interface
  const mediaModel = MediaFactory.createMediaFromValue(FileFactory, media, {
    documentsAllowed: true
  });

  const {
    isMediaArray = false,
    mediaArray = null,
    initialIndex = 0
  } = navigationOptions;

  const processedMediaArray = isMediaArray && mediaArray
    ? mediaArray.map((item) => {
      return MediaFactory.createMediaFromValue(FileFactory, item, { documentsAllowed: true })
    })
    : null;

  const viewControllerFactory = new ViewControllerFactory();

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: MediaZoomLayout,
      behaviors: {
        Resizable: {}
      },
      isImage: mediaModel.get('type') === MediaTypesEnum.IMAGE,
      isVideo: mediaModel.get('type') === MediaTypesEnum.VIDEO,
      isMediaArray,
      mediaArray: processedMediaArray,
      currentIndex: initialIndex
    },
    regionControllers: {
      imageRegion: {
        ViewControllerClass: ImageRegionController,
        media: mediaModel,
        maxWidth: maxDimensions.maxWidth,
        maxHeight: maxDimensions.maxHeight
      }
    },
    delegateEvents: {
      'view:resize': (controller, view) => {
        controller.getView().getRegion('imageRegion').currentView
          .setMaxDimensions(view.getWidth(), view.getHeight());
      },
      'view:media:navigate': (controller, view, navigationData) => {
        if (!navigationData || !navigationData.media) {
          return;
        }

        // Empty the region first to trigger proper cleanup
        const imageRegion = controller.getView().getRegion('imageRegion');
        imageRegion.empty();

        // Create a new instance of the ImageRegionController to show the new media
        const imageRegionController = viewControllerFactory.create(ImageRegionController, {
          media: navigationData.media,
          maxWidth: maxDimensions.maxWidth,
          maxHeight: maxDimensions.maxHeight
        });

        imageRegionController.show(imageRegion);
      }
    }
  };
};
