import { createLazyInjectedApi } from '@common/configs/redux';
import { ajaxBaseQuery } from '@common/libs/ajax/AjaxBaseQuery';
export const RESULTS_PER_PAGE = 12;

export const POST_COMMENTS_TAG = 'POST_COMMENTS';
export const NOTIFICATION_TAGS = {
  NOTIFICATIONS: 'Notifications',
  UNREAD_STATUS: 'UnreadStatus'
};

export const channelsApi = createLazyInjectedApi({
  reducerPath: 'channelsApi',
  baseQuery: ajaxBaseQuery(),
  tagTypes: [POST_COMMENTS_TAG, 'post', NOTIFICATION_TAGS.NOTIFICATIONS, NOTIFICATION_TAGS.UNREAD_STATUS],
  keepUnusedDataFor: 5 * 60,
  endpoints: () => {
    return {};
  }
});

export type ChannelsApiService = typeof channelsApi;
