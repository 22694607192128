const ActionableMenuDropdown = require('@common/components/dropdownButton/ActionableMenuDropdown');
const ArticleDetailsMetadataView = require('@training/apps/articles/ArticleDetailsMetadataView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const MultilingualArticleBundledLanguageChooserController = require('@training/apps/articles/multilingualArticleBundledLanguageChooser/MultilingualArticleBundledLanguageChooserController');
const PageType = require('@common/components/discover/enums/PageType').default;
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const {
  onDeletePage,
  onDeleteLanguageVersion
} = require('@training/apps/articles/PageDeleteHelper');

class ArticleDetailsMetadataRegionController extends LayoutController {
  initialize(options = {}) {
    ({
      model: this.model,
      languageData: this.languageData,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList,
      reportEnabled: this.reportEnabled,
      editList: this.editList,
      authorAndContributorInfoEnabled: this.authorAndContributorInfoEnabled,
      enableDownload: this.enableDownload
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  shouldShowBundledLanguageChooser() {
    return this.isAdmin() && !this.isTrainingModule();
  }

  isTrainingModule() {
    return this.model.get('type') === PageType.TRAINING;
  }

  isAdmin() {
    return apps.auth.session.user.isAdminUser();
  }

  canPublish() {
    return !this.model || ( this.model.canPublish() && this.model.get('community').canPublish());
  }

  regionControllers() {
    const controllers = {};

    if (this.shouldShowBundledLanguageChooser()) {
      controllers.languageChooserRegion = {
        ViewControllerClass: MultilingualArticleBundledLanguageChooserController,
        languageData: this.languageData,
        articleModel: this.model,
        editMode: false
      };

      if (this.model.canDelete()) {
        controllers.deleteActionRegion = {
          viewDefinition: {
            ViewClass: ActionableMenuDropdown,
            primaryText: 'general.delete',
            primaryOnClick: () => {
              onDeleteLanguageVersion(this.model, this.languageData);
            },
            primaryButtonClass: 'qa-delete-action ax-button--destructive',
            buttonConfig: {
              buttonIcon: 'icon-caret_down',
              buttonClass: 'ax-button--destructive inline-block',
              buttonAriaLabel: 'discover.actionText.morePageActions',
              popupAlignment: MenuDropdownPositionEnum.LEFT + MenuDropdownPositionEnum.TOP
            },
            optionsListConfig: [
              {
                buttonText: 'discover.publishFlow.deleteAll',
                buttonSelectorClass: 'secondary-action',
                buttonClass: 'qa-delete-all-action secondary-action',
                clickCallback: () => {
                  onDeletePage(this.languageData);
                }
              }
            ]
          }
        };
      }
    }

    return controllers;
  }

  viewDefinition() {
    return {
      ViewClass: ArticleDetailsMetadataView,
      model: this.model,
      languageData: this.languageData,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList,
      reportEnabled: this.reportEnabled,
      editList: this.editList,
      authorAndContributorInfoEnabled: this.authorAndContributorInfoEnabled,
      enableDownload: this.enableDownload
    };
  }
}

module.exports = ArticleDetailsMetadataRegionController;
