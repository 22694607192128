import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type MediaTypeEnum = EnumType & {
  VIDEO: string,
  EMBEDDED_VIDEO: string,
  IMAGE: string
};

const MediaType = Enum({
  VIDEO: 'video',
  EMBEDDED_VIDEO: 'embeddedVideo',
  IMAGE: 'image'
}) as MediaTypeEnum;

export default MediaType;
