import type User from '@common/data/types/User';
import { channelsApi } from '@training/apps/timeline/channels/api';

type GetUsersSearchRequest = {
  channelCommunityId: number;
  p?: number;
  rpp?: number;
  search: string | null;
};

export type ChannelUser = Pick<User, 'id' | 'profileImage' | 'salutationName' | 'location' | 'jobTitle'>;

const userMentionApi = channelsApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getUsersSearch: builder.query<ChannelUser[], GetUsersSearchRequest>({
        query: ({
          channelCommunityId,
          p = 0,
          rpp = 100,
          search
        }) => {
          return {
            apiEndpoint: `/timeline/channel/${ channelCommunityId }/users/search`,
            data: {
              p,
              rpp,
              search
            }
          };
        }
      })
    };
  }
});

export const { useGetUsersSearchQuery } = userMentionApi;

export type UserMentionApi = typeof userMentionApi;
