const $os = require('detectOS');
const _ = require('underscore');
const CookieUtil = require('CookieUtil');
const env = require('env');
const ErrorHandler = require('ErrorHandler');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');

const {
  sendLog,
  sendErrorLog
} = require('LoggingService');
const {
  guard
} = require('DecaffeinateHelpers');
const logging = require('logging');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');
const MfaHelpers = require('@common/libs/helpers/app/MfaHelpers');

//
// AuthApp takes care of session management and authentication.
//

require('bluebird');

const Backbone = require('Backbone');

const Router = require('./router');
const Session = require('./models/Session');
const MobileDevice = require('@common/data/models/MobileDevice');
const I18n = require('@common/libs/I18n');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const AuthHelpers = require('@common/libs/helpers/app/AuthHelpers');
const AuthTypesEnum = require('@common/data/enums/AuthTypesEnum');

const UserCredentialsRepoFactory = require('@common/modules/auth/libs/UserCredentialsRepoFactory');
const UriTokenAuthenticationStrategy = require('@common/modules/auth/strategies/UriTokenAuthenticationStrategy');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const UserAgreementList = require('@common/data/collections/UserAgreementList');
const PendingUserAgreementChecker = require('@common/modules/auth/views/agreement/PendingUserAgreementChecker');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const MsTeamsHelpers = require('@common/libs/helpers/app/MsTeamsHelpers');
const OAuthConfigTypeEnum = require('@common/data/enums/OAuthConfigTypeEnum');

const AT_WORK_CHECK_REQUEST_TIMEOUT = 5000; //10s

class App {

  constructor(options = {}) {
    this.uriTokenLogin = this.uriTokenLogin.bind(this);
    this.loginWithOAuth = this.loginWithOAuth.bind(this);
    this.login = this.login.bind(this);
    this.reauthenticate = this.reauthenticate.bind(this);
    this.logout = this.logout.bind(this);
    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
    this.redirectToAuthPageFrag = this.redirectToAuthPageFrag.bind(this);
    this.redirectToIndexPage = this.redirectToIndexPage.bind(this);
    this.setPushData = this.setPushData.bind(this);
    this.onSessionChange = this.onSessionChange.bind(this);
    this.checkAtWorkRequirements = this.checkAtWorkRequirements.bind(this);

    this._userCredentialsRepoFactory = new UserCredentialsRepoFactory();

    this.session = new Session();
    this.session.on('change', this.onSessionChange);

    this._routeDispatcher = options.routeDispatcher;

    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
    const nbRequest = this.nbChannel.reqres.request.bind(this.nbChannel.reqres);
    const nbExecute = this.nbChannel.commands.execute.bind(this.nbChannel.commands);

    // The `supportedFeatures` query parameter might get passed in by the native
    // wrapper. We extract it and put it in local storage because the redirect
    // to `auth.html` strips the query parameters.
    const searchQueryParams = UrlHelpers.getQueryParams(window.location.search || '');
    const hashQueryParams = UrlHelpers.getQueryParams(window.location.hash || '');
    const {supportedFeatures} = $.extend(true, {}, searchQueryParams, hashQueryParams);
    if (supportedFeatures && LocalStorageHelpers.supportsLocalStorage()) {
      localStorage.setItem('supportedFeatures', supportedFeatures);
      nbExecute('setSupportedFeatures');
      if (nbRequest('isMsTeamsIntegration')) {
        sessionStorage.setItem('supportedFeatures', supportedFeatures);
        localStorage.removeItem('supportedFeatures');
      }

    }
  }

  createRouter() {
    this.router = new Router({
      nbChannel: this.nbChannel
    });

    this.router.on('all', (route) => {
      return logging.debug(`Navigated to auth route: \`${ route }\``);
    });
  }

  redirect(hash, params = {}, options = {
    replace: true,
    trigger: true
  }) {
    let redirectHash = hash;

    const redirectParams = _.defaults(params, UrlHelpers.getQueryParams(window.location.hash));

    if (!$.isEmptyObject(redirectParams)) {
      redirectHash += `?${ $.param(redirectParams) }`;
    }

    // Reset the hash to make sure the redirect will fire.
    this._routeDispatcher.navigate('#!');
    this._routeDispatcher.navigate(redirectHash, options);
  }

  // Remove query parameters and redirect
  removeQueryParamsAndRedirect(hash, options) {
    this.removeQueryParams(hash);
    this.redirect(hash, options);
  }

  removeQueryParams(hash) {
    let url = window.location.href.replace(/\?.*$/, '');
    url += hash;
    UrlHelpers.replaceUrl(url);
  }

  checkSession() {
    const model = this.session;
    return Promise.resolve(this.session.fetch())
      .then(() => {
        const searchQueryParams = UrlHelpers.getQueryParams(window.location.search);
        const hashQueryParams = UrlHelpers.getQueryParams(window.location.hash);
        const queryParams = _.extend({}, searchQueryParams, hashQueryParams);
        const secureTokenUrlLoginData = this._getUriTokenData(queryParams);
        const hasSecureTokenLoginData = !_.isEmpty(secureTokenUrlLoginData);

        if (!CookieUtil.isEnabled()) {
          this.redirectToLoginPage(queryParams);
          return undefined;
        }

        const isUserLoggedIn = (model.user != null ? model.user.id : undefined);
        const noSecureTokenLoginRequested = (!queryParams.authtoken || (model.user.get('employeeId') === queryParams.employeeid));

        LocalStorageHelpers.setItem('channelDetailsItemId', {});
        LocalStorageHelpers.setItem('timelineReferenceItemIds', {});
        LocalStorageHelpers.setItem('resetTimeline', false);

        if (isUserLoggedIn && noSecureTokenLoginRequested) {
          logging.debug(`User ID ${ model.user.id }`);
          // We need to handle "super user" accounts and prevent them from using
          // the training app when Discover is disabled and bounce them to the admin window.app.
          const isDiscoverEnabled = TenantPropertyProvider.get().getProperty('discoveryZoneEnabled');
          if (!model.user.hasTrainingAppAccess() || (model.user.isSuperuser() && !isDiscoverEnabled)) {
            const adminUrl = guard(apps.base.getAdminLinkOptions(), (x) => {
              return x.url;
            });
            const teamlinkUrl = window.apps.base.getTeamlinkHref();

            // Try admin url first, falls back to teamlink if mobile
            window.location = adminUrl || teamlinkUrl;

            throw new RedirectingAbortedAuthentication.Error('Redirecting to another app; no LearnerZone or Discover access.');
          }

          if (CookieUtil.get('isSAMLLogin')) {
            this.generateAndStoreReauthTokenAsync()
              .finally(() => {
                CookieUtil.unset('isSAMLLogin');
              });
          }

          if (hasSecureTokenLoginData) {
            UrlHelpers.clearQueryParams(['employeeid', 'authtoken', 'timestamp', 'skip-training']);
            this.redirectToIndexPage();
            return undefined;
          }

          if ((/#activate-account/).test(window.location.hash)) {
            this.redirect('#activate-account', queryParams);
          } else if ((/#user-agreements/).test(window.location.hash) && this.session.hasPendingAggreements()) {
            this.redirect('#user-agreements', queryParams);
          } else if ((/#hub\/search/).test(window.location.hash) && model.user.isGuestOrSuperuser()) {
            this.redirect(window.location.hash, queryParams);
          } else {
            this.redirectToIndexPage();
          }
          return undefined;
        }

        // if url has loginError hash, then an error occured
        // and let the user know about it
        if ((/#loginError/).test(window.location.hash)) {
          this.redirect('#loginError');
          return undefined;
        } else if ((/#resetpassword/).test(window.location.hash)) {
          this.redirect('#resetpassword');
          return undefined;
        }

        // Try to reauthenticate, if it fails the error callback will execute.
        // Pass `Promise.reject` as the `error` callback so that we can use
        // promise chaining, and on success recursively check session to
        // re-establish the auth session flow.
        return Promise.resolve(this.reauthenticate({
          error: _.bind(Promise.reject, this, new Error())
        }))
          .then(() => {
          // If reauthenticate succeeds then check the auth session again to
          // re-establish the flow.
            return this.checkSession();
          }, (result = {}) => {
            // Unsetting 'isSAMLLogin' if user is not logged in
            CookieUtil.unset('isSAMLLogin');
            // `errorCode` corresponds to a login error and a redirect is required
            if (result.errorCode) {
              this.redirectToAuthPageFrag('#loginError', {errorCode: result.errorCode});
              return undefined;
            }

            // Execute immediately if they're going to self registration anyway
            if ((/#selfreg/).test(window.location.hash)) {
              this.redirect('#selfreg');
              return undefined;
            }

            // Before anything, check to see if there is a possibility that there is a URI token login to execute
            // since you can use that in most modes
            if (hasSecureTokenLoginData) {
              return this.uriTokenLogin(secureTokenUrlLoginData);
            }

            const params = _.extend({redirectTo: window.location.href}, _.pick(queryParams, 'alt-login'));
            const authenticationSchemes = model.tenant.authenticationSchemes;

            const {
              Local,
              SAML,
              SecureUrl,
              SelfServe,
              OAuth
            } = AuthTypesEnum;
            const defaultLoginPageSchemes = [Local, SelfServe, SAML].map((authType) => {
              return authType.serverId;
            });

            logging.debug(`Loading login page for authenticationSchemes: ${ authenticationSchemes }`);

            if (_.intersection(authenticationSchemes, defaultLoginPageSchemes).length > 0) {
              this.redirectToLoginPage(params);
            } else if (_.isEqual(authenticationSchemes, [OAuth.serverId])
          || (authenticationSchemes.includes(SecureUrl.serverId) && !hasSecureTokenLoginData)) {
              this.redirectToLoginPage(queryParams);
            } else {
              throw new Error(`Did you forget to implement the authenticationSchemes of ${ authenticationSchemes }?`);
            }
            return undefined;
          });
      }, (xhr) => {
        logging.error(`auth App::checkSession error. xhr.status = ${ xhr.status }, xhr.statusText = ${ xhr.statusText }`);
      })
      .then(() => {
        // On The Clock for tenant Compass
        return this.checkOnTheClockRequirements();
      })
      .then(() => {
        return this.checkAtWorkRequirements();
      })
      .then(() => {
        LocalStorageHelpers.setLocalStorageUserId(this.session.user.id);
        return this.checkPendingUserAgreements();
      });
  }

  uriTokenLogin(tokenData = {}) {
    logging.debug('auth app - uriTokenLogin');
    const strategy = new UriTokenAuthenticationStrategy();
    return strategy.login(tokenData).then((user = {}) => {
      return this.generateAndStoreReauthTokenAsync()
        .finally(() => {
          // Log the user in
          this.session.user.clear({silent: true});
          this.session.user.set(user);

          // TODO change this to use `redirectToIndexPage` - for now I'll use
          // `removeQueryParamsAndRedirect` because it does a url replace and
          // the secure url token info is in the `search` portion of the URL
          // instead of the hash so this just works
          this.removeQueryParamsAndRedirect(window.location.hash);
        });
    }, (xhr = {}) => {
      logging.debug(`securl Login Error, status ${ xhr.status }`);
      const launchURL = TenantPropertyProvider.get().getProperty('launchURL');
      if (launchURL) {
        UrlHelpers.replaceUrl(launchURL);
      } else {
        if (xhr.status === 401) {
          this.redirectToAuthPageFrag('#loginError', {errorCode: xhr.status});
          return undefined;
        }
        this.redirectToLoginPage();
      }
      return undefined;
    });
  }

  _getUriTokenData(queryParams = {}) {
    return _.pick(queryParams, 'employeeid', 'authtoken', 'timestamp', 'skip-training');
  }

  loginWithOAuth(providerInfo = {}, loginUri) {
    const deferred = $.Deferred();
    const oauth = Object.assign(providerInfo, {
      lastFragment: Backbone.history.fragment,
      app: env.settings.app
    });
    if (!oauth.providerName) {
      logging.error(`No OAuth provider given, providerName = \`${ oauth.providerName }\``);
      deferred.reject();
      return deferred.promise();
    }
    logging.info(`Logging in with OAuth, provider = '${ oauth.providerName }'`);


    if (this.nbChannel.reqres.request('isInApp')
       && AuthHelpers.doesOAuthExist(oauth.providerName) && !AuthHelpers.isOAuthWebviewCompatible(oauth.providerName)) {

      const supportAutoSignIn = this.nbChannel.reqres.request('supportAutoSignIn');
      if (supportAutoSignIn) {
        oauth.refresh_token = true;
        // Store the OAuth credentials because Android will reload to training and
        // the legacy re-auth flow will take over to sign the user in and migrate
        // the credentials to the new re-auth token.
        if ($os.android) {
          this._userCredentialsRepoFactory.getRepo().storeCredentials({oauth: {
            app: oauth.app,
            providerName: oauth.providerName,
            refresh_token: oauth.refresh_token
          }});
        }
      }

      return this.nbChannel.reqres.request('oauthLogin', {oauth}).then((authState = {}) => {
        let providerToken = authState.accessToken;
        if (providerInfo.providerName !== 'Google'
        && AuthHelpers.getOAuthType(providerInfo.providerName) === OAuthConfigTypeEnum.OPENID_CONNECT.type) {
          providerToken = authState.idToken;
        }
        return this.updateOAuthToken(oauth, providerToken).then(() => {
          return this.generateAndStoreReauthTokenAsync().finally(() => {
            this.redirectToIndexPage();
          });
        }, (errorCode) => {
          if (errorCode != null) {
            this.redirectToAuthPageFrag('#loginError', { errorCode });
          }
        });
      }, (authState = {}) => {
        const {
          error,
          exception
        } = authState;
        const message = `Failed to login with OAuth native: \nerror = \`${ JSON.stringify(error) }\`, \nexception = \`${ exception }\``;

        sendErrorLog({ message });

        if (supportAutoSignIn) {
          this._userCredentialsRepoFactory.getRepo().clearCredentials();
        }

        // In iOS if the user cancels the request to sign in with OAuth the app
        // rejects the promise with an error. That error is a string message
        // which conatins the error code `-3` and can be in different languages:
        // https://github.com/openid/AppAuth-iOS/blob/65ef95c6b38c7889fdda7a22f242fa0156fd43b2/Source/OIDError.h#L103
        if ($os.ios && (error || '').includes('-3')) {
          // For iOS we can just ignore the error message
          return {
            error: null,
            exception
          };
        }
        return authState;
      });

    }
    this.saveDeepLinkHashInCookie();
    AuthHelpers.setOpenIdConnectInCookie(oauth.providerName);
    const url = `${ loginUri }?app=${ oauth.app }`;
    // Using window.location.assign so users can navigate back from openid login page to Axonify login page
    window.location.assign(url);

    return deferred.promise();
  }

  mfaVerify(params = {}, options = {}) {
    return MfaHelpers.mfaVerify(params, {
      error: options.error,

      complete: options.complete,

      success: options.success,

      processParse: (data) => {
        const parsedData = this.session.parse(data);
        this.session.set(parsedData);
      },

      generateAndStoreReauthTokenAsyncCb: () => {
        return this.generateAndStoreReauthTokenAsync();
      },

      displayError: (msg) => {
        options.error(app.layout.flash.error(msg));
      },

      clearCredentialsCb: (xhr) => {
        if (AuthHelpers.shouldClearCredentials(xhr)) {
          this._userCredentialsRepoFactory.getRepo().clearCredentials();
        }
      },

      redirectCb: (url) => {
        this.redirect(url);
      },

      redirectToIndexCb: () => {
        this.redirectToIndexPage();
      },

      redirectToLoginCb: () => {
        this.redirectToLoginPage();
      },

      showSuperUserManagementPageCb: (superManagementAppUrl) => {
        this.showSuperUserManagementPageCb(superManagementAppUrl);
      }
    });
  }

  login(user = {}, options = {}) {
    const { username } = user;
    const { password } = user;

    const attrs = {
      user: username,
      passwd: password,
      app: env.settings.app
    };

    return $.ajax({
      type: 'POST',
      url: '/axonify/login',
      data: JSON.stringify(attrs),
      skipGlobalHandler: true
    })
      .then((data, status, xhr) => {
        return this.generateAndStoreReauthTokenAsync()
          .finally(() => {
            const exception = AxonifyExceptionFactory.fromResponse(xhr);
            const errCode = exception.getErrorCode();

            const parsedData = this.session.parse(data);

            // Set the parsed response data to the session model so that it's still
            // accessible with subsequent `session.get()` calls
            this.session.set(parsedData);


            if (errCode === AxonifyExceptionCode.CLIENT_ERROR_EXPIRED_PASSWORD) {
              this.redirect('#activate-account');
            } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_SUPERUSER_PASSWORD_EXPIRED) {
              const {superManagementAppUrl} = exception.getResponse();
              this.showSuperUserManagementPage(superManagementAppUrl);
            } else if (_.isFunction(options.success)) {
              options.success();
            } else {
              this.redirectToIndexPage();
            }
          });
      }, (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const mfaEnabled = xhr.status === 401 && exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_MFA_AUTHENTICATION_REQUIRED;
        if (mfaEnabled && _.isFunction(options.error)) {
          options.error({mfaEnabled});
          return;
        }

        logging.error(`auth App::login error. xhr.status = ${ xhr.status }, xhr.statusText = ${ xhr.statusText }`);

        switch (xhr.status) {
          case 400: case 401: case 302:
            if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_SUSPENDED_ACCOUNT) {
              window.app.layout.flash.error(I18n.t('flash.userSuspended'));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_LOCKED_ACCOUNT) {
              window.app.layout.flash.error(I18n.t('flash.lockedOut'));
            } else {
              window.app.layout.flash.error(I18n.t('flash.invalidLogin'));
            }
            break;
          default:
            window.app.layout.flash.error(I18n.t('flash.serviceDown'));
        }

        if (AuthHelpers.shouldClearCredentials(xhr)) {
          this._userCredentialsRepoFactory.getRepo().clearCredentials();
        }

        if (_.isFunction(options.error)) {
          options.error();
        } else {
          this.redirectToLoginPage();
        }
      })
      .always(() => {
        if (_.isFunction(options.complete)) {
          options.complete();
        }
      });
  }

  generateReauthTokenAsync() {
    return new Promise((resolve, reject) => {
      logging.info(`training generateReauthTokenAsync: this.session.serverTimeMillis = ${ this.session.serverTimeMillis }`);
      logging.info(`training generateReauthTokenAsync: TenantPropertyProvider.get().getProperty('mobileReauthEnabled') = ${ TenantPropertyProvider.get().getProperty('mobileReauthEnabled') }`);
      logging.info(`training generateReauthTokenAsync: this.nbChannel.reqres.request('supportAutoSignIn') = ${ this.nbChannel.reqres.request('supportAutoSignIn') }`);
      if (TenantPropertyProvider.get().getProperty('mobileReauthEnabled')
          && this.nbChannel.reqres.request('supportAutoSignIn')) {
        logging.info('Generating re-auth token');
        $.ajax({
          type: 'POST',
          url: '/axonify/reauth/token',
          success: (data = {}) => {
            resolve(data.token);
          },
          error: reject
        });
      } else {
        reject(new Error('mobile reauth token is not supported'));
      }
    });
  }

  generateAndStoreReauthTokenAsync() {
    return this.generateReauthTokenAsync()
      .then((reauthToken) => {
        this._userCredentialsRepoFactory.getRepo().storeCredentials({reauthToken});
      })
      .catch((error) => {
        logging.info(error);
      });
  }

  checkOnTheClockRequirements() {
    const isUserLoggedIn = this.session.user?.id;
    if (isUserLoggedIn && TenantPropertyProvider.get().getProperty('onTheClockCheckAtLogin')) {
      return $.ajax({
        apiEndpoint: '/onTheClock',
        dataType: 'text',
        shouldRetry: false
      }).fail(() => {
        logging.error('(Compass) On The Clock check failed');

        NativeBridgeHelpers.appHasLoaded();
        ErrorHandler.handleError({
          errorDetailsMessage: I18n.t('errors.auth.3141'),
          buttonText: I18n.t('general.continue'),
          showDetails: false,
          shouldShowErrorPage: true
        });

        this._logout();
      })
    }

    return Promise.resolve();
  }

  checkAtWorkRequirements(method = 'GET') {
    const propertyPool = TenantPropertyProvider.get();

    if (AuthHelpers.shouldCheckCorporateNetworkAccess(this.session, this.session.user, propertyPool)) {
      const atWorkCheckUrl = propertyPool.getProperty('atWorkCheckUrl') || '';
      const request = new XMLHttpRequest();
      request.timeout = AT_WORK_CHECK_REQUEST_TIMEOUT;
      const endpoint = UrlHelpers.appendQueryParams(atWorkCheckUrl, {
        _: new Date().getTime() // cache buster
      });

      return new Promise((resolve, reject) => {
        request.open(method, endpoint);
        request.onreadystatechange = function(data) {
          if (request.readyState !== 4) {
            return;
          }
          if (request.status >= 200 && request.status < 400 ) {
            resolve(data);
          } else {
            reject(new Error(`At work check failure: ${ request.status } - ${ request.statusText }`));
          }
        };

        request.onerror = function(err) {
          reject(err);
        };

        request.send();
      })
        .catch((error) => {
          if (method === 'GET') {
            return this.checkAtWorkRequirements('HEAD')
              .then(() => {
                sendLog({
                  logData: {
                    logMessage: 'AtWorkCheck GET failure -> HEAD success',
                    logs: false
                  }
                });
              });
          }

          return this._logout()
            .then((result) => {
              let redirectUrl = CookieUtil.get('redirectURL');

              this._userCredentialsRepoFactory.getRepo().clearCredentials();
              // This is a verification on logout to clear the local storage credentials
              // for users who have logged in with the new app but also have credentials
              // stored in local storage which was the way we did it in the old app
              if (this.nbChannel.reqres.request('isInApp') && this.nbChannel.reqres.request('canStoreUserCredentials')) {
                this._userCredentialsRepoFactory.getRepo('LocalStorage').clearCredentials();
              }

              const errorData = {
                errorCode: AxonifyExceptionCode.AUTHENTICATION_ERROR_AT_WORK_VIOLATION,
                loginPrompt: false
              };

              // redirect url cookie set at login when using ?alt-login
              if (redirectUrl.length === 0) {
                // Added specific redirect url param for SAML sessions
                if (result.samlRedirectUrl) {
                  redirectUrl = result.samlRedirectUrl;

                  // Use the redirect url in the case where the user logs out of a secureUrl session
                } else if (!this.nbChannel.reqres.request('isInApp') && result.redirectURL) {
                  redirectUrl = result.redirectURL;
                }
              }

              if (redirectUrl.length > 0) {
                errorData.redirectURL = redirectUrl;
              }

              logging.debug(`Logging out due to at work check failure, redirecting to: '${ redirectUrl }'`);

              this.redirectToAuthPageFrag('#loginError', errorData);

              throw error;
            });
        });
    }

    return Promise.resolve();
  }

  checkPendingUserAgreements() {
    const pendingAgreements = new UserAgreementList();
    const agreementChecker = new PendingUserAgreementChecker(this.session, pendingAgreements);
    return agreementChecker.check().then((result) => {
      const {hasAgreements} = result;
      if (hasAgreements) {
        this.redirectToAuthPageFrag('#user-agreements', {});
      }
    });
  }

  showSuperUserManagementPage(superManagementUrl) {
    const wnd = window.open(superManagementUrl);

    if (!wnd) {
      logging.info('The user was unable to see the super user page since something blocked the page from opening. Letting the user know this.');
      window.app.layout.flash.error(I18n.t('flash.super_popup_blocked'));
    }

    logging.info('The user has been notified of their expired super user password');
  }

  reauthenticate(options = {}) {
    const reauthError = (msg) => {
      if (msg) {
        logging.error(msg);
      }

      if (_.isFunction(options.error)) {
        return options.error();
      }

      return this.redirectToLoginPage({redirectTo: window.location.href});
    };

    // Hotfix: issue with a user who is trying to sign in with secure URL but also has a reauth token
    // Secure URL should always trump any other login so we can check in this case if `nativeAuthRequired` and reject
    if (this.nbChannel.reqres.request('nativeAuthRequired')) {
      return reauthError();
    }

    return Promise.resolve(this._userCredentialsRepoFactory.getRepo().retrieveCredentials())
      .then((credentials) => {
        return this.reauthenticateWithCredentials(credentials, options, reauthError);
      }, () => {
        logging.debug('failed to retrieve credentials');

        if (this.nbChannel.reqres.request('isInApp') && this.nbChannel.reqres.request('canStoreUserCredentials')) {
          logging.debug('credentials were not found in native app, checking local storage...');

          return this._userCredentialsRepoFactory.getRepo('LocalStorage').retrieveCredentials()
            .then((credentials) => {
              this._userCredentialsRepoFactory.getRepo('LocalStorage').clearCredentials();

              // If the old credentials (from LocalStorage) are for an OAuth provider
              // which we authenticate with using the native mobile app code we want
              // to just clear these credentials and fail to reauthenticate.
              if ((guard(credentials.oauth != null ? credentials.oauth.providerName : undefined, (x) => {
                return x.length;
              }) > 0)
               && AuthHelpers.doesOAuthExist(credentials.oauth?.providerName) && !AuthHelpers.isOAuthWebviewCompatible(credentials.oauth?.providerName)) {
                logging.debug(`we can't reauthenticate with '${ credentials.oauth.providerName }' since it is handled by native now`);
                return reauthError();
              }

              logging.debug('credentials found in local storage, trying to reauthenticate');

              return this.reauthenticateWithCredentials(credentials, options, reauthError).done(() => {
                logging.debug('reauthenticated with local storage credentials');
                this._userCredentialsRepoFactory.getRepo().storeCredentials(credentials);
              });
            }, () => {
              logging.debug('credentials not found in local storage, run reauthError');
              return reauthError();
            });
        }

        logging.debug('user is not in mobile, run reauthError');
        return reauthError();
      })
      .then(() => {
        // On The Clock for tenant Compass
        return this.checkOnTheClockRequirements();
      })
      .then(() => {
        return this.checkAtWorkRequirements();
      });
  }

  reauthenticateWithCredentials(credentials = {}, options = {}, reauthError = $.noop) {
    const {reauthToken} = credentials;

    if (reauthToken) {
      return this.reauthenticateWithReauthTokenAsync(reauthToken)
        .then((resp = {}) => {
          this.session.user.set(resp.user);

          if (_.isFunction(options.success)) {
            options.success();
          }
        }, reauthError);
    }
    return this.legacyReauthenticateWithCredentials(credentials, options, reauthError)
      .then(() => {
        // Generate a reauth token and replace the legacy credentials with it.
        return this.generateAndStoreReauthTokenAsync();
      });

  }

  legacyReauthenticateWithCredentials(credentials, options, reauthError) {
    const {
      username,
      password,
      oauth
    } = credentials;

    if (oauth) {
      if ((oauth.providerName != null ? oauth.providerName : '').length > 0) {
        if (this.nbChannel.reqres.request('isInApp')
           && AuthHelpers.doesOAuthExist(oauth.providerName) && !AuthHelpers.isOAuthWebviewCompatible(oauth.providerName)) {
          return this.reauthenticateOAuthNative(oauth, options);
        }
        return this.reauthenticateOAuth(oauth, options);

      }
      return reauthError('Reauthenticate OAuth error: OAuth data is not available.');

    } else if (username && password) {
      logging.debug('Reauthenticating login');

      // Auto signin
      return this.login({
        username,
        password
      }, options);
    }
    return reauthError('Reauthenticate login error: user data is not available.');

  }

  reauthenticateWithReauthTokenAsync(reAuthToken) {
    return new Promise((resolve, reject) => {
      logging.info(`training reauthenticateWithReauthTokenAsync: this.session.serverTimeMillis = ${ this.session.serverTimeMillis }`);
      logging.info(`training reauthenticateWithReauthTokenAsync: TenantPropertyProvider.get().getProperty('mobileReauthEnabled') = ${ TenantPropertyProvider.get().getProperty('mobileReauthEnabled') }`);
      logging.info(`training reauthenticateWithReauthTokenAsync: this.nbChannel.reqres.request('supportAutoSignIn') = ${ this.nbChannel.reqres.request('supportAutoSignIn') }`);
      if (TenantPropertyProvider.get().getProperty('mobileReauthEnabled')
          && this.nbChannel.reqres.request('supportAutoSignIn')) {
        logging.info('Reauthenticate with re-auth token');
        $.ajax({
          type: 'POST',
          url: '/axonify/reauth',
          data: JSON.stringify({reAuthToken}),
          success: resolve,
          error: (xhr) => {
            if (AuthHelpers.shouldClearCredentials(xhr)) {
              this._userCredentialsRepoFactory.getRepo().clearCredentials();
            }
            reject(xhr);
          }
        });
      } else {
        reject(new Error('mobile reauth token is not supported'));
      }
    });
  }

  reauthenticateOAuthNative(oauth = {}, options = {}) {
    logging.debug('Reauthenticating OAuth Native');

    const {
      error: onError = () => {},
      success: onSuccess = () => {}
    } = options;

    return this.nbChannel.reqres.request('reauthenticateOAuth')
      .then((authState = {}) => {
        let providerToken = authState.accessToken;
        if (oauth.providerName !== 'Google'
        && AuthHelpers.getOAuthType(oauth.providerName) === OAuthConfigTypeEnum.OPENID_CONNECT.type) {
          providerToken = authState.idToken;
        }
        return this.updateOAuthToken(oauth, providerToken)
          .then(() => {
            onSuccess();
          }, (errorCode) => {
            if (errorCode != null) {
              return {errorCode};
            }

            onError();
            return undefined;
          });
      }, (authState = {}) => {
        const {
          error,
          exception
        } = authState;
        const message = `Failed to reauthenticate OAuth native: \nerror = \`${ JSON.stringify(error) }\`, \nexception = \`${ exception }\``;

        if (AuthHelpers.shouldSendOAuthLogAsInfo(error, exception)) {
          sendLog({
            logData: {
              logMessage: 'message',
              logs: false
            }
          });
        } else {
          sendErrorLog({message});
        }

        if (this.nbChannel.reqres.request('supportAutoSignIn')) {
          this._userCredentialsRepoFactory.getRepo().clearCredentials();
        }

        onError();
      });
  }

  updateOAuthToken(providerInfo, providerToken) {
    const deferred = $.Deferred();

    if ((providerInfo.providerName == null) || (providerToken == null)) {
      logging.debug(`Can't updateOAuthToken, not enough data: providerName = \`${ providerInfo.providerName }\`, Token = \`${ providerToken }\``);
      deferred.reject();
      return deferred.promise();
    }
    let providerURL = `/axonify/oauth/${ providerInfo.providerName }/login/${ providerToken }`;
    let providerMethod = 'GET';
    let providerPayload = {
      app: env.settings.app
    };
    if (providerInfo.providerName !== 'Google'
    && AuthHelpers.getOAuthType(providerInfo.providerName) === OAuthConfigTypeEnum.OPENID_CONNECT.type) {
      providerURL = `/axonify/oauth/${ providerInfo.providerName }/login`;
      providerMethod = 'POST';
      providerPayload = {
        app: env.settings.app,
        idToken: providerToken
      }
    }
    $.ajax({
      type: providerMethod,
      url: providerURL,
      data: providerPayload,
      skipGlobalHandler: true,
      success() {
        logging.info('Successfully updated the OAuth token');
        deferred.resolve();
      },
      error: (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        logging.error('Error updating the OAuth token');

        if (this.nbChannel.reqres.request('supportAutoSignIn')
            && AuthHelpers.shouldClearCredentials(xhr)
            && exception.getErrorCode() !== AxonifyExceptionCode.SERVER_ERROR_IP_FORBIDDEN) {
          // In this case, we do not want to clear the credentials
          this._userCredentialsRepoFactory.getRepo().clearCredentials();
        }

        deferred.reject(exception.getErrorCode());
      }
    });

    return deferred.promise();
  }

  reauthenticateOAuth(oauth = {}, options = {}) {
    logging.debug('Reauthenticating OAuth');

    const {
      error: onError = () => {},
      success: onSuccess = () => {}
    } = options;

    return $.ajax({
      type: 'GET',
      url: `/axonify/oauth/${ oauth.providerName }`,
      data: {
        app: env.settings.app
      },
      skipGlobalHandler: true,
      success: (resp = {}) => {
        this.session.user.set(resp.user);
        logging.info('Reauthenticate OAuth success');
        onSuccess();
      },
      error: (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const {
          redirectUrl
        } = exception.getResponse();

        // Code 3047 means the user needs to redirect to the OAuth provider,
        // likely to grant permissions (if they were revoked or expired) and the
        // `redirectURL` is the URL we need to redirect to.
        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_OAUTH_REFRESH_TOKEN_FAILED && redirectUrl) {
          logging.info(`Reauthenticate OAuth errCode \`${ exception.getErrorCode() }\`, redirecting the user to \`${ redirectUrl }\``);
          UrlHelpers.replaceUrl(redirectUrl);
        } else {
          logging.error('Reauthenticate OAuth error');

          if (this.nbChannel.reqres.request('supportAutoSignIn') && AuthHelpers.shouldClearCredentials(xhr)) {
            this._userCredentialsRepoFactory.getRepo().clearCredentials();
          }

          onError();
        }
      }
    });
  }

  logout(params = {}) {
    const onLoggedOut = (result) => {
      const redirectUrl = CookieUtil.get('redirectURL');
      this._userCredentialsRepoFactory.getRepo().clearCredentials();
      // This is a verification on logout to clear the local storage credentials
      // for users who have logged in with the new app but also have credentials
      // stored in local storage which was the way we did it in the old app
      if (this.nbChannel.reqres.request('isInApp')
         && this.nbChannel.reqres.request('canStoreUserCredentials')) {
        this._userCredentialsRepoFactory.getRepo('LocalStorage').clearCredentials();
      }

      // redirect url cookie set at login when using ?alt-login
      if (redirectUrl.length) {
        window.location.replace(redirectUrl);

      // Added specific redirect url param for SAML sessions
      } else if (result.samlRedirectUrl) {
        window.location.replace(result.samlRedirectUrl);

      // Use the redirect url in the case where the user logs out of a secureUrl session
      } else if (!this.nbChannel.reqres.request('isInApp') && result.redirectURL) {
        window.location.replace(result.redirectURL);
      } else {
        RedirectingAbortedAuthentication.catchAndLog(() => {
          this.redirectToLoginPage( _.extend(params, { loginPrompt: false }) );
        }, () => {
          logging.info('The user is logged out. They are now being force reloaded into the next auth page');
        })
          .catch((e) => {
            logging.error(e);
            logging.error('There was a problem when redirecting the user to the login page.');
          });
      }
    };

    return this._logout({
      success(result) {
        logging.debug('Successfully signed out');
        onLoggedOut(result);
      },
      error(result) {
        logging.error('Error signing out');

        if (result.status === 401 || result.status === 0) {
          result.skipGlobalHandler = true;

          const exception = AxonifyExceptionFactory.fromResponse(result);

          onLoggedOut(exception.getResponse());
        }
      }
    });
  }

  _logout(options) {
    return Promise.resolve($.ajax(Object.assign({
      type: 'POST',
      url: '/axonify/logout',
      contentType: 'application/x-www-form-urlencoded'
    }, options)));
  }

  unregisterDeviceAndLogout(params = {}) {
    if ( (this.session && this.session.user && this.session.user.id) && this.nbChannel.reqres.request('isInApp') ) {
      this.session.user.unregisterCurrentDevice(() => {
        this.logout(params);
      });
    } else {
      this.logout(params);
    }
  }

  redirectToLoginPage(params = {}) {
    if (MsTeamsHelpers.tryRedirectToMsTeamsContentUrl()) {
      //This if statement allows us to reload the page to go through the auth flow again rather than logout
      //This specific case happens when your computer 'Sleeps' and it comes back
      return;
    }
    if (this.nbChannel.reqres.request('nativeAuthRequired')) {
      this.nbChannel.commands.execute('authenticationRequired');
      return;
    }

    this.redirectToAuthPageFrag('#login', params);
  }

  redirectToAuthPageFrag(frag = '', params = {}) {
    // need to put this condition in here because we only want to redirect to the
    // `login` page if they're already at `auth.html` - otherwise just redirect
    // to `auth.html` (without the hash because history.start is passed silent:
    // true), navigating to `auth.html` will trigger the cycle again and will
    // just send them to `login` the second time around.
    let queryParams;
    let pageFrag = frag;
    if ((/auth\.html$/).test(window.location.pathname)) {
      // grab any existing query parameters from the URL
      let url;
      if (window.location.search) {
        _.defaults(params, UrlHelpers.getQueryParams(window.location.search));
        // constructing the url from location.origin + location.pathname because
        // we want to strip the query parameters and hash if there are any
        url = UrlHelpers.getLocationOrigin() + window.location.pathname;
      }

      if (url) {
        // create and add query params if any are present
        if (!$.isEmptyObject(params)) {
          queryParams = $.param(params);
          pageFrag += `?${ queryParams }`;
        }

        // url is defined only if there were query parameters removed (above) so
        // we want to do a location.assign to navigate to the new url
        url += pageFrag;
        UrlHelpers.replaceUrl(url);
      } else {
        this.redirect(pageFrag, params);
      }
    } else {
      // create and add query params if any are present ie. SAML
      if (!$.isEmptyObject(params)) {
        queryParams = $.param(params);
        pageFrag += `?${ queryParams }`;
      }

      window.location.assign(`/training/auth.html${ pageFrag }`);

      throw new RedirectingAbortedAuthentication.Error('Failed to redirect to page directly since URL was not auth.html; forcing redirect');
    }
  }

  redirectToIndexPage(params = {}, options = {}) {
    // this is the opposite of the `redirectToLoginPage` condition, we want to
    // redirect the user to `/training/` (and let nginx figure out index/mobile)
    // if they're on `auth.html` - otherwise we route them to the `index` hash
    // and pass in any optional query params

    _.defaults(options, {reload: false});

    // This is here since we want to delibrately forward assessment link hashes. If you are wondering why we don't
    // just forward everything, read on...
    // The #index here is used in order to trigger `SessionController::loadIndex` which bootstraps the entire sequence
    // flow process. If you don't use this front door, there's a couple possibiltiies:
    //
    // 1. The first is that the route you forwarded exists, such as #tasks or something. This would just trigger the handler
    // and do the logic. This is maybe desired, but maybe not. For example, if you did it for "Tasks" you could get the task UI.
    // The problem with this, of course, is the the task route handler may not provide a sane way of going back to the "main flow"
    // since it was started from some arbitary hash.
    // This might not sound so bad but today, those hashes get overriden so any old links might suddently start having different, confusing,
    // potentially even broken behaviour.
    //
    // 2. The handler does not exist. The user would get a blank page. We could add a wildcard handler but you don't really solve problem #1 and a wildcard handler
    // has others problems as well that are not worth getting into unless #1 could be effectively solved.
    //
    // However, the assessmentLink has both a route and defined behaviour. And it's brand new, so there are no links in the wild to change behaviour of.
    // So, it is OK to pass that through.
    let pageFrag = (() => {
      if (window.location.hash.includes('#assessmentLink')) {
        return window.location.hash;
      }
      return '#index';
    })();

    // create and add query params if params are passed in
    if (!$.isEmptyObject(params)) {
      const queryParams = $.param(params);
      pageFrag += `?${ queryParams }`;
    }

    const hashQueryParams = UrlHelpers.getQueryParams(window.location.hash);
    const deepLinkHash = CookieUtil.get('deepLinkHash');
    let deepLinkQueryParams = {};

    if (deepLinkHash) {
      CookieUtil.unset('deepLinkHash');
      deepLinkQueryParams = UrlHelpers.getQueryParams(deepLinkHash);
    }

    if (deepLinkQueryParams.redirectTo) {
      window.location.href = deepLinkQueryParams.redirectTo;
      throw new RedirectingAbortedAuthentication.Error(`Forcing deeplink redirect to: ${ deepLinkQueryParams.redirectTo }`);

    } else if (hashQueryParams.redirectTo && UrlHelpers.isSafeRedirectUrl(hashQueryParams.redirectTo)) {
      window.location.href = hashQueryParams.redirectTo;
      throw new RedirectingAbortedAuthentication.Error(`Forcing hash query redirect to: ${ hashQueryParams.redirectTo }`);

    } else if ((/auth\.html$/).test(window.location.pathname)) {
      window.location.assign(`/training/${ pageFrag }`);
      throw new RedirectingAbortedAuthentication.Error('Failed to redirect to index directly since URL was not index.html; forcing redirect');

    } else if (options.reload) {
      window.location.hash = pageFrag;
      window.location.reload();
      throw new RedirectingAbortedAuthentication.Error(`Forced reload redirect to: ${ pageFrag }`);

    } else {
      this.redirect(pageFrag);
    }
  }

  setPushData(data) {
    if ((guard(this.session != null ? this.session.user : undefined, (x) => {
      return x.mobileDevices;
    }) == null)) {
      return;
    }
    let device = this.session.user.mobileDevices.where({
      pushData: data.pushData
    })[0];
    if (!device) {
      device = new MobileDevice({
        pushData: data.pushData,
        deviceType: data.deviceType
      });
      this.session.user.mobileDevices.add(device);
      device.save();
    }
    device.set({current: true});
  }

  onSessionChange() {
    // Set the time offset between the client and the server
    window.apps.base.timeLogController.setTimeDeltaToServerMs(this.session.serverTimeOffset);
    window.apps.base.timeLogController.setAppStartDateMs(this.session.serverTimeMillis);
  }

  getAuthFragment() {
    return this.session.user.getUserType().toLowerCase();
  }

  saveDeepLinkHashInCookie() {
    // Save the hash we're currently visiting in case someone needs to bring us back here later
    const expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + 5);
    CookieUtil.set('deepLinkHash', window.location.hash, expiryDate);
  }
}

module.exports = App;
