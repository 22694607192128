const BreadcrumbCollection = require('@common/data/collections/BreadcrumbCollection');
const I18n = require('@common/libs/I18n');
const Community = require('@common/data/models/Community');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const _ = require('underscore');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const _STRINGS = {
  communitiesPath: 'communities',
  communityPath: 'community',
  communityUrlPrefix: 'community-',
  searchUrlPrefix: '#hub/search',
  channelsUrlPrefix: '#hub/timeline'
};

const _constructBreadcrumb = (
  title,
  id = '',
  target,
  type = '',
  action = () => {
    return {};
  }
) => {

  const breadcrumb = {
    action,
    id,
    type,
    title,
    target
  };
  return title ? breadcrumb : undefined;
};

const parseBreadcrumbsFromArticleCommunities = (communitiesList = [], articleData, breadcrumbs = new BreadcrumbCollection()) => {

  const updatedBreadcrumbs = createBaseCommunityBreadcrumb();

  // Select the home community (if listed/available)
  const homeData = _.findWhere(communitiesList, { isHomeCommunity: true });
  if (homeData) {
    const curModel = new Community(homeData.community);
    updatedBreadcrumbs.push(
      _constructBreadcrumb(
        curModel.getName(),
        _STRINGS.communityPath,
        `${ _STRINGS.searchUrlPrefix }/${ _STRINGS.communityUrlPrefix }${ curModel.get('id') }`
      )
    );
  } else {
    // According to MaryS, only communities the user has access to are returned by the API, so if no home community
    // is available, pick the first on the list
    const communityData = communitiesList[0];

    if (communityData != null) {
      const curModel = new Community(communityData.community);
      updatedBreadcrumbs.push(
        _constructBreadcrumb(
          curModel.getName(),
          _STRINGS.communityPath,
          `${ _STRINGS.searchUrlPrefix }/${ _STRINGS.communityUrlPrefix }${ curModel.get('id') }`
        )
      );
    }
  }

  breadcrumbs.reset(updatedBreadcrumbs);

  return breadcrumbs;
};

const parseBreadcrumbsFromSearchPageState = (pageState) => {

  if (pageState && pageState.get('searchCategory') === SearchCategoryEnum.ARTICLES
  && (pageState.get('community') || pageState.get('subCategory'))) {
    const communityData = pageState.get('community');
    const breadcrumbs = createBaseCommunityBreadcrumb();

    if (communityData != null && communityData.get('names')) {
      breadcrumbs.push(
        _constructBreadcrumb(
          communityData.getName(),
          _STRINGS.communityPath,
          `${ _STRINGS.searchUrlPrefix }/${ SearchCategoryEnum.ARTICLES }/${ _STRINGS.communityUrlPrefix }${ communityData.get('id') }/1/`
        )
      );
    } else if (pageState.get('subCategory') != null) {
      // Add dummy breadcrumb for Needs Review and Favorites because they aren't real communities
      breadcrumbs.push(_constructBreadcrumb());
    }

    if (pageState.get('breadcrumbs')) {
      pageState.get('breadcrumbs').reset(breadcrumbs);
    } else {
      pageState.set('breadcrumbs', new BreadcrumbCollection(breadcrumbs));
    }

    return pageState.get('breadcrumbs');
  }
  return new BreadcrumbCollection();

};

const createBaseCommunityBreadcrumb = () => {
  return ([
    _constructBreadcrumb(
      I18n.t('selfDirected.search.communities'),
      _STRINGS.communitiesPath,
      `${ _STRINGS.searchUrlPrefix }/type-all/1/`
    )
  ]);
};

const parseBreadcrumbsForInsights = (pageMetadata, breadcrumbs = new BreadcrumbCollection()) => {
  const currentPageUrl = pageMetadata.isChannel()
    ? `${ _STRINGS.channelsUrlPrefix }/post/${ pageMetadata.pageId }`
    : `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ pageMetadata.pageId }`;

  // Breadcrumb for current page
  const updatedBreadcrumbs = [_constructBreadcrumb(
    pageMetadata.title,
    'page',
    currentPageUrl
  )];

  // Breadcrumb for home community / first visible shared community if user cannot see home community
  const communityToDisplay = pageMetadata.getHomeCommunity() || pageMetadata.pageSharedCommunitiesList.models[0];
  if (communityToDisplay) {
    const curModel = communityToDisplay.community;
    const communityUrl = pageMetadata.isChannel()
      ? `${ _STRINGS.channelsUrlPrefix }/channel/${ curModel.get('id') }`
      : `${ _STRINGS.searchUrlPrefix }/${ _STRINGS.communityUrlPrefix }${ curModel.get('id') }`;
    updatedBreadcrumbs.unshift(
      _constructBreadcrumb(
        curModel.getName(),
        _STRINGS.communityPath,
        communityUrl
      )
    );
  }

  breadcrumbs.reset(updatedBreadcrumbs);
  return breadcrumbs;
};

module.exports = {
  parseBreadcrumbsFromArticleCommunities,
  parseBreadcrumbsFromSearchPageState,
  createBaseCommunityBreadcrumb,
  parseBreadcrumbsForInsights
};
