const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class CommunitiesManagementLayout extends LayoutView {
  initialize(options = {}) {
    ({
      isChannels: this.isChannels
    } = options);
  }

  className() {
    return 'communities-management-layout parent-height';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/CommunitiesManagementLayout.html');
  }

  templateHelpers() {
    return {
      title: this.isChannels ? I18n.t('channels.channelsManagement.title') : I18n.t('communitiesManagement.title')
    };
  }

  regions() {
    return {
      tabsRegion: '.js-communities-management-tabs'
    };
  }
}

module.exports = CommunitiesManagementLayout;
