// Module
var code = `<form class="js-form general-settings-form">
  <div>
    <label for="community-code"><%- t('communitiesManagement.communityCode') %></label>
    <input id="community-code" class="general-settings-form__code" data-field="code" maxlength="128" />
    <% if (isDiscoverFolderTypeEnabled) { %>
      <%= axSelect({ className: 'general-settings-form__type' , classNameSelect: 'qa-community-type-select' , data: {
        field: 'folderType' , options: 'folderTypes' , shallow: 'true' }, label:
        t('communitiesManagement.communityType.label'), noFormEditorDefault: 'true' }) %>
        <% } %>
  </div>
  <div class="general-settings-form__name translatable-name">
    <%= axSelect({ classNameSelect: 'js-language-selector qa-language-select' , data: { shallow: 'true' },
      id: 'language' , label: t('general.language'), name: 'language' , noFormEditorDefault: 'true' }) %>
      <label for="name"><%- t('communitiesManagement.communityName') %></label>
      <div data-editor="TranslationInput" data-options="nameOptions" data-field="names" name="name"></div>
      <p class="translatable-name__limit"><%- t('translatableName.characterLimit', { maxLength: 60 }) %></p>
      <div class="js-description"></div>
  </div>
  <h4 class="general-settings-form__thumbnailTitle"><%- t('communitiesManagement.thumbnailTitle') %></h4>
  <div class="general-settings-form__image" data-field="thumbnailImage" data-editor="ImageCrop"
    data-options="imageCropOptions">
  </div>
  <h3><%- t('communitiesManagement.generalSettings.enableMobileNotifications.header') %></h3>
  <div class="mobile-notification-setting">
    <%= axCheckbox({ data: { field: 'mobileNotificationsEnabled' , options: 'enableMobileNotificationsOptions' } }) %>
      <div class="setting-text">
        <div><%- t('communitiesManagement.generalSettings.enableMobileNotifications.checkboxLabel') %></div>
        <div class="ax-font ax-font--secondary ax-font__caption caption-text"><%-
            t('communitiesManagement.generalSettings.enableMobileNotifications.checkboxCaption') %></div>
      </div>
  </div>
  <h3><%- t('channels.settings.administrationVisibility') %></h3>
  <div class="administration-visibility">
    <%= axCheckbox({ data: { field: 'hiddenFromAdmin' , options: 'adminHideOptions' } }) %>
      <div class="setting-text">
        <div><%- t('communitiesManagement.generalSettings.hideContent') %></div>
        <div class="ax-font ax-font--secondary ax-font__caption caption-text"><%-
            t('communitiesManagement.generalSettings.hideContentSecondary') %></div>
      </div>
  </div>
  <div class="js-note ax-font ax-font--secondary ax-font__caption"></div>
</form>
`;
// Exports
module.exports = code;