import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

const HubTileMap = {
  // LearnerZone identifiers
  HUB: 'HUB',
  HOME: 'HOME',
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  ALWAYS_ON_DAILY_TRAINING: 'ALWAYS_ON_DAILY_TRAINING',
  REWARDS: 'AUCTION',
  CERTIFICATION_TRAINING: 'CERTIFICATION_TRAINING',
  EXTRA_TRAINING: 'EXTRA_TRAINING',
  GAME_SCORES: 'GAME_SCORES',
  GAME_SCORES_DETAILS: 'GAME_SCORES_DETAILS',
  REPORT_CARD: 'KNOWLEDGE_MAP',
  TOP_PERFORMERS: 'COIN_LEADERBOARD',
  INTRO_TRAINING: 'INTRO_TRAINING',
  FEED: 'NEWS',
  REFERRAL: 'REFERRAL',
  LEARNING_EVENTS: 'LEARNING_EVENTS',
  LEARNING_EVENT_DETAILS: 'LEARNING_EVENT_DETAILS',
  FORMAL_EXAM: 'FORMAL_EXAM',
  REFRESHER_TRAINING: 'REFRESHER_TRAINING',
  PROFILE: 'PROFILE',
  GUIDED_LEARNING: 'GUIDED_LEARNING',
  SELF_DIRECTED_PATHS: 'SELF_DIRECTED_PATHS',
  DISCOVER: 'DISCOVER',
  MESSAGE: 'MESSAGE',
  SEARCH: 'SEARCH',
  TIMELINE: 'TIMELINE',
  CONVERSATIONAL_SEARCH: 'CONVERSATIONAL_SEARCH',
  LEARNER_TRAINING: 'LEARNER_TRAINING',
  TRAINING_PAGE: 'TRAINING_PAGE',
  EXECUTION_TASKS: 'EXECUTION_TASKS',
  HUB_STATS: 'HUB_STATS',
  CAMPAIGN_COMMUNICATIONS: 'CAMPAIGN_COMMUNICATIONS',
  TRAINING_PAYWALL: 'TRAINING_PAYWALL',
  RECOGNITION_PINS: 'RECOGNITION_PINS',
  CONSOLIDATED_COMMS: 'CONSOLIDATED_COMMS',
  CHANNELS: 'CHANNELS',

  // LeaderZone identifiers
  TEAM_METRICS: 'TEAM_METRICS',
  USER_METRICS: 'USER_METRICS',
  IMPACT: 'IMPACT',
  COMPARE: 'COMPARE',
  TEAM_MESSAGE: 'TEAM_MESSAGE',
  PEOPLE: 'PEOPLE',
  EVENTS: 'EVENTS',
  BEHAVIORS: 'BEHAVIORS',
  INSPECTIONS: 'INSPECTIONS',
  EXPORTS: 'EXPORTS',
  SETTINGS: 'SETTINGS',
  INSIGHTS: 'INSIGHTS',
  MANAGE_TRAINING: 'MANAGE_TRAINING'
};

const HubTileIdentifiers = Enum(HubTileMap) as (EnumType & (typeof HubTileMap));

export default HubTileIdentifiers;
