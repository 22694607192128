// Module
var code = `
<h1 class="page-header">
  <div class="ax-container">
    <div class="page-header__content">
      <span class="page-header__icon icon-settings" aria-hidden="true"></span>
      <span class="page-header__title"><%- communityTitle %></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="js-community-header community-header-region clearfix"></div>
    <div class="js-community-management-tabs clearfix"></div>
  </div>
</div>

<div class="js-action-bar"></div>
`;
// Exports
module.exports = code;