const MultilingualArticleBundledLanguageChooserView = require('@training/apps/articles/multilingualArticleBundledLanguageChooser/MultilingualArticleBundledLanguageChooserView');
const BundledLanguageChooserDropdown = require('@common/components/bundledLanguageChooser/BundledLanguageChooserDropdown');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const PageStatus = require('@common/components/discover/enums/PageStatus');
const PageType = require('@common/components/discover/enums/PageType').default;
const _ = require('underscore');
const Backbone = require('Backbone');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');

class MultilingualArticleBundledLanguageChooserController extends LayoutController {
  initialize(options = {}) {
    ({
      languageData: this.languageData,
      articleModel: this.articleModel,
      editMode: this.editMode,
      containerClassName: this.containerClassName,
      confirmActionOnModified: this.confirmActionOnModified = (callback) => {
        callback();
      }
    } = options);

    if (this.articleModel && this.articleModel.get('language')) {
      this.currentLanguageCode = this.articleModel.get('language');
    } else {
      this.currentLanguageCode = window.apps.auth.session.user.get('language');
    }

    this.viewDefinition = this.viewDefinition.bind(this);
    this.regionControllers = this.regionControllers.bind(this);
    this.getDropdownActions = this.getDropdownActions.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: MultilingualArticleBundledLanguageChooserView,
      model: this.languageData
    };
  }

  getPublishStateInfo(languageCode) {
    const datum = this.languageData.get(languageCode);

    if (!datum) {
      return {
        buttonPublishStateLabel: '',
        buttonPublishStateClass: ''
      };
    }

    if (datum.pageStatus.toUpperCase() === PageStatus.SCHEDULED) {
      return {
        buttonPublishStateLabel: I18n.t('learningEvents.scheduled'),
        buttonPublishStateClass: 'pill--scheduled'
      };
    }

    if (datum.pageStatus.toUpperCase() === PageStatus.FACT) {
      return {
        buttonPublishStateLabel: I18n.t('discover.publishFlow.publishState.published'),
        buttonPublishStateClass: 'pill--published'
      };
    }

    if (datum.pageStatus.toUpperCase() === PageStatus.QUARANTINE) {
      return {
        buttonPublishStateLabel: I18n.t('discover.browse.reportedItems'),
        buttonPublishStateClass: 'pill--reported'
      };
    }

    if (datum.pageStatus.toUpperCase() === PageStatus.REVIEW) {
      return {
        buttonPublishStateLabel: I18n.t('discover.publishFlow.publishState.inReview'),
        buttonPublishStateClass: 'pill--in-review'
      };
    }

    if (datum.pageStatus.toUpperCase() === PageStatus.EXPIRED) {
      return {
        buttonPublishStateLabel: I18n.t('discover.publishFlow.publishState.expired'),
        buttonPublishStateClass: 'pill--expired'
      };
    }

    return {
      buttonPublishStateLabel: '',
      buttonPublishStateClass: ''
    };
  }

  navigateToCreatePage(languageCode) {
    const pageId = this.articleModel.get('id');
    //if we are navigating away from a new, unsaved article (no id), we need to rely on whatever the previous query string was (given communityId or fromArticle)
    const query = pageId ? `?fromArticle=${ pageId }` : UrlHelpers.getQueryString(window.location.hash);

    if (this.articleModel.get('type') === PageType.REFERENCE) {
      Backbone.history.navigate(`hub/search/article/create/new-reference/${ languageCode.toLowerCase() }${ query }`, true);
    }
    if (this.articleModel.get('type') === PageType.ARTICLE) {
      Backbone.history.navigate(`hub/search/article/create/new-article/${ languageCode.toLowerCase() }${ query }`, true);
    }
    if (this.articleModel.get('type') === PageType.QUESTION) {
      Backbone.history.navigate(`hub/search/article/create/new-question/${ languageCode.toLowerCase() }${ query }`, true);
    }
  }

  getDropdownActions() {
    const dropdownActions = [];

    _.each(this.languageData.keys(), (languageCode) => {
      if (languageCode === 'bundleId') {
        return;
      }
      const publishStateInfo = this.getPublishStateInfo(languageCode);
      const datum = this.languageData.get(languageCode);

      if (this.editMode || datum) {
        dropdownActions.push({
          buttonText: this.getLanguageI18nKey(languageCode),
          buttonClass: `js-language-picker-dropdown-option-${ languageCode } qa-language-picker-dropdown-option-${ languageCode }`,
          buttonSelectorClass: `js-language-picker-dropdown-option-${ languageCode }`,
          buttonIcon: null,
          buttonAriaLabel: 'discover.publishFlow.navigateLanguage.ariaLabel',
          buttonAriaLabelContext: {languageName: I18n.t(this.getLanguageI18nKey(languageCode))},
          buttonPublishStateLabel: publishStateInfo.buttonPublishStateLabel,
          buttonPublishStateClass: publishStateInfo.buttonPublishStateClass,
          clickCallback: () => {
            this.confirmActionOnModified(() => {
              if (datum) {
                Backbone.history.navigate(`hub/search/article/${ datum.pageId }` + (this.editMode ? '/edit' : ''), true);
                return;
              }

              this.navigateToCreatePage(languageCode);
            });
          }
        });
      }
    });

    dropdownActions.sort((a, b) => {
      const aStr = I18n.t(a.buttonText).toLowerCase();
      const bStr = I18n.t(b.buttonText).toLowerCase();
      return aStr.localeCompare(bStr);
    });

    return dropdownActions;
  }

  getLanguageI18nKey(languageCode) {
    return 'languages.' + languageCode.toUpperCase().replace(/-|_/g, '');
  }

  regionControllers() {
    const publishStateInfo = this.getPublishStateInfo(this.currentLanguageCode);

    return {
      bundledLanguageChooserDropdown: {
        viewDefinition: {
          ViewClass: BundledLanguageChooserDropdown,
          buttonConfig: {
            buttonText: this.getLanguageI18nKey(this.currentLanguageCode),
            buttonIcon: null,
            buttonPublishStateLabel: publishStateInfo.buttonPublishStateLabel,
            buttonPublishStateClass: publishStateInfo.buttonPublishStateClass,
            buttonAriaLabel: 'discover.publishFlow.languageChooser.triggerButton.ariaLabel',
            buttonAriaLabelContext: {languageName: I18n.t(this.getLanguageI18nKey(this.currentLanguageCode))},
            popupAlignment: MenuDropdownPositionEnum.LEFT + MenuDropdownPositionEnum.BOTTOM
          },
          optionsListConfig: this.getDropdownActions(),
          className: this.containerClassName
        }
      }
    };
  }

}

module.exports = MultilingualArticleBundledLanguageChooserController;
