import {
  injectPagedCursorApiEndpoint,
  isResetReferenceItemId
} from '@common/data/paging';
import {
  type TimelineMissingResultItem,
  type TimelinePageResultItem,
  type TimelineResultItem
} from '@training/apps/timeline/api';
import { channelsApi } from '@training/apps/timeline/channels/api';
import { omit } from 'lodash';

export type ChannelDetailsListResponseItem = TimelinePageResultItem | TimelineMissingResultItem;
type ChannelDetailsHeaderResultItem = TimelineResultItem<'CHANNEL_DETAILS_HEADER'>;
type ChannelDetailsEmptyResultItem = TimelineResultItem<'CHANNEL_DETAILS_EMPTY'>;
export type ChannelDetailsListResultItem = ChannelDetailsHeaderResultItem | ChannelDetailsEmptyResultItem | TimelinePageResultItem;

type GetChannelDetailsListResponse = {
  results: ChannelDetailsListResponseItem[];
  totalSearchResults: number;
};

export const channelDetailsListApi = injectPagedCursorApiEndpoint<ChannelDetailsListResultItem, GetChannelDetailsListResponse, { id: string }>({
  api: channelsApi,
  endpointName: 'getChannelDetailsList',
  tagType: 'timeline-CHANNEL_DETAILS',
  endpointDefinition: {
    keepUnusedDataFor: Infinity,
    query: (args) => {
      return {
        apiEndpoint: `/timeline/channel/${ args.id }/posts`,
        data: {
          ...omit(args, 'id'),
          referenceItemId: isResetReferenceItemId(args.referenceItemId) ? undefined : encodeURIComponent(args.referenceItemId)
        }
      };
    }
  }
});

// Used in index.ts to merge the api definitions
export type ChannelDetailsListApi = typeof channelDetailsListApi;

// Used in the query updater
export const { useGetChannelDetailsListResultsApi } = channelDetailsListApi;

// Used in the context
export type UseGetChannelDetailsListApiResult = ReturnType<typeof useGetChannelDetailsListResultsApi>;
