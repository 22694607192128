const Backbone = require('Backbone');
const { ItemView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const MediaFactory = require('@common/libs/file/MediaFactory');
const FileFactory = require('@common/libs/file/FileFactory');
const PageType = require('@common/components/discover/enums/PageType').default;
const PageStatus = require('@common/components/discover/enums/PageStatus');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const EditList = require('@common/data/collections/EditList');
const PageSharedCommunitiesList = require('@common/components/discover/collections/PageSharedCommunitiesList');
const BreadcrumbCollection = require('@common/data/collections/BreadcrumbCollection');
const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const { parseBreadcrumbsFromArticleCommunities } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');

const ContentView = require('@common/components/discover/views/ContentView');
const TrainingModuleContentView = require('@common/components/discover/views/TrainingModuleContentView');
const ArticleDetailsPageView = require('@training/apps/articles/ArticleDetailsPageView');
const ArticleTitleSubView = require('@training/apps/articles/ArticleTitleSubView');
const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const IconBadgeClassModifiers = require('@common/components/iconBadge/IconBadgeClassModifiers').default;
const ContentRegionController = require('@training/apps/articles/ContentRegionController');
const ArticleDetailsMetadataRegionController = require('@training/apps/articles/ArticleDetailsMetadataRegionController');

const ICON_CLASSES = {
  question: 'icon-create-question-nobg',
  training: 'icon-extratraining',
  reference: 'icon-create-link-nobg',
  article: 'icon-create-article-nobg'
};

const DEFAULT_ICON_CLASS = ICON_CLASSES.article;

const _getIsDownloadEnabled = (tenantPropertyProvider, user) => {
  if (
    tenantPropertyProvider.getProperty('enableDownloadForGuests') === false
    && user.isGuestOrSuperuser()
  ) {
    return false;
  }
  return true;

};

const _getBuilderOptions = (articleData) => {

  const builderOptions = {
    badgeModifierClasses: [IconBadgeClassModifiers.PAGE_HEADER_ICON],
    iconClass: ICON_CLASSES[articleData.get('type')] || DEFAULT_ICON_CLASS,
    titleControllerDefinition: {
      viewDefinition: {
        ViewClass: ItemView,
        tagName: 'h1',
        className: 'page-header__title',
        template: articleData.get('type') === PageType.TRAINING ? '<%- title %>' : '<%= title %>',
        templateHelpers: {
          title: articleData.get('title')
        }
      }
    }
  };

  if (articleData.get('type') === 'reference') {
    Object.assign(builderOptions.titleControllerDefinition.viewDefinition, {
      tagName: 'a',
      attributes: {
        href: articleData.get('url'),
        target: '_blank'
      }
    });
  }

  return builderOptions;
};

const _getContentViewClass = (articleData) => {
  const type = PageType.assertLegalValue(articleData.get('type'));
  let Controller;

  switch (type) {
    case PageType.TRAINING:
      Controller = TrainingModuleContentView;
      break;

    case PageType.ARTICLE:
    case PageType.REFERENCE:
    case PageType.QUESTION:
    default:
      Controller = ContentView;
  }

  return Controller;
};

const _showMedia = (controller, view, mediaId) => {
  const media = view.model.currentVersion.richContent._getMediaById(mediaId);
  const mediaModel = MediaFactory.createMediaFromJSON(FileFactory, media);
  window.app.layout.showMedia(mediaModel);
};

const _isMultilingualPageType = (articleData) => {
  return (
    articleData.get('type') === PageType.ARTICLE
    || articleData.get('type') === PageType.REFERENCE
    || articleData.get('type') === PageType.QUESTION
  );
};

const _isAdminUser = () => {
  return apps.auth.session.user.isAdminUser();
};

const _doesUserSeeFancyLanguagePicker = (articleData) => {
  return _isAdminUser() && _isMultilingualPageType(articleData);
};

const getTimelineBreadcrumb = (breadcrumbCollection) => {
  breadcrumbCollection.push({
    action: '#hub/timeline',
    id: '',
    type: '',
    title: I18n.t('training.menu.timeline'),
    target: `#hub/timeline`
  });
}

const viewConfig = (articleData, sessionUser, languageData) => {
  const tenantPropertyProvider = TenantPropertyProvider.get();
  const breadcrumbCollection = new BreadcrumbCollection([]);
  const pageSharedCommunitiesList = new PageSharedCommunitiesList(articleData.get('communities'), {
    pageId: articleData.get('id'),
    parse: true
  });

  const urlHash = UrlHelpers.getQueryString(window.location.hash);
  const currentParameters = UrlHelpers.getQueryParams(urlHash);
  const hasFromDiscover = _.has(currentParameters, 'fromDiscover');
  const isFromDiscover = currentParameters.fromDiscover === 'true';

  if (hasFromDiscover && !isFromDiscover) {
    getTimelineBreadcrumb(breadcrumbCollection);
  } else {
    parseBreadcrumbsFromArticleCommunities(articleData.get('communities'), articleData, breadcrumbCollection);
  }

  const editList = new EditList(null, {
    id: articleData.get('id'),
    queryParams: {
      startingRevision: articleData.get('currentVersion') && articleData.get('currentVersion').revision || 1
    }
  });
  editList.fetch();

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: ArticleDetailsPageView,
      model: articleData,
      userId: sessionUser.id,
      pageSharedCommunitiesList
    },
    regionControllers: {
      breadcrumbRegion: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: breadcrumbCollection,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: false,
          linkLastCrumb: true
        }
      },
      titleRegion: new TitleHeaderDefinitionBuilder(_getBuilderOptions(articleData)).build(),
      mobileSubRegion: {
        viewDefinition: {
          ViewClass: ArticleTitleSubView,
          model: articleData,
          userId: sessionUser.id,
          canStar: articleData.canStar(sessionUser)
        }
      },
      desktopSubRegion: {
        viewDefinition: {
          ViewClass: ArticleTitleSubView,
          model: articleData,
          userId: sessionUser.id,
          canStar: articleData.canStar(sessionUser)
        }
      },
      contentRegion: {
        ViewControllerClass: ContentRegionController,
        viewClass: _getContentViewClass(articleData),
        model: articleData,
        sessionUser,
        delegateEvents: {
          'view:image:clicked': _showMedia
        }
      },
      metadataRegion: {
        ViewControllerClass: ArticleDetailsMetadataRegionController,
        model: articleData,
        languageData,
        pageSharedCommunitiesList,
        reportEnabled: tenantPropertyProvider.getProperty('dzAllowPageReporting') === true,
        editList,
        authorAndContributorInfoEnabled: tenantPropertyProvider.getProperty('dzShowAuthorAndContributorInfo'),
        enableDownload: _getIsDownloadEnabled(tenantPropertyProvider, sessionUser)
      }
    },
    delegateEvents: {
      'view:inflate': () => {
        window.app.layout.setTitle(articleData.getTitle());
      },
      'view:render': (controller, view) => {
        window.apps.base.timeLogController.bindPageViewLog(view, 'ArticleDetailsPage', articleData.get('id'));
        window.app.layout.toggleFooter(true);

        const updatePageStatusInBundle = (newStatus) => {
          const articleLanguage = articleData.get('language');
          const datum = languageData.get(articleLanguage);
          datum.pageStatus = newStatus;
          languageData.set(articleLanguage, datum);
        };

        view.listenTo(view, 'article:reloadContentRegion', () => {
          if (_doesUserSeeFancyLanguagePicker(articleData)) {
            updatePageStatusInBundle(PageStatus.FACT);
          }
          controller.resetRegionController('contentRegion');
          controller.resetRegionController('metadataRegion');
        });

        view.listenTo(view, 'article:reported', () => {
          // Two things gotta happen (potentially) when someone reports a Page. They are:

          // 1) call this method that affects the reported banner above the page body (in the content view)
          const contentController = controller.findControllerByRegion('contentRegion');
          const contentView = contentController && contentController.getView();
          contentView._toggleReportedBanner();

          // 2) If the user sees the fancy language picker in the metadata region, update the status of its model so
          // that the new status is shown
          if (_doesUserSeeFancyLanguagePicker(articleData)) {
            updatePageStatusInBundle(PageStatus.QUARANTINE);
          }

          // With those two adjustments, we can reset the region and everything will look right
          controller.resetRegionController('metadataRegion');
        });

        view.listenTo(view, 'article:approved', () => {
          // Toggle approval notification and approve button on a successful article approval submission
          const contentController = controller.findControllerByRegion('contentRegion');
          const contentView = contentController && contentController.getView();

          contentView._toggleInReviewBanner();
        });
      }
    }
  };
};

module.exports = viewConfig;

