import {
  channelsApi,
  type ChannelDetailsType
} from '@training/apps/timeline/channels/api';

const channelDetailsApi = channelsApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      channelDetails: builder.query<ChannelDetailsType, string>({
        keepUnusedDataFor: 0,
        query: (id) => {
          return {
            apiEndpoint: `/pageCommunities/channels/${ id }`
          };
        }
      })
    };
  }
});

export type ChannelDetailsApi = typeof channelDetailsApi;

export const {
  useChannelDetailsQuery
} = channelDetailsApi;
