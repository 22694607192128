import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type PageTypeEnumType = EnumType & {
  QUESTION: string,
  TRAINING: string,
  REFERENCE: string,
  ARTICLE: string,
  POST: string,
  COMMENT: string
};

const PageType = Enum({
  QUESTION: 'question',
  TRAINING: 'training',
  REFERENCE: 'reference',
  ARTICLE: 'article',
  POST: 'post',
  COMMENT: 'comment'
}) as PageTypeEnumType;

export default PageType;
