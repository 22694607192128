const { Collection } = require('Backbone');
const { LayoutView } = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const Form = require('@common/components/forms/Form');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const PublishPermissionsModel = require('@training/apps/search/communitiesManagement/contentSettings/PublishPermissionsModel');
const CommunityAccessLevelEnum = require('@common/data/enums/CommunityAccessLevelEnum');

require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');

class ContentSettingsLayout extends LayoutView {
  initialize(options = {}) {
    ({model: this.model} = options);

    this.isMixedCommunitiesForbidden = TenantPropertyProvider.get().getProperty('channelsMigrationMixedCommunitiesForbidden');
    this.isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled') || false;
    this.model.set('showOnTimeline', !this.model.get('hideFromGlobalTimeline'));
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/contentSettings/ContentSettingsLayout.html');
  }

  templateHelpers() {
    return {
      isCommsEnabled: this.isCommsEnabled,
      isConversationalSearchEnabled: TenantPropertyProvider.get().getProperty('conversationalSearchEnabled') || false
    };
  }

  ui() {
    return {
      contentSettingsForm: '.content-settings-form'
    };
  }

  onRender() {
    const collection = this._getPublishAccessCollection();

    this.contentSettingsForm = new Form({
      el: this.ui.contentSettingsForm,
      model: this.model,
      context: {
        publishAccessLevelOptions: {
          collection,
          axonSelectOptions: {
            width: '200px',
            useChosen: false
          }
        },
        enableRecommendationsOptions: {
          ariaLabel: I18n.t('discover.metadata.recommendations.subtitle')
        },
        enableQuestionOptions: {
          ariaLabel: I18n.t('communitiesManagement.community.enableAskAQuestion')
        },
        enableCommentsOptions: {
          ariaLabel: I18n.t('discover.metadata.comments.subtitle')
        },
        enableReactionsOptions: {
          ariaLabel: I18n.t('communitiesManagement.community.enableReactions')
        },
        hideContentFromTimelineOptions: {
          ariaLabel: I18n.t('communitiesManagement.community.hideContentFromTimeline')
        },
        includeInConversationalSearchOptions: {
          ariaLabel: I18n.t('communitiesManagement.community.includeInConversationalSearch')
        }
      }
    });

    const fields = this.contentSettingsForm.fields;

    this._toggleDropdownSecondaryText(this.contentSettingsForm);

    this.listenTo(this.contentSettingsForm, 'change:publishAccessLevel', this._toggleDropdownSecondaryText);

    _.each(fields, (value, key) => {
      if (key !== 'publishAccessLevel') {
        let switchValue = value.editor.getValue();
        this._toggleSwitchSecondaryText(key, switchValue);

        this.listenTo(this.contentSettingsForm, 'change:' + key, (form, field) => {
          switchValue = field.getValue();
          this._toggleSwitchSecondaryText(key, switchValue);

          if (key === 'showOnTimeline') {
            this.model.set('showOnTimeline', switchValue);
            this.model.set('hideFromGlobalTimeline', !switchValue);
          }

          // TODO: This, and any reference to isMixedCommunitiesForbidden, should be removed once Channels is merged. Posts will only live in Channels at that point.
          // Questions & Articles & Posts - disable the opposite switch if it is already off.
          // Only apply this logic if comms are enabled (and thus posts are actually turned on).
          if (this.isMixedCommunitiesForbidden && this.isCommsEnabled) {
            const postsEnabledVal = this.contentSettingsForm.fields['postsEnabled'].editor.getValue();
            const articlesEnabledVal = this.contentSettingsForm.fields['articlesEnabled'].editor.getValue();
            const questionsEnabledVal = this.contentSettingsForm.fields['questionsEnabled'].editor.getValue();

            if (key === 'articlesEnabled' && !postsEnabledVal && !questionsEnabledVal) {
              this._toggleSwitchDisabled('postsEnabled', switchValue);
            } else if (key === 'postsEnabled' && !switchValue && (!articlesEnabledVal || !questionsEnabledVal)) {
              this._toggleSwitchDisabled('articlesEnabled', switchValue);
              this._toggleSwitchDisabled('questionsEnabled', switchValue);
            } else if (key === 'postsEnabled' && switchValue && !articlesEnabledVal && !questionsEnabledVal) {
              this._toggleSwitchDisabled('articlesEnabled', switchValue);
              this._toggleSwitchDisabled('questionsEnabled', switchValue);
            } else if (key === 'questionsEnabled' && !articlesEnabledVal && !postsEnabledVal) {
              this._toggleSwitchDisabled('postsEnabled', switchValue);
            }
          }
        });
      }
    });

    this.listenTo(this.model, 'sync change update', this._onChangeUpdate);

    // TODO: This, and any reference to isMixedCommunitiesForbidden, should be removed once Channels is merged. Posts will only live in Channels at that point.
    // As long as at least one of questions, posts & articles aren't switched on, disable the switch that is off.
    // Only apply this logic if comms are enabled (and thus posts are actually turned on).
    if (this.isMixedCommunitiesForbidden
      && this.isCommsEnabled
      && (!this.model.get('articlesEnabled') || !this.model.get('postsEnabled') || !this.model.get('questionsEnabled'))) {
      this._toggleSwitchDisabled('articlesEnabled', this.model.get('postsEnabled') && !this.model.get('articlesEnabled'));
      this._toggleSwitchDisabled('postsEnabled', !this.model.get('postsEnabled') && (this.model.get('articlesEnabled') || this.model.get('questionsEnabled')));
      this._toggleSwitchDisabled('questionsEnabled', this.model.get('postsEnabled') && !this.model.get('questionsEnabled'));
    }
  }

  onAttach() {
    triggerResize(true);
  }

  _onChangeUpdate() {
    this.contentSettingsForm.update(this.model);
  }

  _toggleSwitchDisabled(field, switchValue) {
    this.contentSettingsForm.fields[field]?.editor.$el
      .find('input[type="checkbox"]')
      .attr('disabled', switchValue);
  }

  _getPublishAccessCollection() {
    const PublishAccessCollection = Collection.extend({ model: PublishPermissionsModel });
    return new PublishAccessCollection([
      {
        value: CommunityAccessLevelEnum.MODERATE,
        name: I18n.t('communitiesManagement.community.moderators')
      },
      {
        value: CommunityAccessLevelEnum.EDIT,
        name: I18n.t('communitiesManagement.community.moderatorsAndEditors')
      }
    ]);
  }

  _toggleSwitchSecondaryText(field) {
    const switchElementSecondaryText = this.$el.find('.' + field).closest('.switch-container-wrapper')
      .find('.content-settings__text--secondary');
    const string = I18n.t(`communitiesManagement.community.${ field }.can`);

    $(switchElementSecondaryText).text(string);
  }

  _toggleDropdownSecondaryText(form) {
    const value = form.fields['publishAccessLevel'].editor.getValue();
    const dropdownElementSecondaryText = this.$el.find('.publish-dropdown')
      .find('.content-settings__text--secondary');
    const string = value === CommunityAccessLevelEnum.MODERATE ? I18n.t(`communitiesManagement.community.onlyModeratorsText`) : I18n.t(`communitiesManagement.community.moderatorsAndEditorsText`);

    $(dropdownElementSecondaryText).text(string);
  }

}

module.exports = ContentSettingsLayout;
