import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type CommunityActionEnumType = EnumType & {
  ARCHIVE_COMMUNITY: string;
  AUTHOR_ACCESS: string; // The user has sufficient access to author an article of any page type in the community
  AUTHOR_PAGETYPE: string; // The user has sufficient access to author an article of a specific page type in the community
  DELETE_COMMUNITY: string;
  EDIT_COMMUNITY_SETTINGS: string;
  MOVE_ACCESS: string;
  PUBLISH_ACCESS: string;
  VIEW_COMMUNITY: string;
  VIEW_REPORTS: string;
};

const CommunityAction = Enum({
  ARCHIVE_COMMUNITY: 'ARCHIVE_COMMUNITY',
  AUTHOR_ACCESS: 'AUTHOR_ACCESS', // The user has sufficient access to author an article of any page type in the community
  AUTHOR_PAGETYPE: 'AUTHOR_PAGETYPE', // The user has sufficient access to author an article of a specific page type in the community
  DELETE_COMMUNITY: 'DELETE_COMMUNITY',
  EDIT_COMMUNITY_SETTINGS: 'EDIT_COMMUNITY_SETTINGS',
  MOVE_ACCESS: 'MOVE_ACCESS',
  PUBLISH_ACCESS: 'PUBLISH_ACCESS',
  VIEW_COMMUNITY: 'VIEW_COMMUNITY',
  VIEW_REPORTS: 'VIEW_REPORTS'
}) as CommunityActionEnumType;

export default CommunityAction;
