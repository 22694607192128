const { LayoutView } = require('Marionette');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

const SelectedState = require('@common/components/selectable_list/SelectedState');

const TeamSelector = require('@training/apps/search/communitiesManagement/accessSettings/TeamSelector');
const FilterGroup = require('@common/data/models/FilterGroup');
const _ = require('underscore');
const Form = require('@common/components/forms/Form');

const TeamAccessTableView = require('@training/apps/search/communitiesManagement/accessSettings/TeamAccessTableView');
const TeamAccessItemView = require('@training/apps/search/communitiesManagement/accessSettings/TeamAccessItemView');
const CommunityAccessModel = require('@training/apps/search/communitiesManagement/models/CommunityAccessModel');
const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');

const { ReactControllerDefinitionFactory } = require('@common/modules/react');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const CommunitiesManagementTableEnum = require('@training/apps/training/enums/CommunitiesManagementTableEnum');
const {
  getTableHeaders,
  getTeamAccessLevelCollection
} = require('@training/apps/search/communitiesManagement/CommunityManagementTableHelper');

class TeamAccessLayout extends LayoutView {
  initialize(options = {}) {
    ({
      model: this.model,
      teamsList: this.teamsList,
      showBanner: this.showBanner
    } = options);

    // Use the teamsList directly since it's already been filtered
    this.filteredTeamsList = this.teamsList;

    const defaultFilterData = {
      id: -1,
      type: 2,
      name: I18n.t('communitiesManagement.accessSettings.teamSelectorLabel'),
      needsFetch: false
    };

    const compareFilterState = new SelectedState({
      model: FilterGroup,
      initialSelection: defaultFilterData
    });

    this.teamSelector = new TeamSelector({
      collection: this.filteredTeamsList,
      noDefault: true,
      minExpandLevel: 1,
      selectedState: compareFilterState,
      labelText: I18n.t('communitiesManagement.accessSettings.selectTeamLabel'),
      placeholderText: I18n.t('communitiesManagement.accessSettings.teamSelectorLabel'),
      teamId: this.model.get('owningLocationId') || null
    });

    this.isTypeChannel = this.model.get('type') === 'channel';
    const headerNames = getTableHeaders(CommunitiesManagementTableEnum.TEAM_ACCESS, false, this.isTypeChannel);

    const values = _.compact(CommunitiesManagementAccessLevelHelpers.getAccessLevelValues());
    const AccessLevelsCollection = Backbone.Collection.extend({ model: CommunityAccessModel });
    const accessLevelsList = new AccessLevelsCollection(CommunitiesManagementAccessLevelHelpers.generateModelsData(values, headerNames));

    if (this.isTypeChannel) {
      // remove Editor column for channels
      accessLevelsList.remove(accessLevelsList.findWhere({ level: 30 }));
    }
    this.teamTable = new TeamAccessTableView({
      headerNames,
      rowItemView: TeamAccessItemView,
      collection: getTeamAccessLevelCollection(this.model),
      model: this.model,
      values,
      childViewOptions: {
        accessLevelsList
      },
      teamsList: this.filteredTeamsList
    });

    this.setOwningLocation = this.setOwningLocation.bind(this);
    this._closeTeamSelector = this._closeTeamSelector.bind(this);

    this.teamSelector.on('change', this.setOwningLocation);
    this.listenTo(this.model, 'change:businessUnitId', this._onBusinessUnitChange);
    $('body').on('click', this._closeTeamSelector);
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/TeamAccessLayoutTemplate.html');
  }

  templateHelpers() {
    return {
      includeSubTeamsDescription: this.isTypeChannel
        ? I18n.t('channels.settings.includeSubTeamsSwitchDescription')
        : I18n.t('communitiesManagement.accessSettings.includeSubTeamsSwitchDescription')
    };
  }

  ui() {
    return {
      form: '.js-subteam-form',
      filterableSelector: 'input.hierarchy-filter-current-text'
    };
  }

  onAttach() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        includeSubTeamsOptions: { ariaLabel: I18n.t('communitiesManagement.accessSettings.includeSubTeamsSwitchLabel') }
      }
    });
  }

  regions() {
    return {
      teamSelectorRegion: '.js-team-selector',
      teamTableRegion: '.js-team-table-region',
      warningBannerRegion: '.js-warning-banner'
    };
  }

  onRender() {
    this.teamSelectorRegion.show(this.teamSelector);
    this.teamTableRegion.show(this.teamTable);
    this._renderWarningBanner();
  }

  _renderWarningBanner() {
    this.warningBanner = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/channels/settings/access/TargetClearedWarningBanner'),
      props: {
        showBanner: this.showBanner
      }
    }));
    this.warningBannerRegion.show(this.warningBanner);
  }

  _closeTeamSelector() {
    this.teamSelector.closeMenu();
  }

  setOwningLocation() {
    const selectedTeam = this.teamSelector.getSelectedItem();
    if (selectedTeam && selectedTeam.get('id') !== -1) {
      const selectedNode = this.teamSelector.getNodeById(selectedTeam.get('id'));
      if (selectedNode) {
        selectedNode.setSelected();
        this.model.set('owningLocationId', selectedTeam.get('id'));
        this.showBanner = false;
        this._renderWarningBanner();
      }
    } else {
      this.model.set('owningLocationId', null);
    }
  }

  onDestroy() {
    $('body').off('click', this._closeTeamSelector);
  }

  _onBusinessUnitChange() {
    if (this.teamSelector) {
      this.stopListening(this.teamSelector, 'change', this.setOwningLocation);

      // Use the teamsList directly - filtering is handled in AccessSettingsTabView
      this.filteredTeamsList = this.teamsList;

      const defaultFilterData = {
        id: -1,
        type: 2,
        name: I18n.t('communitiesManagement.accessSettings.teamSelectorLabel'),
        needsFetch: false
      };

      const compareFilterState = new SelectedState({
        model: FilterGroup,
        initialSelection: defaultFilterData
      });

      this.teamSelector = new TeamSelector({
        collection: this.filteredTeamsList,
        noDefault: true,
        minExpandLevel: 1,
        selectedState: compareFilterState,
        labelText: I18n.t('communitiesManagement.accessSettings.selectTeamLabel'),
        placeholderText: I18n.t('communitiesManagement.accessSettings.teamSelectorLabel'),
        teamId: null
      });

      this.model.set('owningLocationId', null, { silent: true });

      this.teamSelectorRegion.show(this.teamSelector);

      this.teamSelector.on('change', this.setOwningLocation);
    }
  }

  isGlobalBu(buId) {
    return buId === null || buId === '' || buId === '0';
  }
}

module.exports = TeamAccessLayout;
