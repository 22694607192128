const $os = require('detectOS');
const logging = require('logging');
const {
  each,
  filter,
  find,
  findWhere
} = require('underscore');
const Marionette = require('Marionette');
const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');
const {
  triggerResize,
  getScrollParent
} = require('@common/libs/helpers/app/BrowserHelpers');
const FileHelpers = require('@common/libs/file/FileHelpers');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const TrainingModuleViewFactory = require('@common/components/training_modules/TrainingModuleViewFactory');
const PreviewTrainingModule = require('@common/components/training_modules/PreviewTrainingModule');

const MediaType = require('@common/data/enums/MediaType').default;
const {DocumentMedia} = require('@common/data/models/media/DocumentMedia');

const MediaViewModel = require('@common/components/media/MediaViewModel');

const DZPage = require('@common/components/discover/models/Page');
const AttachmentListView = require('@training/apps/training/views/AttachmentListView');
const PageType = require('@common/components/discover/enums/PageType').default;
const PageTypeTranslationKeyEnum = require('@common/components/discover/enums/PageTypeTranslationKeyEnum');

const { handleExternalLinkMedia } = require('@training/apps/articles/ExternalLinkMediaHandler');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');
const Community = require('@common/data/models/Community');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/card/Card');

class DzLinkContent extends Marionette.LayoutView {

  initialize() {
    this.page = new DZPage(this.model.get('page'));
    this.articleRead = false;
    this._imageViewers = {};
    this._videoPlayers = {};

    const knowledgeArticleUrl = `${ window.apps.base.getKnowledgeHrefRaw().split('/articles')[0] }/article/`;
    // Build URL for opening article in DZ, you can use the raw URL here since if you're getting this, DZ must exist
    if (this.model.get('page')) {
      this.url = `${ knowledgeArticleUrl }${ this.page.get('id') }`;
    } else {
      this.pageUrl = window.apps.base.getKnowledgeHrefRaw();
    }

    this._videoHandler = this._videoHandler.bind(this);
    this._imageHandler = this._imageHandler.bind(this);
  }

  className() {
    return 'broadcast-article';
  }

  getTemplate() {
    return require('@training/apps/training/templates/DzLinkContentTemplate.html');
  }

  templateHelpers() {
    const isInApp = $os.isInMobileApp();
    const link = this._createLinkObject(isInApp);
    const type = this.page.get('type');
    let publishedInCommunity = '';
    if (this.page.get('communities') && this.page.get('communities').length > 0) {
      const comModel = new Community(this.page.get('communities')[0].community);
      publishedInCommunity = I18n.t('broadcastMessage.publishedInCommunity', { communityName: comModel.getName() });
    }

    return {
      titleElement: this._createTitleElement(this.page.get('title'), type, link),
      type: I18n.t(PageTypeTranslationKeyEnum[type]),
      publishedInCommunity,
      link: link,
      previewImageUrl: this._createPreviewImage(),
      getTypeIcon: () => {
        if (type === PageType.TRAINING) {
          return 'training-article-nobg';
        } else if (type === PageType.ARTICLE) {
          return 'article';
        } else if (type === PageType.REFERENCE) {
          return 'reference';
        } else if (type === PageType.QUESTION) {
          return 'question';
        }
        return type;

      }
    };
  }

  regions() {
    return {
      attachmentList: '.broadcast-article__attachment-wrapper'
    };
  }

  ui() {
    return {
      readBtn: '.broadcast-article__read-button',
      readBtnIcon: '.broadcast-article__read-button span',
      articleContentSection: '.broadcast-article__content-wrapper',
      articleContents: '.broadcast-article-content',
      attachments: '.attachments',
      moduleSection: '.broadcast-article__module-wrapper'
    };
  }

  events() {
    return {
      'click @ui.readBtn': 'onViewContentClick',
      'click .broadcast-article-link': 'onContentClickLink'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      Card: {}
    };
  }

  onShow() {
    const attachmentList = this.getAttachments().map((attachment) => {
      return MediaViewModel.fromMedia(new DocumentMedia(attachment)).toJSON();
    });

    if (attachmentList.length > 0) {
      const attachmentCardListView = new AttachmentListView({
        collection: new Backbone.Collection(attachmentList)
      });

      this.getRegion('attachmentList').show(attachmentCardListView);
    }
  }

  onViewContentClick() {
    this.ui.readBtn.children('span').toggleClass('icon-chevron_down icon-chevron_up');

    const ariaExpanded = (this.ui.readBtn.attr('aria-expanded') === 'true');
    this.ui.readBtn.attr('aria-expanded', !ariaExpanded);

    this.trigger('force:icon:reflow');
    this._triggerContentToggle();

    if (!this.articleRead) {
      this.articleRead = true;
      const articleId = this.page.get('id');

      // Call to mark linked article as 'read'
      $.ajax({
        type: 'POST',
        apiEndpoint: `/articles/${ articleId }/views`,
        data: '{}', // this is needed because server framework doesn't support a POST with empty body
        error: (xhr) => {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', { articleId }));
        }
      });
    }

    // Scroll to attached document when it's expanded
    $(getScrollParent(this.$el[0])).animate({scrollTop: `${ this.$el.position().top }px`});
  }

  onContentClickLink(e) {
    const article = this.model.get('page');
    handleExternalLinkMedia(e, article['id'], article['currentVersion']);
  }

  onAttach() {
    const isTraining = this.page.get('type') === PageType.TRAINING;
    this._initializeMediaInfo(MediaType.VIDEO, this._videoHandler);
    this._initializeMediaInfo(MediaType.EMBEDDED_VIDEO, this._embeddedVideoHandler);

    const imageMedias = this._initializeMediaInfo(MediaType.IMAGE, this._imageHandler);

    if (isTraining) {
      this.initializeTrainingModuleBlock(this.page.get('trainingModule'));
    }

    const attachments = this.getAttachments();
    this._customizeAttachmentLinks(attachments);

    this._tagArticleLinks();

    // Preview image
    if (imageMedias.length) {
      const previewImage = imageMedias[0].mediaLink.media.sizes[0].file.path;
      this.$el.find('.broadcast-article__image-target').css('background-image', previewImage);
    }

  }

  onResize() {
    // Resize the linked images and videos if they exist
    if (this._imageViewers) {
      each(this._imageViewers, (imageViewer) => {
        imageViewer.resize();
      });
    }
    if (this._videoPlayers) {
      each(this._videoPlayers, (videoPlayer) => {
        videoPlayer.resize();
      });
    }
  }

  onBeforeDestroy() {
    each(this._videoPlayers, (videoPlayer) => {
      videoPlayer.destroy();
    });
  }

  getAttachments() {
    return this.getDocumentInfoList(this.page.get('currentVersion').richContent.media);
  }

  getDocumentInfoList(attachment) {
    return filter(attachment, (media) => {
      return media.type === 'document';
    });
  }

  initializeVideoPlayer(mediaLink, videoPlayerOptions) {
    this._videoPlayers[mediaLink.media.id] = VideoPlayerFactory.createPlayerInstance({
      videoPackage: mediaLink,
      viewOptions: videoPlayerOptions
    });

    this._videoPlayers[mediaLink.media.id].render();
  }

  initializeImage(mediaLink, el) {
    this._imageViewers[mediaLink.media.id] = ImageViewerFactory.createViewerInstance({
      media: mediaLink.media,
      $el: el,
      ariaHidden: true
    });

    this.listenTo(this._imageViewers[mediaLink.media.id], 'image:loaded', this.triggerAdjustment);

    this._imageViewers[mediaLink.media.id].render();
  }

  initializeTrainingModuleBlock(data) {
    const currentLanguage = this.page.get('language');
    const trainingModule = new PreviewTrainingModule(data, {
      language: currentLanguage
    });

    const factory = new TrainingModuleViewFactory({
      trainingModule,
      previewingUser: window.apps.auth.session.user
    });

    const viewPromise = factory.createTrainingModulePreviewForLanguage(currentLanguage);
    viewPromise.then((view) => {
      view.setElement(this.ui.moduleSection);
      view.render();
    });
  }

  triggerAdjustment() {
    triggerResize(true);
  }

  _triggerContentToggle() {
    const spaceBelow = window.innerHeight - this.$el.offset().top + this.$el.outerHeight();
    if (this.ui.articleContentSection.height() > spaceBelow || this.contentAnimation === 'toggle') {
      this.contentAnimation = 'toggle';
      this._toggleContent();
    } else {
      this._toggleContentAnimation();
    }
  }

  _toggleContent() {
    this.ui.articleContentSection.toggle();
    this.triggerAdjustment();
  }

  _toggleContentAnimation() {
    this.contentOpen = this.contentOpen === undefined ? false : !this.contentOpen;
    const type = this.contentOpen ? 'slideUp' : 'slideDown';
    const vOptions = {
      easeing: 'easeInSine',
      duration: 250,
      complete: () => {
        this.triggerAdjustment();
      }
    };

    this.ui.articleContentSection.velocity(type, vOptions);
  }

  _initializeMediaInfo(type, handler) {
    const mediaObjectList = [];
    this.$el.find(`.page__media.media--${ type }`).each((index, el) => {
      const $el = $(el);
      const mediaId = $el.data('mediaId');
      if (!mediaId) {
        return;
      }

      mediaObjectList.push(handler($el, mediaId));
    });

    return mediaObjectList;
  }

  _videoHandler($el, mediaId) {
    let rtnVal;

    const media = find(
      this.page.get('currentVersion').richContent.media,
      (rcMedia) => {
        return rcMedia.id === mediaId;
      }
    );

    if (!media) {
      logging.warn('A video element with a media ID was present in the rich content but there was no such matching media with that ID on the model.');

    } else {
      const videoPlayerOptions = {
        el: $el,
        maxHeight: 385
      };
      this.initializeVideoPlayer({ media }, videoPlayerOptions);

      rtnVal = mediaId;
    }

    return rtnVal;
  }

  _embeddedVideoHandler($el, mediaId) {
    let rtnVal;

    const source = $el.data('source');

    if (!source) {
      logging.warn('An embedded video object was found with no source value.');

    } else {
      $el.html(VideoPlayerFactory.getEmbeddedVideoInstance(
        mediaId,
        source
      ));

      rtnVal = mediaId;
    }

    return rtnVal;
  }

  _imageHandler($el, mediaId) {
    const media = find(
      this.page.get('currentVersion').richContent.media,
      (rcMedia) => {
        return rcMedia.id === mediaId;
      }
    );

    if (!media) {
      logging.warn('An image element with a media ID was present in the rich content but there was no such matching media with that ID on the model.');
      return undefined;
    }

    const $imageParent = FroalaRichContentHelper.getImageParentElement($el);
    const mediaLink = { media };
    this.initializeImage(mediaLink, $imageParent);

    return {
      $el,
      mediaLink
    };
  }

  _tagArticleLinks() {
    const $articleLinks = this.ui.articleContents.find('a[href]');
    $articleLinks.each((i, el) => {
      $(el).addClass('broadcast-article-link');
    });
  }

  _customizeAttachmentLinks(attachments = []) {
    this.ui.articleContentSection.find('a[data-media-id]').each((i, el) => {
      const $el = $(el);

      const mediaJSON = findWhere(attachments, {id: $el.data('media-id')});

      // `_getMediaById` could return `undefined` if the media was deleted but the link was not removed.
      // Our code doesn't remove links in the content when the attachment was removed.
      if (mediaJSON != null) {
        const { originalFile } = mediaJSON;
        FileHelpers.customizeAttachmentLinks($el, originalFile);
      }
    });
  }

  _createLinkObject(isInApp) {
    const linkObj = {
      url: this.url,
      target: (isInApp || $os.mobile) ? '_self' : '_blank'
    };

    return linkObj;
  }

  _createTitleElement(title, type, linkObj) {
    if (type === PageType.REFERENCE || type === PageType.TRAINING) {
      return this._createTemplateLink(title, linkObj);
    }
    return this._createTemplateHeader(title);

  }

  _createTemplateLink(title, linkObj) {
    return `<a class="broadcast-article__title" href="${ linkObj.url }" aria-label="${ linkObj.label }" target="${ linkObj.target }">${ title }</a>`;
  }

  _createTemplateHeader(title) {
    return `<h3 class="broadcast-article__title">${ title }</h3>`;
  }

  _createPreviewImage() {
    const media = this.page.getFirstImage();

    return media ? `background: url(${ media.sizes[0].file.path }) no-repeat center; background-size: contain;` : '';
  }
}

module.exports = DzLinkContent;
