const { Collection } = require('Backbone');
const { LayoutView } = require('Marionette');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const Resizable = require('@common/libs/behaviors/resizable/Resizable');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const AccessSettingsGroupModalView = require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsGroupModalView');
const GroupAccessTableView = require('@training/apps/search/communitiesManagement/accessSettings/GroupAccessTableView');
const GroupAccessItemView = require('@training/apps/search/communitiesManagement/accessSettings/GroupAccessItemView');
const GroupList = require('@training/apps/search/communitiesManagement/GroupList');
const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');
const CommunityAccessModel = require('@training/apps/search/communitiesManagement/models/CommunityAccessModel');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const CommunitiesManagementTableEnum = require('@training/apps/training/enums/CommunitiesManagementTableEnum');
const {
  getTableHeaders,
  getTableHeaderClasses
} = require('@training/apps/search/communitiesManagement/CommunityManagementTableHelper');

class GroupAccessLayout extends LayoutView {
  initialize(options = {}) {
    ({
      model: this.model,
      groupList: this.groupList,
      businessUnitId: this.businessUnitId,
      showBanner: this.showBanner
    } = options);

    // Fetch groups with business unit filter if one is set
    const businessUnitId = this.model.get('businessUnitId');
    if (businessUnitId != null) {
      this.groupList.fetch({
        data: { businessUnitId },
        reset: true
      });
    }

    this.selectedGroups = this._getSelectedGroups();
    this.everyoneAccessLevel = this.model.get('everyoneAccessLevel');

    const isTypeChannel = this.model.get('type') === 'channel';
    const headerNames = getTableHeaders(CommunitiesManagementTableEnum.GROUP_ACCESS, false, isTypeChannel);
    const headerClasses = getTableHeaderClasses(CommunitiesManagementTableEnum.GROUP_ACCESS, false, isTypeChannel);

    const values = _.compact(CommunitiesManagementAccessLevelHelpers.getAccessLevelValues());
    const AccessLevelsCollection = Collection.extend({ model: CommunityAccessModel });
    const accessLevelsList = new AccessLevelsCollection(CommunitiesManagementAccessLevelHelpers.generateModelsData(values, headerNames));

    if (isTypeChannel) {
      // remove Editor column for channels
      accessLevelsList.remove(accessLevelsList.findWhere({ level: 30 }));
    }

    this.groupTable = new GroupAccessTableView({
      headerNames,
      headerClasses,
      rowItemView: GroupAccessItemView,
      collection: this.selectedGroups,
      groupList: this.groupList,
      model: this.model,
      businessUnitId: this.businessUnitId,
      childViewOptions: {
        accessLevelsList,
        values,
        businessUnitId: this.businessUnitId
      }
    });
    this.listenTo(this.groupTable, 'childview:group:delete', this._onGroupDelete);
  }

  getTemplate() {
    return `
      <p><%- t('communitiesManagement.accessSettings.groupLabel') %></p>
      <button type="button" class="ax-button ax-button--branded js-group-button group-button" style="margin-bottom: 20px;">
        <span class="icon-plus"></span>
        <span><%- t('groups.add') %></span>
      </button>
      <div class="js-warning-banner"></div>
      <div class="js-group-table-region"></div>\
    `;
  }

  regions() {
    return {
      groupTableRegion: '.js-group-table-region',
      warningBannerRegion: '.js-warning-banner'
    };
  }

  events() {
    return {
      'click .js-group-button': '_onClickGroupButton'
    };
  }

  behaviors() {
    return [{
      behaviorClass: Resizable
    }];
  }

  onRender() {
    this.groupTableRegion.show(this.groupTable);

    this._renderWarningBanner();
  }

  _renderWarningBanner() {
    this.warningBanner = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
      component: import('@training/apps/timeline/channels/settings/access/TargetClearedWarningBanner'),
      props: {
        showBanner: this.showBanner
      }
    }));
    this.warningBannerRegion.show(this.warningBanner);
  }

  _onClickGroupButton() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal'
    });

    const groupListCopy = new GroupList(this.groupList.toJSON());
    const businessUnitId = this.model.get('businessUnitId');

    const modalChildView = new AccessSettingsGroupModalView({
      selectedGroups: this.selectedGroups,
      groupList: groupListCopy,
      businessUnitId: businessUnitId
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });

    this.listenToOnce(modalChildView, 'add:groups', (selectedGroups) => {
      this.groupTable.collection.reset(selectedGroups.models);
      this.groupTable.render();
      this.showBanner = false;
      this._renderWarningBanner();
    });
  }

  _onGroupDelete(view) {
    const group = view.model;
    const groupAccess = this.model.get('groupAccess');
    const accessGroup = _.filter(groupAccess, (accessLevel) => {
      return accessLevel.groupId === group.get('id');
    });
    groupAccess.splice(groupAccess.indexOf(accessGroup));
    this.selectedGroups.remove(view.model);
    BrowserHelpers.triggerResize(true);
  }

  _getSelectedGroups() {
    if (this.model.get('selectedGroups') != null) {
      return this.model.get('selectedGroups');
    }

    this.model.set('selectedGroups', new GroupList());
    return this.model.get('selectedGroups');
  }
}

module.exports = GroupAccessLayout;
