const Backbone = require('Backbone');
const Marionette = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const ReactionsEnum = require('@common/data/enums/ReactionsEnum');
const PageType = require('@common/components/discover/enums/PageType').default;
const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const $os = require('detectOS');
const CreatePostAccessibleModalView = require('@training/apps/timeline/CreatePostAccessibleModalView');

const TRAY_APPEAR_DELAY = 350;
const TRAY_HIDE_DELAY = 250;

const defaultInteractionKeys = [KeyCode.ENTER, KeyCode.SPACE];
const CommentHelpers = require('@training/apps/articles/ArticleDetailsCommentHelpers');

const ReactionsModal = require('@training/apps/search/modals/reactionsModal/ReactionsModal');
const ReactionsDetailsModel = require('@training/apps/search/modals/reactionsModal/ReactionsDetailsModel');
const { persistTimelinePageData } = require('@training/apps/timeline/helpers/TimelineLocalStorageHelpers');

class ReactionsView extends Marionette.LayoutView {

  constructor(options = {}) {
    super(options);
    this.defaultReaction = 'LIKE';
    this.focusTarget = null;
    this.previousFocusedTarget = null;
    this.totalReactionCount = 0;
  }

  initialize(options = {}) {
    ({
      commentsEnabled: this.commentsEnabled,
      showComments: this.showComments,
      commentAriaLabel: this.commentAriaLabel,
      showFavorites: this.showFavorites,
      shouldShowFavorites: this.shouldShowFavorites,
      showReactionsModal: this.showReactionsModal,
      userCommentCount: this.userCommentCount,
      itemViewOptions: this.itemViewOptions,
      isCommsEnabled: this.isCommsTenantPropertyOn
    } = options);
  }

  events() {
    const events = {
      // Hover/long press events to show reaction tray or click on default reaction.
      'mousedown @ui.clickableReactionButton': 'onInteractDefaultReactionButton',
      'mouseenter @ui.reactionPopoverGroup': 'onInteractDefaultReactionButton',
      'touchstart @ui.clickableReactionButton': 'onInteractDefaultReactionButton',
      'click @ui.clickableReactionButton': 'onInteractDefaultReactionButton',

      // Touch-specific handler for movement and context menu override
      touchmove: 'onTouchTarget',
      contextMenu: 'ignoreDefaultBehaviour',

      // Handle reaction selection from tray
      'click .reaction-button': 'onReactionClicked',
      'keydown .reaction-button': 'onReactionKeyed',
      'mouseup @ui.clickableReactionButton': 'onReleaseReactionButton',
      'touchend @ui.clickableReactionButton': 'onReleaseReactionButton',

      // Handle clicking the comment button
      'click @ui.commentButton': 'onClickCommentButton',

      // Keyboard navigation - open/close tray
      'keydown @ui.reactionPopoverGroup': 'onKeyDownDefaultReactionButton',
      'blur @ui.clickableReactionButton': 'onTrayBlur',
      'blur .reaction-button': 'onTrayBlur',
      'focus @ui.clickableReactionButton': 'onTrayFocus',
      'focus .reaction-button': 'onTrayFocus',

      // Close reaction tray on mouseout.
      'mouseleave @ui.reactionTray': 'clearAndHide',
      'mouseleave @ui.clickableReactionButton': 'clearAndHide',
      mouseleave: 'clearAndHide'
    };

    // Toggle Reaction Modal
    if (this.options.showReactionsModal) {
      events['click @ui.reactionSummary'] = 'onClickReactionModal';
      events['keydown @ui.reactionSummary'] = 'onKeyDownReactionSummary';
    }

    return events;
  }

  modelEvents() {
    return {
      change: this.onModelChange
    };
  }

  ui() {
    return {
      clickableReactionButton: '.js-reaction-clickable',
      defaultIcon: '.js-default-icon',
      defaultText: '.js-default-text',
      midDot: '.middot',
      mostLikedOne: '.js-most-liked-one',
      mostLikedTwo: '.js-most-liked-two',
      reactionCount: '.js-reaction-count',
      reactionTray: '.reaction-tray',
      reactionPopoverGroup: '.reaction-popover-group',
      reactionInfoGroupInner: '.js-reaction-info-group-inner',
      reactionButtonsGroup: '.reaction-buttons-group',
      commentCount: '.comment-count',
      commentButton: '.comment-button',
      contextfix: '.ie11-stacking-context-fix',
      reactionButtons: '.js-reaction-buttons',
      reactionSummary: '.js-reaction-summary'
    };
  }

  regions() {
    return {
      favoriteRegion: '.favorite-region'
    };
  }

  getTemplate() {
    return require('@training/apps/search/ReactionsViewTemplate.html');
  }

  templateHelpers() {
    const currentReaction = this.getCurrentReaction();
    this.updateMostLikedReactions();
    this.updatePageViews();
    this.isCommentType = !this.model.get('type') || this.model.get('type') === PageType.COMMENT;

    return {
      reactionIcon: ReactionsEnum[currentReaction || this.defaultReaction],
      reactionName: I18n.t(`reaction.reactionType.${ currentReaction || this.defaultReaction }.name`),
      ReactionsEnum: ReactionsEnum,
      userHasReaction: currentReaction,
      reactionAriaPressed: Boolean(currentReaction),
      totalReactions: this.totalReactionCount,
      hideTotalReactions: this.hideUnless(this.totalReactionCount > 0),
      mostLikedOne: ReactionsEnum[(this.sortedReactions && this.sortedReactions[0] && this.sortedReactions[0][0]) || 'LIKE'],
      mostLikedTwo: ReactionsEnum[(this.sortedReactions && this.sortedReactions[1] && this.sortedReactions[1][0]) || 'LOVE'],

      mostLiked1Hidden: this.hideUnless(this.shouldShowMostLiked1()),
      mostLiked2Hidden: this.hideUnless(this.shouldShowMostLiked2()),
      groupInfoHidden: this.hideUnless(this.shouldShowGroupInfo()),
      midDotHidden: this.hideUnless(this.shouldShowMidDot()),

      showSentiments: this.shouldShowSentiments(),
      showComments: this.shouldShowComments(),
      commentAriaLabel: this.getCommentsAriaLabel(),
      reactionBarAriaLabel: this.getReactionsBarAriaLabel(),
      showFavorites: this.shouldShowFavorites(),
      showButtonsGroupTopBorder: this.shouldShowButtonsGroupTopBorder(),
      commentsEnabled: this.commentsEnabled,
      advancedCommsEnabled: this.isCommsTenantPropertyOn() && (!this.isGuestOrSuperuser() || this.shouldShowComments() || this.shouldShowTrayCommentCount()),
      userCommentCount: this.userCommentCount != null ? CommentHelpers.getCommentCountPhrase(this.userCommentCount) : '',
      hiddenToGuest: this.hideUnless(!this.isGuestOrSuperuser() || (this.isGuestOrSuperuser && this.shouldShowTrayCommentCount())),
      isGuestOrSuperuser: this.isGuestOrSuperuser(),
      showTrayCommentCount: this.shouldShowTrayCommentCount(),

      canShowReactionsModal: this.options.showReactionsModal && (this.shouldShowMostLiked1() || this.shouldShowMostLiked2()),

      forPageViews: this.pageViews ? ' for-page-views' : '',
      pageViews: this.pageViews,
      isCommentType: this.isCommentType
    };
  }

  hideUnless(shouldShowPredicate = false) {
    return shouldShowPredicate ? '' : ' hidden';
  }

  /**
   * If there is nothing visible in Group-Info (the upper shelf of the tray in grid layout, or the left half in
   * list layout) then we shouldn't render it because then there's extra space and a horiz rule
   */
  shouldShowGroupInfo() {
    return (this.shouldShowSentiments() && (this.shouldShowMostLiked1() || this.shouldShowMostLiked2())) || this.shouldShowMidDot() || this.shouldShowTrayCommentCount() || this.shouldShowPageViews();
  }

  shouldShowMostLiked1() {
    return this.sortedReactions && this.sortedReactions[0] && this.sortedReactions[0][1] > 0;
  }

  shouldShowMostLiked2() {
    return this.sortedReactions && this.sortedReactions[1] && this.sortedReactions[1][1] > 0;
  }

  /**
   * This is the comment count in "reaction-info-group-right". If comments are shown, then this is redundant.
   */
  shouldShowTrayCommentCount() {
    return this.commentsEnabled && !this.shouldShowComments() && this.userCommentCount > 0;
  }

  shouldShowPageViews() {
    return this.pageViews !== '';
  }

  shouldShowCommentForm() {
    return this.shouldShowComments() && !this.isGuestOrSuperuser();
  }

  /**
   * There should be a top border on group-buttons only when the layout is in two rows - in grid layout, not list -
   * but also it should be absent when group-info is hidden (which happens when there is nothing in it).
   */
  shouldShowButtonsGroupTopBorder() {
    const type = this.model.get('type');

    if (!type || type === PageType.COMMENT) {
      return false;
    }

    if ($os.mobile) {
      return true;
    }

    return type !== PageType.POST && this.shouldShowGroupInfo();
  }

  /**
   * Determines whether to show the comments collection itself. This is not the same as "commentsEnabled".
   * For this to return true, we know that the tenant has communications on, and the community has comments enabled,
   * and this is a layout where showing comments is apppropriate (ie the article details)
   */
  shouldShowComments() {
    return this.isCommsTenantPropertyOn() && this.commentsEnabled && this.showComments;
  }

  getCommentsAriaLabel() {
    return I18n.t('discover.comments.button.ariaLabel', { title: this.model.get('title') });
  }

  getReactionsBarAriaLabel() {
    return I18n.t('reaction.reactionType.ALL.showReactions', { title: this.model.get('title') });
  }

  /**
   * we show the mid-dot only if there are visible things on both sides of it.
   * A subtle bit of logic in here is that we show "n Comments" in the tray only when the comments themselves are
   * not shown -- since there is a header saying "n Comments" in the comment display
   */
  shouldShowMidDot() {
    return (this.shouldShowTrayCommentCount() || this.pageViews) && (this.shouldShowMostLiked1() || this.shouldShowMostLiked2());
  }

  /**
   * Controls visibility of the entire sentiment widget (Like, Love, etc) in the reactions tray. If this returns
   * false, then in the Reactions tray we might still be seeing Comments and Favourites.
   */
  shouldShowSentiments() {
    return this.isCommsTenantPropertyOn() && !this.isGuestOrSuperuser() && this.model.get('permittedPageActions').REACT;
  }

  isGuestOrSuperuser() {
    return window.apps.auth.session.user.isGuestOrSuperuser();
  }

  toggleReactionPopup(show = false) {
    // This condition is because there are some scenarios where the elements aren't even rendered... like Guest Users.
    // But the popup toggling may still be triggered by other events on the page, like keydowns and mouseouts and such.

    // we need protection here from regions that haven't resolved yet, and elements that don't exist because of the
    // myriad of different configurations
    if (this.ui.reactionTray && this.ui.reactionTray.length > 0 && this.ui.clickableReactionButton && this.ui.clickableReactionButton.length > 0) {

      // the element has to be made visible first, or else it will have no height or width.
      this.ui.contextfix.toggleClass('hidden', !show);
      this.ui.reactionTray.toggleClass('popover-visible', show);
      this.ui.clickableReactionButton.attr('aria-expanded', show);

      // get all the coords and metrics we'll need
      const isTimelineView = $(this.ui.reactionTray)
        .parents('.timeline-feed')
        .length > 0;
      const isCommunityBrowse = $(this.ui.reactionTray)
        .parents('.community-browse')
        .length > 0;
      const isGridView = $(this.ui.reactionTray)
        .closest('.article-search__results-region')
        .hasClass('grid-view')
      || $(this.ui.reactionTray)
        .closest('.search__article-results-region')
        .hasClass('.grid-view');
      const isListView = $(this.ui.reactionTray)
        .closest('.article-search__results-region')
        .not('.grid-view')
        .length > 0
      || $(this.ui.reactionTray)
        .closest('.search__article-results-region')
        .not('.grid-view')
        .length > 0;
      const isArticleDetailView = $(this.ui.reactionTray)
        .parents('.article-details')
        .length > 0;
      const isPostDetailView = $(this.ui.reactionTray)
        .parents('.post-container')
        .length > 0
      || $(this.ui.reactionTray)
        .parents('.post-details-card')
        .length > 0;
      const isPostCommentView = $(this.ui.reactionTray)
        .parents('.post-comment-card')
        .length > 0;

      const isMobile = $os.mobile;

      // the physical bounds of the trigger button isn't the button, it's actually the container that the button is in
      const triggerPosition = $(this.ui.clickableReactionButton)
        .closest('.reaction-bar-button-container')
        .position();

      const buttonGroupWidth = this.ui.reactionButtonsGroup.width();
      const reactionTrayWidth = this.ui.reactionTray.width();
      const reactionTrayHeight = $(this.ui.reactionTray).height();

      // the top position is simple, regardless of RTL or LTR
      const topPosition = triggerPosition.top - reactionTrayHeight;
      $(this.ui.contextfix).css('top', topPosition + 'px');

      // If leftPosition is 0, the popover will appear aligned to the top left corner of this.ui.reactionButtonsGroup
      // even if the buttons inside that container are wrapping or stacked responsively.
      // the leftPosition is an x-coordinate in relation to that top left corner.
      let leftPosition = 0;

      const reactionButtonsWidth = $(this.ui.reactionButtonsGroup)
        .closest('.reaction-buttons')
        .width();

      if (!I18n.isCurrentLanguageRtl()) {
        // this realigns the popover to be flush left with the button. this is the default position
        leftPosition = triggerPosition.left;

        if (isTimelineView || isCommunityBrowse || isPostCommentView) {
          if ((reactionButtonsWidth - triggerPosition.left) < reactionTrayWidth) {
            leftPosition = reactionButtonsWidth - reactionTrayWidth;
          }
        } else if (isListView || isArticleDetailView) {
          if (isMobile) {
            leftPosition = buttonGroupWidth - reactionTrayWidth;
          } else if ( (buttonGroupWidth < reactionTrayWidth) || ((reactionButtonsWidth - triggerPosition.left) < reactionTrayWidth)) {
            leftPosition = reactionButtonsWidth - reactionTrayWidth;
          }
        } else if (isPostDetailView) {
          if (isMobile) {
            leftPosition = buttonGroupWidth - reactionTrayWidth;
          } else if ((reactionButtonsWidth - triggerPosition.left) < reactionTrayWidth) {
            leftPosition = reactionButtonsWidth - reactionTrayWidth;
          }
        }
      } else {
        // This is a RTL language.

        // you would think that this would be a good default position, aligned with the right edge of the button:
        // leftPosition = triggerPosition.left + triggerWidth - reactionTrayWidth;
        // but it's NOT! In almost every case, we want leftPosition = 0, so let's make that the default:
        leftPosition = 0;

        if (isTimelineView || isPostCommentView || isCommunityBrowse || (isGridView && isMobile)) {
          // we need it to be nudged 2rem from the left so it has a visually pleasing margin.
          leftPosition = 20;
        }
      }

      $(this.ui.contextfix).css('left', leftPosition + 'px');
    }
  }

  // We can pass the sentiment in directly, or an event.
  onReactionClicked(e) {
    let targetSentiment;
    if (_.isString(e)) {
      targetSentiment = e;
    } else {
      this.ignoreDefaultBehaviour(e);
      const targetButton = $(e.target).closest('.reaction-button');
      targetSentiment = targetButton.data().sentiment;
      targetButton.trigger('blur');
    }
    this.clearAndHide();
    this.trigger('register:reaction', targetSentiment);
  }

  // For long presses and clicks we want to ignore the default behaviour to allow the custom handlers to do their thing.
  ignoreDefaultBehaviour(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
  }

  // When holding down the like button on mobile, we need to track the pointer to find which reaction they've selected.
  onTouchTarget(e) {
    const elementAtPoint = document.elementFromPoint(e.originalEvent.touches[0].clientX, e.originalEvent.touches[0].clientY);
    const currentTarget = elementAtPoint && $(elementAtPoint).closest('.reaction-button');
    const targetSentiment = currentTarget && currentTarget.data() && currentTarget.data().sentiment;
    if (targetSentiment) {
      if (!this.previousFocusedTarget || targetSentiment !== this.previousFocusedTarget.data().sentiment) {
        currentTarget.addClass('show-label');
        this.hidePreviousFocusedLabel();
      }
      this.focusTarget = targetSentiment;
      this.previousFocusedTarget = currentTarget;
    } else {
      this.focusTarget = null;
    }
  }

  onKeyDownDefaultReactionButton(e) {
    if (defaultInteractionKeys.includes(e.keyCode)) {
      this.ignoreDefaultBehaviour(e);
      this.toggleReactionPopup(true);
    } else {
      this.handleOtherKeyPresses(e);
    }
  }

  onReactionKeyed(e) {
    if (defaultInteractionKeys.includes(e.keyCode)) {
      this.onReactionClicked(e);
    } else {
      this.handleOtherKeyPresses(e);
    }
  }

  handleOtherKeyPresses(e) {
    if (e.keyCode === KeyCode.ESCAPE) {
      this.clearAndHide();
    }
  }

  onInteractDefaultReactionButton(e) {
    this.ignoreDefaultBehaviour(e);
    this.clearTimers();
    this.interactionTimer = setTimeout(() => {
      this.clearTimers();
      this.toggleReactionPopup(true);
    }, TRAY_APPEAR_DELAY);
    return false;
  }

  onReleaseReactionButton() {
    if (this.focusTarget) {
      this.onReactionClicked(this.focusTarget);
      this.focusTarget = null;
    } else if (this.interactionTimer) {
      // kill the timer and do the default click
      this.clearTimers();
      this.onReactionClicked(this.getCurrentReaction() || this.defaultReaction);
    }
    this.hidePreviousFocusedLabel();
    this.clearAndHide();
  }

  clearTimers() {
    if (this.interactionTimer) {
      clearTimeout(this.interactionTimer);
      this.interactionTimer = null;
    }
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
      this.hideTimer = null;
    }
  }

  hidePreviousFocusedLabel() {
    if (this.previousFocusedTarget) {
      $(this.previousFocusedTarget).removeClass('show-label');
      this.previousFocusedTarget = null;
    }
  }

  onTrayBlur(e) {
    if (e.relatedTarget) { //for browsers that support related target, blur will be instant
      // When tabbing out of the reaction tray or away from the default reaction button
      if (!$(e.relatedTarget).hasClass('reaction-button')) {
        this.clearAndHide();
      }
    } else { //For everything else (ex. FF38) we can use a timeout toggle
      this.timeoutId = setTimeout(this.clearAndHide.bind(this), 5); // Give it a few cycles to ensure focus events have fired
    }
  }

  onTrayFocus() {
    clearTimeout(this.timeoutId);
  }

  clearAndHide(e) {
    // Add a small delay for mouse controls so the tray doesn't disappear immediately after the mouse leaves.
    // Keyboard/touch interactions should remain instant as they are intentional
    let keepReactionTrayOpen = false;
    if (e) {
      this.ignoreDefaultBehaviour(e);
      if ($(e.relatedTarget).parents('.reaction-tray').length > 0) {
        /* If there's overlap of the tray and button, ensure the mouseleave event checks
          that we're not entering the tray */
        keepReactionTrayOpen = true;
      }
    }
    if (!keepReactionTrayOpen) {
      this.clearTimers();
      this.hideTimer = setTimeout(() => {
        this.toggleReactionPopup(false);
        this.clearTimers();
      }, e && e.type === 'mouseleave' ? TRAY_HIDE_DELAY : 0);
    }
  }

  onModelChange() {
    if (this.shouldShowSentiments()) {
      const hasUserSelected = this.getCurrentReaction();
      const currentlySelected = (hasUserSelected || this.defaultReaction).toUpperCase();

      // Update the icon and text
      this.changeIcon(this.ui.defaultIcon, ReactionsEnum[currentlySelected]);
      this.ui.defaultText.text(I18n.t(`reaction.reactionType.${ currentlySelected }.name`));

      // Adjust the styling if the user has selected/deselected a reaction
      this.ui.defaultIcon.toggleClass('filled', (hasUserSelected != null));

      this.updateMostLikedReactions(); // this updates this.sortedReactions
      this.updatePageViews();

      const showMostLiked1 = this.sortedReactions[0][1] > 0;
      const showMostLiked2 = this.sortedReactions[1][1] > 0;
      this.ui.mostLikedOne.parent().toggleClass('hidden', !showMostLiked1);
      this.ui.mostLikedTwo.parent().toggleClass('hidden', !showMostLiked2);
      this.ui.reactionSummary.toggleClass('hidden', (!showMostLiked1 && !showMostLiked2));

      this.changeIcon(this.ui.mostLikedOne, ReactionsEnum[this.sortedReactions[0][0]]);
      this.changeIcon(this.ui.mostLikedTwo, ReactionsEnum[this.sortedReactions[1][0]]);

      this.ui.reactionCount.toggleClass('hidden', !(this.totalReactionCount > 0));
      this.ui.reactionCount.text(this.totalReactionCount);

      this.ui.reactionButtonsGroup.toggleClass('show-top-border', this.shouldShowButtonsGroupTopBorder());
      this.ui.clickableReactionButton.attr('aria-pressed', (hasUserSelected != null));

      this.ui.midDot.toggleClass('hidden', !this.shouldShowMidDot());
      this.ui.commentCount.toggleClass('hidden', !this.shouldShowTrayCommentCount());
      this.ui.reactionInfoGroupInner.toggleClass('hidden', !this.shouldShowGroupInfo());
    }
  }

  changeIcon(uiElement, iconName) {
    const filledIcon = uiElement.find('span.filled');
    const outlineIcon = uiElement.find('span.outline');

    filledIcon.removeClass((index, className) => {
      return (className.match(/(^|\s)reaction-icon__filled--\S+/g) || []).join(' ');
    });
    filledIcon.addClass(`reaction-icon__filled--${ iconName }`);

    outlineIcon.removeClass((index, className) => {
      return (className.match(/(^|\s)reaction-icon__outline--\S+/g) || []).join(' ');
    });
    outlineIcon.addClass(`reaction-icon__outline--${ iconName }`);
  }

  updateMostLikedReactions() {
    const reactions = this.model.get('reactions') || {};
    const totalReactions = reactions.totalReactions || {};

    this.totalReactionCount = 0;
    this.sortedReactions = Object.keys(totalReactions).map((k) => {
      this.totalReactionCount += totalReactions[k];
      return [k.toUpperCase(), totalReactions[k]];
    });

    // Sort in descending order
    this.sortedReactions.sort(([k1, v1], [k2, v2]) => {
      if (v2 - v1 !== 0) {
        return v2 - v1;
      }
      // Default to alpha order of keys if there's a tie
      return k1 < k2 ? -1 : 1;
    });
  }

  updatePageViews() {
    let views = this.model.get('pageViewCount');
    // We count views by making an API call to the server to say a page was viewed. At this point
    // in time, that hadn't happened yet, so we add one and report that to the viewer.
    if (_.isNumber(views)) {
      views += 1;
    }

    this.pageViews = StringHelpers.bigNumberFormatter(views);
  }

  getCurrentReaction() {
    const reactions = this.model.get('reactions') || {};
    return (reactions.currentUserReaction && reactions.currentUserReaction.type
      && reactions.currentUserReaction.type.toUpperCase()) || null;
  }

  scrollToCommentForm() {
    this.triggerMethod('scroll:comments');
  }

  persistTimelineData() {
    const {
      timelineId,
      timelineTab,
      searchPageState,
      preserveTimelineScrollPosition
    } = this.itemViewOptions;

    let searchQuery;
    if (searchPageState?.get('searchString') != null) {
      searchQuery = encodeURIComponent(searchPageState.get('searchString'));
    }

    // Don't persist the page data if you're not drilling in from the timeline
    if (preserveTimelineScrollPosition) {
      persistTimelinePageData({
        timelineId,
        timelineTab,
        searchQuery,
        userId: window.apps.auth.session.user.get('id')
      }, 'timelineReferenceItemIds');
    }
  }

  onClickCommentButton(e) {
    e.preventDefault(); // we need this, to prevent the click from bubbling up to the card
    e.stopPropagation();

    if (this.shouldShowCommentForm()) {
      this.scrollToCommentForm();
      return false;
    }

    // This mimics the same URL construction that we have in articleSearchResultControllerDefinitionFactory
    const pageId = this.model.get('pageId');

    let queryString = '';
    if (this.itemViewOptions && this.itemViewOptions.searchPageState != null) {
      queryString = this.itemViewOptions.searchPageState.get('searchString');
    }

    const isBrowsingTimeline = window.location.hash.includes('hub/timeline');
    const postUrl = `#hub/timeline/post/${ encodeURIComponent(pageId) }?query=${ encodeURIComponent(queryString) }&focus=form`
    const articleUrl = `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ encodeURIComponent(pageId) }?query=${ encodeURIComponent(queryString) }&focus=form`;
    const destination = isBrowsingTimeline && this.model.get('type') === 'post' ? postUrl : articleUrl;

    this.persistTimelineData();

    Backbone.history.navigate(destination, {
      trigger: true
    });

    return false;
  }

  onKeyDownReactionSummary(e) {
    if (defaultInteractionKeys.includes(e.keyCode) && this.options.showReactionsModal) {
      this.ignoreDefaultBehaviour(e);
      this.onClickReactionModal(e);
    }
  }

  onClickReactionModal() {
    const pageId = this.model.get('id');
    const isComment = this.model.get('type') === PageType.COMMENT;

    const reactionsDetails = new ReactionsDetailsModel({
      limit: 100,
      pageId,
      isComment
    });

    reactionsDetails.fetch().then(() => {
      const modalView = new CreatePostAccessibleModalView({
        id: 'modalview',
        className: 'modal reactions-details-modal'
      });

      const modalChildView = new ReactionsModal({ reactionsDetails });

      window.app.layout.presentModal(modalView, {
        closeClick: false,
        closeSelector: '.js-close'
      });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenToOnce(modalChildView, 'destroy', () => {
        window.app.layout.dismissModal();
      });
    });
  }
}

module.exports = ReactionsView;
