const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');
const $os = require('detectOS');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const { parseBreadcrumbsFromSearchPageState } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const TopicSearchCollection = require('@training/apps/search/TopicSearchCollection');
const ArticleSearchCollection = require('@training/apps/search/ArticleSearchCollection');
const PathSearchCollection = require('@training/apps/search/PathSearchCollection');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const CreateMenuDefinitionHelper = require('@training/apps/search/CreateMenuDefinitionHelper');
const SearchInputView = require('@training/apps/search/SearchInputView');
const SearchTabbedLayout = require('@training/apps/search/SearchTabbedLayout');
const SearchTabController = require('@training/apps/search/SearchTabController');
const { parseSearchFacets } = require('@training/apps/search/SearchUrlHelper');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const RecentlyUpdatedCommunityList = require('@training/apps/search/RecentlyUpdatedCommunityList');
const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const CommunityContentController = require('@training/apps/search/CommunityContentController');

const {
  getTabContentControllerDefinition,
  getNoQueryAllTabDefinition,
  getNoQueryViewDefinition,
  getNotAvailableViewDefinition,
  getArticleBrowseCommunitiesViewDefinition
} = require('@training/apps/search/SearchTabContentControllerDefinitionFactory');

require('@common/libs/behaviors/mutationObservable/MutationObservable');

const TimeLineCollection = require('@training/apps/timeline/TimelineCollection');

const { getTabConfigs } = require('@training/apps/search/CommunityTabConfigHelper');

const searchPageState = new Backbone.Model();

// Start helper functions for timelog reporting
const _stopSearchTimeLog = (view) => {
  if (view.timerId != null) {
    window.apps.base.timeLogController.stop(view.timerId);
  }
};

const _setSearchTimeLog = (view, searchPageStateObj) => {
  _stopSearchTimeLog(view);

  switch (searchPageStateObj.get('searchCategory')) {
    case SearchCategoryEnum.TOPICS:
      view.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.DiscoveryTopicsTab);
      break;

    case SearchCategoryEnum.ARTICLES:
      if (searchPageStateObj.get('subCategory') === SearchSubCategoryEnum.COMMUNITY) {
        const community = searchPageStateObj.get('community');
        if (community) {
          view.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.DiscoveryCommunity, parseInt(community.get('id'), 10));
        }
      } else {
        view.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.DiscoveryArticlesTab);
      }
      break;

    case SearchCategoryEnum.PATHS:
      view.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.DiscoveryPathsTab);
      break;

    case SearchCategoryEnum.ALL:
    default:
      view.timerId = window.apps.base.timeLogController.start(TimeLogConfig.PageView.Discovery);
  }
};
// End helper functions for timelog reporting

// Start tab helper functions
const _getTabOptions = (tabName, contentCollection) => {
  const isAll = (tabName === SearchCategoryEnum.ALL);

  const defaultTabOptions = {
    tabName,
    tabText: I18n.t(`selfDirected.search.categoryNames.${ tabName }`),
    showCount: !isAll
  };

  const additionalTabOptions = isAll ? { searchPageState } : { contentCollection };

  return Object.assign(defaultTabOptions, additionalTabOptions);
};

const _getTabContentController = (tabName, options = {}) => {
  let definition;

  // If there is a search, or the user is on the Articles tab drilling into a subview (needs review, etc.)
  if (options.searchString || ((tabName === SearchCategoryEnum.ARTICLES) && options.subCategory)) {
    definition = getTabContentControllerDefinition(tabName, {
      searchPageState,
      startTrainingFn: (tabName === SearchCategoryEnum.ARTICLES) ? undefined : options.startTrainingFn
    });

  // If we are showing the 'Not Available' view (e.g. if the user tried to view a detail page they don't have access to)
  } else if (options.showNotAvailable) {
    definition = getNotAvailableViewDefinition();

  // This is the All tab without a search query
  } else if (tabName === SearchCategoryEnum.ALL) {
    definition = getNoQueryAllTabDefinition(searchPageState, options.hasDZAndCommunitiesAccess);

  // If we're here, it is the 'no search' initial view of one of the remaining tabs (Articles, Topics or Paths). Topics and Paths have paywalls active
  } else {
    definition = getNoQueryViewDefinition(searchPageState, true);
  }

  return definition;
};
// End tab helper functions

// Redirection Helper function

/**
 * This function collects all the redirect logic into one place and should be checked before constructing any
 * searchpage. By doing it before constructing the search page, we can avoid visual errors like double-renders. This
 * should also help with performance too.
 *
 * @param {Community} selectedCommunity
 * @returns {Boolean} if a redirect has occurred
 */
const _determineIfRedirectionIsNeeded = (selectedCommunity) => {
  let redirectUrl = '';

  if (!selectedCommunity) {
    // if there's no valid selected community, flash an error and redirect.
    window.app.layout.flash.error(I18n.t('selfDirected.search.errors.communityNotFound'));
    redirectUrl = SearchUrlHelper.BASE_SEARCH_HASH;
  } else {
    const tabData = getTabConfigs(searchPageState, selectedCommunity);
    const tabNames = _.map(tabData.tabList, (t) => {
      return t.tab.tabName;
    });

    if (SearchUrlHelper.isHashMissingCommunityTab(selectedCommunity, Backbone.history.location.hash, tabNames)) {
      // when going from a list of communities to a particular community, we need to determine which tab to show and
      // redirect to that tab.
      searchPageState.set('communitySearchCategory', tabNames[0]);

      redirectUrl = SearchUrlHelper.getHashForCommunitySearch(searchPageState);
    }
  }

  if (redirectUrl) {
    Backbone.history.navigate(redirectUrl, {
      trigger: true,
      replace: true
    });
    return true;
  }

  return false;
};

// END: Redirection Helper function

// Start content region controllers
const _getContentRegionController = (options) => {
  if (options.folderType) {
    //no tabs on the View All pages for specific community types
    return getArticleBrowseCommunitiesViewDefinition(searchPageState);
  }

  const tabConfigs = [];
  const hasExtraTrainingEnabled = window.app.sessionController.getHubController().isExtraTrainingEnabled();

  if (!options.isDZGuest) {
    const allTabConfig = {
      tab: _getTabOptions(SearchCategoryEnum.ALL),
      tabContentController: _getTabContentController(SearchCategoryEnum.ALL, options)
    };

    tabConfigs.push(allTabConfig);
  }

  if (hasExtraTrainingEnabled) {
    const pathsTabConfig = {
      tab: _getTabOptions(SearchCategoryEnum.PATHS, options.pathResults),
      tabContentController: _getTabContentController(SearchCategoryEnum.PATHS, options)
    };

    tabConfigs.splice(1, 0, pathsTabConfig);

    const topicsTabConfig = {
      tab: _getTabOptions(SearchCategoryEnum.TOPICS, options.topicResults),
      tabContentController: _getTabContentController(SearchCategoryEnum.TOPICS, options)
    };

    tabConfigs.splice(1, 0, topicsTabConfig);
  }

  if (options.hasDZAndCommunitiesAccess || options.isDZGuest) {
    const articleTabConfig = {
      tab: _getTabOptions(SearchCategoryEnum.ARTICLES, options.articleResults),
      tabContentController: _getTabContentController(SearchCategoryEnum.ARTICLES, options)
    };

    tabConfigs.splice(1, 0, articleTabConfig);
  }

  return {
    ViewControllerClass: SearchTabController,
    viewDefinition: {
      ViewClass: SearchTabbedLayout,
      searchPageState
    },
    searchPageState,
    showLoneTab: !($os.mobile || $os.tablet || $os.isInMobileApp()) && options.searchString,
    initialTab: options.searchCategory,
    tabConfigs,
    delegateEvents: {
      'view:show:tab': (controller, view) => {
        _.defer(() => {
          _toggleSearchInputVisibility(searchPageState, view);
        });
      }
    }
  };
};
// End content region controllers

// It doesn't make sense to display the Search Input on the Communities Settings or Reports Pages
// This can be removed during the Communities Management work
const _toggleSearchInputVisibility = (pageState, view) => {
  const searchableTabs = [
    SearchCategoryEnum.ALL,
    SearchCategoryEnum.PATHS,
    SearchCategoryEnum.TOPICS
  ];
  const nonSearchablePages = [
    SearchSubCategoryEnum.ARTICLE_REPORTS,
    SearchSubCategoryEnum.TOP_PAGES,
    SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH,
    SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH
  ];
  const $searchInput = view.$el.parent().siblings('.search-bar-container');
  const subCategory = pageState.get('subCategory');

  if ($searchInput.length > 0 && _.contains(searchableTabs, pageState.get('searchCategory'))) {
    // Always show input when navigating away from articles tab
    $searchInput.removeClass('hidden');
  } else if ($searchInput.length > 0 && subCategory != null) {
    // Hide input if you're inside community settings or reports
    $searchInput.toggleClass('hidden', _.contains(nonSearchablePages, subCategory));
  }
};

const viewConfig = (options = {}) => {
  let {
    searchString,
    searchCategory,
    subCategory,
    startTrainingFn,
    showNotAvailable = false,
    communityId,
    pageNum = 1,
    tagList,
    communityList,
    userMetadata,
    community,
    tabName,
    folderType = null
  } = options;

  // NOTE: the router sends eplicit null values for empty parameters, so the default value initialization won't work
  if (searchString == null) {
    searchString = '';
  }

  // Using '__' in place of ' ' in URL so strip them
  searchString = searchString.replace(/__/g, ' ');

  try {
    searchCategory = SearchCategoryEnum.assertLegalValue(searchCategory);
  } catch (e) {
    searchCategory = SearchCategoryEnum.ALL;
  }

  const articleResults = new ArticleSearchCollection( null, { subCategory } );
  const topicResults = new TopicSearchCollection();
  const pathResults = new PathSearchCollection();
  const user = window.apps.auth.session.user;
  const propertyProvider = TenantPropertyProvider.get();
  const dzEnabled = propertyProvider.getProperty('discoveryZoneEnabled');
  const isDZGuest = user.isGuestOrSuperuser();

  const postsResults = new TimeLineCollection( null, { filter: 'posts' } );
  const communityResults = new TimeLineCollection();

  const hasDZAndCommunitiesAccess = dzEnabled && (app.sessionController.getHubController().hasSomeDiscoveryZoneCommunityAvailable()
    || isDZGuest);

  const recentlyUpdatedCommunitiesList = new RecentlyUpdatedCommunityList([], {
    comparator: () => {},
    extraParams: {
      limit: 12,
      communityType: 'FOLDER'
    }
  });

  // Ignore some subcategories so we don't call pageCommunities endpoint multiple times.
  const ignoredSubCategories = [
    SearchSubCategoryEnum.ARTICLE_REPORTS,
    SearchSubCategoryEnum.TOP_PAGES,
    SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH,
    SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH
  ];

  if (hasDZAndCommunitiesAccess && !_.contains(ignoredSubCategories, subCategory)) {

    if (community) {
      searchPageState.set('community', community);
    } else {
      searchPageState.unset('community');
    }
  }

  searchPageState.set({
    searchString,
    searchCategory,
    subCategory,
    searchesArray: [],
    communityList,
    userMetadata,
    showNotAvailable,
    pageNum: pageNum - 1,
    urlTags: parseSearchFacets(tagList),
    folderType
  });

  if (subCategory === SearchSubCategoryEnum.COMMUNITY) {
    searchPageState.set('results', {
      [SearchCategoryEnum.ALL]: communityResults,
      [SearchCategoryEnum.ARTICLES]: articleResults,
      [SearchCategoryEnum.POSTS]: postsResults
    });
    searchPageState.set('communitySearchCategory', tabName);
    searchPageState.unset('mostRecentCommunities');

    const hasRedirected = _determineIfRedirectionIsNeeded(community);

    if (hasRedirected) {
      return {};
    }

  } else {
    searchPageState.set({
      mostRecentCommunities: recentlyUpdatedCommunitiesList,
      results: {
        [SearchCategoryEnum.ARTICLES]: articleResults,
        [SearchCategoryEnum.TOPICS]: topicResults,
        [SearchCategoryEnum.PATHS]: pathResults
      }
    });
    searchPageState.unset('communitySearchCategory', { silent: true });
  }

  // Load any required breadcrumbs into the page state model
  parseBreadcrumbsFromSearchPageState(searchPageState);

  // Get the initial list of selected tags (if any) from url and store them in the page state model and the articleResults collection
  searchPageState.set('articleUrlTags', parseSearchFacets(tagList));

  const returnViewConfig = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      className: 'search',
      template: `
      <div class="ax-container">
        <div class="page-content">
          <h1 class="off-screen"><%- t('appNames.knowledge') %></h1>
          <div class="search-bar-container">
            <div class="ax-grid ax-grid--no-gutter">
              <div class="search-input-region ax-grid__col--m-12"></div>
              <div class="js-create-button-region-mobile create-page-button-container-common mobile-search-button-region ax-grid__col--auto-size <%- (tabType == SearchCategoryEnum.ARTICLES) ? '' : ' hidden' %>"></div>
            </div>
          </div>
          <div class="tabbed-content-wrapper"></div>
        </div>
      </div>
      `,
      templateHelpers: {
        tabType: searchPageState.get('searchCategory'),
        SearchCategoryEnum
      },
      regions: {
        inputRegion: '.search-input-region',
        contentRegion: '.tabbed-content-wrapper'
      }
    },
    regionControllers: {
      inputRegion: {
        viewDefinition: {
          ViewClass: SearchInputView,
          searchPageState: searchPageState
        }
      },
      contentRegion: () => {
        if (subCategory === SearchSubCategoryEnum.COMMUNITY) {
          return {
            ViewControllerClass: CommunityContentController,
            searchPageState,
            selectedCommunity: searchPageState.get('community'),
            tabName: tabName,
            showLoneTab: !($os.mobile || $os.tablet || $os.isInMobileApp()) && searchString
          };
        }
        return _getContentRegionController({
          isDZGuest,
          subCategory,
          searchString,
          searchCategory,
          showNotAvailable,
          pathResults,
          topicResults,
          articleResults,
          startTrainingFn,
          hasDZAndCommunitiesAccess,
          folderType
        });
      }
    },
    delegateEvents: {
      'view:inflate': () => {
        window.app.layout.setTitle(I18n.t('menu.apps.infoLink'));
        window.app.layout.resetLeftHeaderView();
      },
      'view:render': (controller, view) => {
        _setSearchTimeLog(view, searchPageState);
        view.listenTo(searchPageState, 'change:searchCategory', () => {
          _setSearchTimeLog(view, searchPageState);
          if (searchPageState.get('searchCategory') !== SearchCategoryEnum.ARTICLES) {
            view.$el.find('.js-create-button-region-mobile').addClass('hidden');
          } else {
            view.$el.find('.js-create-button-region-mobile').removeClass('hidden');
          }
        });

        app.layout.togglePageHeader(false);
        if (subCategory === SearchSubCategoryEnum.COMMUNITY || folderType) {
          app.layout.showHeaderBackButton();
        }

      },
      'view:before:destroy': (controller, view) => {
        _stopSearchTimeLog(view);
        app.layout.resetLeftHeaderView();
      }
    }
  };

  returnViewConfig.viewDefinition.regions.createButtonRegion = '.js-create-button-region-mobile';

  const metadata = community || userMetadata;

  if (metadata != null) {
    returnViewConfig.regionControllers.createButtonRegion = CreateMenuDefinitionHelper.getCreateMenuRegionController(
      communityId,
      metadata
    );
  }

  return returnViewConfig;
};

module.exports = viewConfig;

