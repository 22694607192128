import type User from '@common/data/types/User';
import {
  channelsApi,
  type PostCreateType,
  type PostDetailsType
} from '@training/apps/timeline/channels/api';

type ViewPostResponseType = {
  entity: {
    author: {
      timestamp: string;
      user: User;
    }
    id: number;
    isActive: boolean;
    tenantId: number;
  };

  status: string;
};

const postDetailsApi = channelsApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      postDetails: builder.query<PostDetailsType, number>({
        providesTags: ['post'],
        query: (id) => {
          return {
            apiEndpoint: `/pages/${ id }`
          };
        }
      }),
      editPost: builder.mutation<PostDetailsType, { postId: number, data: PostCreateType}>({
        invalidatesTags: ['post'],
        query: ({
          postId,
          data
        }) => {
          return {
            apiEndpoint: `/posts/${ postId }`,
            type: 'PUT',
            data
          };
        }
      }),
      createPost: builder.mutation<PostDetailsType, PostCreateType>({
        query: (data) => {
          return {
            apiEndpoint: `/posts`,
            type: 'POST',
            data
          };
        }
      }),
      deletePost: builder.mutation<number, { postId: number, invalidateTags?: boolean }>({
        invalidatesTags: (result, error, { invalidateTags = true }) => {
          return invalidateTags ? ['post'] : [];
        },
        query: (postObject) => {
          return {
            apiEndpoint: `/posts/${ postObject.postId }`,
            skipGlobalHandler: true,
            type: 'DELETE'
          };
        }
      }),
      lockPost: builder.mutation<number, number>({
        query: (postId) => {
          return {
            apiEndpoint: `/posts/${ postId }/lock`,
            type: 'PUT'
          };
        }
      }),
      unlockPost: builder.mutation<number, number>({
        query: (postId) => {
          return {
            apiEndpoint: `/posts/${ postId }/lock`,
            type: 'DELETE'
          };
        }
      }),
      viewPost: builder.mutation<ViewPostResponseType, number>({
        query: (postId) => {
          return {
            apiEndpoint: `/posts/${ postId }/views`,
            type: 'POST',
            data: {}
          };
        }
      })
    };
  }
});

export type PostDetailsApi = typeof postDetailsApi;

export const {
  usePostDetailsQuery,
  useLazyPostDetailsQuery,
  useEditPostMutation,
  useCreatePostMutation,
  useLockPostMutation,
  useUnlockPostMutation,
  useDeletePostMutation,
  useViewPostMutation
} = postDetailsApi;
