const {ItemView} = require('Marionette');
const Form = require('@common/components/forms/Form');

const PageType = require('@common/components/discover/enums/PageType').default;

class ReferenceMetadataView extends ItemView {
  getTemplate() {
    return `<form class="reference-form">
    <div class="metadata__input">
      <h3><%- t('general.url') %></h3>
      <input data-field="url" placeholder="<%- t('general.inputUrl') %>" class="parent-width"/>
    </div>
    <% if (isSystemManaged()) { %>
      <div class="metadata__input system-managed">
        <h3><%- t('discover.metadata.managedBy') %></h3>
        <input data-field="isExternallyManaged" type="checkbox"/><%- t('discover.metadata.systemManaged') %>
      </div>
    <% } %>
  </form>`;
  }

  templateHelpers() {
    return {
      isSystemManaged: () => {
        return this.model.has('isExternallyManaged');
      }
    };
  }

  onRender() {
    if (this.model.get('type') === PageType.REFERENCE) {
      this.referenceForm = new Form({
        el: this.$('.reference-form'),
        model: this.model
      });
    }
  }

  commit() {
    let errors = [];
    if (this.referenceForm != null) {
      errors = errors.concat(this.referenceForm.commit());
    }

    return errors;
  }
}

module.exports = ReferenceMetadataView;
