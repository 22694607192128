const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const CommunitiesManagementLayout = require('@training/apps/search/communitiesManagement/CommunitiesManagementLayout');
const CommunitiesManagementTabController = require('@training/apps/search/communitiesManagement/CommunitiesManagementTabController');

class CommunitiesManagementController extends LayoutController {
  initialize(options = {}) {
    ({
      initialTab: this.initialTab,
      pageNum: this.pageNum,
      filterId: this.filterId,
      isChannels: this.isChannels = false
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: CommunitiesManagementLayout,
      isChannels: this.isChannels
    };
  }

  regionControllers() {
    return {
      tabsRegion: {
        ViewControllerClass: CommunitiesManagementTabController,
        showLoneTab: true,
        initialTab: this.initialTab,
        pageNum: this.pageNum,
        filterId: this.filterId,
        isChannels: this.isChannels
      }
    };
  }
}

module.exports = CommunitiesManagementController;
