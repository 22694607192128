import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type TimelinePageType = EnumType & {
  TIMELINE_POST_DETAILS: string,
  CHANNEL_DETAILS: string,
  CHANNEL_MANAGEMENT: string,
  CHANNEL_SETTINGS: string
};

const TimelinePageEnum = Enum.fromStringArray([
  'TIMELINE_POST_DETAILS',
  'CHANNEL_DETAILS',
  'CHANNEL_MANAGEMENT',
  'CHANNEL_SETTINGS'
]) as TimelinePageType;

export default TimelinePageEnum;
