import { createLazyInjectedApi } from '@common/configs/redux';

export const RESULTS_PER_PAGE = 12;

export const timelineApi = createLazyInjectedApi({
  reducerPath: 'timelineApi',
  keepUnusedDataFor: 5 * 60,
  endpoints: () => {
    return {};
  }
});

export type TimelineApiService = typeof timelineApi;
