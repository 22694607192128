const _ = require('underscore');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const { canPrint } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const { DocumentMedia } = require('@common/data/models/media/DocumentMedia');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');
const AttachmentItemView = require('@common/components/media/AttachmentItemView');
const PageShareItemView = require('@common/components/discover/views/PageShareItemView');
const PageType = require('@common/components/discover/enums/PageType').default;
const { onDeleteLanguageVersion} = require('@training/apps/articles/PageDeleteHelper');
class ContributorListItemView extends Marionette.ItemView {
  getTemplate() {
    return `
      <div class="clearfix page-preview__meta-content">
        <div aria-hidden="true" class="page-preview-meta-content__avatar" data-contributor-id="<%- author.user.id %>"></div>
        <div class="page-preview__meta-content-contributors">
          <div class="meta-content-contributors__author-text"><%- author.user.salutationName %></div>
          <div class="meta-content-contributors__author-status"><%= getStatusText() %></div>
          <div class="comment-value"><%- editComment %></div>
        </div>
      </div>
      `;
  }

  templateHelpers() {
    return {
      getStatusText() {
        const status = this.silentEdit ? 'approved' : 'updated';
        const statusString = I18n.t(`discover.metadata.${ status }`);
        const actionDate = dateHelpers.convertDateFormatToDefaultDate(this.author.timestamp);
        return `${ statusString } &bull; ${ actionDate }`;
      }
    };
  }
}

class ContributorListView extends Marionette.CollectionView {
  getChildView() {
    return ContributorListItemView;
  }

  onRender() {
    // Updates the profile pictures
    for (const contributor of this.collection.toJSON()) {
      const $profileElement = this.$(`.page-preview-meta-content__avatar[data-contributor-id='${ contributor.author.user.id }']`);
      this._getProfileIcon(contributor.author.user, $profileElement);
    }
  }

  _getProfileIcon(user, $profileElement) {
    return new ProfileIconHandler({
      user,
      profileElement: $profileElement
    });
  }
}

class MetadataView extends Marionette.LayoutView {
  className() {
    return 'knowledge-item-metadata-view parent-height full-width';
  }

  getTemplate() {
    return require('@common/components/discover/views/MetadataViewTemplate.html');
  }

  ui() {
    return {
      attachments: '.metadata__attachments',
      homeCommunity: '.metadata-home-community',
      sharedCommunities: '.metadata-shared-communities',
      publishDate: '.metadata-item--publish-date',
      shareLink: '.metadata-share-link',
      contributorList: '.contributor-list',
      reportContainer: '.metadata-item.report-page',
      reportButton: '.js-button-report',
      deleteButtonContainer: '.delete__action',
      deleteButton: '.js-button-delete',
      confirmButton: '.js-button-delete-confirm',
      editButton: '.js-button-edit',
      cancelButton: '.js-button-delete-cancel',
      printButton: '.js-button-print',
      showHistory: '#showhistory',
      insightsButton: '.js-button-insights'
    };
  }

  events() {
    return {
      'click @ui.deleteButton': 'onDeleteButtonClicked',
      'click @ui.cancelButton': 'onCancelButtonClicked'
    };
  }

  triggers() {
    return {
      'click @ui.shareLink': 'page:share',
      'click @ui.showHistory': 'page:history',
      'click @ui.reportButton': 'page:report',
      'click @ui.printButton': 'page:print',
      'click @ui.confirmButton': 'page:delete',
      'click @ui.editButton': 'page:edit',
      'click @ui.insightsButton': 'page:insights'
    };
  }

  initialize(options = {}) {
    ({
      authChannel: this.authChannel,
      glChannel: this.glChannel,
      reportEnabled: this.reportEnabled,
      editList: this.editList,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList,
      authorAndContributorInfoEnabled: this.authorAndContributorInfoEnabled,
      enableDownload: this.enableDownload = true
    } = options);

    this.listenTo(this.editList, 'sync', this.renderEditList);
    this.listenTo(this.pageSharedCommunitiesList, 'sync', this.renderPageSharedCommunitiesList);
    this.listenTo(this.model, 'change:community', this.refreshCommunitiesList);
  }

  templateHelpers() {
    return {
      getAuthor() {
        return this.author;
      },
      getAuthorDate() {
        return dateHelpers.convertDateFormatToDefaultDate(this.author.timestamp);
      },
      tagList() {
        return _.map(this.tags, (tag) => {
          return tag.tag;
        }).join(', ');
      },
      getLanguage(langCode) {
        return I18n.languageNameNative(langCode);
      },
      getDocumentCount: () => {
        return this.model.currentVersion.richContent.getDocumentCount();
      },
      canSharePage: () => {
        return this.model.canShare();
      },
      canReportPage: () => {
        return this.model.canReport() && this.reportEnabled;
      },
      canDeletePage: () => {
        return this.model.canDelete();
      },
      canEditPage: () => {
        return this.model.canEdit();
      },
      canPublishPage: () => {
        return this.model.canPublish();
      },
      getReportText() {
        return I18n.t(`discover.pageTypes.${ this.type }.actionText.report`);
      },
      getDeleteText() {
        return I18n.t(`discover.pageTypes.${ this.type }.actionText.delete.action`);
      },
      getConfirmText() {
        return I18n.t(`discover.pageTypes.${ this.type }.actionText.delete.confirm`);
      },
      getPrintText() {
        return I18n.t(`discover.pageTypes.${ this.type }.actionText.print`);
      },
      canPrint: canPrint(),
      isReference: () => {
        return this.model.get('type') === PageType.REFERENCE;
      },
      isTraining: () => {
        return this.model.get('type') === PageType.TRAINING;
      },
      isAuthorAndContributorInfoDisabled: () => {
        return this.model.isViewOnly() && !this.authorAndContributorInfoEnabled;
      },
      getPublishDate: () => {
        let formattedDate = '';
        const timeStamp = (this.model.get('currentVersion') || {}).publishTimestamp;
        if (timeStamp) {
          formattedDate = dateHelpers.convertDateFormatLongDateTimeWithStrings(timeStamp);
        }

        return formattedDate;
      },
      getPublishTimeZone: () => {
        return (this.model.get('currentVersion') || {}).publishTimeZone;
      }
    };
  }

  onRender() {
    // need to render the attachments
    // go through list of attachments
    const documentInfoList = this.model.currentVersion.richContent.getDocumentInfoList();
    _.each(documentInfoList, (documentInfo) => {
      // create object to send to template
      const documentMedia = new DocumentMedia(documentInfo);
      const attachmentItemView = new AttachmentItemView({
        model: documentMedia,
        enableDownload: this.enableDownload
      });

      // TODO: clean up how this rendering is done, should show a collection view in a region
      // then this event listener can be removed
      this.listenTo(attachmentItemView, 'click:attachment', this.onViewPdfClicked);

      const html = attachmentItemView.render().$el;
      this.ui.attachments.append(html);
    });

    // Show future publish info
    this._renderPublishDate();

    // Update the profile for the original author
    const _author = this.model.get('author').user;
    const $profileElement = this.$(`.page-preview-meta-content__avatar[data-contributor-id='${ _author.id }']`);
    this._getProfileIcon(_author, $profileElement);

    // If the Communities list needs an initial render, call it here.
    this.renderPageSharedCommunitiesList();

    // Hide the contributors block if the initial list is empty (and you would be printing it otherwise)
    if (!(this.model.isViewOnly() || !this.authorAndContributorInfoEnabled) && !(this.editList.length >= 0)) {
      this.ui.contributorList.parent().toggleClass('hidden', true);
    }
  }

  _getProfileIcon(user, $profileElement) {
    return new ProfileIconHandler({
      user,
      profileElement: $profileElement
    });
  }

  _renderPublishDate() {
    const currentVersion = this.model.get('currentVersion');
    if (currentVersion && currentVersion.publishTimestamp && this.model.get('status') === 'review') {
      this.ui.publishDate.removeClass('hidden');
    }
  }

  renderEditList() {
    if (this.editList.length >= 0) {
      this.ui.contributorList.parent().toggleClass('hidden', false);

      if (this.contributorListView == null) {
        this.contributorListView = new ContributorListView({
          el: this.ui.contributorList,
          collection: this.editList
        });
      }

      this.contributorListView.render();
    } else {
      this.ui.contributorList.parent().toggleClass('hidden', true);
    }
  }

  renderPageSharedCommunitiesList() {
    this.renderInitialHomeCommunity = false;
    const homeCommunity = this.pageSharedCommunitiesList.getHomeCommunity();

    // Note that PageShareItemView here is actually the Home Community view
    if (homeCommunity && !_.isEmpty(homeCommunity.attributes) && this.pageShareItemView == null) {
      this.$('.metadata-item--home-community').removeClass('hidden');
      this.pageShareItemView = new PageShareItemView({
        authChannel: this.authChannel,
        glChannel: this.glChannel,
        model: homeCommunity,
        el: this.ui.homeCommunity
      });
      this.pageShareItemView.render();
    }

    if (this.pageSharedCommunitiesList.length || this.model.canShare()) {
      this.$('.metadata-item--shared-communities').removeClass('hidden');
    } else {
      this.$('.metadata-item--shared-communities').addClass('hidden');
    }

    if (!this.pageShareListView) {
      this.pageShareListView = new Marionette.CollectionView({
        childView: PageShareItemView,
        childViewOptions: {
          className: 'ax-grid',
          authChannel: this.authChannel,
          glChannel: this.glChannel
        },
        collection: this.pageSharedCommunitiesList,
        el: this.ui.sharedCommunities
      });
      this.pageShareListView.render();
    }
  }

  refreshCommunitiesList() {
    if (this.model.isNew() || !this.model.get('id')) {
      return;
    }

    // Refresh the communities list to reflect the new primary community
    // and clear any secondary communities after a move
    this.pageSharedCommunitiesList.reset();

    // Fetch updated communities list from the server
    // Update the pageId property to ensure the correct endpoint is used
    this.pageSharedCommunitiesList.pageId = this.model.get('id');

    // Use fetch with the properly constructed URL
    this.pageSharedCommunitiesList.fetch({
      reset: true
    });
  }

  onAttach() {
    // add ellipses for filename
    this.$('.attachment-name').dotdotdot({
      height: 40
    });
  }

  onDeleteButtonClicked() {
    onDeleteLanguageVersion(this.model, this.languageData);
  }

  onViewPdfClicked(attachmentItemView) {
    // user wants to view the pdf, so let's show it
    // get media id
    const mediaId = attachmentItemView.model.get('id');

    // go to that view
    this.trigger('viewDocumentClicked', mediaId);
  }

  onEditClicked() {
    this.triggerMethod('click:editArticle');
  }

}

module.exports = MetadataView;
